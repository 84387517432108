import { EllipsisOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Grid,
  Layout,
  Modal,
  Row,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
  notification
} from "antd";
import {
  Calendar,
  CardCompanyBalance,
  DatePicker,
  FormatDate,
  FormatMoney,
  Header
} from "components";
import { getAuthorization } from "config/service/auth-service";
import moment from "moment";
import React, { Fragment, useMemo } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getHolidays } from "services/banking-service";
import { genericCheckout } from "services/solicitation-service";

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer,
  balance: state.balanceReducer
});

const SolicitationRevisionScreen = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const screens = Grid.useBreakpoint();
  const location = useLocation();
  const {
    compact = false,
    onAfterSubmit,
    onSolicitationLines,
    balance,
    login
  } = props;
  const {
    data = {},
    selectedAdvanceIds,
    additionalDetails
  } = location.state || props;
  const [loading, setLoading] = React.useState(false);
  const [disabledDate, setDisabledDate] = React.useState(false);
  const [isDiscount, setIsDiscount] = React.useState(!compact);
  const [dates, setDates] = React.useState({
    payment: moment(),
    expectation: moment().add(3, "days"),
    approval: moment().add(3, "days")
  });
  const [details, setDetails] = React.useState({
    visible: false,
    title: "",
    data: null
  });
  const [year] = React.useState(moment().year());
  const [holidays, setHolidays] = React.useState([]);
  const [selectedBenefit] = React.useState(
    data.type === "credit" && data.body.data.benefit
      ? data.body.data.benefit
      : (data.type === "charge" || data.type === "card") &&
          data.body.solicitation.benefit
        ? data.body.solicitation.benefit
        : null
  );
  const [incentive, setIncentive] = React.useState(false);
  const [finalize, setFinalize] = React.useState(false);
  const discountValue = useMemo(() => {
    const [discountData] = data.checkout.filter((x) => x.label === "Desconto");
    return discountData ? discountData.value : 0;
  }, [data]);

  const hasDiscount = () => {
    return discountValue > 0;
  };
  const onSave = async () => {
    try {
      if (data.type === "charge") {
        data.body.solicitation.paymentAt = dates.payment.toDate();
        data.body.solicitation.approvalAt = dates.approval.format("yyyy-MM-DD");
        data.body.solicitation.discount = isDiscount;
        if (additionalDetails) {
          data.body.solicitation.additionalDetails = additionalDetails;
        }
      } else {
        data.body.data.paymentAt = dates.payment.toDate();
        data.body.data.approvalAt = dates.approval.format("yyyy-MM-DD");
        if (data.body.solicitation)
          data.body.solicitation.discount = isDiscount;
      }
      const lines = onSolicitationLines
        ? await onSolicitationLines()
        : undefined;
      if (lines && lines.length === 0) {
        return;
      } else if (lines && lines.length > 0) {
        data.body.solicitation.lines = lines;
      }
      if (selectedAdvanceIds) {
        data.body.solicitation.selectedAdvanceIds = selectedAdvanceIds;
      }
      const res = await genericCheckout(
        data.request,
        data.body,
        data.successMessage
      );
      if (res) {
        if (!onAfterSubmit) {
          if (data.type === "refund" || data.type === "charge") {
            navigate(`/solicitation/${res}/report`);
          } else {
            navigate("/solicitation");
          }
        } else {
          onAfterSubmit(true, res);
        }
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setLoading(false);
    }
  };

  const onContinue = async () => {
    setLoading(true);
    setFinalize(true);
  };

  const isHolidayDay = (date) => {
    return (
      holidays.filter((d) => d.date === date.format("YYYY-MM-DD")).length > 0
    );
  };

  const getEndDate = (init, days) => {
    let date = moment(init); // use a clone

    //pedidos no sabado é como se fosse na segunda
    if (date.isoWeekday() === 6) {
      date = date.add(2, "days");
    }
    //pedidos no feriado de sexta é como se fosse na segunda
    else if (date.isoWeekday() === 5 && isHolidayDay(date)) {
      date = date.add(3, "days");
    }
    //pedidos no domingo e feriados //pedidos no feriado de sexta é como se fosse na segunda
    else if (date.isoWeekday() === 7 || isHolidayDay(date)) {
      date = date.add(1, "days");
    }

    while (days > 0) {
      date = date.add(1, "days");
      // desconsidera finais de semana e feriados
      if (
        date.isoWeekday() !== 6 &&
        date.isoWeekday() !== 7 &&
        !isHolidayDay(date)
      ) {
        days -= 1;
      }
    }
    return date;
  };

  const calculateExpectationDates = (payment) => {
    let expectation =
      data.checkout.filter((x) => x.total).length > 0 &&
      data.checkout.filter(
        (x) =>
          x.total &&
          ((data.type !== "charge" &&
            data.type !== "card" &&
            data.type !== "refund") ||
            x.discount === isDiscount)
      )[0].hasPayment
        ? getEndDate(payment, 3)
        : moment();
    setDates({
      expectation: expectation,
      payment: payment,
      approval: moment(expectation)
    });
  };

  const dateCellRender = (e) => {
    const list = [];
    const value = e.format("YYYY-MM-DD");
    const holiday = holidays.filter((h) => h.date === value);

    if (value === moment().format("YYYY-MM-DD")) {
      list.push(<Tag color={"default"}>HOJE</Tag>);
    }
    if (value === dates.payment.format("YYYY-MM-DD")) {
      list.push(<Tag color={"processing"}>PAGAMENTO</Tag>);
    }
    if (value === dates.approval.format("YYYY-MM-DD")) {
      list.push(<Tag color={"success"}>APROVAÇÃO</Tag>);
    }
    if (holiday.length > 0) {
      for (let h of holiday) {
        list.push(
          <Tag className="holiday" color={"error"}>
            {h.name}
          </Tag>
        );
      }
    }
    return list.map((x) => (
      <div>{x}</div>
      // const listData = getListData(value);
    ));
    // return (
    //   <ul className="events">
    //     {listData.map(item => (
    //       <li key={item.content}>
    //         <Badge status={item.type} text={item.content} />
    //       </li>
    //     ))}
    //   </ul>
    // );
  };

  const createLineCheckout = (x) => {
    const switchDiscount = !x.total && x.discount;
    if (switchDiscount && !hasDiscount()) return;
    return (
      <Row>
        {switchDiscount && (
          <Col span={4} style={{ textAlign: "center" }}>
            <Switch
              size="small"
              defaultChecked
              value={isDiscount}
              onChange={(value) => setIsDiscount(value)}
            />
          </Col>
        )}
        <Col span={6} offset={switchDiscount ? 0 : 4}>
          <Text type="secondary">{x.label}</Text>
        </Col>
        {x.quantity !== undefined && (
          <Col span={6} offset={2} style={{ textAlign: "right" }}>
            <Text strong={true}>{x.quantity}</Text>
          </Col>
        )}
        {x.value !== undefined && (
          <Col span={6} offset={2} style={{ textAlign: "right" }}>
            <Text strong={true}>
              <FormatMoney value={x.value} />
            </Text>
          </Col>
        )}
        {x.valueName !== undefined && (
          <Col span={6} offset={2} style={{ textAlign: "right" }}>
            <Text strong={true}>{x.valueName}</Text>
          </Col>
        )}
        {x.details && (
          <Col span={4} style={{ textAlign: "right" }}>
            <Tooltip title="Detalhes">
              <Button
                size="small"
                type="primary"
                shape="circle"
                icon={<EllipsisOutlined />}
                onClick={() => {
                  setDetails({ open: true, data: x.details, visible: true });
                }}
              />
            </Tooltip>
          </Col>
        )}
      </Row>
    );
  };

  const getHoliday = async () => {
    const holidays = await getHolidays(year);
    setHolidays(holidays);
  };

  const init = () => {
    calculateExpectationDates(moment());
    if (data.type === "credit" || data.type === "refund") {
      setIsDiscount(false);
    }
    if (data.type === "credit" && data.body.data.benefit) {
      if (data.body.data.benefit < 0) {
        setIncentive(true);
      }
    }
    if (data.type === "charge" && data.body.solicitation.benefit) {
      if (data.body.solicitation.benefit < 0) {
        setIncentive(true);
      }
    }
  };

  const load = async () => {
    setLoading(true);
    setDisabledDate(data.type === "credit" ? true : false);
    await getHoliday();
    setLoading(false);
  };

  React.useEffect(() => {
    load();
    init();
  }, []);

  React.useEffect(() => {
    init();
  }, [holidays]);

  React.useEffect(() => {
    if (finalize) {
      onSave();
      setFinalize(false);
    }
  }, [finalize]);

  React.useEffect(() => {
    calculateExpectationDates(moment());
  }, [isDiscount]);

  React.useImperativeHandle(ref, () => {
    return {
      submit() {
        onContinue();
      }
    };
  });

  return (
    <Layout className="alymente-layout">
      {!compact && (
        <Header title="Revisão">
          <Row gutter={12}>
            <Col xl={6} lg={8} md={12}>
              <CardCompanyBalance
                benefit={selectedBenefit}
                useBenefit={login.company.benefitAccount ? true : false}
              />
            </Col>
            <Col xl={6} lg={8} md={12}>
              <CardCompanyBalance
                type={"discount"}
                benefit={selectedBenefit}
                useBenefit={login.company.benefitAccount ? true : false}
              />
            </Col>
            <Col xl={6} lg={8} md={12}>
              <CardCompanyBalance type={"charge"} />
            </Col>
          </Row>
        </Header>
      )}
      <Content>
        <Space direction="vertical" size="middle">
          <Row gutter={[12, 16]} justify="center" align="stretch">
            <Col xl={12} lg={24}>
              <Card title="Revisão do Pedido">
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex" }}
                >
                  {data.checkout
                    .filter((x) => !x.total)
                    .map(createLineCheckout)}
                  <Divider />
                  {data.checkout
                    .filter((x) => x.total && x.discount === isDiscount)
                    .map(createLineCheckout)}
                </Space>
              </Card>
            </Col>
            <Col xl={12} lg={24}>
              <Card
                title="Revisão de Datas"
                style={{ height: screens.xl ? "100%" : "auto" }}
              >
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex" }}
                >
                  {data.checkout.filter((x) => x.total).length > 0 &&
                    data.checkout.filter(
                      (x) =>
                        x.total &&
                        ((data.type !== "charge" &&
                          data.type !== "card" &&
                          data.type !== "refund") ||
                          x.discount === isDiscount)
                    )[0].hasPayment && (
                      <Fragment>
                        <Row align="middle">
                          <Col xxl={8} xl={10} lg={12}>
                            <Text type="secondary">Planejo pagar no dia</Text>
                          </Col>
                          <Col xxl={8} xl={10} lg={12}>
                            <DatePicker
                              format={"DD/MM/YYYY"}
                              value={dates.payment}
                              clearIcon={false}
                              onChange={(value) =>
                                calculateExpectationDates(value)
                              }
                            />
                          </Col>
                        </Row>
                        <Row align="middle">
                          <Col xxl={8} xl={10} lg={12}>
                            <Text type="secondary">
                              Expectativa de Aprovação
                            </Text>
                          </Col>
                          <Col
                            xxl={8}
                            xl={10}
                            lg={12}
                            style={{ textAlign: "right" }}
                          >
                            <Text strong={true}>
                              <FormatDate value={dates.expectation} />
                            </Text>
                          </Col>
                        </Row>
                      </Fragment>
                    )}
                  <Row align="middle">
                    <Col xxl={8} xl={10} lg={12}>
                      <Text type="secondary">Apenas aprovar no dia</Text>
                    </Col>
                    <Col xxl={8} xl={10} lg={12}>
                      <DatePicker
                        format={"DD/MM/YYYY"}
                        value={dates.approval}
                        clearIcon={false}
                        onChange={(value) =>
                          setDates((prev) => ({ ...prev, approval: value }))
                        }
                        disabled={disabledDate}
                      />
                    </Col>
                  </Row>
                </Space>
              </Card>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xl={24}>
              <Card>
                <Calendar
                  defaultValue={null}
                  dateCellRender={dateCellRender}
                  disabledDate={(e) => {
                    const value = e.format("YYYY-MM-DD");
                    const holiday = holidays.filter((h) => h.date === value);

                    if (
                      holiday.length > 0 ||
                      e.isoWeekday() === 6 ||
                      e.isoWeekday() === 7
                    ) {
                      return true;
                    }
                    return false;
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Space>
      </Content>
      {!compact && (
        <Footer>
          <Button
            disabled={loading || finalize}
            loading={loading}
            className="btn-save"
            type="primary"
            icon={<SaveOutlined />}
            onClick={onContinue}
          >
            Finalizar
          </Button>
        </Footer>
      )}
      <Modal
        width={1200}
        title={
          <React.Fragment>
            <Typography>{details.data ? details.data.title : ""}</Typography>
            <Typography.Text type="secondary">
              {details.data ? details.data.subtitle : ""}
            </Typography.Text>
          </React.Fragment>
        }
        open={details.visible}
        onCancel={() => {
          setDetails({ visible: false });
        }}
        footer={[
          <Button
            type="primary"
            key="back"
            onClick={() => {
              setDetails({ visible: false });
            }}
          >
            Ok
          </Button>
        ]}
      >
        <Table
          bordered
          scroll={{ y: 450 }}
          pagination={false}
          dataSource={details.data ? details.data.data : []}
          columns={
            details.data
              ? details.data.columns.map((x) => ({
                  ...x,
                  ...(x.details
                    ? {
                        render: (value) =>
                          x.details.type === "money" ? (
                            <FormatMoney value={value} />
                          ) : (
                            <FormatDate
                              value={value}
                              format={x.details.format}
                            />
                          )
                      }
                    : {})
                }))
              : []
          }
        />
      </Modal>
    </Layout>
  );
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  SolicitationRevisionScreen
);
