import React from "react";
import { connect } from "react-redux";
import { Layout, Button, Row, Col, Table, Switch } from 'antd';
import { Header, ColumnEmployee, TableSearch, AutoCompleteCompany } from "components";
import { SaveOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { saveCardManager } from "services/user-service";
import { getAllEmployee } from 'services/employee-service';

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const { Footer, Content } = Layout;

const DataFetch = {
    managers: [],
    users: [],
    loading: false
}

const UserCardManagerScreen = (props) => {
    const { login } = props;
    const [company, setCompany] = React.useState(login.company.id);
    const [data, setData] = React.useState(DataFetch);
    const [selectedRowKeysInsert, setSelectedRowKeysInsert] = React.useState([]);
    const [selectedRowKeysRemove, setSelectedRowKeysRemove] = React.useState([]);

    const load = async () => {
        setData((x) => ({ ...x, loading: true }));
        const users = await getAllEmployee(company)
        setData({
            users: users,
            managers: users.filter(x => x.cardManager),
            loading: false
        });
    }

    React.useEffect(() => {
        load();
    }, [company])

    const insertUser = (users) => {
        const usersInsert = data.users.filter(e => users.includes(e.id)).map(x => ({ ...x, cardManager: true }))
        setData((prev) => ({ ...prev, managers: [...prev.managers, ...usersInsert] }));
        setSelectedRowKeysInsert([])
        setSelectedRowKeysRemove([])
    }

    const removeUser = (managers) => {
        setData((prev) => ({ ...prev, managers: prev.managers.filter(e => !managers.includes(e.id)) }));
        setSelectedRowKeysInsert([])
        setSelectedRowKeysRemove([])
    }

    const save = async () => {
        setData((x) => ({ ...x, loading: true }));
        await saveCardManager(company, data.managers);
        load();
    }

    const updateManagerBranch = (managerBranch, row) => {
        var managersAux = data.managers
        managersAux.filter(e => e.id == row.id)[0].cardManagerBranch = managerBranch
        setData((prev) => ({ ...prev, managers: managersAux }));
    }

    return <Layout className="alymente-layout">
        <Header title={"Gerentes de Cartão"} middle={
            login.company.master ? <AutoCompleteCompany value={company} onChange={setCompany} /> : undefined
        } />
        <Content>
            <Row gutter={16}>
                <Col lg={12}>
                    <Table rowKey={"id"} scroll={{ y: 700 }} dataSource={data.users} loading={data.loading} pagination={false} bordered
                        rowSelection={{
                            selectedRowKeys: selectedRowKeysInsert,
                            onChange: setSelectedRowKeysInsert,
                            getCheckboxProps: (d) => ({
                                disabled: data.managers.filter(e => e.id === d.id).length > 0
                            })
                        }}
                        title={() =>
                            <div style={{ display: 'flex' }}>
                                {
                                    selectedRowKeysInsert.length > 0 &&
                                    <Button size="small" type={"link"} onClick={() => insertUser(selectedRowKeysInsert)}>Adicionar Selecionados</Button>
                                }
                                <div style={{ flex: 1 }}>
                                    <TableSearch data={data.users} filter={["name", "email", "cpf"]}
                                        onSearch={(e) => setData((prev) => ({ ...prev, users: e }))}
                                    />
                                </div>
                            </div>
                        } >
                        <Table.Column title="Todos os colaboradores" dataIndex="name" key="name"
                            render={(value, row) => <ColumnEmployee name={row.name} email={row.email} cpf={row.cpf} />} />
                        <Table.Column width={80} align="center" title="Ações" dataIndex="id" key="id"
                            render={(value, row) =>
                                <Button
                                    type="primary"
                                    shape="circle"
                                    disabled={data.managers.find(manager => manager.id === value)}
                                    icon={<PlusOutlined />} onClick={() => insertUser([value])}></Button>} />
                    </Table>
                </Col>
                <Col lg={12}>
                    <Table rowKey={"id"} scroll={{ y: 700 }} dataSource={data.managers} loading={data.loading} pagination={false} bordered
                        rowSelection={{
                            selectedRowKeys: selectedRowKeysRemove,
                            onChange: setSelectedRowKeysRemove
                        }}
                        title={() =>
                            <div style={{ display: 'flex' }}>
                                {
                                    selectedRowKeysRemove.length > 0 &&
                                    <Button size="small" type={"link"} onClick={() => removeUser(selectedRowKeysRemove)}>Remover Selecionados</Button>
                                }
                                <div style={{ flex: 1 }}>
                                    <TableSearch data={data.managers} filter={["name", "email", "cpf"]}
                                        onSearch={(e) => {
                                            setData((prev) => ({ ...prev, managers: e }))
                                        }}
                                    />
                                </div>
                            </div>
                        }>
                        <Table.Column title={"Gerente de Cartões"} dataIndex="name" key="name" render={(value, row) => <ColumnEmployee name={row.name} email={row.email} cpf={row.cpf} />} />
                        <Table.Column width={140} align="center" title="Gerencia filiais?" dataIndex="cardManagerBranch" key="cardManagerBranch" render={(value, record, index) => <Switch checked={value} onChange={(x) => updateManagerBranch(x, record)} /> } />
                        <Table.Column width={80} align="center" title="Ações" dataIndex="id" key="id" render={(value, row) => <Button type="primary" shape="circle" icon={<MinusOutlined />} onClick={() => removeUser([value])}></Button>} />
                    </Table>
                </Col>
            </Row>
        </Content>
        <Footer>
            <Button disabled={data.loading} loading={data.loading} className="btn-save" type="primary" icon={<SaveOutlined />} onClick={save}>Salvar</Button>
        </Footer>
    </Layout>
}

export default connect(mapStateToProps, {})(UserCardManagerScreen);