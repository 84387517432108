import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as balanceAction from "actions/balance-action";
import { formatMoney } from "utils";
import { CardInfo } from "components/card/card-info";
import PropTypes from "prop-types";
import { Button, Modal, Select, Col } from "antd";
import { translate } from "config/language";
import SubBalance from "components/subbalance/sub-balance";
import BalanceCard from "./balance-card";

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer,
  balance: state.balanceReducer,
  config: state.configReducer
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...balanceAction
    },
    dispatch
  );

const _CardCompanyBalance = (props) => {
  const {
    balance,
    login,
    config,
    getCompanyBalanceAction,
    type,
    benefit,
    useBenefit
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBenefit, setSelectedBenefit] = React.useState(null);

  if (useBenefit || benefit) {
    const [benefitData] = balance.company.subBalances.filter(
      (b) => b.benefit === benefit
    );
    var benefitTitle = benefitData.nameBenefit;
    var benefitName = benefitTitle.split(" ")[1];
  }
  const load = async () => {
    if (login.company.master) {
      for (let system of config.system) {
        await getCompanyBalanceAction(system.code);
      }
    } else {
      await getCompanyBalanceAction();
    }
  };
  React.useEffect(() => {
    load();
  }, [login, config]);
  React.useEffect(() => {
    setSelectedBenefit(benefit);
  }, [benefit]);
  if (type === "balance") {
    return (
      <div>
        {benefit || useBenefit ? (
          <>
            <BalanceCard
              loading={balance.isProcessing}
              title={benefitTitle}
              value={formatMoney(
                balance.company.subBalances.filter(
                  (b) => b.benefit === benefit
                )[0].balance
              )}
            />
          </>
        ) : login.company.master ? (
          <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
            {balance.balances.map((b) => (
              <BalanceCard
                loading={balance.isProcessing}
                title={
                  b.subBalances.length === 1
                    ? `${b.system.toUpperCase()} - ${b.subBalances[0].nameBenefit}`
                    : `${translate("balance")} da Empresa`
                }
                value={formatMoney(b.balance)}
              />
            ))}
          </div>
        ) : (
          <BalanceCard
            loading={balance.isProcessing}
            title={
              balance.company.subBalances.length === 1
                ? balance.company.subBalances[0].nameBenefit
                : `${translate("balance")} da Empresa`
            }
            value={formatMoney(balance.company.balance)}
          />
        )}
      </div>
    );
  } else if (type === "discount") {
    const title = "Desconto Disponível";
    return (
      <CardInfo
        loading={balance.isProcessing}
        title={useBenefit || benefit ? `${title} (${benefitName})` : title}
        value={formatMoney(
          benefit || useBenefit
            ? balance.company.subBalances.filter(
                (b) => b.benefit === benefit
              )[0].available
            : balance.company.available
        )}
      />
    );
  } else if (type === "charge") {
    return (
      <CardInfo
        loading={balance.isProcessing}
        title="Cobrança em Aberto"
        value={formatMoney(balance.company.pendingCharge)}
      />
    );
  } else return undefined;
};

_CardCompanyBalance.defaultProps = {
  type: "balance",
  benefit: null,
  useBenefit: false
};

_CardCompanyBalance.propTypes = {
  type: PropTypes.oneOf(["balance", "discount", "charge"]),
  benefit: PropTypes.any,
  useBenefit: PropTypes.bool
};

const CardCompanyBalance = connect(
  mapStateToProps,
  mapDispatchToProps
)(_CardCompanyBalance);

export { CardCompanyBalance };
