import React, { useEffect, useState } from "react";
import { Layout, Table, Row, Col } from "antd";
import { FormatDate, FormatMoney, Header, TableSearch } from "components";
import moment from "moment";
import { getBills, getAllBillStatus } from "services/bill-service";

const { Content } = Layout;

const DataFetch = {
  data: [],
  filtered: null,
  loading: false,
  readStatus: false
};

const useColumns = () => {
  const [columns, setColumns] = useState([
    {
      title: "Status",
      dataIndex: "status",
      filters: [],
      filterMultiple: false,
      width: 150,
      align: "center"
    },
    {
      title: "Código de Barra",
      dataIndex: "barcode",
      width: 250,
      align: "center"
    },
    {
      title: "Colaborador",
      dataIndex: "name",
      width: 200,
      align: "center"
    },
    {
      title: "CPF",
      dataIndex: "cpf",
      width: 150,
      align: "center"
    },
    {
      title: "Valor",
      dataIndex: "value",
      width: 150,
      align: "right",
      render: (value) => <FormatMoney value={value} />
    },
    {
      title: "Limite Usado",
      dataIndex: "benefit",
      width: 150,
      align: "center"
    },
    {
      title: "Data de Criação",
      dataIndex: "createdAt",
      width: 200,
      align: "center",
      render: (value) => <FormatDate value={moment(value)} />
    },
    {
      title: "Data de Vencimento",
      dataIndex: "dueDate",
      width: 200,
      align: "center",
      render: (value) => <FormatDate value={moment(value)} />
    }
  ]);

  const setColumnFilters = (filters) => {
    setColumns((prevColumns) => [
      { ...prevColumns[0], filters: filters },
      ...prevColumns.slice(1)
    ]);
  };

  return [columns, setColumnFilters];
};
const BillScreen = () => {
  const [data, setData] = useState(DataFetch.data);
  const [loading, setLoading] = useState(false);
  const [filteredStatus, setFilteredStatus] = useState(null);
  const [columns, setColumnFilters] = useColumns();

  const fetchData = async (status = null, barcode = null) => {
    setLoading(true);
    const billData = await getBills(status, barcode);
    setData(billData.data);
    setLoading(false);
  };

  const fetchBillStatus = async () => {
    const billStatusData = await getAllBillStatus();
    setColumnFilters(
      billStatusData.data?.map((status) => ({
        text: status.name,
        value: status.code
      }))
    );
  };

  useEffect(() => {
    fetchData(filteredStatus);
    fetchBillStatus();
  }, []);

  useEffect(() => {
    fetchData(filteredStatus);
  }, [filteredStatus]);

  const handleTableChange = (pagination, filters) => {
    setFilteredStatus(filters.status ? filters.status[0] : null);
  };

  return (
    <Layout className="alymente-layout">
      <Header title="Boletos" />
      <Content>
        <div style={{ padding: "16px" }}>
          <Row align={"end"}>
            <Col xl={8} lg={12} xs={24}>
              <TableSearch
                onlyInput
                request={async (searchValue) => {
                  await fetchData(undefined, searchValue);
                }}
              />
            </Col>
          </Row>
        </div>

        <Table
          columns={columns}
          rowKey={"id"}
          scroll={{ y: 450 }}
          dataSource={data}
          loading={loading}
          onChange={handleTableChange}
        />
      </Content>
    </Layout>
  );
};

export default BillScreen;
