import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import {
  Layout,
  Table,
  DatePicker,
  Select,
  Button,
  Typography,
  Space,
  Spin,
  Tooltip
} from "antd";
import { Header } from "components";
import { connect } from "react-redux";
import {
  getExpenseReport,
  getListExpenseTypes
} from "services/expense-service";
import { getEmployeeBalance } from "services/employee-service";
import { formatMoney } from "utils";
import {
  SearchOutlined as SearchIcon,
  PlusOutlined,
  FileExcelOutlined
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { setExpenseFilter } from "actions/expense-action";
import { useImmer } from "use-immer";
import { createExpenseReportFile } from "./expense";

const { Content, Footer } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setExpenseFilter
    },
    dispatch
  );

export const ExpenseReportScreen = (props) => {
  const { login, setExpenseFilter } = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = useImmer([]);
  const [listExpenseTypes, setListExpenseTypes] = React.useState([]);
  const [filter, setFilter] = React.useState({ expenseType: null, dates: [] });
  const dataWithoutBalanceIndex = React.useMemo(
    () => data.findIndex((x) => x.balance === null),
    [data]
  );

  const isAnyBalanceLoading = React.useMemo(
    () => data.find((x) => x.balance === null),
    [data]
  );
  const load = async () => {
    setIsLoading(true);

    const listExpenseTypesResponse = await getListExpenseTypes();

    setListExpenseTypes(listExpenseTypesResponse);

    setFilter((prev) => ({
      ...prev,
      expenseType:
        listExpenseTypesResponse.length > 0
          ? listExpenseTypesResponse[0].expenseType
          : null
    }));

    const res = await getExpenseReport(filter);
    setData(res);
    setIsLoading(false);
  };

  const loadBalance = async (index) => {
    const item = data[index];
    const balance = (await getEmployeeBalance(item.employee.id)) ?? [];

    setData((prev) => {
      const balanceFound = balance.find(
        (b) => b.benefit === item.benefitBalance.id
      );
      const itemPrev = prev[index];
      itemPrev.balance = balanceFound ? balanceFound.balance : 0;
    });
  };

  const handleFilter = async () => {
    //isEnableLoadingBalance.current = false;
    await load();
  };
  const onExportReport = () => {
    createExpenseReportFile(data);
  };

  const handleCreateSolicitation = () => {
    navigate("/solicitation/type/employee", {
      state: {
        expense: true,
        employees: [...new Set(data.map((x) => x.employee.id))]
      }
    });
    setExpenseFilter(filter);
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (dataWithoutBalanceIndex !== -1) {
      loadBalance(dataWithoutBalanceIndex);
    }
  }, [dataWithoutBalanceIndex]);

  return (
    <Layout className="alymente-layout">
      <Header title="Relatório de Comprovantes de gastos" />
      <Content>
        <Space
          size={"large"}
          style={{ textAlign: "left", float: "right", marginBottom: 30 }}
        >
          <div>
            <Typography.Text>Gasto: </Typography.Text>
            <Select
              placeholder="Selecione um tipo de despesa"
              allowClear
              value={filter.expenseType}
              style={{ width: 200 }}
              onChange={(e) =>
                setFilter((prev) => ({ ...prev, expenseType: e }))
              }
            >
              {listExpenseTypes.map(({ expenseType }) => (
                <Select.Option value={expenseType} key={expenseType}>
                  {expenseType}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <Typography.Text>Data dos comprovantes: </Typography.Text>
            <DatePicker.RangePicker
              value={filter.dates}
              onChange={(e) => setFilter((prev) => ({ ...prev, dates: e }))}
            />
          </div>
          <div>
            <Button type="primary" onClick={handleFilter} icon={<SearchIcon />}>
              Filtrar
            </Button>
          </div>
        </Space>
        <Table
          scroll={{ x: 1000, y: "60vh" }}
          pagination={false}
          dataSource={data}
          loading={isLoading}
          bordered
        >
          <Table.Column
            width={350}
            title="Colaborador"
            dataIndex={["employee", "name"]}
            key="userSex.code"
          />
          <Table.Column
            width={120}
            title="Benefício"
            dataIndex={["benefit", "name"]}
            key="data.benefit.name"
          />
          <Table.Column
            width={150}
            title="Benefício selecionado"
            dataIndex={["benefitBalance", "name"]}
            key="benefitBalance"
          />
          <Table.Column
            align={"right"}
            width={150}
            title={`Total Carga`}
            dataIndex={"totalCharge"}
            key="totalCharge"
            render={(value) => formatMoney(value)}
          />
          <Table.Column
            align={"right"}
            width={200}
            title="Limite Atual"
            dataIndex={"balance"}
            key="balance"
            render={(value, record) =>
              record.balance === null ? (
                <Spin size={"small"} />
              ) : (
                <Space
                  size={"large"}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  styles={{ item: { flex: 1 } }}
                >
                  <Typography.Text>{" - "}</Typography.Text>
                  <Typography.Text>{formatMoney(value)}</Typography.Text>
                </Space>
              )
            }
          />
          <Table.Column
            align={"right"}
            width={200}
            title="Gastos Realizados"
            dataIndex={"balance"}
            key="balance"
            render={(value, record) =>
              record.balance === null ? (
                <Spin size={"small"} />
              ) : (
                <Space
                  size={"large"}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  styles={{ item: { flex: 1 } }}
                >
                  <Typography.Text>{" = "}</Typography.Text>
                  <Typography.Text strong>
                    {formatMoney(record.totalCharge - record.balance)}
                  </Typography.Text>
                </Space>
              )
            }
          />
          <Table.Column
            align={"right"}
            width={200}
            title="Gastos Comprovados"
            dataIndex={"totalExpense"}
            key="totalExpense"
            render={(value) => (
              <Space
                size={"large"}
                style={{ display: "flex", justifyContent: "flex-end" }}
                styles={{ item: { flex: 1 } }}
              >
                <Typography.Text>{" - "}</Typography.Text>
                <Typography.Text>{formatMoney(value)}</Typography.Text>
              </Space>
            )}
          />
          <Table.Column
            align={"right"}
            width={200}
            title="Gastos Pendentes"
            dataIndex={"totalExpense"}
            key="totalExpense"
            render={(value, record) => {
              const sum =
                Math.round(
                  (record.totalCharge - record.balance - value) * 100
                ) / 100;
              return record.balance === null ? (
                <Spin size={"small"} />
              ) : (
                <Space
                  size={"large"}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  styles={{ item: { flex: 1 } }}
                >
                  <Typography.Text>{" = "}</Typography.Text>
                  <Typography.Text strong type={sum < 0 ? "danger" : "primary"}>
                    {formatMoney(sum)}
                  </Typography.Text>
                </Space>
              );
            }}
          />
        </Table>
      </Content>
      {!login.company.master && (
        <Footer>
          <Space>
            {filter.expenseType !== null && (
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={handleCreateSolicitation}
              >
                {`Criar Pedido de Carga para ${listExpenseTypes.find((x) => x.expenseType === filter.expenseType)?.expenseType}`}
              </Button>
            )}
            <Tooltip
              title={
                isLoading || isAnyBalanceLoading
                  ? "Aguarde o carregamento dos dados para exportar o relatório"
                  : ""
              }
              placement="top"
            >
              <Button
                icon={<FileExcelOutlined />}
                disabled={isLoading || isAnyBalanceLoading}
                onClick={onExportReport}
                type="primary"
              >
                Exportar Relatório
              </Button>
            </Tooltip>
          </Space>
        </Footer>
      )}
    </Layout>
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpenseReportScreen);
