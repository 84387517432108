import React from "react";
import { connect } from "react-redux";
import { Outlet, } from 'react-router-dom';
import { Layout } from 'antd';
import { Header, AutoCompleteCompany } from "components";
import { formatDate } from "utils";

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const HomeScreen = (props) => {
    const { login } = props;
    const [company, setCompany] = React.useState(login.company ? login.company.id : null);

    const onChangeCompany = (x) => {
        setCompany(x);
    }

    // if (login.company.activation !== null) {
    //     return <Navigate to="/activation" />
    // } else {
    return (
        <Layout className="alymente-layout secondary-body">
            <Header title={login.company ? login.company.name : ''} subtitle={`Relatório: ${formatDate(new Date(), 'DD/MM/YYYY HH:mm')}`}
                routes={
                    [
                        { label: "Fotobase", route: "/home/fotobase" },
                        { label: "Painel", route: "/home" }
                    ]
                }
                middle={
                    (login.company && login.company.master) ? <AutoCompleteCompany value={company} onChange={onChangeCompany} /> : undefined
                }
            />
            <Outlet context={{ company: company }} />
        </Layout>
    )
    // }
}

export default connect(mapStateToProps, {})(HomeScreen);