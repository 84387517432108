import { getTotalBalance } from "services/company-service";

export const getCompanyBalanceAction = (system = null) => async (dispatch) => {
    try {
        dispatch({ type: "BALANCE_COMPANY" });
        const res = await getTotalBalance(null, system);
        if (system) {
            dispatch({ type: "BALANCE_COMPANY_SYSTEM_SUCCESS", payload: res });
        } else {
            dispatch({ type: "BALANCE_COMPANY_SUCCESS", payload: res });
        }
    } catch (ex) {
        dispatch({ type: "BALANCE_COMPANY_FAIL" });
    }
};