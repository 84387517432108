import { exportXls } from 'utils';
import { formatMoney } from 'utils';
const formatDataObj = (data) => {
   const columns = data.map((item) => ({
      Colaborador: item.employee.name,
      Benefício: item.benefit.name,
      "Benefício selecionado": item.benefitBalance.name,
      'Total Carga': formatMoney(item.totalCharge),
      'Limite Atual': formatMoney(item.balance),
      'Gastos Realizados': formatMoney(item.totalCharge - item.balance),
      'Gastos Comprovados': formatMoney(item.totalExpense),
      'Gastos Pendentes': formatMoney(Math.round((item.totalCharge - item.balance - item.totalExpense) * 100) / 100),
   }));
   return columns;
};
export const createExpenseReportFile = (data) => {
   const columns = formatDataObj(data);
   exportXls().createAndSaveFile(['Relatório'],{'Relatório': columns}, 'Relatório de Despesas', 'Relatório de Despesas');
};
