import React from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Layout, Row, Col, Typography, Button, Grid } from 'antd';
import PropTypes from "prop-types";

const Header = (props) => {
    const { routes = [], onSelectRoute = () => { } } = props;
    const screens = Grid.useBreakpoint();
    const navigate = useNavigate();
    const location = useLocation();
    const onChangeRoute = (data) => {
        if (data.button) {
            onSelectRoute(data)
        } else if (data.external) {
            window.location.href = data.route + location.search;
        } else {
            navigate(data.route + location.search, { replace: false, ...(data.state ? { state: data.state } : undefined) });
        }
    }

    const getTitle = () => {
        if (props.title) return props.title;
        else if (routes.length > 0) {
            const find = routes.find(x => location.pathname === x.route);
            if (find) return find.label;
        }
        return "";
    }

    return <Layout.Header>
        <Row style={{ width: "100%" }} align="middle" justify={props.middle ? 'start' : 'space-between'}>
            <Col
                xxl={props.middle || routes.length > 0 ? 8 : 16}
                xl={props.middle && routes.length > 0 ? 8 : props.middle || routes.length > 0 ? 12 : 24}
                lg={props.middle && routes.length > 0 ? 8 : props.middle || routes.length > 0 ? 12 : 24}
                md={props.middle && routes.length > 0 ? 8 : props.middle || routes.length > 0 ? 12 : 24}
                sm={24}>
                <Typography.Title level={4} style={{ marginBottom: 0, marginTop: 0 }}>{getTitle()}</Typography.Title>
                {props.subtitle &&
                    <Typography.Text type="secondary">{props.subtitle}</Typography.Text>
                }
            </Col>
            {props.middle &&
                <Col xxl={8} xl={routes.length > 0 ? 8 : 12} lg={routes.length > 0 ? 8 : 12} md={routes.length > 0 ? 8 : 12}>
                    {props.middle}
                </Col>
            }
            {routes.length > 0 &&
                <Col xxl={8} xl={props.middle ? 8 : 12} lg={props.middle ? 8 : 12} md={props.middle ? 8 : 12} style={{ textAlign: screens.xl ? "right" : "left" }}>
                    {routes.map(x => (
                        <Button type={location.pathname === x.route ? "default" : "text"} onClick={() => onChangeRoute(x)}>
                            {x.label.toUpperCase()}
                        </Button>
                    ))}
                </Col>
            }
        </Row>
        {props.children &&
            <Row style={{ width: "100%", marginTop: 10 }} align="middle">
                <Col xs={24}>
                    {props.children}
                </Col>
            </Row>
        }
    </Layout.Header >
}

Header.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    middle: PropTypes.element,
    routes: PropTypes.array,
    children: PropTypes.any
};

export { Header }