import React from "react";
import { Typography, Space } from "antd";
import PropTypes from "prop-types";

const ColumnEmployee = (props) => {
    return <Space direction="vertical" size={0}>
        <Typography.Text style={{ fontSize: 12 }}>{props.name}</Typography.Text>
        <Typography.Text style={{ fontSize: 12 }}>{props.email}</Typography.Text>
        <Typography.Text style={{ fontSize: 12 }}>{props.cpf}</Typography.Text>
    </Space>;
};

ColumnEmployee.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    cpf: PropTypes.string.isRequired,
};

export { ColumnEmployee };