import React from "react";
import { connect } from "react-redux";
import {
  Layout,
  Button,
  Typography,
  Row,
  Col,
  Card,
  Input,
  Select,
  Space,
  Spin,
  Alert,
  notification,
  Modal,
  Table
} from "antd";
import {
  SaveOutlined,
  DownOutlined,
  UpOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import {
  Header,
  InputCurrency,
  SwitchLabel,
  InputMask,
  CreditCard,
  FormAddress,
  AutoCompleteCompany,
  Form,
  FormatDate
} from "components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  MARITAL_STATUS_DATA,
  SEX_DATA,
  USER_TYPE_DATA
} from "utils/static-data";
import {
  getByIdUser,
  getAllOccupation,
  saveUser,
  saveUserBatch,
  saveNewCompanyUser,
  getAccountsInfoByUseId
} from "services/user-service";
import {
  getByIdCard,
  loadHistoryCards,
  blockCard as block
} from "services/card-service";
import { bindActionCreators } from "redux";
import * as balanceAction from "actions/balance-action";
import moment from "moment";
import Env from "env";
import { translate } from "config/language";
import { getFieldUser } from "utils";
import { DatePicker } from "components";
import environment from "env";
import { AccountSystem } from "utils/account-system";

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer,
  balance: state.balanceReducer
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...balanceAction
    },
    dispatch
  );

const UserDetailsScreen = React.forwardRef((props, ref) => {
  const {
    login,
    compact = false,
    user = null,
    balance,
    onSubmit = () => {},
    getCompanyBalanceAction
  } = props;
  const { id = user } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [subRoutes, setSubRoutes] = React.useState([]);
  const [data, setData] = React.useState(null);
  const [cards, setCards] = React.useState([]);
  const [alerts, setAlerts] = React.useState([]);
  const [occupations, setOccupations] = React.useState([]);
  const [cardActive, setCardActive] = React.useState([]);
  const [openSocialName, setOpenSocialName] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingCards, setLoadingCards] = React.useState(false);
  const [cardHistoryShow, setCardHistoryShow] = React.useState(false);
  const [motherRequired, setMotherRequired] = React.useState(false);
  const [masterAccountSystem, setMasterAccountSystem] = React.useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [accountsInfo, setAccountsInfo] = React.useState([]);
  const wtSendEmail = Form.useWatch("sendEmail", form);
  const wtSendSms = Form.useWatch("sendSms", form);
  const wtCompany = Form.useWatch("companyExternal", form);
  const isPixTelephone = data?.pixType.name === "Telefone";
  const loadSubRoutes = () => {
    if (id) {
      let routes = [{ label: `Senha`, route: `/user/${id}/forgot` }];
      if (login.company.master || login.user.admin) {
        routes = [
          { label: "Cargas", route: `/user/${id}/solicitations` },
          {
            label: `Resgatar ${translate("balance")}`,
            button: true,
            redeem: true
          },
          { label: `Senha`, route: `/user/${id}/forgot` },
          { label: `Gerência`, route: `/user/${id}/manager` }
        ];
      }

      if (login.user.feature.advantage) {
        routes.push({ label: "Vantagens", route: `/user/${id}/advantages` });
      }

      if (login.company.master) {
        routes.push({
          label: `Consulta ${translate("balance")}`,
          route: `/user/${id}/statement`
        });
        routes.push({ label: "Mudar Empresa", route: `/user/${id}/company` });
        routes.push({
          label: "Histórico de Empresas",
          route: `/user/${id}/history`
        });
      }
      setSubRoutes(routes);
    }
  };

  const load = async () => {
    setLoading(true);
    setOccupations(await getAllOccupation());
    await getCompanyBalanceAction();
    if (id) {
      const res = await getByIdUser(id);
      const disableAccountCard = true;
      if (login.company.master && !disableAccountCard) {
        const accountsInfo = await getAccountsInfoByUseId(id);
        setAccountsInfo(accountsInfo?.data);
      }
      if (res.data) {
        if (!res.data.newCompany) {
          const resCard = await getByIdCard(id);
          setCardActive(resCard);
        } else if (res.data.card) {
          setCardActive([{ cardId: res.data.cardId }]);
        }
        setData(res.data);
      }
    } else {
      form.setFieldsValue({
        company: login.company.master ? {} : login.company,
        companyExternal: login.company.master ? {} : login.company
      });
    }
    setLoading(false);
  };

  const loadFields = () => {
    if (data.birthday) {
      setMotherRequired(moment().diff(data.birthday, "years") < 18);
    }
    form.setFieldsValue({
      ...data,
      companyExternal: data.companyExternal,
      phone:
        data.phone && data.phone.substring(0, 2) == "55"
          ? data.phone.substring(2)
          : data.phone,
      birthday: data.birthday ? moment(data.birthday) : null
    });
    setOpenSocialName(data.hasSocialName);
  };

  const onSave = async (values) => {
    setLoading(true);
    try {
      if (!compact) {
        const occupationName = values.occupation
          ? occupations.find((x) => x.value === values.occupation).name
          : null;
        try {
          await saveUser(id, { ...values, occupationName: occupationName });
          const params =
            searchParams.size > 0 ? `?${searchParams.toString()}` : "";
          navigate(`/user${params}`);
        } catch (err) {
          if (err.data && err.data.infos && err.data.infos.field) {
            let field = getFieldUser(err.data.infos.field);
            form.setFields([
              {
                name: field,
                errors: [err.data.errorMessage]
              }
            ]);
          }
        }
      } else {
        const body = {
          ...values,
          enabled: true,
          id: id ? id : 0,
          sex: values.userSex.code
        };
        delete body.userSex;
        delete body.companyExternal;
        await saveUserBatch(body);
        form.resetFields();
        onSubmit();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const createAccountStatus = () => {
    if (data) {
      const accountStatus = data.accountSwapStatus;
      if (accountStatus) {
        const status = accountStatus.toLowerCase();
        let category = "info";
        if (status === "success") {
          category = "success";
        } else if (status === "error") {
          category = "error";
        }
        return (
          <Alert
            message={`Status de Conta: ${
              status === "success" ? "Ativo" : "Inativo"
            }`}
            type={category}
            showIcon
          />
        );
      }
    }
    return null;
  };

  const createCardStatus = (card) => {
    if (data) {
      if (data.newCompany) {
        return (
          <Alert
            message={`Status: Mudança de Empresa`}
            type={"warning"}
            showIcon
          />
        );
      }
      const cardStatus = card && card.status ? card.status : data.cardStatus;
      if (cardStatus) {
        const status = cardStatus.toLowerCase();
        let category = "info";
        if (status === "inativo") {
          category = "warning";
        } else if (status === "ativo" || status === "enviando") {
          category = "success";
        } else if (status === "bloqueado") {
          category = "error";
        }
        return (
          <Alert
            message={`Status: ${cardStatus.toUpperCase()}`}
            type={category}
            showIcon
          />
        );
      }
    }
    return null;
  };

  const blockCard = async (card) => {
    setLoading(true);
    try {
      await block(id, card);
      setCardActive(await getByIdCard(id));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const openHistoryCards = async () => {
    setLoadingCards(true);
    setCardHistoryShow(true);
    try {
      let res = await loadHistoryCards(id);
      setCards(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingCards(false);
    }
  };

  const loadAlerts = () => {
    var alerts_aux = [];
    if (data.validation && data.validation.code === "E") {
      notification.error({
        message: "Favor revisar os dados desse colaborador."
      });
    } else if (data.address == null) {
      alerts_aux.push({
        message:
          "Colaborador sem endereço cadastrado. Cadastre o endereço do colaborador para a validação de dados, necessária para a emissão de cartão de crédito.",
        type: "warning"
      });
    } else if (data.validation && data.validation.code === "I") {
      notification.error({
        message: "Colaborador com dados incompletos."
      });
    }

    if (data && data.newCompany && login.company.master) {
      alerts_aux.push({
        message:
          "Esse usuário está associado a uma nova empresa. Altere a empresa para que ele possa utilizar o cartão.",
        type: "warning"
      });
    }

    if (data && data.accountSwapStatus == "ERROR") {
      alerts_aux.push({
        message:
          "Colaborador com conta inativa. Revise os dados para que ele possa receber carga e usar o cartão.",
        type: "warning"
      });
    }

    if (data.kycObservation && login.company.master) {
      alerts_aux.push({
        message: data.kycObservation,
        type: "warning"
      });
    }

    setAlerts(alerts_aux);
  };

  React.useImperativeHandle(ref, () => {
    return {
      submit() {
        form.submit();
      }
    };
  });

  const redirectPage = (url) => {
    if (balance.company.subBalances.length == 0) {
      navigate(url, { state: { benefit: null } });
    } else if (balance.company.subBalances.length == 1) {
      navigate(url, {
        state: { benefit: balance.company.subBalances[0].benefit }
      });
    } else {
      navigate("/solicitation/balance", {
        state: {
          url: url,
          type: "credit",
          description: `Selecione um ${translate(
            "balance"
          )} de destino para o resgate`
        }
      });
    }
  };

  React.useEffect(() => {
    if (data) {
      loadFields();
      loadAlerts();
    }
  }, [data]);

  React.useEffect(() => {
    loadSubRoutes();
    load();
  }, [id]);

  return (
    <Layout className="alymente-layout">
      {!compact && (
        <Header
          title={`${data && data.name ? data.name : ""}`}
          routes={subRoutes}
          onSelectRoute={(d) =>
            d.redeem ? redirectPage(`/user/${id}/redeem`) : null
          }
        />
      )}
      <Content style={compact ? { paddingRight: 8, paddingLeft: 8 } : {}}>
        {!compact && (
          <Row gutter={16}>
            {alerts.map((a) => (
              <Col sm={24} style={{ marginBottom: 10 }}>
                <Alert message={a.message} type={a.type} />
              </Col>
            ))}
          </Row>
        )}

        <Row justify="center">
          <Col
            xl={compact ? 24 : 10}
            lg={compact ? 24 : 16}
            md={compact ? 24 : 24}
          >
            <Form form={form} layout="vertical" name="basic" onFinish={onSave}>
              <Space direction="vertical" size="large">
                <Row gutter={compact ? 12 : 0}>
                  <Col md={compact ? 12 : 24}>
                    <Card title="Informações Básicas">
                      <Spin spinning={loading}>
                        <Form.Item name={["companyExternal"]} hidden={true} />
                        {login.company.master && (
                          <Form.Item label="Empresa" name={["company", "id"]}>
                            <AutoCompleteCompany
                              disabled={id ? true : false}
                              onChangeCompany={(value) => {
                                form.setFieldsValue({ companyExternal: value });
                              }}
                            />
                          </Form.Item>
                        )}
                        {login.company.master &&
                          data &&
                          data.otherCompanies &&
                          data.otherCompanies.length > 0 && (
                            <Form.Item label="Outras Empresas">
                              <Select
                                mode="multiple"
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Empresas"
                                defaultValue={data.otherCompanies.map(
                                  (d) => d.name
                                )}
                                disabled
                              ></Select>
                            </Form.Item>
                          )}
                        {!compact && (
                          <Form.Item
                            hasFeedback
                            label="Tipo"
                            initialValue={"E"}
                            name={["userType", "code"]}
                          >
                            <Select placeholder="Selecione um tipo">
                              {USER_TYPE_DATA.map((x) => (
                                <Select.Option value={x.code}>
                                  {x.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                        <Form.Item noStyle>
                          <Form.Item
                            label="Nome"
                            hasFeedback
                            name="name"
                            rules={[
                              { required: true, message: "Insira um nome" }
                            ]}
                          >
                            <Input
                              autoComplete="off"
                              addonAfter={
                                !compact ? (
                                  openSocialName ? (
                                    <UpOutlined
                                      onClick={() => setOpenSocialName(false)}
                                    />
                                  ) : (
                                    <DownOutlined
                                      onClick={() => setOpenSocialName(true)}
                                    />
                                  )
                                ) : null
                              }
                            />
                          </Form.Item>
                          {openSocialName && (
                            <Form.Item
                              label="Nome de Registro"
                              hasFeedback
                              name="officialName"
                              rules={[
                                {
                                  required: true,
                                  message: "Insira um nome de registro"
                                }
                              ]}
                            >
                              <Input autoComplete="off" />
                            </Form.Item>
                          )}
                        </Form.Item>
                        <Form.Item
                          label="Email"
                          hasFeedback
                          name="email"
                          rules={[
                            {
                              required: true,
                              type: "email",
                              message: "Insira um email válido"
                            }
                          ]}
                        >
                          <Input autoComplete="off" type="email" />
                        </Form.Item>
                        {wtCompany && wtCompany.enableCode && (
                          <Form.Item
                            label="Código do Colaborador"
                            hasFeedback
                            name="code"
                          >
                            <Input autoComplete="off" />
                          </Form.Item>
                        )}
                        <Form.Item
                          label="Nome da Mãe"
                          hasFeedback
                          name="mother"
                          rules={[
                            {
                              required: motherRequired,
                              message: "Insira o nome da mãe"
                            }
                          ]}
                        >
                          <Input autoComplete="off" />
                        </Form.Item>
                        <Row gutter={compact ? 10 : 0}>
                          <Col md={compact ? 12 : 24}>
                            <Form.Item
                              label="CPF"
                              hasFeedback
                              name="cpf"
                              rules={[
                                {
                                  required: true,
                                  message: "Insira um CPF válido"
                                }
                              ]}
                            >
                              <InputMask
                                disabled={id ? true : false}
                                mask="999.999.999-99"
                                placeholder="000.000.000-00"
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={compact ? 12 : 24}>
                            <Form.Item
                              label="Data de Nascimento"
                              hasFeedback
                              name="birthday"
                              rules={[
                                {
                                  required: true,
                                  message: "Insira uma data válida"
                                }
                              ]}
                            >
                              <DatePicker
                                style={{ width: "100%" }}
                                format={"DD/MM/YYYY"}
                                onChange={(value) =>
                                  setMotherRequired(
                                    moment().diff(value, "years") < 18
                                  )
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col md={compact ? 12 : 24}>
                            <Form.Item
                              label="Telefone"
                              hasFeedback
                              rules={[
                                {
                                  required: isPixTelephone,
                                  message:
                                    "Insira o celular quando o pix for Celular."
                                }
                              ]}
                              name="phone"
                            >
                              <InputMask
                                addonBefore="+55"
                                mask="(99) 99999-9999"
                                placeholder="(00) 00000-0000"
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={compact ? 12 : 24}>
                            <Form.Item
                              label="Sexo"
                              hasFeedback
                              name={["userSex", "code"]}
                              rules={[
                                { required: true, message: "Insira o sexo" }
                              ]}
                            >
                              <Select placeholder="Selecione um sexo">
                                <Select.Option value={null}></Select.Option>
                                {SEX_DATA.map((x) => (
                                  <Select.Option value={x.code}>
                                    {x.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        {!compact && (
                          <React.Fragment>
                            <Form.Item
                              label="Renda Mensal"
                              hasFeedback
                              name="income"
                            >
                              <InputCurrency />
                            </Form.Item>
                            <Form.Item
                              label="Estado Civil"
                              hasFeedback
                              name={["maritalStatus", "code"]}
                            >
                              <Select placeholder="Selecione um estado civil">
                                {MARITAL_STATUS_DATA.map((x) => (
                                  <Select.Option value={x.code}>
                                    {x.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              label="Profissão"
                              hasFeedback
                              name={"occupation"}
                            >
                              <Select
                                loading={occupations.length === 0}
                                placeholder="Selecione uma profissão"
                              >
                                {occupations.map((x) => (
                                  <Select.Option value={x.value}>
                                    {x.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              hasFeedback
                              name={"enabled"}
                              initialValue={true}
                              valuePropName="checked"
                            >
                              <SwitchLabel label="USUÁRIO ATIVO?" />
                            </Form.Item>

                            <Row gutter={24} align="middle">
                              <Col span={12}>
                                <Form.Item
                                  label="Tipo de PIX"
                                  name={["pixType", "name"]}
                                >
                                  <Input disabled autoComplete="off" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label=" "
                                  hasFeedback
                                  name={"validPix"}
                                  initialValue={false}
                                  valuePropName="checked"
                                >
                                  <SwitchLabel disabled label="PIX VÁLIDO?" />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={24} align="middle">
                              <Col span={24}>{createAccountStatus()}</Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </Spin>
                    </Card>
                  </Col>
                  {login.company.master && id && (
                    <Col>
                      <Card title="Dados da conta" style={{ marginTop: 15 }}>
                        <Spin spinning={loading}>
                          <Table
                            dataSource={accountsInfo}
                            loading={false}
                            pagination={false}
                            bordered
                          >
                            <Table.Column
                              align="center"
                              title="External Key"
                              dataIndex="externalKey"
                              key="externalKey"
                            />
                            <Table.Column
                              align="center"
                              width={150}
                              title="Sistema de conta"
                              dataIndex="accountSystem"
                              key="accountSystem"
                            />
                            <Table.Column
                              align="center"
                              width={150}
                              title="Estado KYC"
                              dataIndex="kycStatus"
                              key="kycStatus"
                              render={(value) =>
                                value == "approved" ? "Aprovado" : "Negado"
                              }
                            />
                            <Table.Column
                              align="center"
                              width={150}
                              title="Status"
                              dataIndex="active"
                              key="active"
                              render={(value) =>
                                value ? "Ativo" : "Bloqueado"
                              }
                            />

                            <Table.Column
                              align="center"
                              width={150}
                              title="Principal"
                              dataIndex="main"
                              key="main"
                              render={(value) => (value ? "Sim" : "Não")}
                            />

                            <Table.Column
                              align="center"
                              width={150}
                              title="Account"
                              dataIndex={["account", "account"]}
                              key="account.account"
                            />
                            <Table.Column
                              align="center"
                              width={150}
                              title="Ativa"
                              dataIndex={["account", "active"]}
                              key="account.active"
                            />
                          </Table>
                        </Spin>
                      </Card>
                    </Col>
                  )}
                  {/* {
                                        !compact &&
                                        <Col md={compact ? 12 : 24}>
                                            <Card title="Senha do Aplicativo" style={!compact ? { marginTop: 15 } : {}}>
                                                <Spin spinning={loading}>
                                                    <Form.Item
                                                        name={"sendEmail"}
                                                        valuePropName="checked"
                                                    >
                                                        <SwitchLabel label="ENVIAR E-MAIL PARA REDEFINIÇÃO DE SENHA DO APLICATIVO" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={"sendSms"}
                                                        valuePropName="checked"
                                                    >
                                                        <SwitchLabel label="ENVIAR SMS PARA REDEFINIÇÃO DE SENHA DO APLICATIVO" />
                                                    </Form.Item>
                                                    {!(wtSendEmail || wtSendSms) &&
                                                        <Row gutter={24} align="middle">
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={"password"}
                                                                >
                                                                    <Input placeholder="Senha do Aplicativo" type={"password"} autoComplete="off" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    name={"confirmation"}
                                                                >
                                                                    <Input placeholder="Confirmação de Senha" type={"password"} autoComplete="off" />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </Spin>
                                            </Card>
                                        </Col>
                                    } */}
                  {!compact && (
                    <Col md={compact ? 12 : 24}>
                      <Card
                        style={!compact ? { marginTop: 15 } : {}}
                        title={cardActive.length === 0 ? "Cartão" : "Cartões"}
                        extra={
                          <Button
                            size="small"
                            type={"link"}
                            onClick={() => openHistoryCards()}
                          >
                            Histórico
                          </Button>
                        }
                      >
                        <Spin spinning={loading}>
                          {cardActive.map((card) => (
                            <Row
                              gutter={24}
                              style={{
                                marginBottom: cardActive.length === 0 ? 0 : 30
                              }}
                            >
                              <Col xl={12}>
                                <CreditCard
                                  name={data ? data.name : null}
                                  number={card ? card.number : null}
                                />
                              </Col>
                              <Col xl={12}>
                                {createCardStatus(card)}
                                {card && !card.delivery && (
                                  <Form.Item
                                    label=" "
                                    hasFeedback
                                    name={"card"}
                                    valuePropName="checked"
                                  >
                                    <SwitchLabel label="Cartão Entregue?" />
                                  </Form.Item>
                                )}
                                {card && card.id && (
                                  <Button
                                    block
                                    danger
                                    type="primary"
                                    onClick={() => blockCard(card)}
                                  >
                                    Bloquear
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          ))}
                        </Spin>
                      </Card>
                    </Col>
                  )}
                  <Col
                    md={compact ? 12 : 24}
                    style={!compact ? { marginTop: 15 } : {}}
                  >
                    <FormAddress
                      loading={loading}
                      form={form}
                      compact={compact}
                    />
                  </Col>
                </Row>
              </Space>
            </Form>
          </Col>
        </Row>
      </Content>
      {!compact && (
        <Footer style={compact ? { padding: 0 } : {}}>
          <Button
            disabled={loading}
            loading={loading}
            className="btn-save"
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => form.submit()}
          >
            Salvar
          </Button>
        </Footer>
      )}
      <Modal
        open={cardHistoryShow}
        title="Cartões do Colaborador"
        onCancel={() => setCardHistoryShow(false)}
        onOk={() => setCardHistoryShow(false)}
        width={600}
      >
        <Table
          scroll={{ y: 400 }}
          dataSource={cards}
          loading={loadingCards}
          pagination={false}
          bordered
        >
          <Table.Column
            title="Número do Cartão"
            dataIndex="cardNumber"
            key="cardNumber"
          />
          <Table.Column
            align="center"
            width={150}
            title="Atualizado em"
            dataIndex="updatedAt"
            key="updatedAt"
            render={(value) => <FormatDate value={value} />}
          />
          <Table.Column
            align="center"
            width={100}
            title="Tipo"
            dataIndex="virtual"
            key="virtual"
            render={(value) => (value ? "Virtual" : "Físico")}
          />
          <Table.Column
            align="center"
            width={150}
            title="Status"
            dataIndex="active"
            key="active"
            render={(value) => (value ? "Ativo" : "Bloqueado")}
          />
        </Table>
      </Modal>
    </Layout>
  );
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(UserDetailsScreen);
