import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Input, Button, Card, Row, Col, Layout, Alert } from "antd";
import { AuthHeader, Form } from "components"
import * as authAction from "actions/auth-action";
import { UserOutlined } from "@ant-design/icons";
import { SoftColor } from "config/constants/Color";
import ReCAPTCHA from "react-google-recaptcha-enterprise";

const mapStateToProps = (state) => ({
    forgot: state.authReducer.forgotReducer
})

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        ...authAction
    }, dispatch);


const ForgotScreen = (props) => {
    const { forgot, forgotAction } = props;
    const recaptchaRef = React.createRef();
    const [inputType, setInputType] = React.useState('email');
    const [disabled, setDisabled] = React.useState(true);
    const [login, setLogin] = React.useState("")
    const [form] = Form.useForm();

    function onChangeRecaptcha(value) {
        if (value) {
            setDisabled(false)
        }
    }

    const onForgot = async (values) => {
        const recaptchaValue = recaptchaRef.current.getValue();
        let access = inputType == "phone" ? "55" + values.login.replace(/[^\w]/gi, '') : login
        forgotAction(access, inputType, recaptchaValue);
    }

    const mask = (val, isPhone) => {
        if (isPhone) {
            val = val.replace("(", '').replace(")", '').replace("-", '').replace(" ", '').replace(" ", '')
            let temp = ''
            for (let i = 0; i < val.length; i++) {
                if (i == 0)
                    temp += '(' + val.charAt(i);
                else if (i == 1)
                    temp += val.charAt(i) + ") "
                else if (i == 6)
                    temp += val.charAt(i) + "-"
                else
                    temp += val.charAt(i)
            }
            return temp
        }
        return val
    }

    const formatPhone = (val) => {
        let tel = val.replace('(', '')
        const isPhone = (val.length > 0 && tel.length > 0 && !isNaN(tel.charAt(0)))

        const removeEspecialChar = val.charAt(val.length - 1) == "(" ||
            val.charAt(val.length - 1) == ")" ||
            val.charAt(val.length - 1) == "-" ||
            val.charAt(val.length - 1) == " "

        if (val.length < login.length && isPhone && removeEspecialChar) {
            if (val.charAt(val.length - 1) == " ")
                val = val.slice(0, -2)
            else
                val = val.slice(0, -1)
        }

        val = val.length < login.length ? val : mask(val, isPhone)

        if (val.length < login.length && val.length == 1 && inputType == "phone")
            val = ""

        if (isPhone)
            setInputType("phone")
        else
            setInputType("email")

        return val
    }

    return (
        <Layout style={{ backgroundColor: SoftColor, height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
            <Layout.Content>
                <Row justify="center" align="middle" className="login">
                    <Col xxl={7} xl={8} lg={12} md={16} sm={24} xs={24}>
                        <Card title={<AuthHeader />} bordered={false} className="card-login">
                            <Form form={form} style={{ width: "100%" }} name="basic" initialValues={{ login: "" }} onFinish={onForgot}>
                                <Form.Item
                                    hasFeedback
                                    name="login"
                                    rules={[
                                        { required: true, message: "Insira um email ou celular válido" }
                                    ]}>
                                    <Input
                                        autoComplete="off"
                                        prefix={inputType == "phone" ? "+55" : ""}
                                        placeholder="E-mail ou celular"
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            form.setFieldsValue({
                                                login: formatPhone(value)
                                            });
                                            setLogin(formatPhone(value))
                                        }}
                                        value={login}
                                        maxLength={inputType == "phone" ? 15 : 100}
                                        type={inputType == "phone" ? "tel" : "email"}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            size="normal"
                                            sitekey="6LeQ6CElAAAAAKvtRkTMvfMcc5-Tr7FxqDhnp1hS"
                                            onChange={onChangeRecaptcha}
                                            hl={"pt-BR"}
                                        />
                                    </div>
                                </Form.Item>
                                {forgot.message.length > 0 &&
                                    <Form.Item>
                                        <Alert message={forgot.message} type={forgot.success ? "success" : "error"} showIcon />
                                    </Form.Item>
                                }
                                <Form.Item>
                                    <Button disabled={disabled} loading={forgot.isProcessing} type="primary" htmlType="submit" style={{ width: "100%" }}>Recuperar Senha</Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="link" href="/auth/login">Voltar</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotScreen);
