import React from "react";
import { FormatMoney } from "components";
import { Table, Typography } from "antd";
import PropTypes from "prop-types";
import { translate } from 'config/language';

const UserBalance = (props) => {
    const { balance, loading, benefit, showTotal = true } = props;

    const prepareBalance = (balance) => {
        let lines = []
        if (balance) {
            for (let detail of balance.details) {
                lines.push({ value: detail.balance, label: detail.benefit == null ? "Flexível" : detail.nameBenefit, strong: false, enabled: !benefit || benefit == detail.benefit  })
            }
            if(showTotal){
                lines.push({ value: balance.balance, label: "Total", strong: true, enabled: !benefit  })
            }
        }
        return lines
    }

    return <Table size="small" dataSource={prepareBalance(balance)} loading={loading} pagination={false} bordered>
        <Table.Column width={170} align={"center"} title="Benefício" dataIndex="label" key="label" render={(value, row) => <Typography.Text disabled={!row.enabled} strong={row.strong}>{value}</Typography.Text>} />
        <Table.Column width={170} align={"center"} title={translate('balance')} dataIndex="value" key="value" render={(value, row) => <FormatMoney disabled={!row.enabled} strong={row.strong} value={value} />} />
    </Table>
};

UserBalance.defaultProps = {
    loading: false,
    benefit: null
};

UserBalance.propTypes = {
    balance: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    benefit: PropTypes.number
};

export { UserBalance }