import React from "react";
import { useOutletContext } from 'react-router-dom';
import { Layout, Table, Switch, Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { getAllCompanyCatalog, saveCompanyCatalog } from "services/company-catalog-service";

const { Footer, Content } = Layout;

const DataFetch = {
    data: [],
    loading: false
}

const CatalogScreen = () => {
    const { company } = useOutletContext();
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(DataFetch)

    React.useEffect(() => {
        load();
    }, [company])

    const load = async () => {
        if (company) {
            setData((x) => ({ ...x, loading: true }));
            const res = await getAllCompanyCatalog(company);
            setData({ data: res.filter(x => !x.isBenefit), loading: false });
        }
    }

    const onEdit = (value, index, field) => {
        const newData = [...data.data];
        newData[index][field] = value;
        setData((x) => ({ ...x, data: newData }));
    }

    const onSave = async () => {
        setLoading(true);
        await saveCompanyCatalog(company, [...data.data]);
        await load();
        setLoading(false);
    }

    return (
        <React.Fragment>
            <Content>
                <Table scroll={{ y: "60vh" }} dataSource={data.data} loading={data.loading} pagination={false} bordered>
                    <Table.Column title="Nome" dataIndex="name" key="name" />
                    <Table.Column align="center" title="Status" dataIndex="enabled" key="enabled" width={200}
                        render={(value, record, index) => <Switch checked={value} onChange={(x) => onEdit(x, index, "enabled")} />} />
                </Table>
            </Content>
            <Footer>
                <Button loading={loading} className="btn-save" type="primary" icon={<SaveOutlined />} onClick={onSave}>Salvar</Button>
            </Footer>
        </React.Fragment>
    )
}

export default CatalogScreen;