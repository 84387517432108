const ConfigState = {
    menu: [],
    system: []
}

const ConfigReducer = (state = ConfigState, action) => {
    const { payload } = action;
    switch (action.type) {
        case "CONFIG_LOAD":
            return Object.assign({}, state, {
                menu: payload.menu,
                system: payload.system
            });
        default:
            return state;
    }
}

export default ConfigReducer;