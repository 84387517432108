import React, { Fragment } from "react";
import { connect } from "react-redux";
import lodash from "lodash";
import {
  Layout,
  Table,
  Button,
  Tag,
  Typography,
  Row,
  Col,
  Space,
  Modal,
  Spin,
  Input,
  notification,
  message
} from "antd";
import {
  DownloadOutlined,
  CloseSquareOutlined,
  PrinterOutlined,
  SwapOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  DollarCircleOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import {
  Header,
  ColumnEmployee,
  FormatDate,
  FormatMoney,
  Form,
  BoxColor,
  DatePicker,
  TableSearch
} from "components";
import { useNavigate, useParams } from "react-router-dom";
import { Logo } from "components/image/logo";
import {
  getByIdSolicitation,
  getSolicitationLines,
  cancelSolicitation,
  getRefundLines,
  getBankSplit,
  getPixQRCode,
  updateSolicitation,
  saveSolicitation,
  cancelChargeLines,
  getfeeLines,
  getBankSplitStatus
} from "services/solicitation-service";
import { getAllBenefit } from "services/benefit-service";
import { useReactToPrint } from "react-to-print";
import { formatDate, formatMoney } from "utils";
import { saveFileResume } from "utils";
import moment from "moment";
import { SolicitationPix } from "./solicitation-pix";
import { translate } from "config/language";
import { downloadBankSlip } from "views/solicitation/solicitation";
import { ColumnVehicle } from "components/table/column-vehicle";
import { getBusinessUnits } from "services/company-service";

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer,
  balance: state.balanceReducer
});

const DataFetch = {
  data: [],
  solicitation: null,
  benefits: [],
  fees: [],
  loading: false
};
const STATUS = {
  INITIATED: "initiated",
  REQUESTED: "requested",
  ENQUEUED: "enqueued",
  PROCESSING: "processing",
  PROCESSING_PAYMENT: "processing_payment",
  PAID: "paid",
  CREATED: "created"
};

// Define notification messages
const MESSAGES = {
  GENERATING: "Boleto está sendo gerado e estará disponível em alguns minutos.",
  PROCESSING: "Boleto sendo processado. Não disponível para download.",
  ERROR: "A geração do boleto apresentou erro. Consulte nosso suporte."
};

const SolicitationReportScreen = (props) => {
  const navigate = useNavigate();
  const { login, balance } = props;
  const isCodeEnabledInCompany = login.company.enableCode;
  const [data, setData] = React.useState(DataFetch);
  const [columns, setColumns] = React.useState([]);
  const [cancelShow, setCancelShow] = React.useState(false);
  const [printShow, setPrintShow] = React.useState(false);
  const [transferShow, setTransferShow] = React.useState(false);
  const [dateShow, setDateShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showPix, setShowPix] = React.useState(false);
  const [carFleet, setCarFleet] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [hasSearchInput, setHasSearchInput] = React.useState(false);
  const [form] = Form.useForm();
  const { id } = useParams();
  const [selectAll, setSelectAll] = React.useState(false);
  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    bodyClass: "print-body",
    copyStyles: true,
    onAfterPrint: () => {
      setPrintShow(false);
    },
    content: () => componentRef.current
  });

  function extractTeamLevels(data) {
    return data && data.length > 0
      ? lodash
          .chain(data)
          .filter(({ level }) => level)
          .groupBy("level")
          .map((teams, level) => ({ level: `Equipe Nível ${level}`, teams }))
          .value()
      : {};
  }
  function mapTeamLevels(userId, levels) {
    return lodash.reduce(
      levels,
      (acc, { level, teams }) => {
        const team = teams.find((team) =>
          team.employees.some((emp) => emp.id === userId)
        );
        acc[level] = team?.name;
        acc[`Código da ${level}`] = team?.code;
        return acc;
      },
      {}
    );
  }
  const load = async () => {
    setData((x) => ({ ...x, loading: true }));
    const _solicitation = await getByIdSolicitation(id);
    setCarFleet(_solicitation.vehicle);
    let _lines = [];
    if (_solicitation.type.code === "C" || _solicitation.type.code === "E") {
      _lines = await getSolicitationLines(id, _solicitation.vehicle);
    } else if (_solicitation.type.code === "R") {
      _lines = await getRefundLines(id);
    }
    _lines = _lines
      .map((value, index) => {
        return { ...value, index: index };
      })
      .sort((a, b) => {
        if (_solicitation.type.code === "R") {
          return a.employee.localeCompare(b.employee);
        }
        return _solicitation.vehicle
          ? a.vehicleName
          : a.name.localeCompare(b.name);
      });

    const _benefits = await getAllBenefit(
      _solicitation.company.id,
      true,
      _solicitation.vehicle
    );
    const fees = await getfeeLines(id);
    setData({
      solicitation: _solicitation,
      data: _lines,
      benefits: _solicitation.vehicle
        ? _benefits.map((b) => {
            return {
              ...b,
              completeBalance: false
            };
          })
        : _benefits,
      fees: fees,
      loading: false
    });

    form.setFieldsValue({
      paymentDate: _solicitation.payment
        ? moment(_solicitation.payment.paymentAt)
        : moment(),
      approvalDate: _solicitation.approval
        ? moment(_solicitation.approval.approvalAt)
        : moment(),
      expectedDate: calculateExpectationDates(
        _solicitation.payment ? _solicitation.payment.paymentAt : null
      )
    });
  };

  const sumTotal = React.useMemo(() => {
    if (data.data.length) {
      return data.data.reduce(
        (previous, current) => previous + current.value,
        0
      );
    } else return data.solicitation ? data.solicitation.value : 0;
  }, [data]);

  const sumCanceled = React.useMemo(() => {
    if (data.data.length) {
      return data.data
        .filter((x) => x.cancel)
        .reduce((previous, current) => previous + current.value, 0);
    } else return 0;
  }, [data]);

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    createTable();
  }, [data]);

  React.useEffect(() => {
    let _data;
    let rows = selectAll
      ? _data.filter((d) => !(d && d.transaction)).map((d) => d.index)
      : [];
    setSelectedRowKeys(rows);
  }, [selectAll]);

  const loadColumnsCompleteBalance = (benefit) => {
    const cols = [
      {
        width: 200,
        align: "right",
        title: `${translate("balance")} Total`,
        dataIndex: benefit.key,
        render: (value, record, index) =>
          createValue(record.total, record, benefit.benefit)
      },
      {
        width: 150,
        align: "right",
        title: `${translate("balance")} Disponível`,
        dataIndex: benefit.key,
        render: (value, record, index) =>
          createValue(record.balance, record, benefit.benefit)
      },
      {
        width: 150,
        align: "right",
        title: "Valor Carga",
        dataIndex: benefit.key,
        render: (value, record) =>
          createValue(record.value, record, benefit.benefit)
      }
    ];
    if (benefit.companySpending) {
      cols.splice(2, 0, {
        width: 200,
        align: "right",
        title: `Gastos Pendentes`,
        dataIndex: benefit.key,
        render: (value, record, index) =>
          createValue(record.pendingExpense, record, benefit.benefit)
      });
    }
    return cols;
  };

  const benefitHasComplete = (benefit) => {
    return (
      data.data.filter(
        (d) => d.benefit.id === benefit.benefit && d.completeBalance
      ).length > 0
    );
  };

  const createTableCharge = () => {
    const type =
      data.solicitation.type.code === "E"
        ? "all"
        : data.data.length > 0 && !data.data[0].benefit.isBenefit
          ? "incentive"
          : "benefit";

    let _columns = [];
    _columns.push({
      fixed: "left",
      width: type === "incentive" ? "auto" : 250,
      key: "name",
      title: carFleet ? "Veículo" : "Colaborador",
      render: (value, row) =>
        carFleet ? (
          <ColumnVehicle
            name={value.vehicleName}
            licensePlate={value.vehicleLicensePlate}
            code={value.vehicleCode}
          />
        ) : (
          <ColumnEmployee name={value.name} email={row.email} cpf={row.cpf} />
        )
    });
    _columns.push({
      key: "transaction",
      width: 150,
      align: "center",
      dataIndex: "transaction",
      title: "Confirmação",

      filters: [
        { text: "Processado", value: "OK" },
        { text: "Não Processado", value: null },
        { text: "Cancelado", value: "CANCELADO" }
      ],
      onFilter: (value, record) => record.transaction === value
    });

    // Se for Incentivo (isBenefit = false), mostra coluna Descrição
    if (data.data[0].benefit.isBenefit === false) {
      _columns.push({
        key: "additionalDetails",
        width: 190,
        align: "left",
        dataIndex: "additionalDetails",
        title: "Descrição"
      });
    }

    let benefitsShow = getBenefits(type);
    if (
      login.company.benefitAccount &&
      data.solicitation.benefit &&
      data.solicitation.type.code != "E"
    ) {
      let benefitSel = benefitsShow.filter(
        (x) => x.benefit === data.solicitation.benefit.id
      )[0];
      benefitsShow = [
        benefitSel,
        ...(benefitSel.parent
          ? benefitsShow.filter((x) => x.benefit === benefitSel.parent)
          : [])
      ];
    }

    benefitsShow.forEach((x) => {
      if (x.completeBalance || (carFleet && benefitHasComplete(x))) {
        _columns.push({
          title: (
            <Row
              width="100%"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <Col>
                <BoxColor color={x.color} />
              </Col>
              <Col style={{ marginLeft: 10 }}>
                <Space>{x.name}</Space>
              </Col>
            </Row>
          ),
          children: loadColumnsCompleteBalance(x)
        });
      } else {
        _columns.push({
          align: "right",
          width: 200,
          key: x.benefit,
          dataIndex: x.key,
          title: () => (
            <Row
              width="100%"
              style={{ alignItems: "center", justifyContent: "right" }}
            >
              <Col>
                <BoxColor color={x.color} />
              </Col>
              <Col style={{ textAlign: "right", marginLeft: 10 }}>{x.name}</Col>
            </Row>
          ),
          render: (value, record, index) =>
            createValue(record.value, record, x.benefit)
        });
      }
    });

    setColumns(_columns);
  };

  const createTableRefund = () => {
    if (data.data.length > 0) {
      let _columns = [
        { key: "employee", dataIndex: "employee", title: "Colaborador" },
        {
          key: "createdAt",
          width: 130,
          dataIndex: "createdAt",
          title: "Data",
          render: (value) => <FormatDate value={value} />
        },
        {
          key: "value",
          width: 130,
          dataIndex: "value",
          title: "Valor",
          render: (value) => <FormatMoney value={value} />
        },
        {
          key: "valueNF",
          width: 130,
          dataIndex: "valueNF",
          title: "Valor a Pagar",
          render: (value) => <FormatMoney value={value} />
        },
        {
          key: "benefit",
          width: 180,
          dataIndex: "benefit",
          title: "Benefício"
        },
        {
          key: "option.name",
          width: 140,
          align: "center",
          title: "Opção",
          dataIndex: ["option", "name"]
        },
        {
          key: "status",
          width: 220,
          align: "center",
          dataIndex: "status",
          title: "Status",
          render: (value, row) => (
            <Tag color={row.category}>{value.toUpperCase()}</Tag>
          )
        }
      ];
      setColumns(_columns);
    }
  };

  const createTableSolicitation = () => {
    let _columns = [
      {
        key: "createdAt",
        width: 130,
        dataIndex: "createdAt",
        title: "Data",
        render: (value) => <FormatDate value={value} />
      },
      { key: "company.name", dataIndex: ["company", "name"], title: "Empresa" },
      {
        key: "approvedAt",
        width: 140,
        dataIndex: "approvedAt",
        title: "Confirmação",
        render: (value) => (value ? "OK" : null)
      },
      {
        key: "value",
        width: 130,
        dataIndex: "value",
        title: "Valor",
        render: (value) => <FormatMoney value={value} />
      }
    ];

    setColumns(_columns);
  };

  const createValue = (value, record, benefit) => {
    return record.benefit && benefit === record.benefit.id
      ? formatMoney(value)
      : formatMoney(0);
  };

  const getBenefits = (type) => {
    return type === "all"
      ? data.benefits
      : type === "incentive"
        ? data.benefits.filter((item) => !item.isBenefit && item.enabled)
        : data.benefits.filter((item) => item.isBenefit);
  };

  const createTable = () => {
    if (
      data.solicitation &&
      (data.solicitation.type.code === "C" ||
        data.solicitation.type.code === "E")
    ) {
      createTableCharge();
    } else if (data.solicitation && data.solicitation.type.code === "R") {
      createTableRefund();
    } else {
      createTableSolicitation();
    }
  };

  const onCancel = async () => {
    setData((x) => ({ ...x, loading: true }));
    let success = await cancelSolicitation(data.solicitation.id);
    setData((x) => ({ ...x, loading: false }));
    setCancelShow(false);
    if (success) {
      navigate("/solicitation");
    }
  };

  const bankSplit = async () => {
    // Set loading state
    setData((x) => ({ ...x, loading: true }));

    const _solicitation = await getBankSplit(data.solicitation.id);

    if (_solicitation.bankSlipUrl !== null) {
      const bankSplitId = _solicitation.bankSlipId;
      const bankSplitStatus = await getBankSplitStatus(bankSplitId);

      // Define bank slip status constants

      // Handle different bank slip statuses
      switch (bankSplitStatus) {
        case STATUS.INITIATED:
        case STATUS.REQUESTED:
        case STATUS.ENQUEUED:
        case STATUS.PROCESSING:
          notification.info({ message: MESSAGES.GENERATING });
          break;
        case STATUS.PROCESSING_PAYMENT:
        case STATUS.PAID:
          notification.info({ message: MESSAGES.PROCESSING });
          break;
        case STATUS.CREATED:
          await downloadBankSlip(_solicitation.bankSlipUrl);
          break;
        default:
          notification.info({ message: MESSAGES.ERROR });
      }
    }

    // Reset loading state
    setData((x) => ({ ...x, loading: false }));
  };

  const pixQRCode = async () => {
    if (data.solicitation.pixQRCode === null) {
      setData((prev) => ({ ...prev, loading: true }));
      const _solicitation = await getPixQRCode(data.solicitation.id);
      setData((prev) => ({ ...prev, loading: false }));
      if (_solicitation) {
        setData((prev) => ({ ...prev, solicitation: _solicitation }));
        setShowPix(true);
      }
    } else {
      setShowPix(true);
    }
  };

  const prepareFileResume = async () => {
    const businessUnits = await getBusinessUnits(data.solicitation.company.id);
    const teamLevels = extractTeamLevels(businessUnits);
    let ws_data = [];
    const type =
      data.data.length > 0 && !data.data[0].benefit.isBenefit
        ? "incentive"
        : "benefit";
    data.data.forEach((d) => {
      let tmp = {
        Nome: d.name,
        CPF: d.cpf,
        ...(isCodeEnabledInCompany && { Código: d.code }),
        ...mapTeamLevels(d.user, teamLevels),
        Email: d.email,
        Confirmacao: d.transaction
      };

      if (type === "incentive") {
        tmp["Descrição"] = d.additionalDetails;
      }

      getBenefits(type).forEach((x) => {
        if (x.completeBalance) {
          tmp[x.name + "-Valor Total"] =
            d.benefit.id === x.benefit ? d.total : 0;
          tmp[x.name + `-${translate("balance")} Disponivel`] =
            d.benefit.id === x.benefit ? d.balance : 0;
          tmp[x.name + "-Valor Carga"] =
            d.benefit.id === x.benefit ? d.value : 0;
        } else {
          tmp[x.name] = d.benefit.id === x.benefit ? d.value : 0;
        }
      });
      ws_data.push(tmp);
    });
    return ws_data;
  };

  const onResume = async () => {
    saveFileResume(
      await prepareFileResume(),
      "Pedido_" + data.solicitation.id,
      "Pedido de Carga"
    );
  };

  const saveDates = async (values) => {
    setData((x) => ({ ...x, loading: true }));

    let body = {
      data: {}
    };
    if (data.solicitation.payment.editable) {
      body.data.payment = {
        paymentAt: values.paymentDate.toDate()
      };
    }
    if (data.solicitation.approval.editable) {
      body.data.approval = {
        approvalAt: values.approvalDate.format("yyyy-MM-DD")
      };
    }

    try {
      await updateSolicitation(id, body);
      setDateShow(false);
      load();
    } catch (e) {
      console.log(e);
    } finally {
      setData((x) => ({ ...x, loading: false }));
    }
  };

  const calculateExpectationDates = (payment) => {
    if (!payment) {
      return moment().format("YYYY-MM-DD");
    }
    let expectation = moment(payment).add(3, "days");
    if (expectation.isoWeekday() === 6) {
      expectation = expectation.add(2, "days");
    } else if (expectation.isoWeekday() === 7) {
      expectation = expectation.add(1, "days");
    }
    return expectation.format("YYYY-MM-DD");
  };

  const onSave = async () => {
    setLoading(true);
    try {
      await saveSolicitation(
        id,
        { code: "A" },
        data.solicitation.invoiceUrl,
        data.solicitation.value
      );
      navigate("/solicitation");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onCancelLines = () => {
    if (selectedRowKeys.length > 0) {
      Modal.confirm({
        title: "Deseja continuar?",
        icon: <InfoCircleOutlined />,
        content:
          "Após a confirmação, você irá cancelar as linhas de carga e os colaboradores não receberam mais a carga informada.",
        onOk: async () => {
          const lines = selectedRowKeys.map((x) => {
            const line = data.data.find((d) => d.user == x);
            return {
              user: line.user,
              benefit: line.benefit.id,
              solicitation: line.solicitation
            };
          });
          await cancelChargeLines(id, lines);
          await load();
          setSelectedRowKeys([]);
        },
        onCancel() {
          setSelectedRowKeys([]);
        }
      });
    } else {
      Modal.info({
        icon: <InfoCircleOutlined />,
        content: "Nenhuma linha selecionada."
      });
    }
  };

  return (
    <Layout className="alymente-layout">
      <Header
        title={`Pedido: ${id}`}
        subtitle={`Total: ${formatMoney(sumTotal)} / Cancelado: ${formatMoney(sumCanceled)}`}
      >
        <Space>
          {/* {data.solicitation && (data.solicitation.type.code === 'T' || data.solicitation.type.code === 'R') && ( */}
          {data.solicitation && data.solicitation.status.code === "D" && (
            <Button
              disabled={
                data.loading ||
                !(data.solicitation && data.solicitation.bankSlip !== null)
              }
              type="primary"
              icon={<DollarCircleOutlined />}
              onClick={() => pixQRCode()}
            >
              Pagamento Pix
            </Button>
          )}
          {/* {data.solicitation && data.solicitation.type.code !== 'E' && ( */}
          {data.solicitation && data.solicitation.status.code === "D" && (
            <Button
              disabled={
                (data.solicitation && data.solicitation.pixQRCode !== null) ||
                data.loading
              }
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => bankSplit()}
            >
              Pagamento Boleto
            </Button>
          )}
          {data.solicitation && data.solicitation.type.code === "C" && (
            <Fragment>
              <Button
                disabled={data.loading}
                type="primary"
                icon={<SwapOutlined />}
                onClick={() => setTransferShow(true)}
              >
                Visualizar Transferência
              </Button>
              <Button
                disabled={data.loading}
                type="primary"
                icon={<DownloadOutlined />}
                onClick={() => onResume()}
              >
                Baixar Resumo
              </Button>
              <Button
                disabled={data.loading}
                type="primary"
                icon={<PrinterOutlined />}
                onClick={() => setPrintShow(true)}
              >
                Imprimir
              </Button>
            </Fragment>
          )}
        </Space>
      </Header>
      <Content>
        <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <Col sm={24}>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginBottom: 10
              }}
            >
              <Button
                style={{ marginRight: 10 }}
                size="small"
                type={"primary"}
                onClick={() => setSelectAll(!selectAll)}
              >
                {selectAll ? "Limpar Todos" : "Selecionar Todos"}
              </Button>
              <Typography.Text>
                Selecionados: {selectedRowKeys.length}/
                {data.data.length === 0
                  ? [data.solicitation].length
                  : data.data.length}
              </Typography.Text>
            </div>
            <div style={{ marginBottom: 10 }}>
              <TableSearch
                data={data.data}
                setHasInput={setHasSearchInput}
                filter={["cpf", "name"]}
                onSearch={(e) => setData((prev) => ({ ...prev, data: e }))}
              />
            </div>
          </Col>
        </Row>
        <Table
          rowKey={(record) => record?.user ?? "index"}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: setSelectedRowKeys,
            getCheckboxProps: (row) => ({
              disabled: row && row.transaction
            })
          }}
          loading={columns.length === 0 || data.loading}
          columns={columns}
          scroll={{ y: 630 }}
          dataSource={(() => {
            if (hasSearchInput && data.data.length === 0) {
              return [];
            } else {
              return data.data.length === 0 ? [data.solicitation] : data.data;
            }
          })()}
          pagination={{ defaultPageSize: 100 }}
          bordered
        />
      </Content>
      <Footer style={{ justifyContent: "space-between" }}>
        {data.solicitation && (
          <Space style={{ alignItems: "center" }}>
            <Typography.Title level={5}>Status:</Typography.Title>
            <Typography.Title level={5}>
              <Tag color={data.solicitation.status.category}>
                {data.solicitation.status.name.toUpperCase()}
              </Tag>
            </Typography.Title>
          </Space>
        )}
        <Space>
          {data.solicitation &&
            data.solicitation.type.code !== "E" &&
            (data.solicitation.status.code === "D" ||
              data.solicitation.status.code === "P") && (
              <Fragment>
                {(data.solicitation.status.code === "P" ||
                  data.solicitation.status.code === "D") &&
                  data.solicitation.type.code === "C" && (
                    <Button
                      disabled={data.loading}
                      type="primary"
                      danger
                      icon={<CloseSquareOutlined />}
                      onClick={onCancelLines}
                    >
                      Cancelar Linhas de Carga
                    </Button>
                  )}
                <Button
                  disabled={data.loading}
                  type="primary"
                  danger
                  icon={<CloseSquareOutlined />}
                  onClick={() => setCancelShow(true)}
                >
                  Cancelar Pedido
                </Button>
              </Fragment>
            )}
          {data.solicitation && data.solicitation.type.code === "C" && (
            <Fragment>
              {data.solicitation && data.solicitation.status.code === "P" && (
                <Button
                  disabled={data.loading}
                  loading={loading}
                  type="primary"
                  icon={<CheckCircleOutlined />}
                  onClick={onSave}
                >
                  Liberar Cargas
                </Button>
              )}
              {data.solicitation.status.code !== "H" &&
                data.solicitation.status.code !== "P" &&
                data.solicitation.status.code !== "I" && (
                  <Button
                    disabled={data.loading}
                    type="primary"
                    icon={<CalendarOutlined />}
                    onClick={() => setDateShow(true)}
                  >
                    Alterar Datas
                  </Button>
                )}
            </Fragment>
          )}
        </Space>
      </Footer>
      <Modal
        open={cancelShow}
        title="Cancelamento de pedido"
        onOk={onCancel}
        onCancel={() => setCancelShow(false)}
        confirmLoading={data.loading}
      >
        <Text>Confirma o cancelamento desse pedido?</Text>
      </Modal>
      <Modal
        open={transferShow}
        title="Transferência"
        onCancel={() => setTransferShow(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={false}
            onClick={() => setTransferShow(false)}
          >
            Fechar
          </Button>
        ]}
      >
        <Row gutter={6} style={{ marginBottom: 8 }}>
          <Col xl={12} align="right">
            <Typography.Text strong className="black">
              Valor Total:{" "}
            </Typography.Text>
          </Col>
          <Col xl={12}>
            <Typography.Text className="black">
              {data.solicitation ? formatMoney(data.solicitation.value) : ""}
            </Typography.Text>
          </Col>
        </Row>
        <Row gutter={6} style={{ marginBottom: 8 }}>
          <Col xl={12} align="right">
            <Typography.Text strong className="black">
              Crédito disponível:{" "}
            </Typography.Text>
          </Col>
          <Col xl={12}>
            <Typography.Text className="black">
              {data.solicitation ? formatMoney(data.solicitation.discount) : ""}
            </Typography.Text>
          </Col>
        </Row>
        <Row gutter={6} style={{ marginBottom: 20 }}>
          <Col xl={12} align="right">
            <Typography.Text strong className="black">
              Valor do Boleto:{" "}
            </Typography.Text>
          </Col>
          <Col xl={12}>
            <Typography.Text className="black">
              {data.solicitation ? formatMoney(data.solicitation.netValue) : ""}
            </Typography.Text>
          </Col>
        </Row>
        {data.fees.map((x) => (
          <Row gutter={6} style={{ marginBottom: 20 }}>
            <Col xl={12} align="right">
              <Typography.Text
                strong
                className="black"
              >{`Taxa de Colaborador ${x.month}: `}</Typography.Text>
            </Col>
            <Col xl={12}>
              <Typography.Text className="black">{`${x.quantity} x ${formatMoney(x.unitaryValue)} = ${formatMoney(x.value)}`}</Typography.Text>
            </Col>
          </Row>
        ))}
        <Row align="center">
          <Typography.Text align="center" type="secondary">
            A carga é realizada assim que o boleto for pago.{" "}
          </Typography.Text>
        </Row>
        <Row align="center">
          <Typography.Text align="center" type="secondary">
            Ao gerar o boleto pode demorar até 01 dia útil para ele aparecer no
            seu e-mail.
          </Typography.Text>
        </Row>
      </Modal>
      <Modal
        width={1000}
        open={printShow}
        title="Relatório de Carga"
        onCancel={() => setPrintShow(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={false}
            onClick={handlePrint}
          >
            Imprimir
          </Button>
        ]}
      >
        {data.solicitation && data.data && (
          <div ref={componentRef} className="print">
            <div className="print-content">
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td align="left" width={"50%"} className="smallInfos">
                      <Text className="black">{formatDate(new Date())}</Text>
                    </td>
                    <td align="left" width={"50%"} className="smallInfos">
                      <Text className="black">alymente</Text>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" className="smallInfos">
                      <Text strong>{data.solicitation.company.name}</Text>
                      <Text>
                        {" "}
                        - Carga {formatDate(data.solicitation.createdAt)}
                      </Text>
                    </td>
                  </tr>
                  <tr>
                    <td align="left">
                      <Typography.Title level={2} style={{ marginTop: 50 }}>
                        Relatório de Carga
                      </Typography.Title>
                    </td>
                  </tr>
                  <tr>
                    <td align="left">
                      <Typography.Title level={3}>
                        {data.solicitation.company.name}
                      </Typography.Title>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table style={{ width: "100%", marginTop: 20 }}>
                <tbody>
                  <tr>
                    <td align="center">
                      <Text className="black" style={{ fontSize: 21 }}>
                        Data da Carga
                      </Text>
                    </td>
                    <td align="center">
                      <Text className="black" style={{ fontSize: 21 }}>
                        Colaboradores
                      </Text>
                    </td>
                    <td align="center">
                      <Text className="black" style={{ fontSize: 21 }}>
                        Valor da Carga
                      </Text>
                    </td>
                    <td align="center">
                      <Text className="black" style={{ fontSize: 21 }}>
                        Valor do Pedido
                      </Text>
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      <Text className="black" strong style={{ fontSize: 21 }}>
                        {formatDate(data.solicitation.createdAt)}
                      </Text>
                    </td>
                    <td align="center">
                      <Text className="black" strong style={{ fontSize: 21 }}>
                        {data.data.length}
                      </Text>
                    </td>
                    <td align="center">
                      <Text className="black" strong style={{ fontSize: 21 }}>
                        {formatMoney(data.solicitation.value)}
                      </Text>
                    </td>
                    <td align="center">
                      <Text className="black" strong style={{ fontSize: 21 }}>
                        {formatMoney(data.solicitation.netValue)}
                      </Text>
                    </td>
                  </tr>
                </tbody>
              </table>
              {data.data.length > 0 && (
                <div className="table-detail" style={{ marginTop: 25 }}>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left" }}>
                          <Typography.Text strong className="white">
                            Nome
                          </Typography.Text>
                        </th>
                        <th style={{ textAlign: "left" }}>
                          <Typography.Text strong className="white">
                            CPF
                          </Typography.Text>
                        </th>
                        <th style={{ textAlign: "left" }}>
                          <Typography.Text strong className="white">
                            Benefício
                          </Typography.Text>
                        </th>
                        {data.data.filter((x) => x.balance != null).length >
                          0 && (
                          <th style={{ textAlign: "left" }}>
                            <Typography.Text strong className="white">
                              Total
                            </Typography.Text>
                          </th>
                        )}
                        {data.data.filter((x) => x.balance != null).length >
                          0 && (
                          <th style={{ textAlign: "left" }}>
                            <Typography.Text strong className="white">
                              {translate("balance")}
                            </Typography.Text>
                          </th>
                        )}
                        <th style={{ textAlign: "left" }}>
                          <Typography.Text strong className="white">
                            Valor Carga
                          </Typography.Text>
                        </th>
                        <th style={{ textAlign: "center" }}>
                          <Typography.Text strong className="white">
                            Confirmação
                          </Typography.Text>
                        </th>
                        {data.data.length > 0 &&
                          !data.data[0].benefit.isBenefit && (
                            <th style={{ textAlign: "left" }}>
                              <Typography.Text strong className="white">
                                Descrição
                              </Typography.Text>
                            </th>
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {data.data.map((l, index) => {
                        return (
                          <tr key={`${l.cpf}_${index}`}>
                            <td>
                              <Typography.Text className="black">
                                {l.name}
                              </Typography.Text>
                            </td>
                            <td>
                              <Typography.Text className="black">
                                {l.cpf}
                              </Typography.Text>
                            </td>
                            <td>
                              <Typography.Text className="black">
                                {l.benefit.name}
                              </Typography.Text>
                            </td>
                            {l.balance != null && (
                              <th style={{ textAlign: "left" }}>
                                <Typography.Text strong className="white">
                                  {formatMoney(l.total)}
                                </Typography.Text>
                              </th>
                            )}
                            {l.balance != null && (
                              <th style={{ textAlign: "left" }}>
                                <Typography.Text strong className="white">
                                  {formatMoney(l.balance)}
                                </Typography.Text>
                              </th>
                            )}
                            <td>
                              <Typography.Text className="black">
                                {formatMoney(l.value)}
                              </Typography.Text>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Typography.Text className="black">
                                {l.transaction ? l.transaction : ""}
                              </Typography.Text>
                            </td>
                            {data.data.length > 0 &&
                              !data.data[0].benefit.isBenefit && (
                                <td>
                                  <Typography.Text className="black">
                                    {l.additionalDetails
                                      ? l.additionalDetails
                                      : ""}
                                  </Typography.Text>
                                </td>
                              )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="print-footer">
              <div>
                <Logo justify="flex-start" />
              </div>
              <div className="alymente">
                <Typography.Text className="black" strong>
                  Alymente Benefícios e Similares LTDA
                </Typography.Text>
                <Typography.Text className="black">
                  <strong>CNPJ:</strong> 29.062.232/0001-56 —{" "}
                  <strong>PAT:</strong> 180642210
                </Typography.Text>
                <Typography.Text className="black">
                  Rua Funchal, 203, Conj 71
                </Typography.Text>
                <Typography.Text className="black">
                  Vila Olímpia, São Paulo/SP, CEP: 04551-904
                </Typography.Text>
              </div>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        open={dateShow}
        title="Alteração de Datas"
        onCancel={() => setDateShow(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={data.loading}
            onClick={() => form.submit()}
          >
            Salvar
          </Button>
        ]}
      >
        <Row justify="center" align="center">
          <Col xl={24}>
            <Form
              form={form}
              layout="vertical"
              name="basic"
              onFinish={saveDates}
              align="center"
            >
              <Space direction="vertical" size="large" align="center">
                <Spin spinning={data.loading}>
                  <Form.Item
                    label="Planejo pagar no dia"
                    name={["paymentDate"]}
                  >
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      disabled={
                        data.solicitation && data.solicitation.payment
                          ? !data.solicitation.payment.editable
                          : true
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Expectativa de Aprovação"
                    name={["expectedDate"]}
                  >
                    <Input disabled={true} autoComplete="off" />
                  </Form.Item>
                  <Form.Item
                    label="Apenas aprovar no dia"
                    name={["approvalDate"]}
                  >
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      disabled={
                        data.solicitation && data.solicitation.approval
                          ? !data.solicitation.approval.editable
                          : true
                      }
                    />
                  </Form.Item>
                </Spin>
              </Space>
            </Form>
          </Col>
        </Row>
      </Modal>
      <SolicitationPix
        open={showPix}
        onClose={() => setShowPix(false)}
        pixQRCode={
          data.solicitation && data.solicitation.pixQRCode
            ? data.solicitation.pixQRCode
            : null
        }
      />
    </Layout>
  );
};

export default connect(mapStateToProps, {})(SolicitationReportScreen);
