import { SaveOutlined } from "@ant-design/icons";
import * as balanceAction from "actions/balance-action";
import {
  Button,
  Col,
  Layout,
  Modal,
  notification,
  Row,
  Select,
  Table,
  Tag
} from "antd";
import {
  CardInfo,
  FormatDate,
  FormatMoney,
  Header,
  TableSearch
} from "components";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  getAllAdvances,
  getAllAdvancesByBusinessUnit
} from "services/advance-service";
import { getAllBenefitCharge } from "services/benefit-service";
import { formatMoney } from "utils";
import { isOnlyManager, ManagerType } from "utils/managers";
import { ADVANCE_STATUS, ADVANCE_STATUS_COLOR } from "utils/static-data";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer,
  balance: state.balanceReducer
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...balanceAction
    },
    dispatch
  );

const SolicitationAdvanceScreen = (props) => {
  const navigate = useNavigate();
  const { login } = props;
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [advances, setAdvances] = React.useState([]);
  const [initialData, setInitialData] = React.useState([]);
  const [isBenefitModalOpen, setIsBenefitModalOpen] = React.useState(false);
  const [benefits, setBenefits] = React.useState(null);
  const [selectedBenefit, setSelectedBenefit] = React.useState(null);

  const getSelectedAdvances = () => {
    const allAdvances = new Map();
    advances.forEach((advance) => {
      allAdvances.set(advance.id, advance);
    });
    return selectedRowKeys.map((advanceId) => {
      return allAdvances.get(advanceId);
    });
  };
  const getTotalAmount = () => {
    return getSelectedAdvances().reduce((partialTotal, advance) => {
      return (partialTotal += advance.amount ?? 0);
    }, 0);
  };
  const totalValue = getTotalAmount();

  const onContinue = () => {
    if (selectedRowKeys.length === 0) {
      notification.error({ message: "Selecione ao menos um colaborador." });
    } else {
      const employees = getSelectedEmployees().map((employee) => ({
        id: employee.employeeId,
        name: employee.employeeName,
        email: employee.employeeEmail,
        cpf: employee.employeeDocument,
        phone: employee.employeePhone,
        account: employee.employeeAccount,
        amount: employee.amount
      }));
      const selectedAdvanceIds = getSelectedAdvances().map(
        (advance) => advance.id
      );
      navigate(`save`, {
        state: {
          benefit: null,
          alreadySelectedBenefit: selectedBenefit,
          employees: employees,
          businessUnit: null,
          selectedAdvanceIds
        }
      });
    }
  };

  const getTotalPerUser = () => {
    const userToAmountMap = new Map();
    getSelectedAdvances().forEach((advance) => {
      const employeeId = advance.employee.id;
      if (!userToAmountMap.has(employeeId)) {
        userToAmountMap.set(employeeId, 0);
      }
      const partialTotal = userToAmountMap.get(employeeId);
      userToAmountMap.set(employeeId, partialTotal + advance.amount);
    });
    return Array.from(userToAmountMap.entries());
  };
  const getSelectedEmployees = () => {
    const selectedEmployees = new Map();
    getSelectedAdvances().forEach((advance) => {
      if (!selectedEmployees.has(advance.employee.id)) {
        selectedEmployees.set(advance.employee.id, {
          employeeId: advance.employee.id,
          employeeName: advance.employee.name,
          employeeEmail: advance.employee.email,
          employeeDocument: advance.employee.document,
          employeePhone: advance.employee.phone,
          employeeAccount: advance.employee.account
        });
      }
    });
    getTotalPerUser().forEach(([employeeId, amount]) => {
      const employee = selectedEmployees.get(employeeId);
      employee.amount = amount;
      selectedEmployees.set(employeeId, employee);
    });
    return Array.from(selectedEmployees.values());
  };
  const loadBenefits = React.useCallback(async () => {
    const res = await getAllBenefitCharge(login.company.id);
    const benefits = res.benefits.filter((b) => b.benefit == -1);
    setBenefits(benefits);
  });

  const onConfirm = () => {
    setIsBenefitModalOpen(true);
  };

  const loadAdvances = async () => {
    const isTeamManager = isOnlyManager(login.user, ManagerType.TEAM);
    const advances = isTeamManager
      ? await getAllAdvancesByBusinessUnit(login.company.id, "approved")
      : await getAllAdvances("approved");
    setAdvances(advances);
    setInitialData(advances);
  };
  const loadAll = async () => {
    setLoading(true);
    await loadAdvances();
    await loadBenefits();
    setLoading(false);
  };

  const changeRowTable = (rows) => {
    setSelectedRowKeys(rows);
  };

  React.useEffect(() => {
    loadAll();
  }, []);

  return (
    <Layout className="alymente-layout">
      <Header
        title="Selecione os adiantamentos para realizar carga pedido"
        subtitle="Selecione os adiantamentos aprovados que deseja realizar carga."
      >
        <Row gutter={12} align="middle">
          <Col xl={4}>
            <CardInfo
              title="Selecionados"
              value={`${selectedRowKeys.length}/${advances.length}`}
            />
          </Col>
          <Col xl={4}>
            <CardInfo title="Valor Total" value={formatMoney(totalValue)} />
          </Col>
        </Row>
      </Header>
      <Content>
        <Table
          rowKey={"id"}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: changeRowTable
          }}
          title={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px"
              }}
            >
              <TableSearch
                data={advances}
                initialData={initialData.data}
                filter={["amount", ["employee", "name"]]}
                onSearch={(e) => {
                  setSelectedRowKeys([]);
                  setAdvances(e);
                }}
              />
            </div>
          )}
          scroll={{ y: "50vh" }}
          dataSource={advances}
          loading={loading}
          pagination={false}
          bordered
        >
          <Table.Column title="Nome" dataIndex={["employee", "name"]} />
          <Table.Column
            width={130}
            title="Data"
            dataIndex="createdAt"
            key="createdAt"
            render={(value) => <FormatDate value={value} />}
          />
          <Table.Column
            width={130}
            title="Valor"
            dataIndex="amount"
            key="value"
            render={(value) => <FormatMoney value={value} />}
          />
          <Table.Column
            width={60}
            align="center"
            title="Status"
            dataIndex="status"
            key="status"
            render={(value, record) =>
              record.isOverdue ? (
                <Tag color="error">VENCIDO</Tag>
              ) : (
                <Tag color={ADVANCE_STATUS_COLOR[value]}>
                  {ADVANCE_STATUS[value].toUpperCase()}
                </Tag>
              )
            }
          />
        </Table>
        <Modal
          open={isBenefitModalOpen}
          title="Selecione o benefício para o qual deseja fazer a carga"
          okText="Confirmar"
          okButtonProps={{
            loading: loading
          }}
          cancelButtonProps={{ disabled: loading }}
          onCancel={() => setIsBenefitModalOpen(false)}
          onOk={() => onContinue()}
        >
          <Select
            placeholder="Selecione um benefício"
            allowClear
            style={{ width: "100%" }}
            onChange={(value) => {
              setSelectedBenefit(value);
            }}
          >
            {benefits?.map((benefit) => {
              return (
                <Select.Option key={benefit.benefit} value={benefit.benefit}>
                  {benefit.name}
                </Select.Option>
              );
            })}
          </Select>
        </Modal>
      </Content>
      <Footer>
        <Button
          className="btn-save"
          type="primary"
          icon={<SaveOutlined />}
          onClick={onConfirm}
        >
          Fazer Pedido
        </Button>
      </Footer>
    </Layout>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolicitationAdvanceScreen);
