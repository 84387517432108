import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SVGLogoAlymente } from 'assets/img/logo2.svg';
import { ReactComponent as SVGLogoAlyPlus } from 'assets/img/aly_plus_dark_logo.svg';
import { ReactComponent as SVGLogoAlyPlusLight } from 'assets/img/aly_plus_light_logo.svg';
import environment from 'env';

const Logo = (props) => {
	const { height, width, justify } = props;
	return (
		<div style={{ display: 'flex', justifyContent: justify }}>
			<SVGLogoAlymente
				height={height}
				width={width}
				className="logo-login"
			/>
		</div>
	);
};

Logo.defaultProps = {
	height: 55,
	justify: 'center',
};

Logo.propTypes = {
	height: PropTypes.number,
	width: PropTypes.number,
	justify: PropTypes.string,
};

export { Logo };
