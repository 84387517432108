import React, { Fragment } from "react";
import { useOutletContext } from "react-router-dom";
import { Layout, Table, Switch, Button, Row, Col, Tooltip } from "antd";
import {
  SaveOutlined,
  EditFilled,
  InfoCircleOutlined
} from "@ant-design/icons";
import { getAllBenefit, saveBenefit } from "services/benefit-service";
import { translate } from "config/language";

const { Footer, Content } = Layout;

const DataFetch = {
  data: [],
  loading: false
};

const IncentiveScreen = () => {
  const { company } = useOutletContext();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(DataFetch);
  const [editManager, setEditManager] = React.useState({
    show: false,
    row: null
  });

  React.useEffect(() => {
    load();
  }, [company]);

  const load = async () => {
    if (company) {
      setData((x) => ({ ...x, loading: true }));
      const res = await getAllBenefit(company);
      setData({ data: res.filter((x) => !x.isBenefit), loading: false });
    }
  };

  const onEdit = (value, index, field) => {
    const newData = [...data.data];
    newData[index][field] = value;
    setData((x) => ({ ...x, data: newData }));
  };

  const onSave = async () => {
    setLoading(true);
    const res = await getAllBenefit(company);
    await saveBenefit(
      company,
      res.map((x) => {
        const find = data.data.find((y) => x.benefit === y.benefit);
        if (find) return find;
        else return x;
      })
    );
    await load();
    setLoading(false);
  };

  const loadManager = (row) => {
    setEditManager({ show: true, row: row });
  };

  const onSaveManager = async (value) => {
    const newData = [...data.data];
    const find = newData.find((x) => x.benefit === value.benefit);
    if (find) {
      find.manager = value.manager;
    }
    setData((prev) => ({ ...prev, data: newData }));
    await onSave();
    onCloseManager();
  };

  const onCloseManager = () => {
    setEditManager({ show: false, row: null });
  };

  return (
    <React.Fragment>
      <Content>
        <Table
          scroll={{ y: "60vh" }}
          dataSource={data.data}
          loading={data.loading}
          pagination={false}
          bordered
        >
          <Table.Column
            title="Benefício"
            dataIndex="name"
            key="name"
            render={(value, record, index) => {
              return (
                <Fragment>
                  {value}
                  {record.account != null && (
                    <Tooltip
                      placement="bottom"
                      title={`Incentivo possui ${translate("balance")} específico`}
                    >
                      <InfoCircleOutlined style={{ marginLeft: 10 }} />
                    </Tooltip>
                  )}
                </Fragment>
              );
            }}
          />
          <Table.Column
            align="center"
            title="Status"
            dataIndex="enabled"
            key="enabled"
            width={200}
            render={(value, record, index) => (
              <Switch
                checked={value}
                onChange={(x) => onEdit(x, index, "enabled")}
              />
            )}
          />
        </Table>
      </Content>
      <Footer>
        <Button
          loading={loading}
          className="btn-save"
          type="primary"
          icon={<SaveOutlined />}
          onClick={onSave}
        >
          Salvar
        </Button>
      </Footer>
    </React.Fragment>
  );
};

export default IncentiveScreen;
