export const config = {
    0: {
        step: "Cadastro",
        title: "Insira os dados adicionais da sua empresa.",
        subtitle: "Para começar, insira os dados adicionais da sua empresa.",
        route: "/activation/company"
    },
    1: {
        step: "Sócios",
        title: "Insira os dados dos sócios.",
        subtitle: "Para avançar, você precisa inserir os dados dos sócios da sua empresa.",
        route: "/activation/shareholder"
    },
    2: {
        step: "Colaboradores",
        title: "Insira os dados obrigatórios de todos os seus colaboradores.",
        subtitle: "Para avançar, você precisa inserir o número previamente informado.",
        route: "/activation/user"
    },
    3: {
        step: "Cartões",
        title: "Faça o pedido dos cartões e pague o boleto para ele ser efetuado.",
        subtitle: "Os cartões serão enviados para o endereço da sua empresa.",
        route: "/activation/card"
    },
    4: {
        step: "Carga",
        title: "Faça o pedido de carga dos seus colaboradores.",
        subtitle: "Eles já podem usar Alymente antes mesmo dos cartões chegarem.",
        route: "/activation/charge"
    },
    5: {
        step: "Pedidos",
        title: "Pague os boletos para finalizar a ativação.",
        subtitle: "Assim que pagar pode levar até 3 dias úteis bancários para serem aprovados.",
        route: "/activation/solicitation"
    },
}