import axios from "axios";
import { findPropObject, handlerError } from "utils";
import { notification } from "antd";
import moment from "moment";
import env from "env";

export const getAllVehicles = async () => {
  try {
    const res = await axios({
      method: "get",
      url: "/api-core/vehicle"
    });

    return res.data ?? [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getAllConfiguredVehicles = async () => {
  try {
    const res = await axios({
      method: "get",
      url: "/api-core/vehicle/configured"
    });

    return res.data ?? [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getVehicle = async (vehicle) => {
  try {
    const res = await axios({
      method: "get",
      url: "/api-core/vehicle/" + vehicle
    });
    return res.data ? res.data : {};
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const vehicleRedeem = async (
  vehicleData,
  type,
  toBenefit,
  benefit,
  value
) => {
  try {
    const { data } = await axios.post(`/api/solicitation/redeemVehicle`, {
      ...vehicleData,
      type: type,
      toBenefit: toBenefit,
      benefit: benefit,
      value: value
    });
    return data;
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};

export const saveVehicle = async (id = null, body) => {
  try {
    const req = {
      ...body
    };
    let res = null;
    if (id) {
      res = await axios({
        method: "put",
        url: "/api-core/vehicle/" + id,
        data: req
      });
    } else {
      res = await axios({
        method: "post",
        url: "/api-core/vehicle",
        data: req
      });
    }
    notification.success({
      message: "Dados do veículo salvo com sucesso!"
    });
    return res.data ? res.data : {};
  } catch (ex) {
    handlerError(ex);
    throw null;
  }
};

export const getCardById = async (id) => {
  try {
    const res = await axios({
      method: "get",
      url: "/api-core/card/" + id
    });
    return res.data ? res.data : {};
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const unblockCardVehicle = async (vehicle, params) => {
  try {
    const res = await axios({
      method: "post",
      url: `/api-core/vehicle/${vehicle}/card/${params.cardId}/active`,
      data: {}
    });

    notification.success({
      message: "Cartão desbloqueado com sucesso!"
    });
    return {};
    // return res.data ? res.data : null;
  } catch (ex) {
    if (ex && ex.status == 201) {
      notification.success({
        message: "Cartão desbloqueado com sucesso!"
      });
      return {};
    }
    handlerError(ex);
    throw null;
  }
};

export const blockCardVehicle = async (vehicle, params) => {
  try {
    const res = await axios({
      method: "post",
      url: `/api-core/vehicle/${vehicle}/card/${params.cardId}/block`,
      data: {}
    });

    notification.success({
      message: "Cartão bloqueado com sucesso!"
    });
    return {};
    // return res.data ? res.data : null;
  } catch (ex) {
    if (ex && ex.status == 201) {
      notification.success({
        message: "Cartão bloqueado com sucesso!"
      });
      return {};
    }
    handlerError(ex);
    throw null;
  }
};

export const activeCardVehicle = async (vehicle, params) => {
  try {
    const res = await axios({
      method: "post",
      url: `/api-core/vehicle/${vehicle}/card/${params.card}/create`,
      data: {
        pin: params.password
      }
    });
    // const res_pass = await axios({
    //     method:'post',
    //     url:'card/password',
    //     baseURL: 'http://localhost:5000/',
    //     data: {
    //         vehicle: vehicle,
    //         pin: params.password,
    //         card: params.card,
    //     }
    //    });

    notification.success({
      message: "Cartão ativado com sucesso!"
    });
    return {};
    // return res.data ? res.data : null;
  } catch (ex) {
    if (ex && ex.status == 201) {
      notification.success({
        message: "Cartão criado com sucesso!"
      });
      return {};
    }
    handlerError(ex);
    throw null;
  }
};

export const updatePasswordVehicle = async (vehicle, params) => {
  try {
    const res_pass = await axios({
      method: "post",
      url: `/api-core/vehicle/${vehicle}/card/${params.card}/reset-password`,
      data: {
        pin: params.password
      }
    });
    notification.success({
      message: "Senha de cartão alterada com sucesso!"
    });
    return {};
    // return res.data ? res.data : null;
  } catch (ex) {
    if (ex && ex.status == 201) {
      notification.success({
        message: "Senha de cartão alterada com sucesso!"
      });
      return {};
    }
    handlerError(ex);
    throw null;
  }
};

export const getVehicleBalance = async (vehicle) => {
  try {
    const res = await axios({
      method: "get",
      url: `/api-core/vehicle/${vehicle}/balance`
    });

    let map = res.data.details.map((d) => {
      return {
        balance: d.balance,
        nameBenefit: d.benefit.name,
        benefit: d.benefit.id
      };
    });
    return map ?? [];
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};
export const linkVehicle = async (vehicleId, employeeId) => {
  try {
    const res = await axios({
      method: "post",
      url: `/api-core/vehicle/${vehicleId}/employee/${employeeId}/link`
    });
    notification.success({ message: "Veículo vinculado com sucesso." });
  } catch (ex) {
    if (ex && ex.status == 201) {
      notification.success({
        message: "Veículo vinculado com sucesso."
      });
      return {};
    }
    handlerError(ex);
  }
};

export const unlinkVehicle = async (vehicleId, employeeId) => {
  try {
    const res = await axios({
      method: "post",
      url: `/api-core/vehicle/${vehicleId}/employee/${employeeId}/unlink`
    });
  } catch (ex) {
    if (ex && ex.status == 201) {
      notification.success({
        message: "Veículo desvinculado com sucesso."
      });
      return {};
    }
    handlerError(ex);
  }
};

export const getStatement = async (
  vehicle,
  company,
  page,
  dateInitial,
  dateFinal
) => {
  let params = {};

  if (page !== null) {
    params["page"] = page;
  }
  if (dateInitial !== null) {
    params["startPeriod"] = dateInitial;
  }
  if (dateFinal !== null) {
    params["endPeriod"] = dateFinal;
  }
  if (vehicle) {
    params["isVehicle"] = true;
  }

  try {
    const res = await axios({
      method: "get",
      url: `/api/statement/${company.externalKey}/${company.businessAccount}${vehicle ? "/" + vehicle.account : ""}`,
      params: params
    });

    return res.data.transactions ?? [];
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getVehicleEmployee = async (vehicleId) => {
  try {
    const res = await axios({
      method: "get",
      url: `/api-core/vehicle/${vehicleId}/employee`
    });

    return res.data ?? [];
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getEmployeeVehicle = async () => {
  try {
    const res = await axios({
      method: "get",
      url: `/api-core/vehicle/employee`
    });

    return res.data ?? [];
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getReportVehicleComposition = async () => {
  try {
    const res = await axios({
      method: "get",
      url: `/api-core/vehicle/report/composition`
    });

    return res.data ?? [];
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};
