import React from "react";
import { Select, Spin } from "antd";
import PropTypes from "prop-types";
import { searchCities } from "services/city-service";

const AutoCompleteCity = (props) => {
  const { value, onChange, onSelectedItem } = props;
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [valueLocal, setValueLocal] = React.useState(null);

  const load = async (query) => {
    setLoading(true);
    if (props.setIsOptionLoading) {
      props.setIsOptionLoading(true);
    }
    setOptions(await searchCities(query));
    setLoading(false);
    if (props.setIsOptionLoading) {
      props.setIsOptionLoading(false);
    }
  };

  React.useEffect(() => {
    load("");
  }, []);

  React.useEffect(() => {
    if (value && options.length > 0) {
      const obj = options.find((x) => x.name === value);
      if (obj) {
        setValueLocal(obj.code);
      }
    }
  }, [value, options]);

  const onChangeLocal = (e) => {
    setValueLocal(e);
    const obj = options.find((x) => x.code === e);
    if (onChange) {
      onChange(obj ? obj.name : null);
    }
    if (onSelectedItem) {
      onSelectedItem(obj);
    }
  };

  return (
    <Select
      allowClear
      optionLabelProp="label"
      notFoundContent={loading ? <Spin size="small" /> : null}
      style={{ width: "100%" }}
      placeholder="Selecione uma cidade"
      value={valueLocal}
      onChange={onChangeLocal}
      showSearch
      filterOption={(input, option) => {
        return (
          option &&
          option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >=
            0
        );
      }}
    >
      {options.map((x) => (
        <Select.Option key={x.code} value={x.code} label={x.name}>
          {`${x.name} - ${x.state}`}
        </Select.Option>
      ))}
    </Select>
  );
};

AutoCompleteCity.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSelectedItem: PropTypes.func
};

export { AutoCompleteCity };
