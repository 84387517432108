export const setExpenseFilter =
   ({ benefit = null, dates = [] }) =>
   (dispatch) => {
      const payload = {
         benefit: benefit,
         dates: dates,
      };
      dispatch({ type: 'EXPENSE_FILTER_SOLICITATION', payload: payload });
   };

export const clearExpenseFilter = (payload) => (dispatch) => {
   dispatch({ type: 'EXPENSE_FILTER_SOLICITATION_CLEAR', payload: payload });
};
