import React from "react";
import { Form as AntdForm, notification } from "antd";

const Form = (props) => {
    const { children, onFinishFailed } = props;

    const _onFinishFailed = (error) => {
        if (error.errorFields.length > 0) {
            notification.error({
                message: "Dados inválidos!",
            })
            if (onFinishFailed) {
                onFinishFailed()
            }
        }
    }

    return (
        <AntdForm {...props} scrollToFirstError={true} onFinishFailed={_onFinishFailed}>
            {children}
        </AntdForm>
    )
};

//Item
Form.Item = AntdForm.Item;
Form.Item.propTypes = { ...AntdForm.Item.propTypes }

//useForm
Form.useForm = AntdForm.useForm;
Form.useForm.propTypes = { ...AntdForm.useForm.propTypes }

//useWatch
Form.useWatch = AntdForm.useWatch;
Form.useWatch.propTypes = { ...AntdForm.useWatch.propTypes }

Form.propTypes = { ...AntdForm.propTypes };

export { Form };