import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import React, { useMemo, useRef, useState } from 'react';
import { search } from "services/user-service";

function DebounceSelect({ fetchOptions, debounceTimeout = 500, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}


const AutoCompleteUser = (props) => {
    const { onChange } = props;
    const [valueLocal, setValueLocal] = useState([]);

    const fetchUserList = async (query) => {
        return search(query).then(b => b.map(user => ({
            label: user.name,
            value: user.id,
        })))
    };

    return (
        <DebounceSelect
            allowClear
            showSearch
            value={valueLocal}
            placeholder="Colaboradores"
            fetchOptions={fetchUserList}
            onChange={(newValue) => {
                setValueLocal(newValue);
                onChange(newValue ? newValue.value : null);
            }}
            style={{
                width: '100%',
            }}
        />
    );
};

export { AutoCompleteUser };