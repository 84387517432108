import React from "react";
import { connect } from "react-redux";
import { Layout, Button, Typography, Modal, Table, Select, Space } from 'antd';
import { Header, InputCurrency, FormatMoney, TableSearch } from "components";
import { useNavigate } from "react-router-dom";
import { getTreasury } from "services/solicitation-service";
import { createPixQRCodeMaster } from "services/company-pix-qrcode-service";
import { SolicitationPix } from './solicitation-pix';
import moment from "moment"
import { AccountSystem } from 'utils/account-system';

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer,
    balance: state.balanceReducer,
    config: state.configReducer,
});

const DataFetch = {
    data: [],
    loading: false
}

const SolicitationTreasuryScreen = (props) => {
    const navigate = useNavigate();
    const { balance, config } = props;
    const [data, setData] = React.useState(DataFetch)
    const [pix, setPix] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [filter, setFilter] = React.useState(moment())
    const [accountSystem, setAccountSystem] = React.useState(AccountSystem.SWAP)
    const [showPix, setShowPix] = React.useState({ open: false, qrcode: null })
    const [loading, setLoading] = React.useState(false);

    const filterData = (date) => {
        setFilter(date)
    }

    const load = async () => {
        setData((x) => ({ ...x, loading: true }));
        const res = await getTreasury(filter.format('YYYY-MM-DD'));
        setData({ data: res.filter(x => x.system.code === accountSystem), loading: false });
    }

    const onPix = async () => {
        setLoading(true)
        const res = await createPixQRCodeMaster(value);
        setLoading(true)
        if (res) {
            setPix(false);
            setShowPix({ open: true, qrcode: res });
        }
    }

    React.useEffect(() => {
        load();
    }, [])

    React.useEffect(() => {
        load();
    }, [filter, accountSystem])

    return (
        <Layout className="alymente-layout">
            <Header title={"Tesouraria"} subtitle="Resumo de tesouraria diário">
            </Header>
            <Content>
                <Table scroll={{ y: 450 }} dataSource={data.data} loading={data.loading} pagination={false} bordered
                    title={() => <Space style={{ display: 'flex', justifyContent: 'right' }}>
                        <TableSearch type="date" value={filter}
                            onSearch={(e) => filterData(e)} justify={'start'} />
                        <Select placeholder="Selecione um sistema de conta" style={{ minWidth: 300 }} value={accountSystem} onChange={(value) => setAccountSystem(value)}>
                            {config.system.map(x => <Select.Option value={x.code}>{x.name}</Select.Option>)}
                        </Select>
                    </Space>
                    }>
                    <Table.Column align={"left"} title="Tipo" dataIndex="name" key="name" render={(value, record, index) => index == (data.data.length - 1) ? <Text strong>{value}</Text> : value} />
                    <Table.Column width={150} align={"right"} title="Entrada" dataIndex="input" key="input" render={(value, record, index) => <FormatMoney strong={index == (data.data.length - 1) ? true : false} value={value} />} />
                    <Table.Column width={150} align={"right"} title="Saída" dataIndex="output" key="output" render={(value, record, index) => <FormatMoney strong={index == (data.data.length - 1) ? true : false} value={value} />} />
                    <Table.Column width={150} title="Sistema" dataIndex={['system', 'name']} key="system.name" />
                </Table>
            </Content>
            {accountSystem === AccountSystem.SWAP &&
                <Footer>
                    <Button type="primary" onClick={() => setPix(true)}>Gerar PIX de Tesouraria</Button>
                </Footer>
            }
            <Modal
                width={500}
                open={pix}
                title="Geração de PIX de Tesouraria"
                onCancel={() => setPix(false)}
                footer={
                    [
                        <Button key="submit" type="primary" loading={loading} onClick={onPix}>
                            Gerar
                        </Button>
                    ]}
            >
                <Typography.Text>Informe o valor total de tesouraria para geração do PIX</Typography.Text>
                <InputCurrency value={value} onChange={(val) => setValue(val)} />
            </Modal>
            <SolicitationPix open={showPix.open} pixQRCode={showPix.qrcode} onClose={() => setShowPix({ open: false, qrcode: null })} />
        </Layout>
    )
}

export default connect(mapStateToProps, {})(SolicitationTreasuryScreen); 