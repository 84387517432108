import React from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getAllUsers } from "services/user-service";
import { createCompanyCard } from "services/card-service";
import { getBankSplit } from "services/solicitation-service";
import { Layout, Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import SolicitationRevisionScreen from "views/solicitation/solicitation-revision-screen";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const ActivationCheckoutCardScreen = (props) => {
  const { login } = props;
  const [usersTotal, setUsersTotal] = React.useState(0);
  const [data, setData] = React.useState(null);
  const { onNext, childRef } = useOutletContext();
  const [loading, setLoading] = React.useState(false);

  const onSubmit = () => {
    setLoading(true);
    childRef.current.submit();
  };

  const onAfterSubmit = (submit = false, solicitation = null) => {
    if (submit && solicitation) {
      downloadBankSlip(solicitation);
    } else {
      setLoading(false);
    }
  };

  const load = async (filter = "") => {
    const res = await getAllUsers();
    if (res) {
      setUsersTotal(res.length);
    }
  };

  const downloadBankSlip = async (solicitation) => {
    const res = await getBankSplit(solicitation);
    if (res) {
      window.open(res.bankSlipUrl, "_blank");
      onNext();
    }
  };

  const onSolicitationLines = async () => {
    const resCreate = await createCompanyCard(login.company.id, {
      data: { quantity: usersTotal, tracking: true, name: login.company.name }
    });
    if (resCreate !== null) {
      return [{ id: resCreate }];
    }
    return [];
  };

  React.useEffect(() => {
    setData(createRevision());
  }, [usersTotal > 0]);

  React.useEffect(() => {
    load();
  }, [login]);

  const createRevision = () => {
    const createCheckout = () => {
      const cardCost = usersTotal * login.company.cardCost;
      const deliveryCost = usersTotal < 20 ? login.company.deliveryCost : 0;
      const total = deliveryCost + cardCost;
      return [
        { label: "Quantidade de Cartões", quantity: usersTotal },
        { label: "Custo dos Cartões", value: cardCost },
        { label: "Custo da Entrega", value: deliveryCost },
        {
          total: true,
          discount: false,
          hasPayment: total > 0,
          label: "Valor Total",
          value: total
        }
      ];
    };

    return {
      request: {
        url: "/api/solicitation/cards",
        method: "post"
      },
      body: {
        solicitation: {
          lines: [], //getLines(),
          discount: false,
          company: login.company.id
        },
        data: {}
      },
      checkout: createCheckout(),
      type: "card",
      successMessage:
        "Assim que você pagar esse boleto os cartões serão enviados."
    };
  };

  if (data) {
    return (
      <React.Fragment>
        <Content>
          <SolicitationRevisionScreen
            compact={true}
            data={data}
            ref={childRef}
            onAfterSubmit={onAfterSubmit}
            onSolicitationLines={onSolicitationLines}
          />
        </Content>
        <Footer>
          <Button
            loading={loading}
            type="primary"
            icon={<ArrowRightOutlined />}
            onClick={onSubmit}
          >
            Próxima Etapa
          </Button>
        </Footer>
      </React.Fragment>
    );
  } else {
    return undefined;
  }
};

export default connect(mapStateToProps, {})(ActivationCheckoutCardScreen);
