import axios from "axios";
import { notification } from "antd";
import { handlerError } from "utils";

export const getBusinessUnits = async (company) => {
  try {
    var params = {};

    if (company) {
      params = { params: { company } };
    }

    const res = await axios.get(`/api/businessunit`, params);
    return res.data.lines && res.data.lines.length > 0 ? res.data.lines : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const saveBusinessUnit = async (id, values, company) => {
  try {
    const req = {
      id: values.id,
      businessUnitCode: values.businessUnitCode ?? null,
      name: values.name,
      manager: values.manager,
      level: values.level
    };
    if (id) {
      await axios.put(`/api/businessunit/${id}`, req);
    } else {
      if (company) {
        req.company = company;
      }
      await axios.post(`/api/businessunit`, req);
    }
    notification.success({
      message: "Salvo com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const saveUserUnit = async (id, data) => {
  try {
    await axios.put(`/api/company/team/${id}`, data);
    notification.success({
      message: "Colaboradores de Unidade de Negócio atualizados com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const saveCompanyTeamLevel = async (data) => {
  try {
    await axios.put(`/api/company/team/level`, data);
    notification.success({
      message: "Salvo com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};
