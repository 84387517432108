export const VEHICLE_BRAND_CAR = [
  { code: "1", type: "car", name: "Acura" },
  { code: "2", type: "car", name: "Agrale" },
  { code: "3", type: "car", name: "Alfa Romeo" },
  { code: "4", type: "car", name: "AM Gen" },
  { code: "5", type: "car", name: "Asia Motors" },
  { code: "189", type: "car", name: "ASTON MARTIN" },
  { code: "6", type: "car", name: "Audi" },
  { code: "207", type: "car", name: "Baby" },
  { code: "7", type: "car", name: "BMW" },
  { code: "8", type: "car", name: "BRM" },
  { code: "123", type: "car", name: "Bugre" },
  { code: "238", type: "car", name: "BYD" },
  { code: "236", type: "car", name: "CAB Motors" },
  { code: "10", type: "car", name: "Cadillac" },
  { code: "245", type: "car", name: "Caoa Chery" },
  { code: "161", type: "car", name: "Caoa Chery/Chery" },
  { code: "11", type: "car", name: "CBT Jipe" },
  { code: "136", type: "car", name: "CHANA" },
  { code: "182", type: "car", name: "CHANGAN" },
  { code: "12", type: "car", name: "Chrysler" },
  { code: "13", type: "car", name: "Citroën" },
  { code: "14", type: "car", name: "Cross Lander" },
  { code: "241", type: "car", name: "D2D Motors" },
  { code: "15", type: "car", name: "Daewoo" },
  { code: "16", type: "car", name: "Daihatsu" },
  { code: "246", type: "car", name: "DFSK" },
  { code: "17", type: "car", name: "Dodge" },
  { code: "147", type: "car", name: "EFFA" },
  { code: "18", type: "car", name: "Engesa" },
  { code: "19", type: "car", name: "Envemo" },
  { code: "20", type: "car", name: "Ferrari" },
  { code: "21", type: "car", name: "Fiat" },
  { code: "149", type: "car", name: "Fibravan" },
  { code: "22", type: "car", name: "Ford" },
  { code: "190", type: "car", name: "FOTON" },
  { code: "170", type: "car", name: "Fyber" },
  { code: "199", type: "car", name: "GEELY" },
  { code: "23", type: "car", name: "GM - Chevrolet" },
  { code: "153", type: "car", name: "GREAT WALL" },
  { code: "24", type: "car", name: "Gurgel" },
  { code: "240", type: "car", name: "GWM" },
  { code: "152", type: "car", name: "HAFEI" },
  { code: "214", type: "car", name: "HITECH ELECTRIC" },
  { code: "25", type: "car", name: "Honda" },
  { code: "26", type: "car", name: "Hyundai" },
  { code: "27", type: "car", name: "Isuzu" },
  { code: "208", type: "car", name: "IVECO" },
  { code: "177", type: "car", name: "JAC" },
  { code: "28", type: "car", name: "Jaguar" },
  { code: "29", type: "car", name: "Jeep" },
  { code: "154", type: "car", name: "JINBEI" },
  { code: "30", type: "car", name: "JPX" },
  { code: "31", type: "car", name: "Kia Motors" },
  { code: "32", type: "car", name: "Lada" },
  { code: "171", type: "car", name: "LAMBORGHINI" },
  { code: "33", type: "car", name: "Land Rover" },
  { code: "34", type: "car", name: "Lexus" },
  { code: "168", type: "car", name: "LIFAN" },
  { code: "127", type: "car", name: "LOBINI" },
  { code: "35", type: "car", name: "Lotus" },
  { code: "140", type: "car", name: "Mahindra" },
  { code: "36", type: "car", name: "Maserati" },
  { code: "37", type: "car", name: "Matra" },
  { code: "38", type: "car", name: "Mazda" },
  { code: "211", type: "car", name: "Mclaren" },
  { code: "39", type: "car", name: "Mercedes-Benz" },
  { code: "40", type: "car", name: "Mercury" },
  { code: "167", type: "car", name: "MG" },
  { code: "156", type: "car", name: "MINI" },
  { code: "41", type: "car", name: "Mitsubishi" },
  { code: "42", type: "car", name: "Miura" },
  { code: "43", type: "car", name: "Nissan" },
  { code: "44", type: "car", name: "Peugeot" },
  { code: "45", type: "car", name: "Plymouth" },
  { code: "46", type: "car", name: "Pontiac" },
  { code: "47", type: "car", name: "Porsche" },
  { code: "185", type: "car", name: "RAM" },
  { code: "186", type: "car", name: "RELY" },
  { code: "48", type: "car", name: "Renault" },
  { code: "195", type: "car", name: "Rolls-Royce" },
  { code: "49", type: "car", name: "Rover" },
  { code: "50", type: "car", name: "Saab" },
  { code: "51", type: "car", name: "Saturn" },
  { code: "52", type: "car", name: "Seat" },
  { code: "247", type: "car", name: "SERES" },
  { code: "183", type: "car", name: "SHINERAY" },
  { code: "157", type: "car", name: "smart" },
  { code: "125", type: "car", name: "SSANGYONG" },
  { code: "54", type: "car", name: "Subaru" },
  { code: "55", type: "car", name: "Suzuki" },
  { code: "165", type: "car", name: "TAC" },
  { code: "56", type: "car", name: "Toyota" },
  { code: "57", type: "car", name: "Troller" },
  { code: "58", type: "car", name: "Volvo" },
  { code: "59", type: "car", name: "VW - VolksWagen" },
  { code: "163", type: "car", name: "Wake" },
  { code: "120", type: "car", name: "Walk" }
];

export const VEHICLE_BRAND_TRUCK = [
  { code: "102", type: "truck", name: "AGRALE" },
  { code: "206", type: "truck", name: "BEPOBUS" },
  { code: "103", type: "truck", name: "CHEVROLET" },
  { code: "121", type: "truck", name: "CICCOBUS" },
  { code: "197", type: "truck", name: "DAF" },
  { code: "179", type: "truck", name: "EFFA-JMC" },
  { code: "104", type: "truck", name: "FIAT" },
  { code: "105", type: "truck", name: "FORD" },
  { code: "191", type: "truck", name: "FOTON" },
  { code: "106", type: "truck", name: "GMC" },
  { code: "181", type: "truck", name: "HYUNDAI" },
  { code: "122", type: "truck", name: "IVECO" },
  { code: "188", type: "truck", name: "JAC" },
  { code: "184", type: "truck", name: "MAN" },
  { code: "108", type: "truck", name: "MARCOPOLO" },
  { code: "196", type: "truck", name: "MASCARELLO" },
  { code: "194", type: "truck", name: "MAXIBUS" },
  { code: "109", type: "truck", name: "MERCEDES-BENZ" },
  { code: "110", type: "truck", name: "NAVISTAR" },
  { code: "111", type: "truck", name: "NEOBUS" },
  { code: "112", type: "truck", name: "PUMA-ALFA" },
  { code: "113", type: "truck", name: "SAAB-SCANIA" },
  { code: "114", type: "truck", name: "SCANIA" },
  { code: "193", type: "truck", name: "SHACMAN" },
  { code: "166", type: "truck", name: "SINOTRUK" },
  { code: "115", type: "truck", name: "VOLKSWAGEN" },
  { code: "116", type: "truck", name: "VOLVO" },
  { code: "144", type: "truck", name: "WALKBUS" }
];
