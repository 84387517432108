import React from "react";
import { connect } from "react-redux";
import { Layout, Button, Typography, Row, Col } from "antd";
import { getAllBenefit } from "services/benefit-service";
import { useNavigate, useLocation } from "react-router-dom";
import { translate } from "config/language";

const { Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer,
  balance: state.balanceReducer
});

const DataFetch = {
  data: [],
  loading: false
};

const SolicitationTypeScreen = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { login, type = "", balance } = props;
  const [data, setData] = React.useState(DataFetch);
  const [redirectEmployee, setRedirectEmployee] = React.useState(false);
  const [hasBenefit, setHasBenefit] = React.useState(false);
  const { benefit, refund } = login.user.feature;

  React.useEffect(() => {
    loadBenefits();
  }, [type]);

  const loadBenefits = async () => {
    if (type === "default") {
      setData((x) => ({ ...x, loading: true }));
      const res = await getAllBenefit(login.company.id, true);
      setData({ benefits: res, loading: false });
      setHasBenefit(res.filter((b) => b.isBenefit && b.enabled).length > 0);
      if (res.filter((b) => !b.isBenefit && b.enabled).length > 0) {
        setRedirectEmployee(false);
      } else {
        setRedirectEmployee(true);
      }
    }
  };

  const onCredit = () => {
    redirectPage("/solicitation/credit", "credit");
  };

  const onRefund = () => {
    navigate("/solicitation/refund");
  };

  const redirectPage = (url, type = "benefit", state = null) => {
    if (balance.company.subBalances.length === 0) {
      navigate(url, { state: { ...state, benefit: null } });
    } else if (balance.company.subBalances.length === 1) {
      navigate(url, {
        state: { ...state, benefit: balance.company.subBalances[0].benefit }
      });
    } else {
      navigate("/solicitation/balance", {
        state: { url: url, type, ...state }
      });
    }
  };

  const onType = () => {
    if (type === "default") {
      return (
        <React.Fragment>
          <Row gutter={16} justify="center">
            <Col
              xl={login.company.carFleet ? 6 : 4}
              lg={login.company.carFleet ? 6 : 8}
              md={24}
            >
              <Button block type="primary" onClick={onCredit}>
                Crédito para Empresa
              </Button>
            </Col>
            <Col
              xl={login.company.carFleet ? 6 : 4}
              lg={login.company.carFleet ? 6 : 8}
              md={24}
            >
              <Button
                loading={data.loading}
                block
                type="primary"
                onClick={() =>
                  redirectEmployee
                    ? redirectPage("/solicitation/charge/benefit")
                    : hasBenefit
                      ? navigate("employee")
                      : redirectPage("/solicitation/charge/incentive")
                }
              >
                Carga para Colaboradores
              </Button>
            </Col>
            {(login.company.master || login.user.admin) &&
              login.company.carFleet && (
                <Col
                  xl={login.company.carFleet ? 6 : 4}
                  lg={login.company.carFleet ? 6 : 8}
                  md={24}
                >
                  <Button
                    loading={data.loading}
                    block
                    type="primary"
                    onClick={() =>
                      redirectPage("/solicitation/charge/carFleet")
                    }
                  >
                    Carga para Veículos
                  </Button>
                </Col>
              )}
            {(login.company.master || login.user.admin) &&
              refund &&
              login.company.corporateRefund && (
                <Col
                  xl={login.company.carFleet ? 6 : 4}
                  lg={login.company.carFleet ? 6 : 8}
                  md={24}
                >
                  <Button block type="primary" onClick={onRefund}>
                    Pagamento de Reembolso
                  </Button>
                </Col>
              )}
          </Row>
          <Row gutter={16} justify="center">
            <Col
              xl={login.company.carFleet ? 6 : 4}
              lg={login.company.carFleet ? 6 : 8}
              md={24}
            >
              <Text>
                Realiza crédito de {translate("balance")} na conta da empresa
                para ser usado em carga para colaboradores.
              </Text>
            </Col>
            <Col
              xl={login.company.carFleet ? 6 : 4}
              lg={login.company.carFleet ? 6 : 8}
              md={24}
            >
              <Text>
                Realiza carga de {translate("balance")} para colaboradores para
                ser gasto por eles em benefícios.
              </Text>
            </Col>
            {(login.company.master || login.user.admin) &&
              login.company.carFleet && (
                <Col
                  xl={login.company.carFleet ? 6 : 4}
                  lg={login.company.carFleet ? 6 : 8}
                  md={24}
                >
                  <Text>
                    Realiza carga de {translate("balance")} para veículos para
                    ser gasto em benefícios.
                  </Text>
                </Col>
              )}
            {(login.company.master || login.user.admin) &&
              refund &&
              login.company.corporateRefund && (
                <Col
                  xl={login.company.carFleet ? 6 : 4}
                  lg={login.company.carFleet ? 6 : 8}
                  md={24}
                >
                  <Text>
                    Realiza o pagamento dos pedidos de reembolso previamente
                    aprovados..
                  </Text>
                </Col>
              )}
          </Row>
        </React.Fragment>
      );
    } else if (type === "employee") {
      return (
        <React.Fragment>
          <Row gutter={16} justify="center">
            <Col xl={7} lg={8} md={24}>
              <Button
                block
                type="primary"
                onClick={() =>
                  redirectPage("/solicitation/charge/incentive", "incentive")
                }
              >
                Incentivo
              </Button>
            </Col>
            {benefit && (
              <Col xl={7} lg={8} md={24}>
                <Button
                  block
                  type="primary"
                  onClick={() => redirectPage("/solicitation/charge/benefit")}
                >
                  Benefícios
                </Button>
              </Col>
            )}
          </Row>
          <Row gutter={16} justify="center">
            <Col xl={7} lg={8} md={24}>
              <Text>
                Realiza a carga em um {translate("balance")} livre, sem
                restrição de benefícios. Pode ser usado em qualquer lugar.
              </Text>
            </Col>
            {benefit && (
              <Col xl={7} lg={8} md={24}>
                <Text>
                  Realiza a carga em benefícios. O uso fica restrito aos
                  benefícios habilitados.
                </Text>
              </Col>
            )}
          </Row>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    if (state?.expense === true) {
      const { expense, employees = [] } = state;
      redirectPage("/solicitation/charge/benefit", "benefit", {
        expense: expense,
        employees: employees
      });
    }
  }, [state]);

  return (
    <React.Fragment>
      <Content
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column"
        }}
      >
        {onType()}
      </Content>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, {})(SolicitationTypeScreen);
