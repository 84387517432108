import React from "react";
import { Outlet }from 'react-router-dom';
import { connect } from "react-redux";
import { Layout } from 'antd';
import { Header, AutoCompleteCompany } from "components";

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const subRoutes = [
    { label: "Benefícios", route: "/benefit" },
    { label: "Incentivos", route: "/benefit/incentive" },
    { label: "Marcas de Catálogo", route: "/benefit/catalog" }
]

const BenefitContainerScreen = (props) => {
    const { login } = props;
    const [company, setCompany] = React.useState(login.company.id);

    const onChangeCompany = (x) => {
        setCompany(x);
    }

    return (
        <Layout className="alymente-layout">
            <Header routes={subRoutes}
                middle={
                    login.company.master ? <AutoCompleteCompany value={company} onChange={onChangeCompany} /> : undefined
                } />
            <Outlet context={{ company: company }} />
        </Layout>
    )
}

export default connect(mapStateToProps, {})(BenefitContainerScreen);
