import axios from "axios";
import { handlerError } from "utils";

export const getAllTransferRefund = async () => {
  try {
    const res = await axios.get(`/api/employee/transfer/refund`);
    return res.data.data && res.data.data.length > 0 ? res.data.data : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getAllTransfer = async (filter = undefined) => {
  try {
    const res = await axios.get(`/api/employee/transfer`, {
      params: { date: filter }
    });
    return res.data.data && res.data.data.length > 0 ? res.data.data : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const batchTransferTransfering = async () => {
  try {
    const res = await axios.post(`/api/employee/transfer/batch/transfering`);
    return res.data
      ? {
          total: res.data.total,
          count: res.data.count,
          totalErrors: res.data.totalErrors,
          countErrors: res.data.countErrors
        }
      : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const batchTransferConfirming = async () => {
  try {
    const res = await axios.post(`/api/employee/transfer/batch/confirming`);
    return res.data
      ? {
          total: res.data.total,
          count: res.data.count,
          totalErrors: res.data.totalErrors,
          countErrors: res.data.countErrors
        }
      : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const batchTransferRefundConfirming = async () => {
  try {
    const res = await axios.post(
      `/api/employee/transfer/batch/refund/confirming`
    );
    return res.data
      ? {
          total: res.data.total,
          count: res.data.count,
          totalErrors: res.data.totalErrors,
          countErrors: res.data.countErrors
        }
      : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const batchTransferRefundTransfering = async () => {
  try {
    const res = await axios.post(
      `/api/employee/transfer/batch/refund/transfering`
    );
    return res.data
      ? {
          total: res.data.total,
          count: res.data.count,
          totalErrors: res.data.totalErrors,
          countErrors: res.data.countErrors
        }
      : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const retryTransfer = async (ids, refund = false) => {
  try {
    const res = await axios.post(
      `/api/employee/transfer/retry`,
      { data: ids },
      { params: { refund: refund } }
    );
    return res.data
      ? {
          total: res.data.total,
          count: res.data.count,
          totalErrors: res.data.totalErrors,
          countErrors: res.data.countErrors
        }
      : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const transferAccountBalance = async () => {
  try {
    const res = await axios.get(`/api/employee/transfer/balance`);
    return res.data
      ? {
          balance: res.data.balance,
          refund: res.data.refund,
          transfer: res.data.transfer
        }
      : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};
