import React from 'react';
import { Card, Statistic, Skeleton, Flex } from 'antd';
import PropTypes from 'prop-types';

const CardInfo = (props) => {
   return (
      <Card bodyStyle={{ ...props.style }}>
         <Flex style={{justifyContent:"space-between",alignItems:"end", gap:20}}>
            <Statistic loading={props.loading} title={props.title} value={props.value} valueStyle={props.size == 'medium' ? { fontSize: 17 } : {}} />
            {props.children}
         </Flex>
      </Card>
   );
};

CardInfo.defaultProps = {
   loading: false,
   title: '',
   value: '',
   size: 'large',
   style: {},
};

CardInfo.propTypes = {
   loading: PropTypes.bool,
   title: PropTypes.string.isRequired,
   value: PropTypes.string.isRequired,
   size: PropTypes.string,
   style: PropTypes.object,
};

export { CardInfo };

