import axios from "axios";
import { handlerError } from "utils";
import { notification } from "antd";
import env from "env";

export const getAllExpenseTypes = async () => {
  try {
    const response = await axios({
      method: "get",
      url: "/api-expense/expense-type"
    });

    return response.data ?? [];
  } catch (error) {
    handlerError(error);
    return [];
  }
};

export const getExpenseType = async (expenseType) => {
  try {
    const response = await axios({
      method: "get",
      url: `/api-expense/expense-type/${expenseType}`
    });

    return response.data ? response.data : {};
  } catch (error) {
    handlerError(error);
    return [];
  }
};

export const toggleEnabledExpenseType = async ({ id }) => {
  try {
    const response = await axios({
      method: "patch",
      url: `/api-expense/expense-type/${id}/toggle-enabled`
    });

    return response.data ? response.data : {};
  } catch (error) {
    handlerError(error);
    return [];
  }
};

export const toggleBlockExpenseType = async ({ id }) => {
  try {
    const response = await axios({
      method: "patch",
      url: `/api-expense/expense-type/${id}/toggle-block`
    });

    return response.data ? response.data : {};
  } catch (error) {
    handlerError(error);
    return [];
  }
};

export const saveExpenseType = async ({ id = null, body, message = null }) => {
  try {
    const req = {
      ...body
    };
    let response = null;

    if (id) {
      response = await axios({
        method: "put",
        url: `/api-expense/expense-type/${id}`,
        data: req
      });
    } else {
      response = await axios({
        method: "post",
        url: "/api-expense/expense-type",
        data: req
      });
    }

    notification.success({
      message: !!message ? message : "Tipo de despesa salvo com sucesso!"
    });

    return response.data ? response.data : {};
  } catch (ex) {
    handlerError(ex);
    throw null;
  }
};
