import React from "react";
import { connect } from "react-redux";
import {
  Layout,
  Button,
  Card,
  Row,
  Col,
  Space,
  Table,
  Switch,
  Typography,
  Spin,
  Modal,
  Form,
  Select,
  notification
} from "antd";
import { Header, SwitchLabel } from "components";
import { SaveOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  getByIdUser,
  saveUserBatch,
  saveUserManagerMatriz
} from "services/user-service";
import {
  removeBenefitManager,
  updateBenefitManager,
  getAllManagerBenefit,
  saveBenefitManager
} from "services/benefit-manager-service";
import { getAllBenefit } from "services/benefit-service";
import {
  getBusinessUnits,
  saveBusinessUnit
} from "services/business-unit-service";

const { Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const DataFetch = {
  data: [],
  loading: false
};

const UserDetailsManager = () => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [addLoading, setAddLoading] = React.useState(false);
  const [user, setUser] = React.useState({
    id: null,
    cpf: null,
    cardManager: false,
    cardManagerBranch: false,
    registerManager: false,
    registerBranchManager: false,
    company: null,
    matriz: null,
    canManageMatriz: false,
    manageMatriz: false
  });
  const [dataBenefits, setDataBenefits] = React.useState(DataFetch);
  const [dataEquip, setDataEquip] = React.useState(DataFetch);
  const [benefits, setBenefits] = React.useState([]);
  const [add, setAdd] = React.useState({ open: false, type: "" });
  const [form] = Form.useForm();

  const load = async () => {
    setLoading(true);
    const res = await getByIdUser(id);
    if (res) {
      const company = res.data.company.id;
      onUser(res);
      loadEquip(company);
      loadManagerBenefit();
      loadBenefits(company);
    }
    setLoading(false);
  };

  const loadManagerBenefit = async () => {
    setDataBenefits((x) => ({ ...x, loading: true }));
    const resData = await getAllManagerBenefit(id);
    setDataBenefits({ data: resData, loading: false });
  };

  const loadBenefits = async (company) => {
    setBenefits(await getAllBenefit(company));
  };

  const loadEquip = async (company) => {
    setDataEquip((x) => ({ ...x, loading: true }));
    const res = await getBusinessUnits(company);
    setDataEquip({ data: res, loading: false });
  };

  const onUser = (res) => {
    const data = res.data;

    setUser({
      id: data.id,
      cpf: data.cpf,
      cardManager: data.cardManager,
      cardManagerBranch: data.cardManagerBranch,
      registerManager: data.registerManager,
      registerBranchManager: data.registerBranchManager,
      company: data.company.id,
      matriz: data.companyExternal.matriz ? data.companyExternal.matriz : null,
      canManageMatriz: data.canManageMatriz,
      manageMatriz: data.manageMatriz
    });
  };

  const onChangeUser = (value, field) => {
    setUser((prev) => ({ ...prev, [field]: value }));
  };

  const onSaveCard = async () => {
    setLoading(true);
    const res = await saveUserBatch({
      ...user,
      fields: ["cardManager", "cardManagerBranch"]
    });
    notification.success({
      message: "Salvo com sucesso"
    });
    onUser(res);
    setLoading(false);
  };
  const onSaveRegister = async () => {
    setLoading(true);
    const res = await saveUserBatch({
      ...user,
      fields: ["registerManager", "registerBranchManager"]
    });
    notification.success({
      message: "Salvo com sucesso"
    });
    onUser(res);
    setLoading(false);
  };

  const onRemoveBenefit = async (value) => {
    try {
      await removeBenefitManager(value.benefit.id, value.manager.id);
      loadManagerBenefit();
    } catch (e) {
      console.log(e);
    }
  };

  const onRemoveEquip = async (value) => {
    try {
      await saveBusinessUnit(
        value.id,
        { ...value, manager: null },
        user.company
      );
      loadEquip(user.company);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeManagerBranch = (active, row) => {
    try {
      const data = [...dataBenefits.data];
      const find = data.find((x) => x.benefit.id === row.benefit.id);
      if (find) {
        find.managerBranch = active;
        setDataBenefits((prev) => ({ ...prev, data: data }));
      }
      setTimeout(async () => {
        await updateBenefitManager(active, row.benefit.id, row.manager.id);
        loadManagerBenefit();
      }, 0);
    } catch (e) {
      console.log(e);
    }
  };

  const onAddManager = async (values) => {
    setAddLoading(true);
    try {
      if (add.type === "benefit") {
        await saveBenefitManager(values.benefit, id);
        loadManagerBenefit();
      } else {
        const equip = dataEquip.data.find((x) => x.id === values.equip);
        await saveBusinessUnit(
          equip.id,
          { id: equip.id, name: equip.name, level: equip.level, manager: id },
          user.company
        );
        loadEquip(user.company);
      }
      setAdd({ open: false, type: "" });
    } catch (e) {
      console.log(e);
    } finally {
      setAddLoading(false);
    }
  };

  const onSaveManagerMatriz = async () => {
    setLoading(true);
    await saveUserManagerMatriz(id, user.manageMatriz);
    setLoading(false);
  };

  React.useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    form.resetFields();
  }, [add]);

  const selectBenefits = dataBenefits.data.map((y) => y.benefit.id);
  const tableEquip = dataEquip.data.filter(
    (x) => x.manager && x.manager.id === parseInt(id)
  );
  const selectEquip = tableEquip.map((x) => x.id);

  return (
    <Layout className="alymente-layout">
      <Header
        title="Gerência"
        subtitle="Aqui você pode editar as gerências de um colaborador"
      ></Header>
      <Content>
        <Space
          direction="vertical"
          size="large"
          style={{ width: "100%", marginBottom: 50 }}
        >
          <Row justify="center" gutter={[24, 24]}>
            <Col xl={6} lg={12} xs={24}>
              <Card title="Gerência de Cartão">
                <Spin spinning={loading}>
                  <Space
                    direction="vertical"
                    size="large"
                    style={{ width: "100%" }}
                  >
                    <SwitchLabel
                      label="GERENTE DE CARTÃO?"
                      checked={user.cardManager}
                      onChange={(e) => onChangeUser(e, "cardManager")}
                    />
                    <SwitchLabel
                      label="GERENTE DE CARTÃO DE FILIAIS?"
                      checked={user.cardManagerBranch}
                      onChange={(e) => onChangeUser(e, "cardManagerBranch")}
                    />
                    <Button
                      style={{ float: "right" }}
                      type="primary"
                      icon={<SaveOutlined />}
                      onClick={onSaveCard}
                    >
                      Salvar
                    </Button>
                  </Space>
                </Spin>
              </Card>
            </Col>
            <Col xl={6} lg={12} xs={24}>
              <Card title="Gerência de Cadastro">
                <Spin spinning={loading}>
                  <Space
                    direction="vertical"
                    size="large"
                    style={{ width: "100%" }}
                  >
                    <SwitchLabel
                      label="GERENTE DE CADASTRO?"
                      checked={user.registerManager}
                      onChange={(e) => onChangeUser(e, "registerManager")}
                    />
                    <SwitchLabel
                      label="GERENTE DE CADASTRO DE FILIAL?"
                      checked={user.registerBranchManager}
                      onChange={(e) => onChangeUser(e, "registerBranchManager")}
                    />
                    <Button
                      style={{ float: "right" }}
                      type="primary"
                      icon={<SaveOutlined />}
                      onClick={onSaveRegister}
                    >
                      Salvar
                    </Button>
                  </Space>
                </Spin>
              </Card>
            </Col>
            {user.canManageMatriz && (
              <Col xl={6} lg={12} xs={24}>
                <Card title="Gerenciar a matriz">
                  <Spin spinning={loading}>
                    <Space
                      direction="vertical"
                      size="large"
                      style={{ width: "100%" }}
                    >
                      <SwitchLabel
                        label="HABILITADO?"
                        checked={user.manageMatriz}
                        onChange={(e) => onChangeUser(e, "manageMatriz")}
                      />
                      <Button
                        style={{ float: "right" }}
                        type="primary"
                        icon={<SaveOutlined />}
                        onClick={onSaveManagerMatriz}
                      >
                        Salvar
                      </Button>
                    </Space>
                  </Spin>
                </Card>
              </Col>
            )}
          </Row>
          <Row gutter={[24, 24]} style={{ alignItems: "flex-end" }}>
            <Col lg={12} xs={24}>
              <Typography.Title level={4} style={{ marginBottom: 0 }}>
                Gerência de Benefícios/Incentivo
              </Typography.Title>
            </Col>
            <Col lg={12} xs={24} style={{ textAlign: "right" }}>
              <Button
                disabled={benefits.length === 0}
                className="btn-save"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setAdd({ open: true, type: "benefit" })}
              >
                Adicionar
              </Button>
            </Col>
            <Col xs={24}>
              <Table
                scroll={{ y: 200 }}
                dataSource={dataBenefits.data}
                loading={dataBenefits.loading}
                pagination={false}
                bordered
              >
                <Table.Column
                  width={200}
                  align={"left"}
                  title="Benefício"
                  dataIndex={["benefit", "name"]}
                  key="benefit.name"
                />
                <Table.Column
                  align={"left"}
                  title="Gerente"
                  dataIndex={["manager", "name"]}
                  key="manager.name"
                />
                <Table.Column
                  width={150}
                  align={"center"}
                  title="Gerencia filiais?"
                  dataIndex="managerBranch"
                  key="managerBranch"
                  render={(value, record, index) => (
                    <Switch
                      checked={value}
                      onChange={(x) => onChangeManagerBranch(x, record)}
                    />
                  )}
                />
                <Table.Column
                  width={100}
                  align={"center"}
                  title="Ações"
                  render={(value, record, index) => (
                    <Button
                      danger
                      size="small"
                      type={"link"}
                      onClick={() => onRemoveBenefit(record)}
                    >
                      Remover
                    </Button>
                  )}
                />
              </Table>
            </Col>
          </Row>
          <Row gutter={[24, 24]} style={{ alignItems: "flex-end" }}>
            <Col lg={12} xs={24}>
              <Typography.Title level={4} style={{ marginBottom: 0 }}>
                Gerência de Equipes
              </Typography.Title>
            </Col>
            <Col lg={12} xs={24} style={{ textAlign: "right" }}>
              <Button
                disabled={dataEquip.data.length === 0}
                className="btn-save"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setAdd({ open: true, type: "equip" })}
              >
                Adicionar
              </Button>
            </Col>
            <Col xs={24}>
              <Table
                scroll={{ y: 200 }}
                dataSource={tableEquip}
                loading={dataEquip.loading}
                pagination={false}
                bordered
              >
                <Table.Column
                  align={"left"}
                  title="Equipe"
                  dataIndex={"name"}
                  key="name"
                />
                <Table.Column
                  width={100}
                  align={"center"}
                  title="Level"
                  dataIndex={"level"}
                  key="level"
                />
                <Table.Column
                  width={100}
                  align={"center"}
                  title="Ações"
                  render={(value, record, index) => (
                    <Button
                      danger
                      size="small"
                      type={"link"}
                      onClick={() => onRemoveEquip(record)}
                    >
                      Remover
                    </Button>
                  )}
                />
              </Table>
            </Col>
          </Row>
        </Space>
      </Content>
      {benefits.length > 0 && (
        <Modal
          open={add.open}
          okButtonProps={{ loading: addLoading }}
          onOk={form.submit}
          onCancel={() => setAdd({ open: false, type: "u" })}
          title={`Adicionar gerência de ${
            add.type === "benefit" ? "benefício" : "equipe"
          }`}
        >
          <Form
            form={form}
            layout="vertical"
            name="basic"
            onFinish={onAddManager}
          >
            {add.type === "benefit" && (
              <Form.Item
                hasFeedback
                name="benefit"
                rules={[{ required: true, message: "Selecione um benefício" }]}
              >
                <Select
                  placeholder="Selecione um benefício"
                  style={{ width: "100%" }}
                >
                  {benefits
                    .filter((x) => !selectBenefits.includes(x.benefit))
                    .map((x) => (
                      <Select.Option value={x.benefit}>{x.name}</Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            {add.type === "equip" && (
              <Form.Item
                hasFeedback
                name="equip"
                rules={[{ required: true, message: "Selecione uma equipe" }]}
              >
                <Select
                  placeholder="Selecione uma equipe"
                  style={{ width: "100%" }}
                >
                  {dataEquip.data
                    .map((x) => ({ id: x.id, name: x.name }))
                    .filter((x) => !selectEquip.includes(x.id))
                    .map((x) => (
                      <Select.Option value={x.id}>{x.name}</Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </Form>
        </Modal>
      )}
    </Layout>
  );
};

export default connect(mapStateToProps, {})(UserDetailsManager);
