import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Input, Button, Card, Row, Col, Layout, Alert, Spin } from "antd";
import { AuthHeader, Form } from "components";
import { useQuery } from "utils";
import * as authAction from "actions/auth-action";
import { LockFilled } from "@ant-design/icons";
import { SoftColor } from "config/constants/Color";

const mapStateToProps = (state) => ({
    reset: state.authReducer.resetReducer
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
        ...authAction
    }, dispatch);


const ResetScreen = (props) => {
    const params = useQuery();
    const token = params.get("token") || null;
    const { reset, getResetAction, resetAction } = props;

    React.useEffect(() => {
        getResetAction(token);
    }, [token, getResetAction]);

    const onReset = (values) => {
        resetAction(token, values.password);
    };

    const FormContent = () => (
        <Form style={{ width: "100%" }} name="basic" initialValues={{ email: "" }} onFinish={onReset}>
            {reset.data &&
                <Form.Item>
                    <Alert message={`Olá ${reset.data.name}! Defina sua nova senha do aplicativo.`} type={"success"} />
                </Form.Item>
            }
            <Form.Item
                hasFeedback
                name="password"
                rules={[{ required: true, message: "Insira sua senha" }, { min: 5, message: "A senha deve possuir 5 ou mais caracteres" }]}>
                <Input.Password prefix={<LockFilled />} placeholder="Senha do aplicativo" />
            </Form.Item>
            <Form.Item
                hasFeedback
                name="confirm"
                dependencies={["password"]}
                rules={[{ required: true, message: "Insira a confirmação de senha" }, ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error("A senha do aplicativo deve ser igual à confirmação"));
                    }
                })]}>
                <Input.Password prefix={<LockFilled />} placeholder="Confirmação de Senha" />
            </Form.Item>
            {reset.message.length > 0 &&
                <Form.Item>
                    <Alert message={reset.message} type={"error"} showIcon />
                </Form.Item>
            }
            <Form.Item>
                <Button loading={reset.isProcessing} type="primary" htmlType="submit" style={{ width: "100%" }}>Enviar</Button>
            </Form.Item>
            <Form.Item>
                <Button type="link" href="/auth/login">Voltar</Button>
            </Form.Item>
        </Form>
    );

    const InitialState = () => {
        if (!reset.data && reset.message.length === 0) {
            return (<Spin size="large" style={{ display: "block" }} />);
        } else if (!reset.data && reset.message.length > 0) {
            return (<Alert message={reset.message} type="error" showIcon />);
        } else {
            return (<></>);
        }
    };

    const SuccessContent = () => {
        if (reset.type === "app") {
            return (<Alert message="Agora você já pode entrar no aplicativo da Alymente com sua nova senha!" type="success" showIcon />);
        }
        return (<Button type="link" href="/auth/login">Clique aqui para entrar com sua nova senha do aplicativo.</Button>);
    };

    return (
        <Layout style={{ backgroundColor: SoftColor, height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
            <Layout.Content>
                <Row justify="center" align="middle" className="login">
                    <Col xxl={6} xl={8} lg={10} md={14} sm={24}>
                        <Card title={<AuthHeader />} bordered={false} bodyStyle={reset.success ? { display: "flex", justifyContent: "center" } : {}}>
                            {(token && reset.success) && <SuccessContent />}
                            {(token && !reset.success) &&
                                reset.data ? <FormContent /> : <InitialState />
                            }
                            {!token && <Alert message="Token não encontrado." type="error" showIcon />}
                        </Card>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetScreen);
