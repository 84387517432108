import React from "react";
import { Space, Tooltip, Avatar, Spin, theme } from "antd";
import { UserOutlined, DollarOutlined, CreditCardOutlined, IdcardOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { convertCategoryColorToken } from 'utils'

const ColumnEmployeeStatus = (props) => {
    const { token } = theme.useToken();
    const { data } = props;
    const checkRequest = data.validation && !data.validation.onRequest;

    const validation = () => {
        if (data.validation === null) {
            return (<Tooltip title={`Validação de Dados: Inexistente`}>
                <Avatar style={{ backgroundColor: token.colorError }} size="small" icon={<IdcardOutlined />} />
            </Tooltip>)
        }
        else if (data.accountSwapStatus === 'ERROR') {
            return (<Tooltip title={`Validação de Dados: Conta Inativa`}>
                <Avatar style={{ backgroundColor: token.colorError }} size="small" icon={<IdcardOutlined />} />
            </Tooltip>)
        }
        else {
            return (<Tooltip title={data.validation ? `Validação de Dados: ${data.validation.name}` : "Validação de Dados: Carregando"}>
                {checkRequest ?
                    <Avatar style={{ backgroundColor: data.validation ? token[convertCategoryColorToken(data.validation.category)] : token.colorText }} size="small" icon={<IdcardOutlined />} /> :
                    <Spin size="small" />
                }
            </Tooltip>)
        }
    }

    return <Space direction="horizontal" size={"small"}>
        <Tooltip title={data.cardStatus.name}>
            <Avatar style={{ backgroundColor: token[convertCategoryColorToken(data.cardStatus.category)] }} size="small" icon={<CreditCardOutlined />} />
        </Tooltip>
        {validation()}
        {/* <Tooltip title={data.chargeStatus.name}>
            <Avatar style={{ backgroundColor: token[convertCategoryColorToken(data.chargeStatus.category)] }} size="small" icon={<DollarOutlined />} />
        </Tooltip> */}
        <Tooltip title={`Usuário Ativo: ${data.enabled ? "Sim" : "Não"}`}>
            <Avatar style={{ backgroundColor: data.enabled ? token.colorSuccess : token.colorError }} size="small" icon={<UserOutlined />} />
        </Tooltip>
    </Space>;
};

ColumnEmployeeStatus.propTypes = {
    data: PropTypes.any.isRequired
};

export { ColumnEmployeeStatus };