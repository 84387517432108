import React from "react";
import { connect } from "react-redux";
import { Layout, Table, Row, Select } from 'antd';
import { getTotalBalance, getStatement } from "services/company-service";
import { Header, FormatMoney, FormatDate } from "components";
import { translate } from 'config/language';
import { AccountSystem } from 'utils/account-system';

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer,
    config: state.configReducer,
});

const DataFetch = {
    data: [],
    loading: false
}

const CompanyStatementScreen = (props) => {
    const { login, config } = props;
    const [data, setData] = React.useState(DataFetch)
    const [system, setSystem] = React.useState(AccountSystem.SWAP);

    const load = async () => {
        setData((x) => ({ ...x, loading: true }));
        const balance = await getTotalBalance(null, system);
        const statement = await getStatement(system);
        setData({ balance: [{ "total": balance ? balance.balance : 0 }], statement: statement || [], loading: false });
    }

    React.useEffect(() => {
        load();
    }, [system])

    return (
        <Layout className="alymente-layout">
            <Header title={login.company?.name}
                middle={
                    <Select style={{ width: '100%' }} placeholder="Selecione um sistema de conta" value={system} onChange={setSystem}>
                        {config.system.map(x => <Select.Option value={x.code}>{x.name}</Select.Option>)}
                    </Select>} />
            <Content>
                <Row justify="center" style={{ marginBottom: 20 }}>
                    <Table size="small" dataSource={data.balance} loading={data.loading} pagination={false} bordered>
                        <Table.Column width={170} align={"center"} title={translate('balance')} dataIndex="total" key="total" render={(value) => <FormatMoney value={value} />} />
                    </Table>
                </Row>
                <Table scroll={{ y: "calc(70vh - 130px)" }} dataSource={data.statement} loading={data.loading} pagination={false} bordered>
                    <Table.Column width={170} align={"center"} title="ID" dataIndex="id" key="id" />
                    <Table.Column width={170} align={"center"} title="Data" dataIndex="date" key="date" render={(value) => <FormatDate value={value} format={"DD/MM/YYYY HH:mm"} />} />
                    <Table.Column width={170} align={"center"} title={translate('statement')} dataIndex="name" key="name" />
                    <Table.Column width={170} align={"center"} title="Valor" dataIndex="value" key="value" render={(value, row) => <FormatMoney value={(row.reversal ? "+" : "-") + value} />} />
                </Table>
            </Content>
            <Footer>

            </Footer>
        </Layout >
    )
}

export default connect(mapStateToProps, {})(CompanyStatementScreen);
