import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  notification,
  Table,
  Tooltip,
  Pagination,
  Typography,
  Row,
  Col
} from "antd";
import {
  LoadingOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  CheckCircleOutlined
} from "@ant-design/icons";
import moment from "moment";
import { DatePicker } from "..";

const EditableContext = React.createContext(null);

const EditableCell = (props) => {
  const {
    dataIndex,
    title,
    inputType,
    select,
    record,
    handleSave,
    editable,
    children,
    required,
    ...restProps
  } = props;
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        [dataIndex + "_" + record.key]: getValue()
      });
      form.validateFields([dataIndex + "_" + record.key]);
    }
  }, [record]);

  const getValue = () => {
    if (record) {
      if (inputType === "date")
        return record[dataIndex] ? moment(record[dataIndex]) : "";
      return record[dataIndex];
    } else {
      return null;
    }
  };

  const toggleEdit = () => {
    form.setFieldsValue({
      [dataIndex + "_" + record.key]: getValue()
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      let ret = {};
      for (let val in values) {
        let index = val.split("_");
        if (index[1] == record.key) {
          ret[index[0]] = values[val];
        }
      }
      handleSave({ ...record, ...ret });
    } catch (errInfo) {
      let ret = {};
      for (let val in errInfo.values) {
        let index = val.split("_");
        if (index[1] == record.key) {
          ret[index[0]] = errInfo.values[val];
        }
      }
      handleSave({ ...record, ...ret });
    }
  };

  const getStatus = () => {
    let message = record.message;
    let icon = "";
    if (record.status == "saving") {
      icon = <LoadingOutlined className="color-gray" />;
    } else if (record.status == "error") {
      icon = <CloseCircleOutlined className="color-error" />;
    } else if (record.status == "notChanges") {
      icon = <MinusCircleOutlined className="color-gray" />;
    } else if (record.status == "success") {
      icon = <CheckCircleOutlined />;
    }
    return (
      <span style={{ marginLeft: 0 }}>
        <Tooltip title={message}>{icon}</Tooltip>
      </span>
    );
  };

  let childNode = children;

  let inputNode = (
    <Input
      ref={inputRef}
      onPressEnter={save}
      onBlur={save}
      disabled={
        dataIndex == "cpf" && (!record.line || record.id) ? true : false
      }
    />
  );

  if (inputType === "number")
    inputNode = (
      <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />
    );
  else if (inputType === "date")
    inputNode = (
      <DatePicker
        format={"DD/MM/YYYY"}
        ref={inputRef}
        onPressEnter={save}
        onBlur={save}
      />
    );
  else if (inputType === "select")
    inputNode = (
      <Select ref={inputRef} onPressEnter={save} onBlur={save}>
        {select.map((s) => {
          return <Select.Option value={s.value}>{s.label}</Select.Option>;
        })}
      </Select>
    );

  childNode =
    dataIndex == "status" ? (
      getStatus()
    ) : record ? (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Form.Item
          {...(record.errorField &&
            dataIndex == record.errorField.name && {
              help: record.errorField.error,
              validateStatus: "error"
            })}
          style={{
            margin: 0,
            flex: 1
          }}
          name={dataIndex + "_" + (record ? record.key : "")}
          rules={[
            {
              required: required,
              message: `${title} é um campo obrigatório.`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
        {dataIndex == "status" && record.status ? getStatus() : null}
      </div>
    ) : (
      <div
        style={{
          flexDirection: "row",
          display: "flex"
        }}
      >
        <div style={{ flex: 1 }}>{children}</div>
      </div>
    );

  return <td {...restProps}>{childNode}</td>;
};

const TableForm = ({
  bordered,
  dataSource,
  columns,
  scroll,
  form,
  onFinish,
  selectedRowKeys,
  onChangeRowSelect,
  loading,
  selectRows
}) => {
  const components = {
    body: {
      cell: EditableCell
    }
  };

  return (
    <Form form={form} component={false} onFinish={onFinish}>
      <EditableContext.Provider value={form}>
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          bordered={bordered}
          dataSource={dataSource}
          columns={columns}
          scroll={scroll}
          rowSelection={
            selectRows
              ? {
                  selectedRowKeys: selectedRowKeys,
                  onChange: onChangeRowSelect,
                  columnWidth: 40
                }
              : null
          }
          loading={loading}
        />
      </EditableContext.Provider>
    </Form>
  );
};

export { TableForm };
