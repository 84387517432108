import React from "react";
import { connect } from "react-redux";
import {
  Layout,
  Table,
  Typography,
  Space,
  Tag,
  Tooltip,
  Button,
  Modal,
  Row,
  Col,
  Avatar,
} from "antd";
import {
  InfoCircleOutlined as InfoCircle,
  TransactionOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  batchTransferRefundConfirming,
  batchTransferRefundTransfering,
  getAllTransferRefund,
  retryTransfer,
} from "services/transfer-service";
import { FormatDate, FormatMoney } from "components";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { formatMoney, getMaskedCpf } from "utils";
import { useOutletContext } from "react-router-dom";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer,
});

const DataFetch = {
  data: [],
  filtered: null,
  loading: false,
};

const TransferRefundScreen = (props) => {
  const { login } = props;
  const { onRefreshBalance, balance } = useOutletContext();
  const navigate = useNavigate();
  const [data, setData] = React.useState(DataFetch);
  const [loadingBatch, setLoadingBatch] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const checkBatchTransfering = React.useMemo(
    () => data.data.filter((x) => x.status.code === "c").length > 0,
    [data],
  );
  const checkBatchValidation = React.useMemo(
    () => data.data.filter((x) => x.status.code === "w").length > 0,
    [data],
  );

  const load = async () => {
    setData((x) => ({ ...x, loading: true }));
    const res = await getAllTransferRefund();
    setData({ data: res, loading: false, readStatus: true, filtered: null });
    onRefreshBalance();
  };

  const onRetry = async () => {
    setLoadingBatch(true);
    const res = await retryTransfer(selectedRowKeys, true);
    setSelectedRowKeys([]);
    await load();
    onConfirmMessage(res);
    setLoadingBatch(false);
  };

  const onBatchPendents = async () => {
    setLoadingBatch(true);
    const res = await batchTransferRefundConfirming();
    await load();
    onConfirmMessage(res);
    setLoadingBatch(false);
  };

  const onBatchConfirmed = async () => {
    setLoadingBatch(true);
    const res = await batchTransferRefundTransfering();
    await load();
    //onConfirmMessage(res);
    setLoadingBatch(false);
  };

  const onConfirmMessage = (res) => {
    if (res) {
      Modal.success({
        width: 530,
        title: (
          <Typography.Title level={4}>Totais Transferências</Typography.Title>
        ),
        content: (
          <React.Fragment>
            <Row justify="space-between">
              <Col>
                <Typography.Title level={5}>Bem-sucedidas:</Typography.Title>
              </Col>
              <Col>
                <Typography.Title level={5}>
                  <strong>
                    {formatMoney(res.total)} / {res.count}
                  </strong>
                </Typography.Title>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                <Typography.Title level={5}>Com erros:</Typography.Title>
              </Col>
              <Col>
                <Typography.Title level={5}>
                  <strong>
                    {formatMoney(res.totalErrors)} / {res.countErrors}
                  </strong>
                </Typography.Title>
              </Col>
            </Row>
          </React.Fragment>
        ),
      });
    }
  };

  React.useEffect(() => {
    load();
  }, []);

  return (
    <>
      <Content>
        <Table
          rowKey={"id"}
          scroll={{ y: 500, x: 1500 }}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: setSelectedRowKeys,
            hideSelectAll: true,
            getCheckboxProps: (row) => ({
              disabled: row.pixAlert && row.validPix ? false : true,
            }),
          }}
          dataSource={data.data}
          loading={data.loading}
          pagination={false}
          bordered
        >
          <Table.Column
            width={150}
            align={"center"}
            title="Data"
            dataIndex="createdAt"
            key="createdAt"
            render={(value) => <FormatDate value={value} />}
          />
          <Table.Column
            width={300}
            title="Colaborador"
            dataIndex="employee"
            key="employee"
            render={(value, row) => (
              <React.Fragment>
                <Space>
                  {value}
                  {row.updatedEmployee && (
                    <Tooltip
                      placement="bottom"
                      title={`Usuário atualizado em ${moment(
                        row.dateUpdatedUser,
                      ).format("DD/MM/YYYY HH:mm")}`}
                    >
                      <InfoCircle />
                    </Tooltip>
                  )}
                </Space>
              </React.Fragment>
            )}
          />

          <Table.Column
            width={150}
            title="CPF"
            dataIndex="employeeCpf"
            key="employeeCpf"
            render={(value) => getMaskedCpf(value)}
          />
          <Table.Column
            width={250}
            title="Empresa"
            dataIndex="employeeCompany"
            key="employeeCompany"
          />

          <Table.Column
            width={150}
            align="center"
            title="Tipo"
            dataIndex={"corporate"}
            key="corporate"
            render={(value) => (value ? "Corporativo" : "Colaborador")}
          />
          <Table.Column
            width={250}
            title="PIX"
            dataIndex="pixKey"
            key="pixKey"
            render={(value, row) => (
              <Row justify="space-between">
                <Col>{value}</Col>
                <Col>
                  {row.pixAlert && (
                    <Tooltip title={row.pixAlert.message}>
                      <Avatar
                        className={`bg-${row.pixAlert.category.toLowerCase()}`}
                        size="small"
                        icon={<InfoCircle />}
                      />
                    </Tooltip>
                  )}
                </Col>
              </Row>
            )}
          />
          <Table.Column
            width={150}
            align={"right"}
            title="Valor Nota"
            dataIndex="valueNF"
            key="valueNF"
            render={(value) => <FormatMoney value={value} />}
          />
          <Table.Column
            width={200}
            align={"right"}
            title="Valor Transfêrencia"
            dataIndex="valueTransaction"
            key="valueTransaction"
            render={(value) => <FormatMoney value={value} />}
          />
          <Table.Column
            width={90}
            align={"right"}
            title="Taxa"
            dataIndex="fee"
            key="fee"
            render={(value) => <FormatMoney value={value} />}
          />
          <Table.Column
            fixed="right"
            width={250}
            align={"center"}
            title="Status"
            dataIndex="status"
            key="status"
            render={(value, row) => (
              <Tooltip title={row.detalhes}>
                <Tag color={value.category}>{value.name.toUpperCase()}</Tag>
              </Tooltip>
            )}
          />
        </Table>
      </Content>
      <Footer>
        <Row justify="space-between" style={{ width: "100%" }}>
          <Col>
            <Button
              loading={loadingBatch}
              disabled={selectedRowKeys.length === 0}
              type="primary"
              icon={<ReloadOutlined />}
              onClick={onRetry}
            >
              Adicionar Re-Tentativas no Lote
            </Button>
          </Col>
          <Col>
            <Space>
              <Button
                disabled={!checkBatchValidation}
                loading={loadingBatch}
                type="primary"
                icon={<TransactionOutlined />}
                onClick={onBatchPendents}
              >
                Adicionar Reembolsos 'Aguardando' no Lote
              </Button>
              {checkBatchTransfering && (
                <Button
                  danger
                  disabled={balance.transfer?.total > balance.balance}
                  loading={loadingBatch}
                  type="primary"
                  icon={<TransactionOutlined />}
                  onClick={onBatchConfirmed}
                >
                  Processar Lote de Reembolsos
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </Footer>
    </>
  );
};

export default connect(mapStateToProps, {})(TransferRefundScreen);
