import { getAllEmployee } from "services/employee-service";

export const getAllEmployees = async (master, company, level) => {
    const res = master && company ? await getAllEmployee(company, true) : await getAllEmployee(null, true);
    const levelBusinessUnits = res.businessUnits.filter(x => x.level === level)
    return {
        employees: res.employees.map(u => {
            const businessUnit = levelBusinessUnits.find(x => x.employees.map(e => e.id).indexOf(u.id) >= 0);
            return { ...u, show: true, businessUnit: { id: businessUnit ? businessUnit.id : null } }
        }),
        levelBusinessUnits: levelBusinessUnits,
    }
}