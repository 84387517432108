import { notification } from "antd";
import axios from "axios";
import { handlerError } from "utils";
import { getBusinessUnitsEmployeesId } from "./company-service";

export const getAllExpenses = async () => {
  try {
    const { data } = await axios.get(`/api-expense/expense`);

    return data;
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};
export const getAllExpenseByBusinessUnit = async (companyId) => {
  const allExpenses = await getAllExpenses();
  const bUnitEmployeesId = await getBusinessUnitsEmployeesId(companyId);
  const employeeIdSet = new Set(bUnitEmployeesId.map((id) => Number(id)));
  const filteredExpenses = allExpenses.filter((expense) =>
    employeeIdSet.has(Number(expense.employeeId))
  );
  return filteredExpenses;
};

export const getListExpenseTypes = async () => {
  const allExpenseList = await getAllExpenses();

  const uniqueExpenseTypesd = [];

  allExpenseList.forEach((obj) => {
    if (!uniqueExpenseTypesd.includes(obj.expenseType)) {
      uniqueExpenseTypesd.push(obj.expenseType);
    }
  });

  const result = uniqueExpenseTypesd.map((expenseType) => ({
    expenseType
  }));

  return result;
};

// deprecated
export const updateAllExpenses = async (data) => {
  try {
    const res = await axios.put(`/api/expense/status`, { expenses: data });
    notification.success({ message: "Salvo com sucesso" });
    return res.data;
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getExpenseReport = async (filter) => {
  try {
    const params = {};
    if (filter?.expenseType) {
      params.expenseType = filter.expenseType;
    }
    if (filter?.dates && filter.dates.length === 2) {
      params.startDate = filter.dates[0].format("YYYY-MM-DD");
      params.endDate = filter.dates[1].format("YYYY-MM-DD");
    }
    console.log("params: " + JSON.stringify(filter));
    const { data } = await axios.get(`/api/expense/report`, { params });
    console.log("data: " + JSON.stringify(data.data));
    return (
      data.data
        .map((item) => ({ ...item, balance: null }))
        .sort((a, b) => a.employee.name.localeCompare(b.employee.name)) || []
    );
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getExpenseById = async (id) => {
  try {
    const { data } = await axios.get(`/api-expense/expense/${id}`);
    return data;
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};

export const getExpenseImagesByExpenseId = async (expenseId) => {
  try {
    const { data } = await axios.get(`/api-expense/expense/${expenseId}/image`);
    return data;
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};

export const retryGetAddress = async (id, postalCode) => {
  try {
    const { data } = await axios.put(
      `/api/expense/${id}/${postalCode}/address`
    );
    return data;
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};
export const getExpensesByCompanyIdAndPeriod = async (
  companyId,
  startPeriod,
  endPeriod,
  getAllPeriod,
  benefits
) => {
  const params = {};
  if (!getAllPeriod) {
    params.startPeriod = startPeriod;
    params.endPeriod = endPeriod;
  }
  params.benefits = benefits?.join();
  params.companyId = companyId;
  try {
    const data = await axios.get("/api-expense/expense", {
      params
    });
    const { data: expenseReport } = data;
    return expenseReport;
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getExpensesByvehicle = async (vehicle) => {
  const params = {};
  params.vehicleId = vehicle;
  try {
    const { data } = await axios.get("/api-expense/expense", {
      params
    });
    return data;
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getExpenseConfig = async () => {
  try {
    const { data } = await axios.get("/api-expense/expense/config");
    return data;
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};

export const updateExpense = async (id, body) => {
  try {
    const res = await axios.put(`/api-expense/expense/${id}`, body);
    notification.success({ message: "Salvo com sucesso" });
    return res.data;
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};
