import React from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getAllUsers } from "services/user-service";
import { getBankSplit } from "services/solicitation-service";
import { getCompanyBenefits } from "services/company-service";
import { Row, Col, Card, Table, Layout, Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import SolicitationRevisionScreen from "views/solicitation/solicitation-revision-screen";
import { formatMoney } from "utils";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const ActivationCheckoutChargeScreen = (props) => {
  const { login } = props;
  const [employees, setEmployees] = React.useState([]);
  const [benefits, setBenefits] = React.useState([]);
  const [data, setData] = React.useState(null);
  const { onNext, childRef } = useOutletContext();
  const [loading, setLoading] = React.useState(false);

  const onSubmit = () => {
    setLoading(true);
    childRef.current.submit();
  };

  const onAfterSubmit = (submit = false, solicitation = null) => {
    if (submit && solicitation) {
      downloadBankSlip(solicitation);
    } else {
      setLoading(false);
    }
  };

  const load = async (filter = "") => {
    const res = await getAllUsers();
    if (res) {
      setBenefits(await getCompanyBenefits());
      setEmployees(res);
    }
  };

  const downloadBankSlip = async (solicitation) => {
    const res = await getBankSplit(solicitation);
    if (res) {
      window.open(res.bankSlipUrl, "_blank");
      onNext();
    }
  };

  React.useEffect(() => {
    setData(createRevision());
  }, [employees.length > 0 && benefits.length > 0]);

  React.useEffect(() => {
    load();
  }, [login]);

  const createRevision = () => {
    const createLines = () => {
      let lines = [];
      employees.forEach((u) => {
        benefits.forEach((b) => {
          let line = { user: u.id, benefit: 0, value: 0 };
          line.benefit = b.benefit;
          line.value = b.value;
          line.total = b.value;
          line.balance = null;
          line.balanceDate = null;
          if (b.value > 0 && u.account != null) {
            lines.push(line);
          } else if (u.account == null) {
            //messageNotAccount = true
          }
        });
      });
      return lines;
    };
    const lines = createLines();
    const createCheckout = () => {
      const total = lines.reduce(
        (previous, current) => previous + current.value,
        0
      );
      return [
        { label: "Valor", value: total },
        { label: "Taxa", value: 0 },
        {
          total: true,
          discount: false,
          hasPayment: total > 0,
          label: "Valor Total",
          value: total
        }
      ];
    };

    return {
      request: {
        url: "/api/solicitation",
        method: "post"
      },
      body: {
        solicitation: {
          lines: lines,
          discount: false
        }
      },
      checkout: createCheckout(),
      type: "charge"
    };
  };

  if (data) {
    return (
      <React.Fragment>
        <Content>
          <Row justify={"center"}>
            <Col xl={12}>
              <Card title="Valor por Benefício">
                <Table
                  loading={benefits.length === 0}
                  dataSource={benefits.filter((x) => x.value > 0)}
                  pagination={false}
                  bordered
                >
                  <Table.Column
                    align={"left"}
                    title="Benefício"
                    dataIndex="name"
                    key="name"
                  />
                  <Table.Column
                    align={"right"}
                    title="Valor"
                    dataIndex="value"
                    key="value"
                    render={(value) => formatMoney(value)}
                  />
                </Table>
              </Card>
            </Col>
            <Col>
              <SolicitationRevisionScreen
                compact={true}
                data={data}
                ref={childRef}
                onAfterSubmit={onAfterSubmit}
              />
            </Col>
          </Row>
        </Content>
        <Footer>
          <Button
            loading={loading}
            type="primary"
            icon={<ArrowRightOutlined />}
            onClick={onSubmit}
          >
            Próxima Etapa
          </Button>
        </Footer>
      </React.Fragment>
    );
  } else {
    return undefined;
  }
};

export default connect(mapStateToProps, {})(ActivationCheckoutChargeScreen);
