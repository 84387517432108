import React from "react";
import ShareholdersForm from "./shareholders-form";
import PropTypes from "prop-types";

export default function ShareholdersUpdateForm({
  onUpdate,
  isOpen,
  loading,
  setIsOpen,
  data,
  formConfig
}) {
  const overriddenFormConfig = {
    ...formConfig,
    cpf: { disabled: true },
    cnpj: { disabled: true },
    shareholderType: { disabled: true }
  };

  return (
    <ShareholdersForm
      data={data}
      onSave={onUpdate}
      loading={loading}
      isOpen={isOpen}
      title="Atualizar Sócio"
      okText="Atualizar"
      onCancel={() => {}}
      setIsOpen={setIsOpen}
      formConfig={overriddenFormConfig}
      successMessage="Sócio atualizado com sucesso"
    />
  );
}

ShareholdersUpdateForm.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    cpf: PropTypes.string,
    cnpj: PropTypes.string,
    birthday: PropTypes.string,
    foundedAt: PropTypes.string,
    mother: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    shareholderType: PropTypes.shape({
      code: PropTypes.string.isRequired
    }),
    address: PropTypes.object
  }),
  formConfig: PropTypes.shape({
    name: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    birthday: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    mother: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    email: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    phone: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    address: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    })
  })
};
