import { themeColors } from './index'
export const styleLogin = {
    '.login': {
        '.ant-card': {
            backgroundColor: themeColors.darkColor,
            '.ant-card-head': {
                borderBottom: '0 !important'
            },
            '.ant-btn-link': {
                color: `${themeColors.highlightColor} !important`,
                float: 'right',
                padding: 0,
                ':hover': {
                    color: `${themeColors.highlightColor} !important`,
                    textDecoration: 'underline'
                }
            },
            '.logo-login': {
                '& path': {
                    fill: themeColors.highlightColor,
                }
            },
            '.ant-btn-primary:not(:disabled)': {
                backgroundColor: themeColors.highlightColor,
                color: themeColors.darkColor
            }
        },
        '@media (max-width: 576px)': {
            '&': {
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 0,
                width: '100%'
            },
            '& .ant-btn-link': {
                display: 'none'
            }
        }
    },
    '.card-login': {
        '@media (max-width: 576px)': {
            '&': {
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                borderRadius: 0,
                width: '100%'
            },
            '& .ant-btn-link': {
                display: 'none'
            }
        }
    }
}