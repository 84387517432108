import { formatMoney } from "utils";

/* Configurações dos gráficos */
const scales = {
  none: {
    xAxes: { display: false },
    yAxes: { display: false }
  },
  xy: (format) => {
    return {
      xAxis: {
        grid: { display: false },
        ticks: { font: { family: "Manrope", color: "#797979", size: 10 } }
      },
      yAxis: {
        grid: { display: true },
        ticks: {
          font: { family: "Manrope", color: "#797979", size: 10 },
          callback: function (value) {
            return format ? formatMoney(value, false) : value;
          }
        }
      }
    };
  }
};

const config = {
  bar: (data, label) => {
    const maxValue = Math.max(...data.datasets[0].data);
    let options = {
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true,
            max: maxValue < 10 ? 10 : maxValue
          }
        },
        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: label
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };

    if (label) {
      options.options.plugins["tooltip"] = {
        callbacks: {
          label: label
        }
      };
    }
    return options;
  },
  stacked: (data, label) => {
    let options = {
      data: data,
      options: {
        indexAxis: "y",
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        },
        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };

    if (label) {
      options.options.plugins["tooltip"] = {
        callbacks: {
          label: label
        }
      };
    }

    return options;
  },
  doughnut: (data, showLabels, position, label) => {
    let plugins = {
      tooltip: {
        callbacks: {
          label: label
        }
      },
      legend: {
        display: true,
        position: position
      }
    };
    plugins["datalabels"] = showLabels
      ? {
          formatter: formatMoney,
          anchor: "end",
          align: "top",
          font: {
            weight: "bold",
            family: "Manrope",
            size: "10"
          }
        }
      : {
          display: false
        };

    return {
      data: data,
      options: {
        scales: scales.none,
        plugins: plugins,
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  columns: (data, showLabels, format = true) => {
    return {
      data: data,
      options: {
        scales: scales.xy(format),
        plugins: {
          datalabels: {
            display: !showLabels ? false : true,
            formatter: formatMoney,
            anchor: "end",
            align: "top",
            font: {
              weight: "bold",
              family: "Manrope",
              size: "10"
            }
          },
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: function (data) {
                return formatMoney(data.raw);
              },
              title: function (data) {
                return data[0].label;
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  }
};

export default config;
