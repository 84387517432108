import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Spin,
  Switch,
  Table
} from "antd";
import { FormatMoney, Header, SwitchLabel } from "components";
import usePage from "hooks/usePage";
import React from "react";
import { NumericFormat } from "react-number-format";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getAllExpenseTypes,
  saveExpenseType,
  toggleBlockExpenseType,
  toggleEnabledExpenseType
} from "services/expense-type-service";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const DataFetch = {
  data: [],
  loading: false
};

const ExpenseTypeScreen = (props) => {
  const { login } = props;
  const navigate = useNavigate();
  const [data, setData] = React.useState(DataFetch);
  const [pageParams, setPageParams] = useSearchParams();
  const [formCreate] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [isModalCreateOpen, setIsModalCreateOpen] = React.useState(false);

  const load = async () => {
    setData((x) => ({ ...x, loading: true }));
    const res = await getAllExpenseTypes();
    setData((x) => ({ data: res, loading: false }));
  };
  const localLoad = (e) => setData((prev) => ({ ...prev, data: e }));
  const { page, search, changePage, localSearch } = usePage(load, localLoad);

  React.useEffect(() => {
    load();
  }, []);

  const handleCreateOrSaveExpenseType = async (values) => {
    setLoading(true);

    try {
      values.spendingLimit =
        typeof values.spendingLimit == "string"
          ? Number(values.spendingLimit.replace(".", "").replace(",", "."))
          : values.spendingLimit;

      await saveExpenseType({
        id: values.id,
        body: {
          ...values,
          block: values.block,
          enabled: values.enabled,
          companyCorporateName: login.company.corporateName,
          companyName: login.company.name,
          companyDocument: login.company.cnpj.trim(),
          companyAccount: login.company.account,
          companyBaasId: login.company.externalKey,
          companyBusinessAccount: login.company.businessAccount
        }
      });
      load();
    } catch (e) {
    } finally {
      formCreate.resetFields();
      setIsModalCreateOpen(false);
      setLoading(false);
    }
  };

  const handleCancelModal = () => {
    formCreate.resetFields();
    setIsModalCreateOpen(false);
  };

  const onToggleBlockOrEnabled = async (value, index, field) => {
    setData((x) => ({ ...x, loading: true }));
    const newData = [...data.data];

    const currentIndex = index + (page - 1) * 10;

    try {
      newData[currentIndex][field] = value;

      if (field === "enabled") {
        await toggleEnabledExpenseType({ id: newData[currentIndex].id });
      } else if (field === "block") {
        await toggleBlockExpenseType({ id: newData[currentIndex].id });
      }

      setData((x) => ({ ...x, data: newData, loading: false }));
    } catch (error) {
    } finally {
    }
  };

  const handleShowEditExpenseType = (id) => {
    setIsModalCreateOpen(true);

    if (id) {
      const expenseType = data.data.filter((d) => d.id === id)[0];
      formCreate.setFieldsValue({
        id,
        name: expenseType.name,
        block: expenseType.block,
        enabled: expenseType.enabled,
        spendingLimit: expenseType.spendingLimit
      });
    } else {
      formCreate.resetFields();
    }
  };

  return (
    <Layout className="alymente-layout">
      <Header title="Tipos de Despesas" />
      <Content>
        <Table
          onChange={(page) => {
            changePage(page.current);
          }}
          scroll={{ y: 650, x: 1000 }}
          dataSource={data.data}
          loading={data.loading}
          pagination={{ current: page }}
          bordered
        >
          <Table.Column
            align={"left"}
            title="Nome"
            dataIndex="name"
            key="name"
          />
          <Table.Column
            align="right"
            title="Limite de gastos"
            dataIndex="spendingLimit"
            key="spendingLimit"
            width={340}
            render={(value) => <FormatMoney value={value} />}
          />
          <Table.Column
            align="center"
            title="Habilitar"
            dataIndex="enabled"
            key="enabled"
            width={170}
            render={(value, record, index) => (
              <Switch
                checked={value}
                onChange={(x) => onToggleBlockOrEnabled(x, index, "enabled")}
              />
            )}
          />
          <Table.Column
            align="center"
            title="Bloquear"
            dataIndex="block"
            key="block"
            width={170}
            render={(value, record, index) => (
              <Switch
                checked={value}
                onChange={(x) => onToggleBlockOrEnabled(x, index, "block")}
              />
            )}
          />
          <Table.Column
            fixed="right"
            width={100}
            align={"center"}
            title="Ações"
            dataIndex="id"
            key="id"
            render={(value) => (
              <Button
                size="small"
                type={"link"}
                onClick={() => handleShowEditExpenseType(value)}
              >
                Editar
              </Button>
            )}
          />
        </Table>

        <Modal
          title="Tipo de Despesa"
          onOk={formCreate.submit}
          open={isModalCreateOpen}
          okText="Salvar"
          onCancel={handleCancelModal}
          confirmLoading={loading}
        >
          <Form
            style={{ marginTop: 20 }}
            form={formCreate}
            layout="vertical"
            name="basic"
            onFinish={handleCreateOrSaveExpenseType}
          >
            <Spin spinning={loading}>
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label="Nome"
                    hasFeedback
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Insira o nome do tipo da despesa"
                      }
                    ]}
                  >
                    <Input autoComplete="off" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label="Limite de gastos"
                    hasFeedback
                    name="spendingLimit"
                    rules={[
                      { required: true, message: "Insira o limite de gastos" }
                    ]}
                  >
                    <NumericFormat
                      className="input-currency"
                      decimalSeparator={","}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      displayType={"input"}
                      thousandSeparator={"."}
                      prefix={""}
                      onFocus={(x) => x.target.select()}
                      onValueChange={(value) =>
                        formCreate.setFieldValue(
                          "spendingLimit",
                          value.floatValue
                        )
                      }
                      disabled={loading}
                    />
                  </Form.Item>
                  <Form.Item name="id" hidden></Form.Item>
                </Col>

                <Col sm={24} style={{ marginBottom: 20 }}>
                  <Alert
                    type="info"
                    message="Para desativar alerta de “Limite de Gasto” para o colaborador e aprovador, inserir 0 como valor."
                    showIcon
                  />
                </Col>
              </Row>

              <Form.Item
                name={"enabled"}
                initialValue={true}
                valuePropName="checked"
              >
                <SwitchLabel label="HABILITAR?" />
              </Form.Item>

              <Form.Item
                name={"block"}
                initialValue={false}
                valuePropName="checked"
              >
                <SwitchLabel label="Bloquear fora do Limite de Gasto?" />
              </Form.Item>

              <Col sm={24} style={{ marginTop: 20 }}>
                <Alert
                  type="info"
                  message="Ao selecionar o “Bloquear fora do Limite de Gasto” você irá bloquear a inserção de uma nova despesa quando ela passar do valor “Limite de Gasto”."
                  showIcon
                />
              </Col>
            </Spin>
          </Form>
        </Modal>
      </Content>
      <Footer>
        <Button
          className="btn-save"
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => setIsModalCreateOpen(true)}
        >
          Novo Tipo de Despesa
        </Button>
      </Footer>
    </Layout>
  );
};

export default connect(mapStateToProps, {})(ExpenseTypeScreen);
