import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Layout,
  Button,
  Row,
  Col,
  Card,
  Input,
  Select,
  Spin,
  Typography,
  Tag
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { Header, InputCurrency, Form } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { EXPENSE_STATUS_DATA } from "utils/static-data";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import {
  getExpenseById,
  retryGetAddress,
  updateExpense,
  getExpenseImagesByExpenseId
} from "services/expense-service";

import moment from "moment";
import { DatePicker } from "components";
import { getEmployeeCodes } from "services/employee-service";
const { Text } = Typography;

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const ExpenseDetailsScreen = (props) => {
  const { login } = props;
  const { id = null } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [fullImageOdometer, setFullImageOdometer] = React.useState(false);
  const [fullImageInvoice, setFullImageInvoice] = React.useState(false);
  const [fullImageFuelPump, setFullImageFuelPump] = React.useState(false);
  const [fullImageCarLicense, setFullImageCarLicense] = React.useState(false);
  const [expenseImages, setExpenseImages] = useState([]);

  const isCodeEnabledInCompany = login.company.enableCode;

  const load = async () => {
    setLoading(true);

    if (id) {
      let expenseResponse = await getExpenseById(id);
      const hasToLoadAddress =
        expenseResponse.postalCode && !expenseResponse.city;
      if (hasToLoadAddress) {
        await retryGetAddress(id, expenseResponse.postalCode);
        expenseResponse = await getExpenseById(id);
      }

      if (expenseResponse) {
        if (isCodeEnabledInCompany) {
          const [employeeCode] = await getEmployeeCodes([
            expenseResponse.employeeId
          ]);
          expenseResponse.code = employeeCode?.code;
        }
        const companies =
          expenseResponse.expenseGroupCompany.length > 0
            ? expenseResponse.expenseGroupCompany
                .map((company) => company.companyName)
                .sort()
                .join(", ")
            : undefined;

        setData({ ...expenseResponse, companies });

        if (expenseResponse.expenseImages.length > 0) {
          const expenseImagesResponse = await getExpenseImagesByExpenseId(id);
          setExpenseImages(expenseImagesResponse);
        }
      }
    }

    setLoading(false);
  };

  const loadFields = () => {
    const expense = data;

    form.setFieldsValue({
      ...expense,
      amount: expense.hotelDailyCost ? expense.amount / 100 : "",
      hotelDailyCost: expense.hotelDailyCost
        ? expense.hotelDailyCost / 100
        : "",
      createdAt: moment(expense.createdAt),
      transactionAt: moment(expense.transactionAt),
      hotelStartDate: expense.hotelStartDate
        ? moment(expense.hotelStartDate)
        : null,
      hotelEndDate: expense.hotelEndDate ? moment(expense.hotelEndDate) : null,
      ...(isCodeEnabledInCompany && { code: expense.code })
    });
  };

  const onSave = async (values) => {
    setLoading(true);
    try {
      const body = {
        status: values.status
      };

      await updateExpense(id, body);
      navigate("/expense");
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  // The following function is used to get the image of the expense by type
  function getExpenseImageByType(type) {
    const expenseImage = expenseImages.find((image) => image.type === type);

    if (!expenseImage) {
      return null;
    }

    return `data:image/jpeg;base64,${expenseImage.image}`;
  }

  useEffect(() => {
    if (data) {
      loadFields();
    }
  }, [data]);

  useEffect(() => {
    load();
  }, [id]);

  return (
    <Layout className="alymente-layout">
      <Header
        title={`${data && data.employeeName ? data.employeeName : ""}`}
        subtitle="Selecione um status para o comprovante"
      ></Header>
      <Content>
        <Form form={form} layout="vertical" name="basic" onFinish={onSave}>
          <Row justify="center" gutter={24}>
            <Col xl={18} lg={20} md={24}>
              <Card title="Comprovante">
                <Spin spinning={loading}>
                  <Row gutter={24}>
                    {!(
                      fullImageInvoice ||
                      fullImageFuelPump ||
                      fullImageOdometer ||
                      fullImageCarLicense
                    ) && (
                      <React.Fragment>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="Data da Transação"
                            name={"transactionAt"}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              showTime={{ format: "HH:mm:ss" }}
                              format="DD/MM/YYYY HH:mm:ss"
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="Data da Criação do Comprovante"
                            name={"createdAt"}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              showTime={{ format: "HH:mm:ss" }}
                              format="DD/MM/YYYY HH:mm:ss"
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col md={24}>
                          <Form.Item label="Colaborador" name={"employeeName"}>
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        {isCodeEnabledInCompany && (
                          <Col md={24}>
                            <Form.Item
                              label="Código do Colaborador"
                              name={"code"}
                            >
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        )}
                        <Col md={24}>
                          <Form.Item label="Descrição" name={"merchantName"}>
                            <Input disabled />
                          </Form.Item>
                        </Col>

                        {data?.companies && (
                          <Col md={24}>
                            <Form.Item
                              label="Empresa(s) vínculada(s) ao Gasto"
                              name={"companies"}
                            >
                              <Input.TextArea
                                disabled
                                rows={3}
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                        )}

                        <Col lg={12} md={24}>
                          <Form.Item label="Tipo de Gasto" name={"expenseType"}>
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="Status"
                            name={"status"}
                            rules={[
                              { required: true, message: "Insira um status" }
                            ]}
                          >
                            <Select
                              placeholder="Selecione um status"
                              allowClear
                            >
                              {EXPENSE_STATUS_DATA.map((item) => (
                                <Select.Option
                                  key={item.name}
                                  value={item.code}
                                >
                                  <Tag
                                    color={item.category}
                                    bordered={false}
                                    style={{
                                      background: "none",
                                      fontSize: "14px"
                                    }}
                                  >
                                    {item.name}
                                  </Tag>
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item label="Valor" name={["amount"]}>
                            <InputCurrency disabled autoComplete="off" />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="Quilometragem Km)"
                            name={["mileage"]}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item label="Litros (L)" name={["litersFuel"]}>
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="Valor / Litro"
                            name={["amountLitersFuel"]}
                          >
                            <InputCurrency disabled autoComplete="off" />
                          </Form.Item>
                        </Col>
                        <Col md={24}>
                          <Form.Item label="Combustível" name={["fuelType"]}>
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        {data?.licensePlate && (
                          <Col md={24}>
                            <Form.Item
                              label="Placa do Carro"
                              name={["licensePlate"]}
                            >
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        )}
                        {data?.hotelStartDate && (
                          <>
                            <Col lg={12} md={24}>
                              <Form.Item
                                label="Data de Início da Hospedagem"
                                name={"hotelStartDate"}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  showTime={false}
                                  format="DD/MM/YYYY"
                                  disabled
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={24}>
                              <Form.Item
                                label="Data de Fim da Hospedagem"
                                name={"hotelEndDate"}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  showTime={false}
                                  format="DD/MM/YYYY"
                                  disabled
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={24}>
                              <Form.Item
                                label="Valor da Diária"
                                name={["hotelDailyCost"]}
                              >
                                <InputCurrency disabled autoComplete="off" />
                              </Form.Item>
                            </Col>
                            <Col lg={12} md={24}>
                              <Form.Item
                                label="Dados do Hotel"
                                name={["hotelDetails"]}
                              >
                                <Input disabled />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                        {data?.street && (
                          <Col md={24}>
                            <Form.Item label="Rua" name={["street"]}>
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        )}
                        {data?.neighborhood && (
                          <Col md={24}>
                            <Form.Item label="Bairro" name={["neighborhood"]}>
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        )}
                        {data?.postalCode && (
                          <Col md={24}>
                            <Form.Item label="Cep" name={["postalCode"]}>
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        )}
                        {data?.city && (
                          <Col md={24}>
                            <Form.Item label="Cidade" name={["city"]}>
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        )}
                        {data?.state && (
                          <Col md={24}>
                            <Form.Item label="Estado" name={["state"]}>
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        )}
                        {data?.country && (
                          <Col md={24}>
                            <Form.Item label="Pais" name={["country"]}>
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        )}
                      </React.Fragment>
                    )}
                    {data && (
                      <React.Fragment>
                        {!(
                          fullImageFuelPump ||
                          fullImageInvoice ||
                          fullImageCarLicense
                        ) && (
                          <Col xl={fullImageOdometer ? 24 : 6} md={24}>
                            <Col md={24} style={{ textAlign: "center" }}>
                              <Col
                                style={{ marginBottom: 10, marginTop: 10 }}
                                md={24}
                                align="center"
                              >
                                <Text>Foto do Hodômetro</Text>
                              </Col>
                              {getExpenseImageByType("hodometer") ? (
                                fullImageOdometer ? (
                                  <InnerImageZoom
                                    width={500}
                                    src={getExpenseImageByType("hodometer")}
                                    hideHint={true}
                                    moveType="drag"
                                    hideCloseButton={true}
                                  />
                                ) : (
                                  <img
                                    alt="image"
                                    src={getExpenseImageByType("hodometer")}
                                    width={150}
                                  />
                                )
                              ) : (
                                <Text type="secondary">Não cadastrada</Text>
                              )}
                              {getExpenseImageByType("hodometer") && (
                                <Col md={24} style={{ textAlign: "center" }}>
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      setFullImageOdometer((prev) => !prev)
                                    }
                                  >
                                    {fullImageOdometer
                                      ? "Diminuir Imagem"
                                      : "Expandir Imagem"}
                                  </Button>
                                </Col>
                              )}
                            </Col>
                          </Col>
                        )}
                        {!(
                          fullImageFuelPump ||
                          fullImageOdometer ||
                          fullImageCarLicense
                        ) && (
                          <Col xl={fullImageInvoice ? 24 : 6} md={24}>
                            <Col md={24} style={{ textAlign: "center" }}>
                              <Col
                                style={{ marginBottom: 10, marginTop: 10 }}
                                md={24}
                                align="center"
                              >
                                <Text>Foto da Nota Fiscal</Text>
                              </Col>
                              {getExpenseImageByType("invoice") ? (
                                fullImageInvoice ? (
                                  <InnerImageZoom
                                    width={500}
                                    src={getExpenseImageByType("invoice")}
                                    hideHint={true}
                                    moveType="drag"
                                    hideCloseButton={true}
                                  />
                                ) : (
                                  <img
                                    alt="image"
                                    src={getExpenseImageByType("invoice")}
                                    width={150}
                                  />
                                )
                              ) : (
                                <Text type="secondary">Não cadastrada</Text>
                              )}

                              {getExpenseImageByType("invoice") && (
                                <Col md={24} style={{ textAlign: "center" }}>
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      setFullImageInvoice((prev) => !prev)
                                    }
                                  >
                                    {fullImageInvoice
                                      ? "Diminuir Imagem"
                                      : "Expandir Imagem"}
                                  </Button>
                                </Col>
                              )}
                            </Col>
                          </Col>
                        )}
                        {!(
                          fullImageInvoice ||
                          fullImageOdometer ||
                          fullImageCarLicense
                        ) && (
                          <Col xl={fullImageFuelPump ? 24 : 6} md={24}>
                            <Col md={24} style={{ textAlign: "center" }}>
                              <Col
                                style={{ marginBottom: 10, marginTop: 10 }}
                                md={24}
                                align="center"
                              >
                                <Text>Foto da Bomba de Combustível</Text>
                              </Col>
                              {getExpenseImageByType("gas_pump") ? (
                                fullImageFuelPump ? (
                                  <InnerImageZoom
                                    width={500}
                                    src={getExpenseImageByType("gas_pump")}
                                    hideHint={true}
                                    moveType="drag"
                                    hideCloseButton={true}
                                  />
                                ) : (
                                  <img
                                    alt="image"
                                    src={getExpenseImageByType("gas_pump")}
                                    width={150}
                                  />
                                )
                              ) : (
                                <Text type="secondary">Não cadastrada</Text>
                              )}
                              {getExpenseImageByType("gas_pump") && (
                                <Col md={24} style={{ textAlign: "center" }}>
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      setFullImageFuelPump((prev) => !prev)
                                    }
                                  >
                                    {fullImageFuelPump
                                      ? "Diminuir Imagem"
                                      : "Expandir Imagem"}
                                  </Button>
                                </Col>
                              )}
                            </Col>
                          </Col>
                        )}
                        {!(
                          fullImageInvoice ||
                          fullImageOdometer ||
                          fullImageFuelPump
                        ) && (
                          <Col xl={fullImageCarLicense ? 24 : 6} md={24}>
                            <Col md={24} style={{ textAlign: "center" }}>
                              <Col
                                style={{ marginBottom: 10, marginTop: 10 }}
                                md={24}
                                align="center"
                              >
                                <Text>Foto da Placa do Veículo</Text>
                              </Col>
                              {getExpenseImageByType("license_plate") ? (
                                fullImageCarLicense ? (
                                  <InnerImageZoom
                                    width={500}
                                    src={getExpenseImageByType("license_plate")}
                                    hideHint={true}
                                    moveType="drag"
                                    hideCloseButton={true}
                                  />
                                ) : (
                                  <img
                                    alt="image"
                                    src={getExpenseImageByType("license_plate")}
                                    width={150}
                                  />
                                )
                              ) : (
                                <Text type="secondary">Não cadastrada</Text>
                              )}
                              {getExpenseImageByType("license_plate") && (
                                <Col md={24} style={{ textAlign: "center" }}>
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      setFullImageCarLicense((prev) => !prev)
                                    }
                                  >
                                    {fullImageCarLicense
                                      ? "Diminuir Imagem"
                                      : "Expandir Imagem"}
                                  </Button>
                                </Col>
                              )}
                            </Col>
                          </Col>
                        )}
                      </React.Fragment>
                    )}
                  </Row>
                </Spin>
              </Card>
            </Col>
          </Row>
        </Form>
      </Content>
      <Footer>
        <Button
          className="btn-save"
          disabled={loading}
          loading={loading}
          type="primary"
          icon={<SaveOutlined />}
          onClick={() => form.submit()}
        >
          Salvar
        </Button>
      </Footer>
    </Layout>
  );
};

export default connect(mapStateToProps, {})(ExpenseDetailsScreen);
