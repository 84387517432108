import axios from "axios";
import { handlerError } from "utils";

export async function getBills(billStatus, barcode) {
  try {
    const config = {
      params: {}
    };

    if (billStatus) {
      config.params.status = billStatus;
    }
    if (barcode) {
      config.params.barcode = barcode;
    }
    const res = await axios.get("/api/bill", config);
    return res.data ?? [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
}

export async function getAllBillStatus() {
  try {
    const res = await axios.get("/api/bill/status");
    return res.data ?? [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
}
