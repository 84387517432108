import React from 'react';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Input, Button, Card, Row, Col, Layout, Select } from 'antd';
import { AuthHeader, Form } from 'components';
import * as authAction from 'actions/auth-action';
import { getAllChildrenCompanies } from 'services/company-service';
import { SoftColor } from 'config/constants/Color';
import { getAuthorization } from 'config/service/auth-service';
import Env from 'env';

const mapStateToProps = (state) => ({
	login: state.authReducer.loginReducer,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			...authAction,
		},
		dispatch
	);

const CompaniesScreen = (props) => {
	const navigate = useNavigate();
	const { login, loginCompanyAction } = props;
	const [iframe, setIframe] = React.useState(false);

	const onLogin = async (values) => {
		await loginCompanyAction(values.company);
		navigate('/activation');
	};

	return (
		<Layout style={{ backgroundColor: SoftColor, height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
			<Layout.Content>
				<Row
					justify="center"
					align="middle"
					className="login">
					<Col
						xxl={6}
						xl={8}
						lg={10}
						md={14}
						sm={24}>
						<Card
							title={<AuthHeader />}
							bordered={false}
							className="card-login">
							<Form
								style={{ width: '100%' }}
								name="basic"
								initialValues={{ email: login.user?.email, company: null }}
								onFinish={onLogin}>
								<Form.Item
									hasFeedback
									name="email">
									<Input
										disabled
										autoComplete="off"
										inputMode="email"
										placeholder="E-mail"
									/>
								</Form.Item>
								<Form.Item
									hasFeedback
									name="company"
									rules={[{ required: true, message: 'Selecione uma empresa' }]}>
									<Select
										loading={login.children?.data.length === 0}
										placeholder="Selecione uma empresa">
										{login.children?.data.map((x) => (
											<Select.Option value={x.id}>{x.name}</Select.Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item>
									<Button
										loading={login.isProcessing}
										type="primary"
										htmlType="submit"
										style={{ width: '100%' }}>
										Selecionar
									</Button>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</Layout.Content>
		</Layout>
	);
	//}
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesScreen);
