import { combineReducers } from 'redux';
import environment from 'env';

const LoginState = {
	isProcessing: false,
	message: '',
	user: null,
	company: null,
	children: {
		data: [],
		has: false,
	},
};

const LoginReducer = (state = LoginState, action) => {
	const { payload } = action;
	const hasChildren = payload?.children?.length > 1;
	const isMatriz = hasChildren && payload?.company?.matriz === null;
	switch (action.type) {
		case 'LOGIN':
			return Object.assign({}, state, {
				isProcessing: true,
				message: '',
				user: null,
				company: null,
				children: null,
			});
		case 'LOGIN_SUCCESS':
			payload.user.feature.benefit = true;
			return Object.assign({}, state, {
				isProcessing: false,
				message: '',
				user: payload.user,
				company: payload.company ? { ...payload.company, multicompany: hasChildren || payload.company.master, isMatriz } : null,
				children: payload.children ? { data: payload.children, has: hasChildren } : state.children,
			});
		case 'LOGIN_FAIL':
			return Object.assign({}, state, {
				isProcessing: false,
				message: payload.errorMessage,
				user: null,
				company: null,
				children: null,
			});
		case 'LOGIN_2FA':
			return Object.assign({}, state, {
				isProcessing: true,
				message: '',
			});
		case 'LOGIN_2FA_FAIL':
			return Object.assign({}, state, {
				isProcessing: false,
				message: payload.errorMessage,
			});
		default:
			return state;
	}
};

const ForgotState = {
	isProcessing: false,
	message: '',
	success: false,
};

const ForgotReducer = (state = ForgotState, action) => {
	const { payload } = action;
	switch (action.type) {
		case 'FORGOT':
			return Object.assign({}, state, {
				isProcessing: true,
				message: '',
				success: false,
			});
		case 'FORGOT_SUCCESS':
			return Object.assign({}, state, {
				isProcessing: false,
				message: payload,
				success: true,
			});
		case 'FORGOT_FAIL':
			return Object.assign({}, state, {
				isProcessing: false,
				message: payload.errorMessage,
				success: false,
			});
		default:
			return state;
	}
};

const ResetState = {
	isProcessing: false,
	message: '',
	success: false,
	data: null,
	type: null,
};

const ResetReducer = (state = ResetState, action) => {
	const { payload } = action;
	switch (action.type) {
		case 'GET_RESET_SUCCESS':
			return Object.assign({}, state, {
				data: payload,
			});
		case 'RESET':
			return Object.assign({}, state, {
				isProcessing: true,
				message: '',
				success: false,
			});
		case 'RESET_SUCCESS':
			return Object.assign({}, state, {
				isProcessing: false,
				message: '',
				success: true,
				type: payload,
			});
		case 'RESET_FAIL':
			return Object.assign({}, state, {
				isProcessing: false,
				message: payload.errorMessage,
				success: false,
			});
		default:
			return state;
	}
};

export default combineReducers({
	loginReducer: LoginReducer,
	forgotReducer: ForgotReducer,
	resetReducer: ResetReducer,
});
