import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Layout, Button, Table, Modal, Tag } from "antd";
import { FormatDate, Header, AutoCompleteCompany } from "components";
import {
  loadAllSolicitationsCard,
  updateSolicitationsCard
} from "services/card-service";
import { InfoCircleOutlined } from "@ant-design/icons";
import _ from "lodash";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const DataFetch = {
  data: [],
  loading: false
};

const CardSolicitationsScreen = (props) => {
  const { login } = props;
  const [data, setData] = React.useState(DataFetch);

  const load = async () => {
    setData((x) => ({ ...x, loading: true }));
    const res = await loadAllSolicitationsCard();
    setData((x) => ({ ...x, data: res, loading: false }));
  };

  const onSend = (type, id) => {
    Modal.confirm({
      title:
        type == "send"
          ? "Confirma o envio de cartão ao colaborador?"
          : "Confirma o cartão entregue ao colaborador?",
      icon: <InfoCircleOutlined />,
      content:
        type == "send"
          ? "O envio ficará com o status Novo até estar associado a um pedido."
          : "O colaborador já poderá ativar o cartão pelo aplicativo.",
      onOk: async () => {
        setData((x) => ({ ...x, loading: true }));
        await updateSolicitationsCard(id, type == "send" ? "s" : "d");
        load();
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };

  const onCancel = (id) => {
    Modal.confirm({
      title: "Confirma o cancelamento de solicitação de cartão do colaborador?",
      icon: <InfoCircleOutlined />,
      content: "A solicitação de cartão será cancelada.",
      onOk: async () => {
        setData((x) => ({ ...x, loading: true }));
        await updateSolicitationsCard(id, "c");
        load();
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };

  React.useEffect(() => {
    load();
  }, []);

  return (
    <Layout className="alymente-layout">
      <Header
        title={`Solicitações de Cartão`}
        subtitle={"Acompanhe os cartões solicitados pelos colaboradores"}
      />
      <Content>
        <Table
          scroll={{ y: 700 }}
          dataSource={data.data}
          loading={data.loading}
          pagination={false}
          bordered
        >
          <Table.Column
            width={130}
            title="Data"
            dataIndex={["createdAt"]}
            key="createdAt"
            render={(value) => <FormatDate value={value} />}
          />
          <Table.Column title="Colaborador" dataIndex={["user"]} key="user" />
          <Table.Column
            width={120}
            align="center"
            title="Status"
            dataIndex="status"
            key="status"
            filters={_.uniqWith(
              data.data.map((d) => {
                return { text: d.status.name, value: d.status.code };
              }),
              _.isEqual
            )}
            onFilter={(value, record) => record.status.code === value}
            render={(value, row) => {
              let status = value;
              if (value.code != "n" && value.code != "c") {
                status = row.delivery;
              }
              return (
                <Tag color={status.category}>{status.name.toUpperCase()}</Tag>
              );
            }}
          />
          <Table.Column
            width={120}
            align="center"
            title="Situação"
            dataIndex="status"
            key="status"
            render={(value, row) => {
              let status = "";
              if (value.code != "n" && value.code != "c") {
                status = value.code == "s" ? "Em envio" : "Entregue";
              } else if (value.code == "c") {
                status = "Cancelado";
              }
              return status;
            }}
          />
          <Table.Column
            width={260}
            fixed="right"
            align="center"
            title="Ações"
            dataIndex="id"
            key="id"
            render={(value, row) => (
              <React.Fragment>
                <Button
                  disabled={row.status.code != "n" || data.loading}
                  danger
                  size="small"
                  type={"link"}
                  onClick={() => onCancel(value)}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={row.status.code != "n" || data.loading}
                  loading={data.loading}
                  size="small"
                  type={"link"}
                  onClick={() => onSend("send", value)}
                >
                  Enviar
                </Button>
                <Button
                  disabled={row.status.code != "n" || data.loading}
                  loading={data.loading}
                  size="small"
                  type={"link"}
                  onClick={() => onSend("delivery", value)}
                >
                  Entregar
                </Button>
              </React.Fragment>
            )}
          />
        </Table>
      </Content>
      <Footer></Footer>
    </Layout>
  );
};

export default connect(mapStateToProps, {})(CardSolicitationsScreen);
