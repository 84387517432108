import React from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";
import {
  Layout,
  Table,
  Alert,
  Row,
  Col,
  Statistic,
  Modal,
  Typography
} from "antd";
import { CardStatistic, FormatDate, FormatMoney } from "components";
import charts from "./configCharts";
import { colors, colors2, formatMoney, getMonth } from "utils/index";
import moment from "moment";
import {
  getReportUrls,
  getReportUrlsCard,
  getDashboard
} from "services/report-service";
import { getAllBenefit } from "services/benefit-service";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import { translate } from "config/language";

const { Content } = Layout;

ChartJS.register(
  ChartDataLabels,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const DashboardScreen = (props) => {
  const { login } = props;
  const { company } = useOutletContext();
  const [reportCorporateRefund, setReportCorporateRefund] = React.useState({
    loading: true,
    data: []
  });
  const [reportActiveBalance, setReportActiveBalance] = React.useState({
    loading: true,
    data: [],
    show: false,
    dataTable: []
  });
  const [reportCard, setReportCard] = React.useState({
    loading: true,
    data: []
  });
  const [reportExpiredCard, setReportExpiredCard] = React.useState({
    loading: true,
    data: [],
    show: false,
    dataTable: []
  });
  const [reportStablishmentExpense, setReportStablishmentExpense] =
    React.useState({ loading: true, data: [], show: false, dataTable: [] });
  const [reportMonth, setReportMonth] = React.useState({
    loading: true,
    data: []
  });
  const [reportCharge, setReportCharge] = React.useState({
    loading: true,
    data: []
  });
  const [reportHighBalance, setReportHighBalance] = React.useState({
    loading: true,
    data: [],
    show: false,
    dataTable: []
  });
  const [reportEmployee, setReportEmployee] = React.useState({
    loading: true,
    data: [],
    show: false,
    dataTable: []
  });
  const [reportRecentCharge, setReportRecentCharge] = React.useState({
    loading: true,
    data: [],
    show: false,
    dataTable: []
  });
  const [reportBenefit, setReportBenefit] = React.useState({
    loading: true,
    data: []
  });
  const [benefits, setBenefits] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const load = async () => {
    const isLoad =
      (!login.company.master && login.company.id === company) ||
      (login.company.master && login.company.id !== company);
    if (isLoad) {
      setLoading(true);
      const urlsOthers = await getReportUrls();
      const urlsCard = await getReportUrlsCard();
      const benefits = await getAllBenefit(company);
      setBenefits(benefits);
      const urls = { ...urlsOthers, ...urlsCard };
      for (let x in urls) {
        setReport(x, [], true);
      }
      setLoading(false);
      for (let x in urls) {
        if (getStaticReports(x)) {
          getDashboard(urls[x], company).then((dash) => {
            setReport(x, dash, false);
          });
        } else {
          respondToVisibility(document.getElementById(x), (visible, target) => {
            const chart = target.getAttribute("id");
            const loaded = target.getAttribute("loaded");

            if (visible && loaded === "false") {
              target.setAttribute("loaded", "true");
              getDashboard(urls[chart], company).then((dash) => {
                setReport(chart, dash, false);
              });
            }
          });
        }
      }
    }
  };

  const respondToVisibility = function (element, callback) {
    let options = {
      root: document.documentElement
    };

    let observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        callback(entry.intersectionRatio > 0, entry.target);
      });
    }, options);

    observer.observe(element);
  };

  const getStaticReports = (label) => {
    return (
      label === "reportCard" ||
      label === "reportCharge" ||
      label === "reportEmployee" ||
      label === "reportExpiredCard"
    );
  };

  const setReport = (label, dash, loading) => {
    switch (label) {
      case "reportCorporateRefund":
        return setReportCorporateRefund((x) => ({
          ...x,
          data: dash,
          loading: loading
        }));
      case "reportActiveBalance":
        return setReportActiveBalance((x) => ({
          ...x,
          data: dash,
          loading: loading,
          dataTable: dash.details
        }));
      case "reportCard":
        return setReportCard((x) => ({ ...x, data: dash, loading: loading }));
      case "reportStablishmentExpense":
        return setReportStablishmentExpense((x) => ({
          ...x,
          data: dash,
          loading: loading,
          dataTable: dash.details
        }));
      case "reportMonth":
        return setReportMonth((x) => ({ ...x, data: dash, loading: loading }));
      case "reportCharge":
        return setReportCharge((x) => ({ ...x, data: dash, loading: loading }));
      case "reportHighBalance":
        return setReportHighBalance((x) => ({
          ...x,
          data: dash,
          loading: loading,
          dataTable: dash.details
        }));
      case "reportEmployee":
        return setReportEmployee((x) => ({
          ...x,
          data: dash,
          loading: loading,
          dataTable: dash.details
        }));
      case "reportRecentCharge":
        return setReportRecentCharge((x) => ({
          ...x,
          data: dash,
          loading: loading,
          dataTable: dash.details
        }));
      case "reportBenefit":
        return setReportBenefit((x) => ({
          ...x,
          data: dash,
          loading: loading
        }));
      case "reportExpiredCard":
        return setReportExpiredCard((x) => {
          return {
            ...x,
            data: dash,
            loading: loading,
            dataTable: dash.details
          };
        });
      default:
        console.log("Não localizado!");
        break;
    }
  };

  const getCardInfo = (field) => {
    if (reportEmployee?.data?.summary?.length > 0) {
      return reportEmployee.data.summary[0][field];
    }
    return "";
  };

  const mapDescription = (array) => array.map((x) => x.description);

  const findValueMonth = (array) => (array.length == 1 ? array[0] : null);

  const getConfig = (report, legendPosition = "bottom", showLabel = false) => {
    if (report === "reportCard") {
      let array = [
        ...new Set([
          ...mapDescription(reportCard.data.card),
          ...mapDescription(reportCard.data.cardUser)
        ])
      ].map((d) => {
        const var1 = findValueMonth(
          reportCard.data.card.filter((x) => x.description == d)
        );
        const var2 = findValueMonth(
          reportCard.data.cardUser.filter((x) => x.description == d)
        );
        return {
          description: d,
          value: [var1 ? var1.cards : 0, var2 ? var2.users : 0],
          color: var1 ? var1.color : var2 ? var2.color : colors2.error
        };
      });

      return charts.stacked({
        labels: ["Envio e cartões", "Colaboradores com cartões"],
        datasets: array.map((x) => {
          return {
            label: x.description,
            data: x.value,
            backgroundColor: x.color,
            hoverBackgroundColor: x.color,
            borderColor: "white",
            borderWidth: 2,
            customData: x
          };
        })
      });
    } else if (report === "reportActiveBalance") {
      const mapCount = (days, data) => {
        if (data.length > 0) {
          if (data.filter((x) => x.days === days).length > 0) {
            return data.filter((x) => x.days === days)[0].count;
          }
        }
        return 0;
      };
      const _data = [
        {
          label: "0-30 dias",
          count: mapCount(0, reportActiveBalance.data.summary),
          background: colors2.success
        },
        {
          label: "30-60 dias",
          count: mapCount(30, reportActiveBalance.data.summary),
          background: colors2.warning
        },
        {
          label: "60-90 dias",
          count: mapCount(60, reportActiveBalance.data.summary),
          background: colors2.warning
        },
        {
          label: "90+ dias",
          count: mapCount(90, reportActiveBalance.data.summary),
          background: colors2.alert
        },
        {
          label: "Nunca usou",
          count: mapCount(null, reportActiveBalance.data.summary),
          background: colors2.error
        }
      ];

      return charts.doughnut(
        {
          labels: _data.map((b) => b.label),
          datasets: [
            {
              data: _data.map((b) => b.count),
              backgroundColor: _data.map((x) => x.background),
              datalabels: {
                color: _data.map((x) => x.background)
              }
            }
          ]
        },
        false,
        "bottom",
        (data) => {
          return (
            data.label +
            ": " +
            Number(data.dataset.data[data.dataIndex]) +
            (Number(data.dataset.data[data.dataIndex]) > 1
              ? " colaboradores"
              : " colaborador")
          );
        }
      );
    } else if (report === "reportHighBalance") {
      const mapCount = (value, data) => {
        if (data.length > 0) {
          if (data.filter((x) => x.value == value).length > 0) {
            return data.filter((x) => x.value == value)[0].count;
          }
        }
        return 0;
      };

      const _data = [
        {
          label: "R$0-R$1.000",
          count: mapCount(0, reportHighBalance.data.summary),
          background: colors2.success
        },
        {
          label: "R$1.000-R$3.000",
          count: mapCount(1000, reportHighBalance.data.summary),
          background: colors2.warning
        },
        {
          label: "R$3.000-R$5.000",
          count: mapCount(3000, reportHighBalance.data.summary),
          background: colors2.alert
        },
        {
          label: "R$5.000+",
          count: mapCount(5000, reportHighBalance.data.summary),
          background: colors2.error
        }
      ];

      return charts.doughnut(
        {
          labels: _data.map((b) => b.label),
          datasets: [
            {
              data: _data.map((b) => b.count),
              backgroundColor: _data.map((x) => x.background),
              datalabels: {
                color: _data.map((x) => x.background)
              }
            }
          ]
        },
        false,
        "bottom",
        (data) => {
          return (
            data.label +
            ": " +
            Number(data.dataset.data[data.dataIndex]) +
            (Number(data.dataset.data[data.dataIndex]) > 1
              ? " colaboradores"
              : " colaborador")
          );
        }
      );
    } else if (report === "reportExpiredCard") {
      const months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ];
      const data = reportExpiredCard.data.summary;
      return charts.bar(
        {
          labels: data.map((x) => {
            return months[x.month - 1];
          }), //
          datasets: [
            {
              data: data.map((x) => {
                return x.total;
              }),
              backgroundColor: reportExpiredCard.data.summary.map((x) => {
                const currentMonth = Number(moment().format("M"));
                const currentYear = Number(moment().format("YYYY"));
                const dataMonth = Number(x.month);
                const dataYear = Number(x.year);

                if (dataMonth === currentMonth) {
                  return colors2.alert;
                } else if (
                  dataMonth < currentMonth &&
                  dataYear === currentYear
                ) {
                  return colors2.error;
                }
                return colors2.success;
              }),

              borderColor: "white",
              borderWidth: 2
            }
          ]
        },
        (data) => {
          console.log(data);
          return "Cartões expirados: " + data.dataset.data[data.dataIndex];
        }
      );
    } else if (report === "reportCharge") {
      const lastMonth = {
        value: Number(moment().subtract(1, "months").format("M")),
        text: getMonth(moment().locale("pt").subtract(1, "months").month())
      };
      const currentMonth = {
        value: Number(moment().format("M")),
        text: getMonth(moment().locale("pt").month())
      };
      const compare = (a, b) => {
        if (a.day > b.day) return 1;
        if (b.day > a.day) return -1;
        return 0;
      };
      const findValueMonth = (array) =>
        array.length === 1 ? array[0].value : 0;

      let benefits = reportCharge.data.filter((x) => x.isBenefit);
      let incentivos = reportCharge.data.filter((x) => !x.isBenefit);

      let days = [...new Set(reportCharge.data.map((x) => x.day))]
        .map((day) => {
          return {
            day: day,
            value: [
              findValueMonth(
                benefits.filter(
                  (x) => x.day == day && x.month == lastMonth.value
                )
              ),
              findValueMonth(
                benefits.filter(
                  (x) => x.day == day && x.month == currentMonth.value
                )
              ),
              findValueMonth(
                incentivos.filter(
                  (x) => x.day == day && x.month == lastMonth.value
                )
              ),
              findValueMonth(
                incentivos.filter(
                  (x) => x.day == day && x.month == currentMonth.value
                )
              )
            ]
          };
        })
        .sort(compare);

      return charts.stacked(
        {
          labels: [
            "Benefícios " + lastMonth.text,
            "Benefícios " + currentMonth.text,
            "Incentivos " + lastMonth.text,
            "Incentivos " + currentMonth.text
          ],
          datasets: days.map((x) => {
            return {
              data: x.value,
              backgroundColor: [
                colors2.warning,
                colors2.success,
                colors2.warning,
                colors2.success
              ],
              hoverBackgroundColor: [
                colors2.warning,
                colors2.success,
                colors2.warning,
                colors2.success
              ],
              borderColor: "white",
              borderWidth: 2,
              customData: x
            };
          })
        },
        (data) => {
          return (
            "Dia " +
            data.dataset.customData.day +
            ": " +
            formatMoney(data.dataset.data[data.dataIndex])
          );
        }
      );
    } else if (report === "reportRecentCharge") {
      return charts.doughnut(
        {
          labels: reportRecentCharge.data.summary.map((b) => b.description),
          datasets: [
            {
              data: reportRecentCharge.data.summary.map((b) => b.users),
              backgroundColor: reportRecentCharge.data.summary.map(
                (b) => b.color
              )
            }
          ]
        },
        false,
        "bottom",
        (data) => {
          return data.label + ": " + data.dataset.data[data.dataIndex];
        }
      );
    } else if (
      report === "reportCorporateRefund" &&
      login.company.corporateRefund
    ) {
      return charts.doughnut(
        {
          labels: reportCorporateRefund.data.map((b) => b.description),
          datasets: [
            {
              data: reportCorporateRefund.data.map((b) => b.refunds),
              backgroundColor: reportCorporateRefund.data.map((b) => b.color)
            }
          ]
        },
        false,
        "bottom",
        (data) => {
          return data.label + ": " + data.dataset.data[data.dataIndex];
        }
      );
    } else if (report === "reportStablishmentExpense") {
      const _data = [
        {
          label: "Top 5",
          count: reportStablishmentExpense.data.summary[0].top_5,
          background: colors2.success
        },
        {
          label: "Top 10",
          count: reportStablishmentExpense.data.summary[0].top_10,
          background: colors2.warning
        },
        {
          label: "Outros",
          count: reportStablishmentExpense.data.summary[0].other,
          background: colors2.alert
        }
      ];
      return charts.doughnut(
        {
          labels: _data.map((b) => b.label),
          datasets: [
            {
              data: _data.map((b) => b.count),
              backgroundColor: _data.map((x) => x.background),
              datalabels: {
                color: _data.map((x) => x.background)
              }
            }
          ]
        },
        false,
        "bottom",
        (data) => {
          return (
            data.label + ": " + formatMoney(data.dataset.data[data.dataIndex])
          );
        }
      );
    } else if (report === "reportBenefit") {
      return charts.doughnut(
        {
          labels: reportBenefit.data.map((b) => b.description),
          datasets: [
            {
              data: reportBenefit.data.map((b) => b.value),
              backgroundColor: reportBenefit.data.map((b) =>
                benefits.filter((ben) => ben.name === b.description).length > 0
                  ? benefits.filter((ben) => ben.name === b.description)[0]
                      .color
                  : colors.benefits["undefined"]
              )
            }
          ]
        },
        showLabel,
        legendPosition,
        (data) => {
          return (
            data.label + ": " + formatMoney(data.dataset.data[data.dataIndex])
          );
        }
      );
    } else if (report === "reportMonth") {
      return charts.columns(
        {
          labels: reportMonth.data.map((d) => d.day),
          datasets: [
            {
              data: reportMonth.data.map((d) => d.value),
              backgroundColor: colors2.success,
              borderColor: colors2.success,
              borderWidth: 1,
              datalabels: {
                color: colors.debit.background
              }
            }
          ]
        },
        showLabel
      );
    }
  };

  React.useEffect(() => {
    if (company) load();
  }, [company]);

  return (
    <React.Fragment>
      <Content>
        <Row gutter={16}>
          {!(loading || reportCard.data.loading) &&
          reportCard.data &&
          reportCard.data.alert
            ? reportCard.data.alert.map((r) => (
                <Col sm={24} kstyle={{ marginBottom: 10 }}>
                  <Alert message={r.description} type={r.type} />
                </Col>
              ))
            : null}
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={12} sm={24} id="reportCard" loaded="true">
            <CardStatistic
              height={300}
              title="Cartões"
              loading={loading || reportCard?.loading}
            >
              {reportCard?.data.card ? (
                <Bar {...getConfig("reportCard")} />
              ) : null}
            </CardStatistic>
          </Col>
          <Col lg={12} sm={24} id="reportExpiredCard" loaded="true">
            <CardStatistic
              height={300}
              title="Expiração de cartões"
              loading={loading || reportExpiredCard?.loading}
              expandable={true}
              onExpand={() =>
                setReportExpiredCard((x) => ({ ...x, show: true }))
              }
            >
              {reportExpiredCard.data.summary ? (
                <Bar {...getConfig("reportExpiredCard")} />
              ) : null}
            </CardStatistic>
          </Col>
          <Col lg={12} sm={24} id="reportEmployee" loaded="true">
            <CardStatistic
              height={380}
              title="Estatísticas de Colaboradores"
              expandable={true}
              onExpand={() => setReportEmployee((x) => ({ ...x, show: true }))}
            >
              <Row gutter={16} style={{ height: 100 }}>
                <Col span={12}>
                  <Statistic
                    title="Colaboradores Ativos"
                    value={getCardInfo("enabled")}
                    loading={loading || reportEmployee.loading}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Colaboradores que Não Logaram no App"
                    value={getCardInfo("not_login")}
                    loading={loading || reportEmployee.loading}
                  />
                </Col>
              </Row>
              <Row gutter={16} style={{ height: 100 }}>
                <Col span={12}>
                  <Statistic
                    title="Colaboradores Inativos"
                    value={getCardInfo("disabled")}
                    loading={loading || reportEmployee.loading}
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Colaboradores Sem Dados Obrigatórios"
                    value={getCardInfo("without_mandatory_data")}
                    loading={loading || reportEmployee.loading}
                  />
                </Col>
              </Row>
            </CardStatistic>
          </Col>
          <Col lg={12} sm={24} id="reportCharge" loaded="true">
            <CardStatistic
              height={380}
              title="Cargas"
              loading={loading || reportCharge?.loading}
            >
              {Array.isArray(reportCharge?.data) ? (
                <Bar {...getConfig("reportCharge")} />
              ) : null}
            </CardStatistic>
          </Col>

          <Col lg={12} sm={24} id="reportRecentCharge" loaded="false">
            <CardStatistic
              height={380}
              title="Vantagens"
              expandable={true}
              onExpand={() =>
                setReportRecentCharge((x) => ({ ...x, show: true }))
              }
              loading={loading || reportRecentCharge?.loading}
            >
              {reportRecentCharge?.data.summary ? (
                <Doughnut {...getConfig("reportRecentCharge")} />
              ) : null}
            </CardStatistic>
          </Col>
          {login.company.corporateRefund && (
            <Col lg={12} sm={24} id="reportCorporateRefund" loaded="false">
              <CardStatistic
                height={380}
                title="Reembolso Corporativo"
                loading={loading || reportCorporateRefund?.loading}
              >
                {Array.isArray(reportCorporateRefund?.data) ? (
                  <Doughnut {...getConfig("reportCorporateRefund")} />
                ) : null}
              </CardStatistic>
            </Col>
          )}
          <Col lg={12} sm={24} id="reportStablishmentExpense" loaded="false">
            <CardStatistic
              height={380}
              title="Estabelecimentos Importantes"
              expandable={true}
              onExpand={() =>
                setReportStablishmentExpense((x) => ({ ...x, show: true }))
              }
              loading={loading || reportStablishmentExpense?.loading}
            >
              {reportStablishmentExpense?.data.summary ? (
                <Doughnut {...getConfig("reportStablishmentExpense")} />
              ) : null}
            </CardStatistic>
          </Col>
          <Col lg={12} sm={24} id="reportBenefit" loaded="false">
            <CardStatistic
              height={380}
              title="Uso de benefícios"
              expandable={true}
              onExpand={() => setReportBenefit((x) => ({ ...x, show: true }))}
              loading={loading || reportBenefit?.loading}
            >
              {Array.isArray(reportBenefit?.data) ? (
                <Doughnut {...getConfig("reportBenefit")} />
              ) : null}
            </CardStatistic>
          </Col>
          <Col lg={12} sm={24} id="reportMonth" loaded="false">
            <CardStatistic
              height={380}
              title="Gastos do mês atual"
              expandable={true}
              onExpand={() => setReportMonth((x) => ({ ...x, show: true }))}
              loading={loading || reportMonth?.loading}
            >
              {Array.isArray(reportMonth?.data) ? (
                <Bar {...getConfig("reportMonth")} />
              ) : null}
            </CardStatistic>
          </Col>
          <Col lg={12} sm={24} id="reportActiveBalance" loaded="false">
            <CardStatistic
              height={380}
              title={`${translate("balance")} parado`}
              expandable={true}
              onExpand={() =>
                setReportActiveBalance((x) => ({ ...x, show: true }))
              }
              loading={loading || reportActiveBalance?.loading}
            >
              {reportActiveBalance?.data.summary ? (
                <Doughnut {...getConfig("reportActiveBalance")} />
              ) : null}
            </CardStatistic>
          </Col>
          <Col lg={12} sm={24} id="reportHighBalance" loaded="false">
            <CardStatistic
              height={380}
              title={`${translate("balance")} Alto`}
              expandable={true}
              onExpand={() =>
                setReportHighBalance((x) => ({ ...x, show: true }))
              }
              loading={loading || reportHighBalance?.loading}
            >
              {reportHighBalance?.data.summary ? (
                <Doughnut {...getConfig("reportHighBalance")} />
              ) : null}
            </CardStatistic>
          </Col>
        </Row>
      </Content>
      <Modal
        title={"Detalhamento de Expiração de Cartões"}
        width={"90%"}
        style={{ top: "2.5em" }}
        bodyStyle={{ maxHeight: "730px", overflow: "auto" }}
        open={reportExpiredCard.show}
        onCancel={() => setReportExpiredCard((x) => ({ ...x, show: false }))}
        cancelButtonProps={{ block: false }}
        onOk={() => setReportExpiredCard((x) => ({ ...x, show: false }))}
      >
        <Table
          key={"email"}
          dataSource={reportExpiredCard.dataTable}
          loading={loading || reportExpiredCard.loading}
          bordered
        >
          <Table.Column
            width={180}
            align={"left"}
            title="Nome"
            dataIndex="name"
            key="name"
          />

          <Table.Column
            width={180}
            align={"left"}
            title="Email"
            dataIndex="email"
            key="email"
          />
          <Table.Column
            width={180}
            align={"left"}
            title="Número do Cartão"
            dataIndex="cardNumber"
            key="cardNumber"
          />
          <Table.Column
            width={180}
            align={"left"}
            title="Data de expiração"
            dataIndex="expiredAt"
            key="expiredAt"
            render={(value) => <FormatDate value={value} format="DD/MM/YYYY" />}
          />
        </Table>
      </Modal>
      <Modal
        title={"Detalhamento de Estastísticas de Colaboradores"}
        width={"90%"}
        style={{ top: "2.5em" }}
        bodyStyle={{ maxHeight: "730px", overflow: "auto" }}
        open={reportEmployee.show}
        onCancel={() => setReportEmployee((x) => ({ ...x, show: false }))}
        cancelButtonProps={{ block: false }}
        onOk={() => setReportEmployee((x) => ({ ...x, show: false }))}
      >
        <Table
          key={"email"}
          dataSource={reportEmployee.dataTable}
          loading={loading || reportEmployee.loading}
          bordered
        >
          <Table.Column
            width={180}
            align={"left"}
            title="Email"
            dataIndex="email"
            key="email"
          />
          <Table.Column
            width={180}
            align={"left"}
            title="Status"
            dataIndex="enabled"
            key="enabled"
          />
          <Table.Column
            width={180}
            align={"left"}
            title="Possui Dados Obrigatórios"
            dataIndex="mandatory_data"
            key="mandatory_data"
          />
          <Table.Column
            width={180}
            align={"left"}
            title="Data de Login"
            dataIndex="login_at"
            key="login_at"
            render={(value) => (
              <FormatDate value={value} format="DD/MM/YYYY HH:mm" />
            )}
          />
        </Table>
      </Modal>
      <Modal
        title={`Detalhamento de Colaboradores com ${translate(
          "balance"
        )} Parado`}
        width={"90%"}
        style={{ top: "2.5em" }}
        bodyStyle={{ maxHeight: "730px", overflow: "auto" }}
        open={reportActiveBalance.show}
        onCancel={() => setReportActiveBalance((x) => ({ ...x, show: false }))}
        cancelButtonProps={{ block: false }}
        onOk={() => setReportActiveBalance((x) => ({ ...x, show: false }))}
      >
        <Table
          key={"email"}
          dataSource={reportActiveBalance.dataTable}
          loading={loading || reportActiveBalance.loading}
          bordered
        >
          <Table.Column
            width={230}
            align={"left"}
            title="Email"
            dataIndex="email"
            key="email"
          />
          <Table.Column
            width={80}
            align={"left"}
            title="Valor"
            dataIndex="value"
            key="value"
            render={(value) => <FormatMoney value={value} />}
          />
          <Table.Column
            width={50}
            align={"left"}
            title="Último pagamento"
            dataIndex="last_date"
            key="last_date"
            render={(value) => (
              <FormatDate value={value} format="DD/MM/YYYY HH:mm" />
            )}
          />
          <Table.Column
            width={80}
            align={"left"}
            title="Qtde. Dias do Último Pagamento"
            dataIndex="days"
            key="days"
          />
        </Table>
      </Modal>
      <Modal
        title={`Detalhamento de Colaboradores com ${translate("balance")} Alto`}
        width={"90%"}
        style={{ top: "2.5em" }}
        bodyStyle={{ maxHeight: "730px", overflow: "auto" }}
        open={reportHighBalance.show}
        onCancel={() => setReportHighBalance((x) => ({ ...x, show: false }))}
        cancelButtonProps={{ block: false }}
        onOk={() => setReportHighBalance((x) => ({ ...x, show: false }))}
      >
        <Table
          key={"email"}
          dataSource={reportHighBalance.dataTable}
          loading={loading || reportHighBalance.loading}
          bordered
        >
          <Table.Column
            width={230}
            align={"left"}
            title="Email"
            dataIndex="email"
            key="email"
          />
          <Table.Column
            width={80}
            align={"left"}
            title="Valor"
            dataIndex="value"
            key="value"
            render={(value) => <FormatMoney value={value} />}
          />
        </Table>
      </Modal>
      <Modal
        title={"Detalhes de Vantagens"}
        width={"90%"}
        style={{ top: "2.5em" }}
        bodyStyle={{ maxHeight: "730px", overflow: "auto" }}
        open={reportRecentCharge.show}
        onCancel={() => setReportRecentCharge((x) => ({ ...x, show: false }))}
        cancelButtonProps={{ block: false }}
        onOk={() => setReportRecentCharge((x) => ({ ...x, show: false }))}
      >
        <Table
          dataSource={
            reportRecentCharge.dataTable
              ? reportRecentCharge.dataTable.map((r, i) => {
                  return { ...r, key: i };
                })
              : []
          }
          loading={loading || reportRecentCharge.loading}
          bordered
        >
          <Table.Column
            width={230}
            align={"left"}
            title="Email"
            dataIndex="email"
            key="email"
          />
          <Table.Column
            width={80}
            align={"left"}
            title="Aprovado em"
            dataIndex="approvedAt"
            key="approvedAt"
            render={(value) => (
              <FormatDate value={value} format="DD/MM/YYYY HH:mm" />
            )}
          />
          <Table.Column
            width={80}
            align={"left"}
            title="Benefício Recente"
            dataIndex="recentBenefit"
            key="recentBenefit"
            render={(value) => (value ? "Sim" : "Não")}
          />
        </Table>
      </Modal>
      <Modal
        title={"Detalhamento de Estabelecimentos Importantes"}
        width={"90%"}
        style={{ top: "2.5em" }}
        bodyStyle={{ maxHeight: "730px", overflow: "auto" }}
        open={reportStablishmentExpense.show}
        onCancel={() =>
          setReportStablishmentExpense((x) => ({ ...x, show: false }))
        }
        cancelButtonProps={{ block: false }}
        onOk={() =>
          setReportStablishmentExpense((x) => ({ ...x, show: false }))
        }
      >
        <Table
          dataSource={reportStablishmentExpense.dataTable}
          loading={loading || reportStablishmentExpense.loading}
          bordered
        >
          <Table.Column
            width={230}
            align={"left"}
            title="Estabelecimento"
            dataIndex="stablishment"
            key="stablishment"
          />
          <Table.Column
            width={80}
            align={"left"}
            title="Valor"
            dataIndex="value"
            key="value"
            render={(value) => <FormatMoney value={value} />}
          />
        </Table>
      </Modal>
      <Modal
        title={"Uso de benefícios"}
        width={"90%"}
        style={{ top: "2.5em" }}
        bodyStyle={{ maxHeight: "730px", overflow: "auto" }}
        open={reportBenefit.show}
        onCancel={() => setReportBenefit((x) => ({ ...x, show: false }))}
        cancelButtonProps={{ block: false }}
        onOk={() => setReportBenefit((x) => ({ ...x, show: false }))}
      >
        <Typography.Text type="secondary" strong>
          Aqui você consegue ver quais benefícios os seus colaboradores estão
          usando.
        </Typography.Text>

        <div style={{ height: 600, marginTop: 30 }}>
          {Array.isArray(reportBenefit?.data) ? (
            <Doughnut {...getConfig("reportBenefit", "left", true)} />
          ) : null}
        </div>
      </Modal>
      <Modal
        title={"Gastos do mês atual"}
        width={"90%"}
        style={{ top: "2.5em" }}
        bodyStyle={{ maxHeight: "730px", overflow: "auto" }}
        open={reportMonth.show}
        onCancel={() => setReportMonth((x) => ({ ...x, show: false }))}
        cancelButtonProps={{ block: false }}
        onOk={() => setReportMonth((x) => ({ ...x, show: false }))}
      >
        <Typography.Text type="secondary" strong>
          Esse gráfico é uma visualização do mês. Você consegue ver os gastos
          por dia.
        </Typography.Text>

        <div style={{ height: 600, marginTop: 30 }}>
          {Array.isArray(reportMonth?.data) ? (
            <Bar {...getConfig("reportMonth", null, true)} />
          ) : null}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default connect(mapStateToProps, {})(DashboardScreen);
