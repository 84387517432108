import { PlusOutlined } from "@ant-design/icons";
import { Button, Layout, Space, Table, Tag } from "antd";
import { FormatDate, FormatMoney, Header, TableSearch } from "components";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllAdvances,
  getAllAdvancesByBusinessUnit
} from "services/advance-service";
import { isOnlyManager, ManagerType } from "utils/managers";
import {
  ADVANCE_STATUS,
  ADVANCE_STATUS_COLOR,
  ADVANCE_TYPE
} from "utils/static-data";

const { Content, Footer } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const DataFetch = {
  data: [],
  loading: false
};

const AdvanceScreen = (props) => {
  const [data, setData] = React.useState(DataFetch);
  const [initialData, setInitialData] = React.useState(DataFetch);
  const navigate = useNavigate();
  const { login } = props;
  const load = async () => {
    setData((x) => ({ ...x, loading: true }));
    const isTeamManager = isOnlyManager(login.user, ManagerType.TEAM);
    const res = isTeamManager
      ? await getAllAdvancesByBusinessUnit(login.company.id)
      : await getAllAdvances();
    setData({ data: res, loading: false });
    setInitialData({ data: res, loading: false });
  };

  React.useEffect(() => {
    load();
  }, []);
  {
    return (
      <Layout className="alymente-layout">
        <Header title="Adiantamento" />
        <Content>
          <Table
            dataSource={data.data}
            loading={data.loading}
            scroll={{ y: 650 }}
            title={() => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px"
                }}
              >
                <TableSearch
                  data={data}
                  initialData={initialData.data}
                  filter={["amount", ["employee", "name"]]}
                  onSearch={(e) => {
                    setData((prev) => ({ ...prev, data: e }));
                  }}
                />
              </div>
            )}
            pagination={false}
            bordered
          >
            <Table.Column
              width={110}
              title="Colaborador"
              dataIndex={["employee", "name"]}
              key="employee"
              render={(value) => {
                return value;
              }}
            />
            <Table.Column
              width={60}
              align="center"
              title="Data de Criação"
              dataIndex="createdAt"
              key="createdAt"
              render={(value) => <FormatDate value={value} />}
            />

            <Table.Column
              width={100}
              align="center"
              title="Tipo"
              dataIndex="type"
              filters={Object.entries(ADVANCE_TYPE).map(([value, text]) => ({
                text: text,
                value: value
              }))}
              onFilter={(value, record) => record.type === value}
              key="advanceType"
              render={(value) => ADVANCE_TYPE[value]}
            />
            <Table.Column
              width={50}
              align="right"
              title="Valor"
              dataIndex="amount"
              key="value"
              render={(value) => <FormatMoney value={value} />}
            />

            <Table.Column
              width={60}
              align="center"
              title="Status"
              dataIndex="status"
              key="status"
              render={(value, record) =>
                record.isOverdue ? (
                  <Tag color="error">VENCIDO</Tag>
                ) : (
                  <Tag color={ADVANCE_STATUS_COLOR[value]}>
                    {ADVANCE_STATUS[value].toUpperCase()}
                  </Tag>
                )
              }
              filters={Object.entries(ADVANCE_STATUS).map(([value, text]) => ({
                text: text,
                value: value
              }))}
              onFilter={(value, record) => record.status === value}
            />
            <Table.Column
              fixed="right"
              width={50}
              align="center"
              title="Ações"
              dataIndex="id"
              key="id"
              render={(value) => (
                <Button
                  size="small"
                  type={"link"}
                  onAuxClick={() => window.open("/advance/" + value)}
                  onClick={() => navigate(`/advance/${value}`)}
                >
                  Detalhes
                </Button>
              )}
            />
          </Table>
        </Content>
        <Footer>
          <Space align="end">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate("/solicitation/advance")}
            >
              Gerar Pedido de Carga
            </Button>
            <Button type="primary" onClick={() => navigate(`/advance/redeem`)}>
              Resgatar Adiantamentos
            </Button>
          </Space>
        </Footer>
      </Layout>
    );
  }
};

export default connect(mapStateToProps, {})(AdvanceScreen);
