import React from "react";
import { connect } from "react-redux";
import { Layout, Table, Switch, Button, Typography, Space, Col, Row, Select, Form } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { Header, AutoCompleteCompany } from "components";
import { getAllManagerBenefit, saveBenefitManager, removeBenefitManager, updateBenefitManager } from "services/benefit-manager-service";
import { getAllUsers } from "services/user-service";
import { getAllBenefit } from "services/benefit-service";
import { getAllEmployee } from "services/employee-service";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const DataFetch = {
    data: [],
    loading: false,
}

const BenefitsFetch = {
    data: [],
    loading: false,
}

const UsersFetch = {
    data: [],
    loading: false,
}

const BenefitManagerScreen = (props) => {
    const { login } = props;
    const [data, setData] = React.useState(DataFetch)
    const [benefits, setBenefits] = React.useState(BenefitsFetch)
    const [users, setUsers] = React.useState(UsersFetch)
    const [loading, setLoading] = React.useState(false)
    const [form] = Form.useForm();

    const load = async () => {
        setData((x) => ({ ...x, loading: true }));
        const resData = await getAllManagerBenefit();
        setData({ data: resData, loading: false });
    }

    const loadBenefits = async () => {
        setBenefits((x) => ({ ...x, loading: true }));
        const resData = await getAllBenefit();
        setBenefits({ data: resData, loading: false });
    }

    const loadUsers = async () => {
        setUsers((x) => ({ ...x, loading: true }));
        const resData = await getAllEmployee();
        setUsers({ data: resData, loading: false });
    }

    const onSave = async (values) => {
        setLoading(true);
        try {
            await saveBenefitManager(values.benefit, values.manager);
            form.resetFields()
            load()
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    const onRemove = async (value) => {
        setLoading(true);
        try {
            await removeBenefitManager(value.benefit.id, value.manager.id);
            load()
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    const onChangeManagerBranch = async (active, row) => {
        setLoading(true);
        try {
            await updateBenefitManager(active, row.benefit.id, row.manager.id);
            load()
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }


    React.useEffect(() => {
        load();
        loadBenefits()
        loadUsers()
    }, [])

    return (
        <Layout className="alymente-layout">
            <Header
                title="Gerente de Benefícios"
                subtitle="Você pode permitir gerentes para administrar os benefícios">
            </Header>
            <Content>
                <Form form={form} layout="vertical" name="basic" onFinish={onSave}>
                    <Row gutter={12}>
                        <Col xl={5} lg={5} sm={7}>
                            <Form.Item
                                hasFeedback
                                name="benefit"
                                rules={[{ required: true, message: "Selecione um benefício" }]}
                            >
                                <Select placeholder="Selecione um benefício" style={{width: "100%"}}>
                                    {benefits.data.map(x => <Select.Option value={x.benefit}>{x.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xl={15} lg={14} sm={10}>
                            <Form.Item
                                hasFeedback
                                name="manager"
                                rules={[{ required: true, message: "Selecione um gerente" }]}
                            >
                                <Select 
                                    showSearch 
                                    placeholder="Selecione um gerente" 
                                    style={{width: "100%"}}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.title?.toLowerCase() ?? '').includes(input?.toLowerCase())}
                                >
                                    {users.data.map(x => <Select.Option title={x.name} value={x.id}>{x.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xl={4} lg={5} sm={7}>
                            <Button style={{width: '100%'}} loading={false} className="btn-save" type="primary" icon={<SaveOutlined />} onClick={() => form.submit()}>Adicionar</Button>
                        </Col>
                    </Row>
                </Form>
                <Table scroll={{y: 800}} dataSource={data.data} loading={data.loading || loading} pagination={false} bordered >
                    <Table.Column width={200} align={"left"} title="Benefício" dataIndex={["benefit", "name"]} key="benefit.name" />
                    <Table.Column align={"left"} title="Gerente" dataIndex={["manager", "name"]} key="manager.name" />
                    <Table.Column width={150} align={"center"} title="Gerencia filiais?" dataIndex="managerBranch" key="managerBranch" render={(value, record, index) => <Switch checked={value} onChange={(x) => onChangeManagerBranch(x, record)} />} />
                    <Table.Column width={100} align={"center"} title="Ações" render={(value, record, index) => <Button danger size="small" type={"link"} onClick={() => onRemove(record)}>Remover</Button> } />
                </Table>
            </Content>
            <Footer>
            </Footer>
        </Layout>
    )
}

export default connect(mapStateToProps, {})(BenefitManagerScreen);