import React from "react";
import { Select, Spin } from "antd";
import PropTypes from "prop-types";
import { search } from "services/company-service";

const AutoCompleteCompany = (props) => {
  const { value, onChange, onChangeCompany, disabled } = props;
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [valueLocal, setValueLocal] = React.useState(null);

  const load = async (query) => {
    setLoading(true);
    setOptions(await search(query));
    setLoading(false);
  };

  const onChangeLocal = (e) => {
    setValueLocal(e);

    const obj = options.find((x) => x.id === e);
    if (onChange) {
      onChange(obj ? obj.id : null);
      if (onChangeCompany) {
        onChangeCompany(obj);
      }
    }
  };

  React.useEffect(() => {
    load("");
  }, []);

  React.useEffect(() => {
    if (value && options.length > 0) {
      const obj = options.find((x) => x.id === value);
      if (obj) {
        setValueLocal(obj.id);
      }
    }
  }, [value, options]);

  return (
    <Select
      disabled={disabled}
      notFoundContent={loading ? <Spin size="small" /> : null}
      style={{ width: "100%" }}
      placeholder="Selecione uma empresa"
      value={valueLocal}
      onChange={onChangeLocal}
      showSearch
      options={options.map((x) => ({ value: x.id, label: x.name }))}
      allowClear={true}
      filterOption={(input, option) => {
        return (
          option &&
          option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >=
            0
        );
      }}
    />
  );
};

AutoCompleteCompany.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeCompany: PropTypes.func,
  disabled: PropTypes.bool
};

export { AutoCompleteCompany };
