import axios from "axios";
import { handlerError } from "utils";
import { notification } from "antd";

export const getAllManagerBenefit = async (user = null, company = null) => {
  try {
    const res = await axios.get(`/api/benefit/manager`, {
      params: { user: user, company: company }
    });
    return res.data.managers && res.data.managers.length > 0
      ? res.data.managers
      : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const saveBenefitManager = async (benefit, manager) => {
  try {
    await axios.post(`/api/benefit/manager`, { benefit, manager });
    notification.success({
      message: "Gerente inserido a benefício com sucesso!"
    });
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};

export const removeBenefitManager = async (benefit, manager) => {
  try {
    await axios.delete(`/api/benefit/manager`, {
      params: { benefit, manager }
    });
    notification.success({
      message: "Gerente de benefício removido com sucesso!"
    });
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};

export const updateBenefitManager = async (managerBranch, benefit, manager) => {
  try {
    await axios.post(`/api/benefit/manager/managerBranch`, {
      benefit,
      manager,
      managerBranch: managerBranch
    });
    notification.success({
      message: "Gerente de benefício atualizado com sucesso!"
    });
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};
