import React from "react";
import { Outlet } from 'react-router-dom';
import { connect } from "react-redux";
import { Layout, Typography } from 'antd';
import { Header } from "components";
import { transferAccountBalance } from 'services/transfer-service'
import { formatMoney } from 'utils';
import environment from 'env';

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const TAX = 0.24;
const TransferContainerScreen = (props) => {
    const { login } = props;
    const [balance, setBalance] = React.useState({ balance: 0, refund: { total: 0, count: 0 }, transfer: { total: 0, count: 0 } });
    const total = React.useMemo(() => balance.refund.total + balance.transfer.total, [balance])
    const taxRefund = React.useMemo(() => balance.refund.count * TAX, [balance])
    const taxTransfer = React.useMemo(() => balance.transfer.count * TAX, [balance])
    const {refund} = login.user.feature;
    let subRoutes = [
        { label: "Transfêrencias", route: "/transfer" },
        { label: "Reembolsos", route: "/transfer/refund" }
    ]


    const load = async () => {
        const res = await transferAccountBalance();
        if (res) {
            setBalance(res);
        }
    }

    React.useEffect(() => {
        load();
    }, [])

    if(!refund) {
        subRoutes = subRoutes.filter(route => route.label !== "Reembolsos");
    }

    const Subtitle = React.useCallback(() => {
        return (<>
            <Typography.Text type="secondary">
                {`Saldo Bluepay: ${formatMoney(balance.balance)}`}<br />
                {`Total Valores: ${formatMoney(total)} | Total Taxas: ${formatMoney(taxRefund + taxTransfer)}`}<br />
                {refund && `Reembolso: ${formatMoney(balance.refund.total)} | Taxas: ${formatMoney(taxRefund)}`}{refund && <br />}
                {`Transferências: ${formatMoney(balance.transfer.total)} | Taxas: ${formatMoney(taxTransfer)}`}
            </Typography.Text>
        </>
        )
    }, [balance])

    //`Saldo Bluepay: ${formatMoney(balance.balance)} | Reembolso: ${formatMoney(balance.totalRefund)} | Trans.: ${formatMoney(balance.totalTransfers)}`
    return (
        <Layout className="alymente-layout">
            <Header routes={subRoutes} subtitle={<Subtitle />} />
            <Outlet context={{ onRefreshBalance: load, balance: balance }} />
        </Layout>
    )
}

export default connect(mapStateToProps, {})(TransferContainerScreen);
