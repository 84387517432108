import React, { useRef } from "react";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

const InputNumber = (props) => {
  const { disabled = false, addonBefore = "", value = 0 } = props;
  const [internalValue, setInternalValue] = React.useState("0");

  const onValueChange = (values) => {
    console.log(values);
    setInternalValue(values.value);
    _onChange(values.floatValue ? values.floatValue : 0);
  };

  const _onChange = (value) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  React.useEffect(() => {
    setInternalValue(Number.isFinite(value) ? value.toString() : "0");
  }, [value]);

  return (
    <span className="input-currency-group-wrapper">
      <span className="input-currency-wrapper">
        <span
          className={`input-currency-group-addon ${
            disabled ? "input-currency-group-addon-disabled" : ""
          }`}
        >
          {addonBefore}
        </span>
        <NumericFormat
          className="input-currency"
          decimalSeparator={","}
          fixedDecimalScale={true}
          decimalScale={2}
          value={internalValue}
          displayType={"input"}
          thousandSeparator={"."}
          prefix={""}
          onFocus={(x) => x.target.select()}
          onValueChange={onValueChange}
          disabled={disabled}
        />
      </span>
    </span>
  );
};

InputNumber.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  addonBefore: PropTypes.string
};

const InputCurrency = (props) => {
  const { disabled = false } = props;

  return <InputNumber {...props} disabled={disabled} addonBefore={"R$"} />;
};

InputCurrency.propTypes = {
  defaultValue: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export { InputCurrency, InputNumber };
