import React from "react";
import { Row, Col, Layout } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { DarkColor, SoftColor } from "config/constants/Color";
import Lottie from "lottie-react";
import loading from "assets/animation/loading.json";

const LoadingScreen = (props) => {
    const { color = SoftColor } = props;
    return (
        <Layout style={{ backgroundColor: color, height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
            <Layout.Content>
                <Row justify="center" align="middle">
                    <Col span={2}>
                        {/* <LoadingOutlined style={{ fontSize: 70, color: DarkColor }} /> */}
                        <Lottie animationData={loading} style={{ width: 200 }} />
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
};

export { LoadingScreen };
