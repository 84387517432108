import { Outlet } from 'react-router-dom';
import { useStyleRegister} from '@ant-design/cssinjs';
import { useToken } from 'antd/es/theme/internal';
import { styleLogin } from 'theme/login';

export const ContainerAuth = () => {
    const [theme, token, hashId] = useToken()
    useStyleRegister(
        {
            theme,
            path: ['.login'],
            hashId,
            token: { _tokenKey: 'login' },
        },
        () => (styleLogin),
    );
    return (
        <Outlet />
    )
}