import React from "react";
import { Card, Typography } from "antd";
import PropTypes from "prop-types";

const CreditCard = (props) => {
    return (
        <Card className="credit-card" bordered={false}>
            <Typography.Title level={5}>{props.name}</Typography.Title>
            <Typography.Title level={5}>
                {(props.number && props.number.length > 0) ? props.number : "XXXX XXXX XXXX XXXX"}
            </Typography.Title>
        </Card>
    );
};

CreditCard.defaultProps = {
    name: "",
    number: ""
};

CreditCard.propTypes = {
    name: PropTypes.string,
    number: PropTypes.string
};

export { CreditCard }