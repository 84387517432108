import React from "react";

import { Checkbox, Popover, Icon, Button, Row, Col } from "antd";

const CheckboxMenu = (props) => {
  const onChange = (selection) => {
    return props.onChange(selection);
  };

  const checkboxRender = () => {
    const groups = props.options
      .map(function (e, i) {
        return i % 3 === 0 ? props.options.slice(i, i + 3) : null;
      })
      .filter(function (e) {
        return e;
      });

    return (
      <div style={{ width: 380 }}>
        <Checkbox.Group onChange={onChange} value={props.value}>
          <Row>
            {groups.map((group, i) => {
              return (
                <Col
                  key={"checkbox-group-" + i}
                  span={Math.floor(24 / groups.length)}
                >
                  {group.map((item, i) => {
                    return (
                      <Checkbox
                        key={item.dataIndex}
                        value={item.dataIndex}
                        style={{ marginTop: 10 }}
                      >
                        {item.title}
                      </Checkbox>
                    );
                  })}
                </Col>
              );
            })}
          </Row>
        </Checkbox.Group>
        <Button
          style={{ padding: 0, marginTop: 10 }}
          size="small"
          type={"link"}
          onClick={() => onChange([])}
        >
          Limpar
        </Button>
      </div>
    );
  };

  return (
    <Popover content={checkboxRender} trigger="click" placement="bottomLeft">
      {props.children}
    </Popover>
  );
};

export default CheckboxMenu;
