import React from "react";
import { formatMoney } from "utils";
import PropTypes from "prop-types";
import { Typography } from 'antd';

const { Text } = Typography;

const FormatMoney = (props) => {
	const { value, strong = false, disabled, discount = false } = props;

	return <React.Fragment><Text disabled={disabled} strong={strong} type={discount == true ? "secondary" : value < 0 ? "danger" : ""}>{formatMoney(value)}</Text></React.Fragment>;
};

FormatMoney.defaultProps = {
	value: 0,
	disabled: false
};

FormatMoney.propTypes = {
	value: PropTypes.number,
	disabled: PropTypes.bool
};

export { FormatMoney };