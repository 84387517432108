import axios from "axios";
import { handlerError } from "utils";
import { getAllEmployee } from "./employee-service";

export const getAllAdvances = async (status = null) => {
  try {
    let params = {};
    if (status) {
      params.status = status;
    }
    const res = await axios.get("/api-expense/advance", {
      params: params
    });
    return res.data;
  } catch (e) {
    handlerError(e);
  }
};
export const getAllAdvancesByBusinessUnit = async (
  companyId,
  status = null
) => {
  try {
    const res = await getAllEmployee(companyId, true);
    const [businessUnit] = res.businessUnits;
    const { employees } = businessUnit;
    const employeesId = employees.map((employee) => String(employee.id));
    const allAdvances = await getAllAdvances(status);
    return allAdvances.filter((advance) =>
      employeesId.includes(advance.employeeId)
    );
  } catch (e) {
    handlerError(e);
  }
};

export const getAdvanceById = async (id) => {
  try {
    const res = await axios.get(`/api-expense/advance/${id}`);
    return res.data;
  } catch (e) {
    handlerError(e);
  }
};

export const updateAdvance = async (id, status, reasonDenial = null) => {
  const data = {
    status,
    reasonDenial
  };
  const res = await axios.put(`/api-expense/advance/${id}`, data);
  return res.data;
};

export const advanceRedeem = async (users, advances, type, benefit, value) => {
  try {
    const { data } = await axios.post(`/api/solicitation/redeem/batch`, {
      users: users,
      advances: advances,
      type: type
    });
    return data;
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};
