import React from "react";
import { Card, Spin, Button } from "antd";
import PropTypes from "prop-types";
import { ExpandOutlined } from '@ant-design/icons';

const CardStatistic = (props) => {
    return (
        <Card 
            className="card-dashboard h-full" 
            title={props.title} 
            bordered={false} 
            // loading={props.loading}
            extra={props.expandable ? <Button size="small" type={"link"} onClick={props.onExpand}><ExpandOutlined /></Button> : null}
            bodyStyle={props.height ? {height: props.height} : {}}
        >
            {
                props.loading ?
                <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spin size="large" />
                </div>
                :
                props.children
            }
        </Card>
    );
};

CardStatistic.defaultProps = {
    title: "",
    loading: false,
    expandable: false,
    onExpand: () => {},
    height: 'auto'
};

CardStatistic.propTypes = {
    title: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    expandable: PropTypes.bool,
    onExpand: PropTypes.func,
    height: PropTypes.number
};

export { CardStatistic }