import React from 'react';
import { Switch, Typography, Space } from 'antd';
import PropTypes from "prop-types";

const SwitchLabel = (props) => {
    return <Space size={"middle"}>
        <Switch {...props} />
        <Typography.Text>{props.label.toUpperCase()}</Typography.Text>
    </Space>
}

SwitchLabel.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired
};

export { SwitchLabel }