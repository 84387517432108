import axios from "axios";
import { notification } from "antd";
import { handlerError } from "utils";

export const getAllBenefit = async (
  company,
  charge = false,
  vehicle = false
) => {
  try {
    const res = await axios.get(`/api/company/benefit`, {
      params: { allBalance: true, company: company, charge: charge, vehicle }
    });
    return res.data.benefits && res.data.benefits.length > 0
      ? res.data.benefits
      : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getAllBenefitCharge = async (company, vehicle = false) => {
  try {
    const res = await axios.get(`/api/company/benefit`, {
      params: { allBalance: true, company: company, charge: true, vehicle }
    });
    return res.data ? res.data : {};
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};

export const saveBenefit = async (company, lines) => {
  try {
    const req = {
      benefits: {
        lines: lines.map((x) => ({
          benefit: x.benefit,
          enabled: x.enabled,
          spending: x.companySpending,
          minimumValue: x.minimumValue,
          completeBalance: x.completeBalance,
          manager: x.manager ? x.manager.id : null
        }))
      }
    };
    await axios.put(`/api/company/benefit`, req, {
      params: { company: company }
    });
    notification.success({
      message: "Salvo com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
  }
};
