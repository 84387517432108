import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import ptBr from "antd/lib/locale/pt_BR";
import { ConfigProvider } from "antd";
import configureStore from "store";
import { MainRoutes } from "config/routes/main-routes";
import { setupInterceptors } from "config/service/http-service";
import { initLanguage } from "config/language";
import { themeColors, themeText, themeLayout } from "theme";
import {
  StyleProvider,
  legacyLogicalPropertiesTransformer
} from "@ant-design/cssinjs";
import "styles/style.scss";

const store = configureStore();
setupInterceptors(store);

initLanguage();

ReactDOM.render(
  <Provider store={store}>
    <StyleProvider
      hashPriority="high"
      transformers={[legacyLogicalPropertiesTransformer]}
    >
      <ConfigProvider
        locale={ptBr}
        theme={{
          token: {
            colorPrimary: themeColors.mediumColor,
            colorLink: themeColors.blueColor,
            colorInfo: themeColors.blueColor,
            colorSuccess: themeColors.successColor,
            colorWarning: themeColors.warningColor,
            colorError: themeColors.errorColor,
            colorBgContainerDisabled: themeColors.disabledColor,
            colorTextDisabled: themeColors.disabledTextColor,
            borderRadius: themeLayout.borderRadius,
            boxShadow: themeLayout.boxShadow,
            fontFamily: themeText.fontFamily,
            colorText: themeColors.mediumColor
          },
          components: {
            Card: {
              colorBgContainer: themeColors.softColor
            },
            Button: {
              boxShadow: "none !important"
            },
            Layout: {
              colorBgBase: "red",
              colorBgContainer: "red",
              colorBgHeader: "#fff",
              colorBgBody: "#fff"
            },
            Menu: {
              itemBg: themeColors.softColor,
              itemColor: themeColors.mediumColor,
              itemHoverColor: themeColors.mediumColor,
              itemSelectedColor: "#fff",
              itemActiveBg: "red",
              itemHoverBg: themeColors.softColor,
              colorBgTextActive: "#fff",
              colorBgTextHover: themeColors.mediumColor,
              darkItemColor: themeColors.mediumColor,
              darkSubMenuItemBg: "rgb(240, 235, 225)",
              darkItemHoverColor: themeColors.mediumColor,
              motion: false
            },
            Table: {
              colorTextHeading: "#fff"
            }
          }
        }}
      >
        <MainRoutes />
      </ConfigProvider>
    </StyleProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
