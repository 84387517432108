import React from "react";
import { notification } from "antd";
import { useLocation } from "react-router-dom";
import moment from "moment";
import * as XLSX from "xlsx";
import env from "env";
export const URL_MS_SERVER = "https://us-east1-alymente.cloudfunctions.net"

export const handlerError = (ex, notif = true) => {
  let error = "Ocorreu um erro, tente novamente";
  if (ex.response && ex.response.data.errorMessage) {
    error = ex.response.data.errorMessage;
  }
  if (ex.data && ex.data.errorMessage) {
    error = ex.data.errorMessage;
  }
  if (!!ex.response?.data?.message) {
    error = ex.response.data.message;
  }
  if (!!ex.response?.data?.errors) {
    const [firstError] = ex.response.data.errors;
    error = firstError.message;
  }
  if (notif) {
    notification.error({
      message: error
    });
  }
  return { errorMessage: error };
};

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const formatDate = (date = new Date(), format = "DD/MM/YYYY") => {
  let dt = moment(date, true);
  if (dt.isValid()) {
    return dt.format(format);
  } else {
    return "";
  }
};

export const formatMoney = (value, isSymbol = true) => {
  let format = new Intl.NumberFormat("pt-br", {
    minimumFractionDigits: 2,
    ...(isSymbol ? { style: "currency", currency: "BRL" } : {})
  }).format(value);
  if (format) {
    return format;
  } else {
    return "";
  }
};

export const formatDecimalNumber = (value, precision) => {
  let format = new Intl.NumberFormat("pt-br", {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision
  }).format(value);
  if (format) {
    return format;
  } else {
    return "";
  }
};
export const exportXls = () => {
  const createBook = (title) => {
    let wb = XLSX.utils.book_new();
    wb.Props = {
      Title: title,
      Author: "Alymente",
      CreatedDate: new Date()
    };
    return wb;
  };
  const saveFile = (wb, filename) => {
    XLSX.writeFile(wb, filename + ".xls");
  };
  return {
    createAndSaveFile: (
      sheets,
      data,
      filename,
      title,
      isAoaPosition = null
    ) => {
      const wb = createBook(title);
      wb.SheetNames.push(...sheets);
      sheets.forEach((sheet, index) => {
        if (isAoaPosition != null && isAoaPosition == index) {
          console.log(JSON.stringify(data[sheet][0]));
        }
        const ws =
          isAoaPosition != null && isAoaPosition == index
            ? XLSX.utils.aoa_to_sheet(data[sheet][0], {
                cellDates: true,
                dateNF: "YYYY-MM-DD"
              })
            : XLSX.utils.json_to_sheet(data[sheet], {
                cellDates: true,
                dateNF: "YYYY-MM-DD"
              });
        wb.Sheets[sheet] = ws;
      });
      saveFile(wb, filename);
    }
  };
};

export const saveFileResume = (ws_data, name, title) => {
  let sheet = name;
  let wb = XLSX.utils.book_new();
  wb.Props = {
    Title: title,
    Author: "Alymente",
    CreatedDate: new Date()
  };
  wb.SheetNames.push(sheet);
  let ws = XLSX.utils.json_to_sheet(ws_data, {
    cellDates: true,
    dateNF: "YYYY-MM-DD"
  });
  wb.Sheets[sheet] = ws;
  XLSX.writeFile(wb, sheet + ".xls");
};

export const generateTemplateExcel = () => {
  const validateFields = (value, filter, key) => {
    if (filter[key]) {
      return filter[key](value);
    } else if (value) return value.trim();
    else return "";
  };
  const createHeader = (header) => {
    return header.join("\t") + "\n";
  };
  const createLine = (line, fields, filter) => {
    let lineString = "";
    fields.forEach((f) => {
      lineString += validateFields(line[f], filter, f) + "\t";
    });
    return lineString + "\n";
  };
  const validEdit = (data, dataExcel, visibleFields) => {
    if (data.name != dataExcel.name && visibleFields.includes("name"))
      return true;
    else if (data.email != dataExcel.email && visibleFields.includes("email"))
      return true;
    else if (formatCpf(data.cpf) != formatCpf(dataExcel.cpf)) return true;
    else if (
      !moment(dataExcel.birthday).isSame(data.birthday, "day") &&
      visibleFields.includes("birthday")
    )
      return true;
    else if (
      emptyValue(data.phone) != emptyValue(dataExcel.phone) &&
      visibleFields.includes("phone")
    )
      return true;
    else if (
      emptyValue(data.sex) != emptyValue(dataExcel.sex) &&
      visibleFields.includes("sex")
    )
      return true;
    else if (
      emptyValue(data.code) != emptyValue(dataExcel.code) &&
      visibleFields.includes("code")
    )
      return true;
    else if (visibleFields.includes("address") && data.addressMap == null)
      return true;
    else if (
      visibleFields.includes("address") &&
      emptyValue(data.addressMap.address) != emptyValue(dataExcel.address)
    )
      return true;
    else if (
      visibleFields.includes("address") &&
      emptyValue(data.addressMap.number) != emptyValue(dataExcel.number)
    )
      return true;
    else if (
      visibleFields.includes("address") &&
      emptyValue(data.addressMap.complement) != emptyValue(dataExcel.complement)
    )
      return true;
    else if (
      visibleFields.includes("address") &&
      emptyValue(data.addressMap.neighborhood) !=
        emptyValue(dataExcel.neighborhood)
    )
      return true;
    else if (
      visibleFields.includes("address") &&
      emptyValue(data.addressMap.zipcode) != emptyValue(dataExcel.zipcode)
    )
      return true;
    else if (
      visibleFields.includes("address") &&
      emptyValue(data.addressMap.city) != emptyValue(dataExcel.city)
    )
      return true;
    else if (
      visibleFields.includes("address") &&
      emptyValue(data.addressMap.state) != emptyValue(dataExcel.state)
    )
      return true;
    return false;
  };
  const convertLinetoJson = (line, fields, filter, data, visibleFields) => {
    let tmp = line.split("\t");
    if (tmp.length === 1 && tmp[0] === "") return false;
    let res = {};
    fields.forEach((field, index) => {
      if (field === "birthday") {
        res[field] =
          tmp[index] !== "" ? moment(tmp[index], "DD/MM/YYYY").toDate() : null;
      } else res[field] = validateFields(tmp[index], filter, field);
    });
    const findUser = data.filter(
      (d) => formatCpf(d.cpf) == formatCpf(res["cpf"])
    );
    res.edited =
      findUser.length > 0 ? validEdit(findUser[0], res, visibleFields) : true;
    return res;
  };
  return {
    example: (header, data, fields, filter) => {
      let body = "";
      const headerString = createHeader(header);
      body += headerString;
      for (let l of data) {
        let line = { ...l };
        Object.keys(line)
          .filter((key) => !fields.includes(key))
          .forEach((key) => delete line[key]);
        body += createLine(line, fields, filter);
      }
      return body;
    },
    read: (body, data, fields, filter, visibleFields) => {
      let lines = body.split("\n");
      let list = [];
      for (let i = 1; i < lines.length; i++) {
        let json = convertLinetoJson(
          lines[i],
          fields,
          filter,
          data,
          visibleFields
        );
        if (json) list.push(json);
      }
      return list;
    }
  };
};

export const formatCpf = (cpf) => {
  return cpf.replace(/\./g, "").replace(/\-/g, "");
};

export const getMaskedCpf = (cpf) => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};
export const validateCpf = (cpf) => {
  if (cpf !== "") {
    if (formatCpf(cpf).length < 11) {
      cpf = formatCpf(cpf).padStart(11, "0");
    }
  }
  return cpf;
};

export const replacePhone = (phone) => {
  return phone
    ? phone
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/ /g, "")
        .replace(/\-/g, "")
    : null;
};

export const removePhoneMask = (phone) => {
  return phone ? phone.replace(/\D/g, "") : null;
};

export const emptyValue = (value) => {
  return value === null || value === undefined || value === "" ? null : value;
};

export const clearFormatedCPF = (value) =>
  value.replace(new RegExp("\\.|\\-", "g"), "");

export const emptyObject = (obj, key) => {
  return obj && obj[key] ? obj[key] : null;
};

export const replaceNumber = (value) => {
  return value.replace(".", "").replaceAll("R$", "");
};

export const findPropObject = (obj, prop) => {
  prop = Array.isArray(prop) ? prop : [prop];
  for (const element of prop) {
    if (!obj) {
      return null;
    }
    if (typeof obj[element] == "undefined") return null;
    obj = obj[element];
  }
  if (!isNaN(obj) && typeof obj === "number") {
    obj = obj ? replaceNumber(formatMoney(obj, false)) : null;
  }
  return obj ? obj.toString() : null;
};

export const colors = {
  credit: {
    background: "rgba(0, 149, 255, 0.6)",
    border: "rgba(0, 149, 255, 1)"
  },
  debit: {
    background: "rgba(255, 128, 134, 0.6)",
    border: "rgba(255, 128, 134, 1)"
  },
  balance: {
    background: "rgba(18, 130, 117, 0.6)",
    border: "rgba(18, 130, 117, 1)"
  },
  ticket: {
    background: "rgba(147, 105, 255, 0.6)",
    border: "rgba(147, 105, 255, 1)"
  },
  unit: {
    background: "rgba(128, 128, 128, 0.6)",
    border: "rgba(128, 128, 128, 1)"
  },
  ok: { background: "rgba(80, 209, 216, 1)", border: "rgba(0, 149, 255, 1)" },
  warning1: {
    background: "rgba(239, 239, 89, 1)",
    border: "rgba(239, 239, 89, 1)"
  },
  warning2: {
    background: "rgba(242, 183, 3, 1)",
    border: "rgba(242, 183, 3, 1)"
  },
  warning3: {
    background: "rgba(239, 136, 1, 1)",
    border: "rgba(239, 136, 1, 1)"
  },
  error: { background: "rgba(231, 1, 0, 1)", border: "rgba(231, 1, 0, 1)" },
  benefits: {
    Refeição: "#FF8086",
    Alimentação: "#FF8FFC",
    Educação: "#0095FF",
    Mobilidade: "#EFEF59",
    Consulta: "#9374FF",
    Saúde: "#9374FF",
    Entretenimento: "#FF007F",
    "Bem Estar": "#BAE8E8",
    Academia: "#1B1464",
    Transporte: "#7AFF96",
    Combustível: "#FFCC83",
    undefined: "#000000"
  }
};

export const colors2 = {
  error: "rgba(231, 1, 0, 1)",
  alert: "rgba(239, 136, 1, 1)",
  warning: "rgba(242, 183, 3, 1)",
  success: "rgba(80, 209, 216, 1)"
};

export const getMonth = (month) => {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ];
  return months[month];
};

export const searchCnpj = () => {
  const receitaFields = {
    "NÚMERO DE INSCRIÇÃO": "cnpj",
    "DATA DE ABERTURA": "created_at",
    "NOME EMPRESARIAL": "name",
    "TÍTULO DO ESTABELECIMENTO (NOME DE FANTASIA)": "fantasyname",
    PORTE: "size",
    "CÓDIGO E DESCRIÇÃO DA ATIVIDADE ECONÔMICA PRINCIPAL": "cnae",
    "CÓDIGO E DESCRIÇÃO DAS ATIVIDADES ECONÔMICAS SECUNDÁRIAS": "other_cnae",
    "CÓDIGO E DESCRIÇÃO DA NATUREZA JURÍDICA": "legalcode",
    LOGRADOURO: "address",
    NÚMERO: "address_number",
    COMPLEMENTO: "address_details",
    CEP: "zipcode",
    "BAIRRO/DISTRITO": "district",
    MUNICÍPIO: "city",
    UF: "state",
    "ENDEREÇO ELETRÔNICO": "email",
    TELEFONE: "phone",
    "ENTE FEDERATIVO RESPONSÁVEL (EFR)": "efr",
    "SITUAÇÃO CADASTRAL": "status",
    "DATA DA SITUAÇÃO CADASTRAL": "updated_at",
    "MOTIVO DE SITUAÇÃO CADASTRAL": "status_details"
  };
  const listFields = [
    "CÓDIGO E DESCRIÇÃO DAS ATIVIDADES ECONÔMICAS SECUNDÁRIAS"
  ];
  const parse = (receita) => {
    let previousLine = "";
    let result = {};
    let lines = receita.split("\n");

    // Busca campos simples
    lines.forEach((line) => {
      // Busca campo baseado na linha anterior
      let field = receitaFields[previousLine];
      if (field != null) {
        // Valor do campo é a linha atual
        result[field] = line;
      }
      previousLine = line;
    });

    // Busca campos com lista de valores
    listFields.forEach((label) => {
      // Campo é uma lista
      let field = receitaFields[label];
      result[field] = [];

      var index = lines.indexOf(label);

      for (var i = index + 1; i < lines.length; i++) {
        // Se a linha tem conteúdo
        if (lines[i].trim().length > 0) {
          result[field].push(lines[i]);
        } else {
          // Sai do loop
          i = lines.length;
        }
      }
    });

    return result;
  };
  const open = () => {
    var url =
      "https://solucoes.receita.fazenda.gov.br/Servicos/cnpjreva/cnpjreva_Solicitacao.asp";
    window.open(url, "_blank");
  };
  return { parse, open };
};

export const formatAddress = (address) => {
  return `${address.address}, ${address.number}, ${address.neighborhood}, ${address.city}-${address.state}, ${address.zipcode}`;
};
export const getCountryByCode = (code) => {
  const countries = {
    AFG: "Afghanistan",
    ALA: "Åland Islands",
    ALB: "Albania",
    DZA: "Algeria",
    ASM: "American Samoa",
    AND: "Andorra",
    AGO: "Angola",
    AIA: "Anguilla",
    ATA: "Antarctica",
    ATG: "Antigua and Barbuda",
    ARG: "Argentina",
    ARM: "Armenia",
    ABW: "Aruba",
    AUS: "Australia",
    AUT: "Austria",
    AZE: "Azerbaijan",
    BHS: "Bahamas",
    BHR: "Bahrain",
    BGD: "Bangladesh",
    BRB: "Barbados",
    BLR: "Belarus",
    BEL: "Belgium",
    BLZ: "Belize",
    BEN: "Benin",
    BMU: "Bermuda",
    BTN: "Bhutan",
    BOL: "Bolivia",
    BES: "Bonaire, Sint Eustatius and Saba",
    BIH: "Bosnia and Herzegovina",
    BWA: "Botswana",
    BVT: "Bouvet Island",
    BRA: "Brazil",
    IOT: "British Indian Ocean Territory",
    BRN: "Brunei Darussalam",
    BGR: "Bulgaria",
    BFA: "Burkina Faso",
    BDI: "Burundi",
    CPV: "Cabo Verde",
    KHM: "Cambodia",
    CMR: "Cameroon",
    CAN: "Canada",
    CYM: "Cayman Islands",
    CAF: "Central African Republic",
    TCD: "Chad",
    CHL: "Chile",
    CHN: "China",
    CXR: "Christmas Island",
    CCK: "Cocos Islands",
    COL: "Colombia",
    COM: "Comoros",
    COG: "Congo",
    COD: "Congo",
    COK: "Cook Islands",
    CRI: "Costa Rica",
    CIV: "Côte d'Ivoire",
    HRV: "Croatia",
    CUB: "Cuba",
    CUW: "Curaçao",
    CYP: "Cyprus",
    CZE: "Czech Republic",
    DNK: "Denmark",
    DJI: "Djibouti",
    DMA: "Dominica",
    DOM: "Dominican Republic",
    ECU: "Ecuador",
    EGY: "Egypt",
    SLV: "El Salvador",
    GNQ: "Equatorial Guinea",
    ERI: "Eritrea",
    EST: "Estonia",
    ETH: "Ethiopia",
    FLK: "Falkland Islands",
    FRO: "Faroe Islands",
    FJI: "Fiji",
    FIN: "Finland",
    FRA: "France",
    GUF: "French Guiana",
    PYF: "French Polynesia",
    ATF: "French Southern Territories",
    GAB: "Gabon",
    GMB: "Gambia",
    GEO: "Georgia",
    DEU: "Germany",
    GHA: "Ghana",
    GIB: "Gibraltar",
    GRC: "Greece",
    GRL: "Greenland",
    GRD: "Grenada",
    GLP: "Guadeloupe",
    GUM: "Guam",
    GTM: "Guatemala",
    GGY: "Guernsey",
    GIN: "Guinea",
    GNB: "Guinea-Bissau",
    GUY: "Guyana",
    HTI: "Haiti",
    HMD: "Heard Island and McDonald Islands",
    VAT: "Holy See",
    HND: "Honduras",
    HKG: "Hong Kong",
    HUN: "Hungary",
    ISL: "Iceland",
    IND: "India",
    IDN: "Indonesia",
    IRN: "Islamic Republic of Iran",
    IRQ: "Iraq",
    IRL: "Ireland",
    IMN: "Isle of Man",
    ISR: "Israel",
    ITA: "Italy",
    JAM: "Jamaica",
    JPN: "Japan",
    JEY: "Jersey",
    JOR: "Jordan",
    KAZ: "Kazakhstan",
    KEN: "Kenya",
    KIR: "Kiribati",
    PRK: "Democratic People's Republic of Korea",
    KOR: "Republic of Korea",
    KWT: "Kuwait",
    KGZ: "Kyrgyzstan",
    LAO: "Lao People's Democratic Republic",
    LVA: "Latvia",
    LBN: "Lebanon",
    LSO: "Lesotho",
    LBR: "Liberia",
    LBY: "Libya",
    LIE: "Liechtenstein",
    LTU: "Lithuania",
    LUX: "Luxembourg",
    MAC: "Macao",
    MKD: "Macedonia",
    MDG: "Madagascar",
    MWI: "Malawi",
    MYS: "Malaysia",
    MDV: "Maldives",
    MLI: "Mali",
    MLT: "Malta",
    MHL: "Marshall Islands",
    MTQ: "Martinique",
    MRT: "Mauritania",
    MUS: "Mauritius",
    MYT: "Mayotte",
    MEX: "Mexico",
    FSM: "Federated States of Micronesia",
    MDA: "Republic of Moldova",
    MCO: "Monaco",
    MNG: "Mongolia",
    MNE: "Montenegro",
    MSR: "Montserrat",
    MAR: "Morocco",
    MOZ: "Mozambique",
    MMR: "Myanmar",
    NAM: "Namibia",
    NRU: "Nauru",
    NPL: "Nepal",
    NLD: "Netherlands",
    NCL: "New Caledonia",
    NZL: "New Zealand",
    NIC: "Nicaragua",
    NER: "Niger",
    NGA: "Nigeria",
    NIU: "Niue",
    NFK: "Norfolk Island",
    MNP: "Northern Mariana Islands",
    NOR: "Norway",
    OMN: "Oman",
    PAK: "Pakistan",
    PLW: "Palau",
    PSE: "State of Palestine",
    PAN: "Panama",
    PNG: "Papua New Guinea",
    PRY: "Paraguay",
    PER: "Peru",
    PHL: "Philippines",
    PCN: "Pitcairn",
    POL: "Poland",
    PRT: "Portugal",
    PRI: "Puerto Rico",
    QAT: "Qatar",
    REU: "Réunion",
    ROU: "Romania",
    RUS: "Russian Federation",
    RWA: "Rwanda",
    BLM: "Saint Barthélemy",
    SHN: "Saint Helena, Ascension and Tristan da Cunha",
    KNA: "Saint Kitts and Nevis",
    LCA: "Saint Lucia",
    MAF: "Saint Martin",
    SPM: "Saint Pierre and Miquelon",
    VCT: "Saint Vincent and the Grenadines",
    WSM: "Samoa",
    SMR: "San Marino",
    STP: "Sao Tome and Principe",
    SAU: "Saudi Arabia",
    SEN: "Senegal",
    SRB: "Serbia",
    SYC: "Seychelles",
    SLE: "Sierra Leone",
    SGP: "Singapore",
    SXM: "Sint Maarten",
    SVK: "Slovakia",
    SVN: "Slovenia",
    SLB: "Solomon Islands",
    SOM: "Somalia",
    ZAF: "South Africa",
    SGS: "South Georgia and the South Sandwich Islands",
    SSD: "South Sudan",
    ESP: "Spain",
    LKA: "Sri Lanka",
    SDN: "Sudan",
    SUR: "Suriname",
    SJM: "Svalbard and Jan Mayen",
    SWZ: "Swaziland",
    SWE: "Sweden",
    CHE: "Switzerland",
    SYR: "Syrian Arab Republic",
    TWN: "Taiwan, Province of China",
    TJK: "Tajikistan",
    TZA: "United Republic of Tanzania",
    THA: "Thailand",
    TLS: "Timor-Leste",
    TGO: "Togo",
    TKL: "Tokelau",
    TON: "Tonga",
    TTO: "Trinidad and Tobago",
    TUN: "Tunisia",
    TUR: "Turkey",
    TKM: "Turkmenistan",
    TCA: "Turks and Caicos Islands",
    TUV: "Tuvalu",
    UGA: "Uganda",
    UKR: "Ukraine",
    ARE: "United Arab Emirates",
    GBR: "United Kingdom of Great Britain and Northern Ireland",
    USA: "United States of America",
    UMI: "United States Minor Outlying Islands",
    URY: "Uruguay",
    UZB: "Uzbekistan",
    VUT: "Vanuatu",
    VEN: "Venezuela (Bolivarian Republic of)",
    VNM: "Viet Nam",
    VGB: "Virgin Islands",
    VIR: "Virgin Islands of the United States",
    WLF: "Wallis and Futuna",
    ESH: "Western Sahara",
    YEM: "Yemen",
    ZMB: "Zambia",
    ZWE: "Zimbabwe"
  };
  return countries[code];
};

export const submitForm = async (form) => {
  let result = true;
  try {
    await form.validateFields();
  } catch (ex) {
    result = false;
  } finally {
    form.submit();
    return result;
  }
};

export const getFieldUser = (field) => {
  if (
    field == "city" ||
    field == "state" ||
    field == "zipcode" ||
    field == "address" ||
    field == "number" ||
    field == "complement" ||
    field == "neighborhood"
  ) {
    return ["address", field];
  }
  return field;
};

export function convertCategoryColorToken(category) {
  if (!category || category === "default") {
    return "colorTextDisabled";
  } else if (category === "info") {
    return "colorInfo";
  } else if (category === "success") {
    return "colorSuccess";
  } else if (category === "warning") {
    return "colorWarning";
  } else if (category === "error") {
    return "colorError";
  }
}

const getRndInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

const generateHex = (size) => {
  let hex = "";
  for (let i = 0; i < size; i++) hex += getRndInteger(10, 15).toString(16);
  return hex;
};

const bitwiseXorHexString = (pinBlock1, pinBlock2) => {
  var result = "";
  for (let index = 0; index < 16; index++) {
    const temp = (
      parseInt(pinBlock1.charAt(index), 16) ^
      parseInt(pinBlock2.charAt(index), 16)
    )
      .toString(16)
      .toUpperCase();
    result += temp;
  }
  return result;
};

const formatDecimal = (value) => {
  let decimal = "";
  for (var i = 0; i < value.length; i++) {
    if (value.toUpperCase().charCodeAt(i) >= 65)
      decimal += value.toUpperCase().charCodeAt(i) - 65;
    else decimal += value.charAt(i);
  }
  return decimal;
};

export const pinBlock = (pin, cardId) => {
  const pan = cardId.replace(new RegExp("-", "g"), "").substring(0, 12);
  const pin_format = "3" + pin.length + pin + generateHex(14 - pin.length);
  const pan_format = "0000" + formatDecimal(pan);
  const result = bitwiseXorHexString(pin_format, pan_format);
  return result;
};

export const isStaging = () => {
  return env.api.includes('staging') || env.api.includes('10.0.2.2')  || env.api.includes('localhost')
}


export const getUrlMs = () => {
  return URL_MS_SERVER;
}