import { Table } from 'antd';
import { CardInfo } from 'components/card/card-info';
import { translate } from 'config/language';
import { useMemo } from 'react';
import { connect } from 'react-redux';
import { formatMoney } from 'utils';

const mapStateToProps = (state) => ({
   login: state.authReducer.loginReducer,
   balance: state.balanceReducer,
});
const SubBalance = ({ balance }) => {
   const columns = [
      {
         title: 'Benefício',
         dataIndex: 'nameBenefit',
      },
      {
         title: translate('balance'),
         dataIndex: 'balance',
         width: 250,
         render: (value) => <CardInfo title={translate('balance')} value={formatMoney(value)}></CardInfo>,
      },
      {
         title: 'Desconto Disponível',
         dataIndex: 'available',
         width: 250,
         render: (value) => <CardInfo title={'Desconto Disponível'} value={formatMoney(value)}></CardInfo>,
      },
   ];
   const subBalances = useMemo(
      () => balance.company.subBalances,

      [balance.company.subBalances]
   );
   return <Table columns={columns}  dataSource={subBalances} pagination={false} showHeader={false} size="small" />;
};

export default connect(mapStateToProps, {})(SubBalance);
