import axios from "axios";
import moment from "moment";
import { handlerError } from "utils";

export const getReportUrls = async () => {
  try {
    const res = await axios.get(`/api/report`);
    return res.data.urls ? res.data.urls : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getDashboard = async (url, company) => {
  try {
    const res = await axios.get(`${url}?company=${company}`);

    return res.data ? res.data : {};
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};

export const getFotobase = async (url, company, view, filter) => {
  try {
    const req = {
      company: company,
      view: view,
      filter: filter
    };
    const res = await axios.post(`${url}`, req);
    return res.data.data ? res.data.data : {};
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};

export const getReportUrlsCard = async () => {
  try {
    const res = await axios.get(`/api/report/card`);
    return res.data.urls ? res.data.urls : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getReportUrlsFotobase = async () => {
  try {
    const res = await axios.get(`/api/report/fotobase`);
    return res.data ? res.data : {};
  } catch (ex) {
    handlerError(ex);
    return {};
  }
};
