import React from "react";
import { connect } from "react-redux";
import { Layout, Button, Typography, Row, Col, Table, Tag, notification } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Header, InputCurrency, SwitchLabel, InputMask, CreditCard, FormAddress, AutoCompleteCompany, Form, UserBalance, CardInfo, LoadingText } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { getUserBalanceId, userRedeem } from "services/user-service";
import { getEmployee, getAdvantages, getStatusAdvantage, activeAdvantage } from "services/employee-service";
import moment from "moment";
import Env from "env";

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const DataFetch = {
    data: {},
    loadingBalance: false
}


const UserAdvantagesScreen = (props) => {
    const { login } = props;
    const { id } = useParams();

    const [data, setData] = React.useState(DataFetch)

    const load = async () => {
        setData((x) => ({ ...x, loading: true }));
        const res = await getAdvantages(id)
        if(res.advantages)
            res.advantages.map(d => d.loading = true)
        setData({ data: res, loading: false });
    }

    const createMsgStatus = () => {
        let desc = ""
        if (data.data.status == "Inativo" && data.data.chargeDifferenceDays == -1)
            desc = data.data.status + " - " + "Colaborador sem carga em benefício"
        else if (data.data.chargeDifferenceDays == 0)
            desc = data.data.status + " - " + "Colaborador com carga em benefício hoje"
        else
            desc = data.data.status + " - " + "Colaborador com carga em benefício há " + data.data.chargeDifferenceDays + " dias";
        return desc
    }

    const loadStatus = async () => {
        if(data.data.advantages){
            for(let advantage of data.data.advantages){
                let status = await getStatusAdvantage(advantage.urlStatus)
                if(status.success){
                    advantage.statusMessage = <Tag color={status.category}>{status.status.toUpperCase()}</Tag>
                    advantage.status = status.status
                } 
                advantage.loading = false
                setData((x) => ({ ...x, data: data.data }))
            }
        }
    }

    const active = async (data) => {
        setData((x) => ({ ...x, loading: true }));
        const res = await activeAdvantage(data.name, id)
        if(res.success){
            notification.success({ message: "Vantagem ativa com sucesso!" });
        }
        load();
    }

    React.useEffect(() => {
        loadStatus();
    }, [data.data.advantages])

    React.useEffect(() => {
        load();
    }, [id])

    return <Layout className="alymente-layout">
        <Header title={"Vantagens"}>
            <Row gutter={12} align="middle">
                <Col xl={8} lg={24}>
                    <CardInfo size="medium" title="Status" value={createMsgStatus()} loading={data.loading} />
                </Col>
            </Row>
        </Header>
        <Content>
            <Row style={{ marginBottom: 20 }} justify="center">
                <Table dataSource={data.data.advantages} loading={data.loading} pagination={false} bordered >
                    <Table.Column width={170} align={"center"} title="Vantagem" dataIndex="name" key="name" />
                    <Table.Column width={200} align={"center"} title="Status" dataIndex="key" key="key" render={(value, record, index) => <LoadingText loading={record.loading} value={record.statusMessage} />} />
                    <Table.Column width={170} align={"center"} title="Ação" dataIndex="id" key="id" render={(value, record, index) => <Button type={"primary"} loading={record.loading} disabled={record.status === 'Ativo' || !(record.default || record.automaticActivation)} onClick={() => {active(record)}}>Ativar</Button> } />
                </Table>
            </Row>
        </Content>
    </Layout>

}

export default connect(mapStateToProps, {})(UserAdvantagesScreen); 