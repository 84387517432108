import React from 'react';
import { connect } from 'react-redux';
import { Layout, Button, Typography, Row, Col, notification, Space, Table, Tooltip, Tag, Alert } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { Header, DatePicker, FormatMoney, CardCompanyBalance } from 'components';
import { getSolicitationReportData } from 'services/consolidated-report-service';
import { getHolidays } from 'services/banking-service';
import moment from 'moment';
import warning from 'antd/es/_util/warning';

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer,
    balance: state.balanceReducer,
});

const SolicitationCashFlow = (props) => {
    const { balance, login } = props;
    const [data, setData] = React.useState([]);
    const [getChildrenCompanies, setGetChildrenCompanies] = React.useState(false);
    const [openReportDateModal, setOpenReportDateModal] = React.useState(false);
    const [date, setDate] = React.useState([moment().add(1, 'days')]);
    const [loading, setLoading] = React.useState(false);
    const dataSource = data.filtered ? data.filtered : data.data;
    const [colunas, setColunas] = React.useState([]);
    const [holidays, setHolidays] = React.useState([]);
    const [year] = React.useState(moment().year());

    const isMasterCompany = () => {
        return props.login.company.master;
    };

    const load = async () => {
        setLoading(true);
        await getHoliday();
        setLoading(false);
    };

    React.useEffect(() => {
        load();
    }, []);

    const getEndDate = (init, days) => {
        let date = moment(init); // use a clone
        //pedidos no sabado é como se fosse na segunda
        if (date.isoWeekday() === 6) {
            date = date.add(2, 'days');
        }
        //pedidos no feriado de sexta é como se fosse na segunda
        else if (date.isoWeekday() === 5 && isHolidayDay(date)) {
            date = date.add(3, 'days');
        }
        //pedidos no domingo e feriados //pedidos no feriado de sexta é como se fosse na segunda
        else if (date.isoWeekday() === 7 || isHolidayDay(date)) {
            date = date.add(1, 'days');
        }
        while (days > 0) {
            date = date.add(1, 'days');
            // desconsidera finais de semana e feriados
            if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7 && !isHolidayDay(date)) {
                days -= 1;
            }
        }
        return date;
    };

    const isHolidayDay = (date) => {
        return holidays.filter((d) => d.date === date.format('YYYY-MM-DD')).length > 0;
    };

    const getHoliday = async () => {
        const holidays = await getHolidays(year);
        setHolidays(holidays);
    };

    function mapSolicitationDetails(sol) {
        return sol.map(({ id, benefit, company, type, value, netValue, discount, fee, status, payment, approval, businessUnit }) => ({
            //Pedidos: {
            solicitation: id,
            benefit: benefit?.name,
            company: company.name,
            cnpj: company.cnpj,
            type: type,
            value: value,
            netValue: netValue,
            discount: discount,
            fee: fee,
            cashFlowValue: (discount > 0 && netValue == 0) ? discount : (discount > 0 && netValue > 0) ? (discount + netValue) : netValue,
            status: status.name,
            paidAt: payment?.paidAt && moment(payment.paidAt).format("yyyy-MM-DD"),
            paymentAt: payment?.paymentAt && moment(payment.paymentAt).format("yyyy-MM-DD"),
            expectedPaymentAt: moment(getEndDate(payment?.paymentAt, 3)).format("yyyy-MM-DD"),
            approvedAt: approval?.approvedAt && moment(approval.approvedAt).format("yyyy-MM-DD"),
            approvalAt: approval?.approvalAt && moment(approval.approvalAt).format("yyyy-MM-DD"),
            equip: businessUnit?.name,
            //},
        }));
    }

    const onSelectReportDateRange = async () => {

        if (date.length <= 1) {
            notification.warning({ message: 'Favor preencher uma data final para pesquisar.' });
            return;
        }

        let [startPeriod, endPeriod] = date;

        const companiesId = getChildrenCompanies ? login.children.data.map((c) => c.id) : [login.company.id];
        setLoading(true);
        try {
            const solicitationReportData = await getSolicitationReportData(
                companiesId,
                startPeriod.format('YYYY-MM-DD'),
                endPeriod.format('YYYY-MM-DD'),
                true
            );

            if (solicitationReportData.length === 0) {
                throw new Error('Não foram encontrados dados para o período informado.');
            } else {
                const solicitationDetails = mapSolicitationDetails(solicitationReportData);

                let _lines = [];
                _lines = [
                    { key: 1, in: true, desc: false, type: 'T', name: 'Pedido de Crédito - Entrada' },
                    { key: 2, in: true, desc: false, type: 'C', name: 'Pedido de Carga - Entrada' },
                    { key: 3, in: false, desc: true, type: 'C', name: 'Pedido de Carga - Desconto' },
                    { key: 4, in: false, desc: false, type: 'C', name: 'Pedido de Carga - Saída' },
                    { key: 5, in: true, desc: false, type: 'R', name: 'Pedido de Reembolso - Entrada' },
                    { key: 6, in: false, desc: true, type: 'R', name: 'Pedido de Reembolso - Desconto' },
                    { key: 7, in: false, desc: false, type: 'R', name: 'Pedido de Reembolso - Saída' },
                ];

                let dateStart = moment(startPeriod);
                let dateEnd = moment(endPeriod);
                let _colunas = [];

                _colunas.push({
                    key: 'name',
                    width: 150,
                    fixed: 'left',
                    align: 'left',
                    dataIndex: 'name',
                    title: 'Tipo',
                    ellipsis: {
                        showTitle: false,
                    },
                    render: (value) => {
                        let color = value.includes('Crédito') ? 'green' : 'purple';
                        if (value.includes('Reembolso')) {
                            color = 'blue';
                        }
                        return (
                            <Tag color={color} > {value}</Tag>)
                    }
                });

                while (moment(dateStart).isSameOrBefore(dateEnd, 'day')) {
                    const tituloCol = 'dt_' + dateStart.format('DD-MM-YYYY');
                    const toolTipCol = 'tt_' + dateStart.format('DD-MM-YYYY');
                    _colunas.push({
                        key: tituloCol,
                        width: 120,
                        align: 'center',
                        title: dateStart.format('DD/MM/YYYY'),
                        ellipsis: {
                            showTitle: false,
                        },
                        render: (value) =>
                        (
                            <Tooltip placement='topLeft' color={'darkgreen'} title={value[toolTipCol] != '' ? value[toolTipCol] : 'Não há pedidos.'}>
                                <span><FormatMoney value={value[tituloCol]} discount={value.name.includes('Desconto')} /></span></Tooltip>
                        )
                    });

                    const credit = _lines.find((x) => x.type === 'T');
                    credit[tituloCol] = solicitationDetails
                        .filter((item) => dateStart.isSame(item.approvedAt != null ? item.approvedAt : item.approvalAt, 'date') && item.type.code === 'T')
                        .reduce((a, s) => a + s.netValue, 0);

                    const ttCredit = _lines.find((x) => x.type === 'T');
                    ttCredit[toolTipCol] = solicitationDetails
                        .filter((item) => dateStart.isSame(item.approvedAt != null ? item.approvedAt : item.approvalAt, 'date') && item.type.code === 'T' && item.netValue != 0)
                        .map((x) => (' ' + x.solicitation)).join();
                    ttCredit[toolTipCol] = ttCredit[toolTipCol] === '' ? '' : 'Pedido(s): ' + ttCredit[toolTipCol];

                    const chargeIn = _lines.find((x) => x.type === 'C' && x.in === true && x.desc === false);
                    chargeIn[tituloCol] = solicitationDetails
                        .filter((item) => dateStart.isSame(item.approvedAt != null ? item.approvedAt : item.expectedPaymentAt, 'date') && item.type.code === 'C')
                        .reduce((a, s) => a + s.netValue, 0);

                    const ttChargeIn = _lines.find((x) => x.type === 'C' && x.in === true && x.desc === false);
                    ttChargeIn[toolTipCol] = solicitationDetails
                        .filter((item) => dateStart.isSame(item.approvedAt != null ? item.approvedAt : item.expectedPaymentAt, 'date') && item.type.code === 'C' && item.netValue != 0)
                        .map((x) => (' ' + x.solicitation)).join();
                    ttChargeIn[toolTipCol] = ttChargeIn[toolTipCol] === '' ? '' : 'Pedido(s): ' + ttChargeIn[toolTipCol];

                    const chargeDesc = _lines.find((x) => x.type === 'C' && x.in === false && x.desc === true);
                    chargeDesc[tituloCol] = solicitationDetails
                        .filter((item) => dateStart.isSame(item.approvedAt != null ? item.approvedAt : item.approvalAt, 'date') && item.type.code === 'C')
                        .reduce((a, s) => a + s.discount, 0);

                    const ttChargeDesc = _lines.find((x) => x.type === 'C' && x.in === false && x.desc === true);
                    ttChargeDesc[toolTipCol] = solicitationDetails
                        .filter((item) => dateStart.isSame(item.approvedAt != null ? item.approvedAt : item.approvalAt, 'date') && item.type.code === 'C' && item.discount != 0)
                        .map((x) => (' ' + x.solicitation)).join();
                    ttChargeDesc[toolTipCol] = ttChargeDesc[toolTipCol] === '' ? '' : 'Pedido(s): ' + ttChargeDesc[toolTipCol];

                    const chargeOut = _lines.find((x) => x.type === 'C' && x.in === false && x.desc === false);
                    chargeOut[tituloCol] = solicitationDetails
                        .filter((item) => dateStart.isSame(item.approvedAt != null ? item.approvedAt : item.approvalAt, 'date') && item.type.code === 'C')
                        .reduce((a, s) => a + s.cashFlowValue, 0);
                    chargeOut[tituloCol] = chargeOut[tituloCol] == 0 ? 0 : (-1 * chargeOut[tituloCol]);

                    const ttChargeOut = _lines.find((x) => x.type === 'C' && x.in === false && x.desc === false);
                    ttChargeOut[toolTipCol] = solicitationDetails
                        .filter((item) => dateStart.isSame(item.approvedAt != null ? item.approvedAt : item.approvalAt, 'date') && item.type.code === 'C' && item.cashFlowValue != 0)
                        .map((x) => (' ' + x.solicitation)).join();
                    ttChargeOut[toolTipCol] = ttChargeOut[toolTipCol] === '' ? '' : 'Pedido(s): ' + ttChargeOut[toolTipCol];

                    const refundIn = _lines.find((x) => x.type === 'R' && x.in === true && x.desc === false);
                    refundIn[tituloCol] = solicitationDetails
                        .filter((item) => dateStart.isSame(item.approvedAt != null ? item.approvedAt : item.approvalAt, 'date') && item.type.code === 'R')
                        .reduce((a, s) => a + s.netValue, 0);

                    const ttRefundIn = _lines.find((x) => x.type === 'R' && x.in === true && x.desc === false);
                    ttRefundIn[toolTipCol] = solicitationDetails
                        .filter((item) => dateStart.isSame(item.approvedAt != null ? item.approvedAt : item.approvalAt, 'date') && item.type.code === 'R' && item.netValue != 0)
                        .map((x) => (' ' + x.solicitation)).join();
                    ttRefundIn[toolTipCol] = ttRefundIn[toolTipCol] === '' ? '' : 'Pedido(s): ' + ttRefundIn[toolTipCol];

                    const refundDesc = _lines.find((x) => x.type === 'R' && x.in === false && x.desc === true);
                    refundDesc[tituloCol] = solicitationDetails
                        .filter((item) => dateStart.isSame(item.approvedAt != null ? item.approvedAt : item.approvalAt, 'date') && item.type.code === 'R')
                        .reduce((a, s) => a + s.discount, 0);

                    const ttRefundDesc = _lines.find((x) => x.type === 'R' && x.in === false && x.desc === true);
                    ttRefundDesc[toolTipCol] = solicitationDetails
                        .filter((item) => dateStart.isSame(item.approvedAt != null ? item.approvedAt : item.approvalAt, 'date') && item.type.code === 'R' && item.discount != 0)
                        .map((x) => (' ' + x.solicitation)).join();
                    ttRefundDesc[toolTipCol] = ttRefundDesc[toolTipCol] === '' ? '' : 'Pedido(s): ' + ttRefundDesc[toolTipCol];

                    const refundOut = _lines.find((x) => x.type === 'R' && x.in === false && x.desc === false);
                    refundOut[tituloCol] = solicitationDetails
                        .filter((item) => dateStart.isSame(item.approvedAt != null ? item.approvedAt : item.approvalAt, 'date') && item.type.code === 'R')
                        .reduce((a, s) => a + s.cashFlowValue, 0);
                    refundOut[tituloCol] = refundOut[tituloCol] == 0 ? 0 : (-1 * refundOut[tituloCol]);

                    const ttRefundOut = _lines.find((x) => x.type === 'R' && x.in === false && x.desc === false);
                    ttRefundOut[toolTipCol] = solicitationDetails
                        .filter((item) => dateStart.isSame(item.approvedAt != null ? item.approvedAt : item.approvalAt, 'date') && item.type.code === 'R' && item.cashFlowValue != 0)
                        .map((x) => (' ' + x.solicitation)).join();
                    ttRefundOut[toolTipCol] = ttRefundOut[toolTipCol] === '' ? '' : 'Pedido(s): ' + ttRefundOut[toolTipCol];

                    dateStart = dateStart.add(1, 'day');
                }

                setColunas([]);
                setColunas(_colunas);
                setData([]);
                setData(_lines);
            }
        } catch (e) {
            notification.error({ message: e.message });
        }
        setOpenReportDateModal(false);
        setLoading(false);
    };

    const table = React.useMemo(() => {
        return (
            <Table
                loading={false}
                pagination={false}
                columns={colunas}
                scroll={{ y: 600, x: 'max-content' }}
                dataSource={data}
                bordered
                summary={(pageData) => {
                    let [startPeriod, endPeriod] = date;

                    let dateStart = moment(startPeriod);
                    let dateEnd = moment(endPeriod);
                    const summary = [];

                    const totalBalance = balance.company.balance;
                    let dailySummary = totalBalance;
                    //let index = 0;

                    while (moment(dateStart).isSameOrBefore(dateEnd, 'day')) {
                        const tituloCol = 'dt_' + dateStart.format('DD-MM-YYYY');
                        // if (index === 0) {
                        //     dailySummary = totalBalance;
                        // } else {
                        //dailySummary += summary[index - 1];
                        dailySummary += (data.filter((i) => i.type === 'T' && i.in === true && i.desc === false).map((x) => x[tituloCol]).reduce((a, b) => a + b, 0)); //Somando o Crédito 
                        dailySummary += (data.filter((i) => i.type === 'C' && i.in === true && i.desc === false).map((x) => x[tituloCol]).reduce((a, b) => a + b, 0)); //Somando a Carga 
                        dailySummary -= (-1 * (data.filter((i) => i.type === 'C' && i.in === false && i.desc === false).map((x) => x[tituloCol]).reduce((a, b) => a + b, 0))); //Subtraindo a Carga 
                        dailySummary += (data.filter((i) => i.type === 'R' && i.in === true && i.desc === false).map((x) => x[tituloCol]).reduce((a, b) => a + b, 0)); //Somando o Reembolso 
                        dailySummary -= (-1 * (data.filter((i) => i.type === 'R' && i.in === false && i.desc === false).map((x) => x[tituloCol]).reduce((a, b) => a + b, 0))); //Subtraindo o Reembolso                            
                        // }
                        summary.push(dailySummary);

                        //index++;
                        dateStart = dateStart.add(1, 'day');
                    }
                    return (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} fixed="center">
                                <strong>Total Dia:</strong>
                            </Table.Summary.Cell>
                            {summary.map((sum, index) => (
                                <Table.Summary.Cell index={index + 1} align="center">
                                    <Text type={sum < 0 ? 'warning' : 'success'} style={{ fontWeight: 'bold' }}>
                                        <FormatMoney value={sum} />
                                    </Text>
                                </Table.Summary.Cell>
                            ))}
                        </Table.Summary.Row>
                    );
                }}
            />
        );
    }, [data]);

    const onCashFlow = () => {
        return (
            <Layout className="alymente-layout">
                <Header title="Fluxo de Caixa">
                    <Row gutter={12}>
                        <Col xxl={5} xl={8} lg={12}>
                            <CardCompanyBalance type={'balance'} />
                        </Col>
                        {!isMasterCompany() && (
                            <Col xxl={5} xl={8} lg={12}>
                                <CardCompanyBalance type={'discount'} />
                            </Col>
                        )}
                    </Row>
                </Header>
                <Content>
                    <React.Fragment>
                        <Row style={{ marginTop: 10, marginBottom: 10 }}>
                            <Col sm={24}>
                                <Alert type="warning" message="Valor de Entrada considera a Data de Expectativa de Pagamento do Pedido que é a Data de Pagamento + 3 dias úteis." showIcon />
                            </Col>
                        </Row>

                        <Row gutter={16} justify="start" style={{ marginBottom: 20 }}>
                            <Col sm={24} align="start">
                                <Text>Selecione o Período:</Text>
                            </Col>
                            <Col xl={7} lg={8} md={24}>
                                <DatePicker.RangePicker
                                    disabled={loading}
                                    style={{ width: '100%' }}
                                    format="DD/MM/YYYY"
                                    value={date}
                                    disabled={[true, false]}
                                    minDate={moment().add(1, 'days')}
                                    onChange={(e) => setDate(e)}
                                />
                            </Col>
                            <Col xl={4} lg={8} md={24}>
                                <Button block type="primary" loading={loading} onClick={onSelectReportDateRange}>
                                    Pesquisar
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={16} justify="start">
                            {colunas.length > 0 && <React.Fragment>{table}</React.Fragment>}
                        </Row>
                    </React.Fragment>
                </Content>
            </Layout>
        );
    };

    return (
        <React.Fragment>
            <Content style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>{onCashFlow()}</Content>
        </React.Fragment>
    );
};

export default connect(mapStateToProps, {})(SolicitationCashFlow);
;