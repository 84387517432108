import React from 'react';
import { connect } from 'react-redux';
import { Table, Layout, Row, Col, Button, Space } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { CardInfo, FormatMoney, Header } from 'components';
import { formatMoney } from 'utils';
import { UnorderedListOutlined } from '@ant-design/icons';
import { translate } from 'config/language';
const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
   login: state.authReducer.loginReducer,
   balance: state.balanceReducer,
});

const DataFetch = {
   data: [],
   loading: false,
};

const SolicitationBalanceScreen = (props) => {
   const navigate = useNavigate();
   const { balance } = props;
   const location = useLocation();
   const { state } = location;
   const { url, type, description = `Selecione um ${translate('balance')} a ser utilizado para a operação` } = state || props;
   const [selectedBenefit, setSelectedBenefit] = React.useState(0);
   const [data, setData] = React.useState(
      balance.company.subBalances
         .filter((b) => (type == 'incentive' ? b.benefit < 0 : b.benefit > 0) || b.benefit == null || type == 'credit')
         .map((b, index) => {
            return { ...b, key: index };
         })
   );

   const load = async () => {};

   React.useEffect(() => {
      load();
   }, []);

   const columns = [
      {
         title: 'Benefício',
         dataIndex: 'nameBenefit',
      },
      {
         title: translate('balance'),
         dataIndex: 'balance',
         width: 250,
         render: (value) => <CardInfo title={translate('balance')} value={formatMoney(value)}></CardInfo>,
      },
      {
         title: 'Desconto Disponível',
         dataIndex: 'available',
         width: 250,
         render: (value) => <CardInfo title={'Desconto Disponível'} value={formatMoney(value)}></CardInfo>,
      },
   ];

   const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
         // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
         setSelectedBenefit(selectedRows[0].key);
      },
      selectedRowKeys: [selectedBenefit],
   };

   return (
      <Layout className="alymente-layout">
         <Header title={`Selecione o ${translate('balance')}`} subtitle={description}></Header>
         <Content>
            <Row justify="center">
               <Col lg={21} xl={16}>
                  <Table
                     rowSelection={{
                        type: 'radio',
                        ...rowSelection,
                     }}
                     columns={columns}
                     dataSource={data}
                     pagination={false}
                     showHeader={false}
                     size="small"
                  />
               </Col>
            </Row>
         </Content>
         <Footer>
            <Space align="end">
               <Button
                  className="btn-save"
                  type="primary"
                  icon={<UnorderedListOutlined />}
                  onClick={() => {
                     navigate(url, { state: { ...state, benefit: data[selectedBenefit].benefit } });
                  }}
               >
                  Continuar
               </Button>
            </Space>
         </Footer>
      </Layout>
   );
};

export default connect(mapStateToProps, {})(SolicitationBalanceScreen);
