import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Layout, Button, Table, Modal } from 'antd';
import { FormatDate, Header, AutoCompleteCompany } from "components";
import { loadAllHistoryCards, unblockCard } from "services/card-service";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const DataFetch = {
    data: [],
    loading: false
}

const CardListScreen = (props) => {
    const { login } = props;
    const [company, setCompany] = React.useState(login.company.master ? null : login.company.id);
    const [data, setData] = React.useState(DataFetch)

    const load = async () => {
        setData((x) => ({ ...x, loading: true }));
        const res = await loadAllHistoryCards(company);
        setData((x) => ({ ...x, data: res, loading: false }));
    }

    const unBlock = (row) => {
        Modal.confirm({
            title: `Confirma o desbloqueio do cartão ${row.cardNumber} ?`,
            icon: <InfoCircleOutlined />,
            onOk: async () => {
                setData((x) => ({ ...x, loading: true }));
                await unblockCard(row.id);
                load()
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    React.useEffect(() => {
        if (company) {
            load();
        }
    }, [company])

    return (
        <Layout className="alymente-layout">
            <Header
                title={`Desbloqueio de Cartões`}
                subtitle={'Selecione o cartão que deseja desbloquear'}
                middle={
                    login.company.master ? <AutoCompleteCompany value={company} onChange={setCompany} /> : undefined
                } />
            <Content>
                <Table scroll={{ y: 700 }} dataSource={data.data} loading={data.loading} pagination={false} bordered >
                    <Table.Column title="Empresa" dataIndex={["user", "company", "name"]} key="user.company.name" />
                    <Table.Column title="Colaborador" dataIndex={["user", "name"]} key="user.name" />
                    <Table.Column title="CPF" dataIndex={["user", "cpf"]} key="user.cpf" align="center" />
                    <Table.Column title="Número Cartão" dataIndex={"cardNumber"} width={160} key="cardNumber" />
                    <Table.Column title="Data Bloqueio" dataIndex={"createdAt"} width={130} key="createdAt" align="center" render={(value) => <FormatDate value={value} />} />
                    <Table.Column title="Expiração" dataIndex={"expiry"} width={100} key="expiry" align="center" />
                    <Table.Column title="Tipo" dataIndex={"virtual"} width={100} key="virtual" align="center" render={(value) => value ? "virtual" : "físico"} />} />
                    <Table.Column fixed="right" width={150} title="Ação" dataIndex={"id"} key="id" align="center" render={(value, row) => {
                        return <Button disabled={row.active || row.virtual} size="small" type={"link"} onClick={() => { unBlock(row) }}>Desbloquear</Button>
                    }} />
                </Table>
            </Content>
            <Footer>

            </Footer>
        </Layout>
    )
}

export default connect(mapStateToProps, {})(CardListScreen);