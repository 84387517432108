import React from "react";
import { connect } from "react-redux";
import { Layout, Button, Typography, Row, Col, Modal, Select } from "antd";
import { SaveOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Header,
  CardCompanyBalance,
  InputCurrency,
  CardInfo
} from "components";
import { useNavigate, useLocation } from "react-router-dom";
import { pendingCharges } from "./solicitation";
import { formatMoney } from "utils";
import { getAllBenefit } from "services/benefit-service";
import { translate } from "config/language";

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer,
  balance: state.balanceReducer
});

const SolicitationCreditScreen = (props) => {
  const navigate = useNavigate();
  const { login, balance } = props;
  const [value, setValue] = React.useState(0);
  const [benefits, setBenefits] = React.useState([]);
  const location = useLocation();
  const { benefit } = location.state || props;
  const createRevision = async () => {
    const createValues = async () => {
      const charges = await pendingCharges.load();
      const taxInvoice =
        login.company.taxInvoice && login.company.taxInvoice > 0
          ? login.company.taxInvoice
          : 0;
      const createInvoiceAuto = login.company.createInvoiceAuto;
      const fee = charges ? charges.fee : 0;
      const total = value + fee + taxInvoice;
      return {
        charges,
        taxInvoice,
        createInvoiceAuto,
        fee,
        total,
        value,
        hasPayment: value + fee > 0 ? true : false
      };
    };
    const createCheckout = async () => {
      const values = await createValues();
      const fields = [
        { label: "Valor", value: values.value },
        { label: "Taxa", value: values.fee, details: values.charges },
        {
          total: true,
          discount: false,
          hasPayment: values.hasPayment,
          label: "Valor Total",
          value: values.total
        }
      ];
      if (values.taxInvoice > 0) {
        fields.splice(2, 0, { label: "Taxa Extra", value: values.taxInvoice });
      }
      if (benefits.length > 0) {
        fields.splice(1, 0, {
          label: translate("balance"),
          valueName:
            benefits.filter((b) => b.benefit === benefit).length > 0
              ? benefits.filter((b) => b.benefit === benefit)[0].name
              : `${translate("balance")} Geral`
        });
      }
      return fields;
    };

    return {
      request: {
        url: "/api/solicitation/credit",
        method: "post"
      },
      body: {
        data: {
          value: value,
          benefit: benefit
        }
      },
      checkout: await createCheckout(),
      type: "credit",
      successMessage: "Pedido de crédito realizado com sucesso."
    };
  };

  const onConfirm = () => {
    Modal.confirm({
      title: "Deseja continuar?",
      icon: <InfoCircleOutlined />,
      content: "Após a confirmação, você irá fazer a revisão do seu pedido.",
      onOk: async () => {
        navigate("/solicitation/charge/revision", {
          state: { data: await createRevision() }
        });
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };

  const loadBenefits = React.useCallback(async () => {
    if (login.company.benefitAccount) {
      const res = await getAllBenefit(login.company.id, true);
      const benefits = res.filter((x) => x.account != null && x.enabled);
      setBenefits(benefits);
    }
  }, []);

  React.useEffect(() => {
    loadBenefits();
  }, []);

  return (
    <Layout className="alymente-layout">
      <Header>
        <Row gutter={12} align="middle">
          <Col>
            <CardCompanyBalance
              benefit={benefit}
              useBenefit={login.company.benefitAccount ? true : false}
            />
          </Col>
          {benefit || login.company.benefitAccount ? (
            <Col>
              <CardCompanyBalance
                benefit={benefit}
                useBenefit={login.company.benefitAccount ? true : false}
                type={"discount"}
              />
            </Col>
          ) : (
            <Col xl={4}>
              <CardCompanyBalance
                benefit={benefit}
                useBenefit={login.company.benefitAccount ? true : false}
                type={"discount"}
              />
            </Col>
          )}
          <Col xl={4}>
            <CardCompanyBalance type={"charge"} />
          </Col>
        </Row>
      </Header>
      <Content
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Row>
          <Col sm={24} align="center">
            <Text>Qual o valor do pedido?</Text>
          </Col>
          <Col sm={24} align="center">
            <Text type="secondary">
              Digite um valor para entrar como {translate("balance")} na conta
              da empresa.
            </Text>
          </Col>
        </Row>
        <Row>
          <Col xl={4} sm={10} offset={10} style={{ marginTop: 15 }}>
            <InputCurrency value={value} onChange={(val) => setValue(val)} />
          </Col>
        </Row>
      </Content>
      <Footer>
        <Button
          className="btn-save"
          type="primary"
          icon={<SaveOutlined />}
          onClick={onConfirm}
        >
          Fazer Pedido
        </Button>
      </Footer>
    </Layout>
  );
};

export default connect(mapStateToProps, {})(SolicitationCreditScreen);
