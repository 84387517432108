const BalanceState = {
    isProcessing: false,
    loaded: false,
    company: {
        available: 0,
        balance: 0,
        details: null,
        pendingCharge: 0,
        subBalances: []
    },
    benefit: null,
    balances: []
}

const BalanceReducer = (state = BalanceState, action) => {
    const { payload } = action;
    switch (action.type) {
        case "BALANCE_COMPANY":
            return Object.assign({}, state, {
                isProcessing: true,
            });
        case "BALANCE_COMPANY_SUCCESS":
            return Object.assign({}, state, {
                isProcessing: false,
                company: {
                    system: payload.system,
                    available: payload.available,
                    balance: payload.balance,
                    details: payload.details,
                    pendingCharge: payload.pendingCharge,
                    subBalances: payload.subBalances
                },
                loaded: true
            });
        case "BALANCE_COMPANY_FAIL":
            return Object.assign({}, state, {
                isProcessing: false,
                company: BalanceState.company
            });
        case "BALANCE_COMPANY_SYSTEM_SUCCESS":
            const find = state.balances.find(x => x.system === payload.system);
            if (find) {
                find.system = payload.system;
                find.available = payload.available;
                find.balance = payload.balance;
                find.details = payload.details;
                find.pendingCharge = payload.pendingCharge;
                find.subBalances = payload.subBalances;
            } else {
                state.balances.push({
                    system: payload.system,
                    available: payload.available,
                    balance: payload.balance,
                    details: payload.details,
                    pendingCharge: payload.pendingCharge,
                    subBalances: payload.subBalances
                });
            }
            return Object.assign({}, state, {
                isProcessing: false,
                balances: state.balances,
                loaded: true
            });
        default:
            return state;
    }
}

export default BalanceReducer;
