import React from "react";
import { Card, Spin, Row, Col, Form, Input, Select } from "antd";
import PropTypes from "prop-types";
import { AutoCompleteBank } from "components/autocomplete/autocomplete-bank";
import { BANK_ACCOUNT_TYPE_DATA } from "utils/static-data";

const FormBankAccount = (props) => {
    const { loading, title } = props;

    return (
        <Card title={title}>
            <Spin spinning={loading}>
                <Row gutter={24}>
                    <Col xl={12} lg={24}>
                        <Form.Item
                            label="Tipo de Conta"
                            name={["bankAccount", "type", "code"]}>
                            <Select placeholder="Selecione um tipo">
                                {BANK_ACCOUNT_TYPE_DATA.map(x => <Select.Option value={x.code}>{x.name}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={12} lg={24}>
                        <Form.Item
                            label="Banco"
                            name={["bankAccount", "bank", "code"]}
                        >
                            <AutoCompleteBank />
                        </Form.Item>
                    </Col>
                    <Col xl={12} lg={24}>
                        <Form.Item
                            label="Agência"
                            name={["bankAccount", "branch", "number"]}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col xl={12} lg={24}>
                        <Form.Item
                            label="Dígito Agência"
                            name={["bankAccount", "branch", "digit"]}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col xl={12} lg={24}>
                        <Form.Item
                            label="Conta"
                            name={["bankAccount", "account", "number"]}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col xl={12} lg={24}>
                        <Form.Item
                            label="Dígito Conta"
                            name={["bankAccount", "account", "digit"]}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <Form.Item
                            label="Nome do Titular"
                            name={["bankAccount", "holder", "name"]}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <Form.Item
                            label="CPF/CNPJ do Titular"
                            name={["bankAccount", "holder", "document"]}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Col>
                </Row>
            </Spin>
        </Card>
    )
};

FormBankAccount.defaultProps = {
    loading: false,
    title: "Dados Bancários"
};

FormBankAccount.propTypes = {
    loading: PropTypes.bool,
    title: PropTypes.string
};

export { FormBankAccount };