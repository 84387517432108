import axios from "axios";
import { handlerError } from "utils";
import { PrivateRoutes } from "config/routes/routes";

export const getConfig = async () => {
  try {
    const res = await axios.get("/api/config");
    const { data } = res;
    return {
      menu: createMenu(data.menu),
      system: data.system
    };
  } catch (ex) {
    handlerError(ex);
  }
};

function createMenu(menu) {
  return menu.map((x) => {
    const route = PrivateRoutes.filter(
      (r) => r.context === x.navigationContext
    );
    if (route.length > 0) {
      return {
        url: route[0].url,
        external: false,
        context: route[0].context,
        label: route[0].label
      };
    }
  });
}
