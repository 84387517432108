import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Layout,
  Modal,
  notification,
  Select,
  Space,
  Spin,
  Table,
  Tag
} from "antd";
import {
  FormatDate,
  FormatMoney,
  Header,
  InputCurrency,
  TableSearch
} from "components";
import { translate } from "config/language";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { advanceRedeem, getAllAdvances } from "services/advance-service";
import { getAllBenefit } from "services/benefit-service";
import {
  ADVANCE_STATUS,
  ADVANCE_STATUS_COLOR,
  ADVANCE_TYPE
} from "utils/static-data";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const DataFetch = {
  data: [],
  loading: false
};

const AdvanceRedeemScreen = (props) => {
  const { login } = props;
  const [data, setData] = React.useState(DataFetch);
  const [initialData, setInitialData] = React.useState(DataFetch);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openPartial, setOpenPartial] = React.useState(false);
  const [benefits, setBenefits] = React.useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const load = async () => {
    setData((x) => ({ ...x, loading: true }));
    const res = await getAllAdvances();
    setData({
      data: res.filter((d) => d.status == "finished"),
      loading: false
    });

    setInitialData({
      data: res.filter((d) => d.status == "finished"),
      loading: false
    });
  };
  const filterOverdue = () => {
    const overdueRows = data.data
      .filter((advance) => advance.isOverdue)
      .map((advance) => advance.id);
    setSelectedRowKeys((selectedRows) => [...selectedRows, ...overdueRows]);
  };

  const getType = (type) => {
    switch (type) {
      case "C":
        return "completo";
      case "F":
        return "de incentivo";
      case "B":
        return "de benefícios";
      default:
        return "";
    }
  };

  const onRedeem = async (type) => {
    if (type) {
      Modal.confirm({
        title: "Deseja continuar?",
        icon: <InfoCircleOutlined />,
        content: `Após a confirmação, o ${translate("balance")} ${getType(type)} dos colaboradores serão resgatados.`,
        onOk: async () => {
          redeem(type);
        },
        onCancel() {}
      });
    } else {
      setOpenPartial(true);
      setLoading(true);
      const res = await getAllBenefit(login.company.id);
      setBenefits(res.filter((x) => x.isBenefit));
      setLoading(false);
    }
  };

  const redeem = async (type, benefit = null, value = null) => {
    const advances = data.data.filter((d) => selectedRowKeys.includes(d.id));
    const advancesId = advances.map((a) => a.id);

    let users = [];
    const employees = Object.groupBy(advances, ({ employee }) => employee.id);
    for (let employee in employees) {
      const benefits = Object.groupBy(
        employees[employee],
        ({ benefit }) => benefit.id
      );
      for (let benefit in benefits) {
        const sumValue = benefits[benefit].reduce(
          (acc, o) => acc + o.amount,
          0
        );
        users.push({
          user: employee,
          benefit: benefit,
          advanceValue: sumValue
        });
      }
    }

    setSaving(true);
    try {
      let res = await advanceRedeem(users, advancesId, type);
      if (res.success) {
        notification.success({ message: `${translate("balance")} Resgatado!` });
        navigate("/solicitation");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSaving(false);
    }
  };

  const onSubmitPartialRedeem = async (values) => {
    const benefit = values.benefit === 0 ? null : values.benefit;
    const value = values.value;
    await redeem(null, benefit, value);
    setOpenPartial(false);
  };

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {}, [selectedRowKeys]);

  return (
    <Layout className="alymente-layout">
      <Header
        title={"Resgate de Adiantamentos"}
        subtitle="Selecione os adiantamentos para resgate."
      ></Header>
      <Content>
        <Table
          rowKey={"id"}
          scroll={{ y: 450 }}
          dataSource={data.data}
          loading={data.loading}
          title={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px"
              }}
            >
              <TableSearch
                data={data}
                initialData={initialData.data}
                filter={["amount", ["employee", "name"]]}
                onSearch={(e) => {
                  setData((prev) => ({ ...prev, data: e }));
                }}
              />
            </div>
          )}
          pagination={false}
          bordered
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: setSelectedRowKeys
          }}
        >
          <Table.Column
            width={110}
            title="Colaborador"
            dataIndex={["employee", "name"]}
            key="employeeName"
            render={(value) => {
              return value;
            }}
          />
          <Table.Column
            width={60}
            align="center"
            title="Data de Criação"
            dataIndex="createdAt"
            key="createdAt"
            render={(value) => <FormatDate value={value} />}
          />

          <Table.Column
            width={100}
            align="center"
            title="Tipo"
            dataIndex="type"
            key="type"
            render={(value) => ADVANCE_TYPE[value]}
          />
          <Table.Column
            width={50}
            align="right"
            title="Valor"
            dataIndex="amount"
            key="amount"
            render={(value) => <FormatMoney value={value} />}
          />
          <Table.Column
            width={60}
            align="center"
            title="Benefício Carga"
            dataIndex={["benefit", "name"]}
            key="benefitName"
          />
          <Table.Column
            width={60}
            align="center"
            title="Status"
            dataIndex="status"
            key="status"
            render={(value, record) =>
              record.isOverdue ? (
                <Tag color="error">VENCIDO</Tag>
              ) : (
                <Tag color={ADVANCE_STATUS_COLOR[value]}>
                  {ADVANCE_STATUS[value].toUpperCase()}
                </Tag>
              )
            }
          />
        </Table>
        <Modal
          title={`Resgate parcial de ${translate("balance")}`}
          open={openPartial}
          okText={"Resgatar"}
          cancelText={"Voltar"}
          onOk={() => form.submit()}
          okButtonProps={{
            loading: saving || loading
          }}
          confirmLoading={false}
          onCancel={() => {
            setOpenPartial(false);
          }}
        >
          {
            <Spin spinning={loading}>
              <Form
                form={form}
                layout="vertical"
                name="basic"
                onFinish={onSubmitPartialRedeem}
              >
                <Form.Item
                  label={translate("balance")}
                  hasFeedback
                  name="benefit"
                  rules={[{ required: true, message: "Selecione um item" }]}
                >
                  <Select style={{ width: "100%" }}>
                    <Select.Option value={0}>Flexível</Select.Option>
                    {benefits.map((item) => (
                      <Select.Option key={item.benefit} value={item.benefit}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={"Valor"}
                  hasFeedback
                  name="value"
                  rules={[{ required: true, message: "Informe um valor" }]}
                >
                  <InputCurrency autoComplete="off" />
                </Form.Item>
              </Form>
            </Spin>
          }
        </Modal>
      </Content>
      <Footer>
        <Space align="end">
          <Button
            disabled={saving}
            loading={saving}
            block
            type="primary"
            onClick={() => onRedeem("C")}
          >
            Resgatar {translate("balance")} Completo
          </Button>
          {
            <Button
              disabled={saving}
              loading={saving}
              block
              type="primary"
              onClick={filterOverdue}
            >
              Selecionar Vencidos
            </Button>
          }
        </Space>
      </Footer>
    </Layout>
  );
};

export default connect(mapStateToProps, {})(AdvanceRedeemScreen);
