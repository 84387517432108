import { styleTable } from 'theme/table';
import { styleCard } from 'theme/card';

export const themeColors = {
	darkColor: '#155c54',
	mediumColor: '#128275',
	lightColor: '#018275',
	highlightColor: '#35e8d3',
	softColor: '#f9f6ef',
	blueColor: '#0d6fec',
	grayColor: '#757575',
	successColor: '#52c41a',
	warningColor: '#faad14',
	errorColor: '#f5222d',
	disabledColor: '#d9d9d9',
	disabledTextColor: '#6f6f6f',
};

export const themeText = {
	bold: 700,
	medium: 500,
	extra: 800,
	fontFamily: 'Manrope',
};

export const themeLayout = {
	borderRadius: 6,
	boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
};

export const style = {
	'.alymente': {
		'.alymente-sider': {
			'.antLayoutSiderChildren': {
				display: 'flex',
				flexDirection: 'column',
				'.ant-menu-item': {
					'&:active': {
						color: '#fff !important',
					},
				},
			},
		},
		'.alymente-layout': {
			'.ant-layout-header': {
				height: 'auto',
				lineHeight: 'normal',
				paddingTop: '15px',
				paddingBottom: '15px',
				minHeight: '10vh',
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
			},
			'.ant-layout-content': {
				overflowX: 'auto',
				paddingLeft: '50px',
				paddingRight: '50px',
			},
			'.ant-layout-footer': {
				height: '10vh',
				display: 'flex',
				justifyContent: 'flex-end',
				alignItems: 'center',
			},
		},
		...styleTable(themeColors),
		...styleCard(themeColors),
		'.color-error': {
			color: themeColors.errorColor,
		},
		'.color-gray': {
			color: themeColors.grayColor,
		},
		'.color-warning': {
			color: themeColors.warningColor,
		},
	},
	'.ant-modal': {
		...styleTable(themeColors),
	},
};

export * from './table';
