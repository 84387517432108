import { FileExcelOutlined } from "@ant-design/icons";
import { Alert, Button, Checkbox, DatePicker, Form, Modal, Select } from "antd";
import React, { Fragment, useEffect } from "react";

export default function PeriodReport({
  loading,
  onReportGeneration,
  childrenCompaniesConfig,
  getAllDateConfig,
  buttonConfig,
  isVisible,
  benefits = [],
  childrens = [],
  screen,
  company,
  multiBalances = false
}) {
  const [date, setDate] = React.useState([]);
  const [getChildrenCompanies, setGetChildrenCompanies] = React.useState(false);
  const [getAllPeriod, setGetAllPeriod] = React.useState(false);
  const [openReportDateModal, setOpenReportDateModal] = React.useState(false);
  const [geral, setGeral] = React.useState(false);
  const [form] = Form.useForm();
  childrenCompaniesConfig ??= {
    visible: false
  };
  getAllDateConfig ??= {
    visible: false
  };
  isVisible ??= false;
  buttonConfig ??= {
    title: "Gerar relatório",
    icon: <FileExcelOutlined />
  };
  const reportGenerationHandler = async (values) => {
    let startPeriod = null;
    let endPeriod = null;
    if (!values.allPeriod) {
      [startPeriod, endPeriod] = values.dates;
      startPeriod = startPeriod.format("YYYY-MM-DD");
      endPeriod = endPeriod.format("YYYY-MM-DD");
    }

    await onReportGeneration(
      startPeriod,
      endPeriod,
      values.children,
      values.allPeriod,
      values.benefits,
      values.childrens,
      values.geral
    );
    setOpenReportDateModal(false);
  };
  loading ??= false;

  const disabledDate = (current, { from }) => {
    if (from) {
      return current.diff(from, "days") >= 31;
    }

    return false;
  };

  // childrens.filter(c => c.id != company.id).map(b => b.id)

  useEffect(() => {
    if (getChildrenCompanies) {
      form.setFieldsValue({
        childrens: childrens.filter((c) => c.id != company.id).map((b) => b.id)
      });
    } else {
      form.setFieldsValue({ childrens: [] });
    }
  }, [getChildrenCompanies]);

  return (
    isVisible && (
      <>
        <Button
          loading={loading}
          type="primary"
          icon={buttonConfig.icon}
          onClick={() => {
            form.resetFields();
            setGetChildrenCompanies(false);
            setGetAllPeriod(false);
            setOpenReportDateModal(true);
            setGeral(false);
          }}
        >
          {buttonConfig.title}
        </Button>
        <Modal
          open={openReportDateModal}
          title="Filtros"
          okText="Gerar Relatório"
          okButtonProps={{
            loading: loading,
            disabled: date?.length != 2 && !getAllPeriod
          }}
          cancelButtonProps={{ disabled: loading }}
          onCancel={() => setOpenReportDateModal(false)}
          onOk={() => form.submit()}
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={reportGenerationHandler}
          >
            {
              <Form.Item label="Selecione o período do relatório" name="dates">
                <DatePicker.RangePicker
                  {...(screen == "solicitation"
                    ? {
                        disabledDate: disabledDate
                      }
                    : {})}
                  disabled={loading || getAllPeriod}
                  style={{ width: "100%" }}
                  format="DD/MM/YYYY"
                  value={date}
                  onChange={(e) => setDate(e)}
                />
              </Form.Item>
            }

            {benefits.length > 0 && (
              <Form.Item
                label={`Selecione os ${screen == "refund" ? "tipos de despesa" : (screen == "expense" ? "tipos de gasto" : "benefícios")}`}
                name="benefits"
                valuePropName="checked"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder={`Selecione os ${screen == "refund" ? "tipos de despesa" : (screen == "expense" ? "tipos de gasto" : "benefícios")}`}
                  options={benefits.map((b) => {
                    if (b.name) {
                      // para relatorio de reembolso
                      return {
                        label: b.name,
                        value: screen == "refund" ? b.name: b.benefit
                      };
                    }

                    // para relatorio de gastos
                    return {
                      label: b,
                      value: b
                    };
                  })}
                />
              </Form.Item>
            )}
            {screen == "solicitation" && multiBalances && (
              <Form.Item name="geral" valuePropName="checked">
                <Checkbox
                  checked={geral}
                  style={{ alignSelf: "flex-end", marginTop: 10 }}
                  onChange={() => {
                    setGeral(!geral);
                  }}
                >
                  Considerar Pedido com Limite Geral?
                </Checkbox>
              </Form.Item>
            )}
            {childrenCompaniesConfig.visible && (
              <Fragment>
                <Form.Item name="children" valuePropName="checked">
                  <Checkbox
                    checked={getChildrenCompanies}
                    style={{ alignSelf: "flex-end", marginTop: 10 }}
                    onChange={() => {
                      setGetChildrenCompanies(!getChildrenCompanies);
                    }}
                  >
                    Considerar Pedidos das Filiais?
                  </Checkbox>
                </Form.Item>
                {getChildrenCompanies && (
                  <Form.Item name="childrens">
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Selecione as filiais"
                      options={childrens
                        .filter((c) => c.id != company.id)
                        .map((b) => {
                          return {
                            label: b.name,
                            value: b.id
                          };
                        })}
                    />
                  </Form.Item>
                )}
              </Fragment>
            )}

            {getAllDateConfig.visible && (
              <Form.Item name="allPeriod" valuePropName="checked">
                <Checkbox
                  checked={getAllPeriod}
                  style={{ alignSelf: "flex-end", marginTop: 10 }}
                  onChange={() => {
                    setGetAllPeriod(!getAllPeriod);
                  }}
                >
                  Considerar todo o período?
                </Checkbox>
              </Form.Item>
            )}
            {getChildrenCompanies && (
              <Alert
                style={{ marginTop: 10 }}
                message="Gerar relatório com filiais inclusas poderá demorar devido a alta carga de dados."
                banner
              />
            )}
            {getAllPeriod && (
              <Alert
                style={{ marginTop: 10 }}
                message="Gerar relatório levando em conta todo o período poderá demorar devido a alta carga de dados."
                banner
              />
            )}
          </Form>
        </Modal>
      </>
    )
  );
}
