import React from "react";
import { connect } from "react-redux";
import { useOutletContext } from 'react-router-dom';
import { Layout, Button, notification } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'

import CompanyShareholderScreen from "views/company/company-shareholder-screen";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const ActivationShareholderScreen = (props) => {
    const { login } = props;
    const { onNext, childRef } = useOutletContext();
    const [count, setCount] = React.useState(0);

    const onSubmit = () => {
        if (count === 0) {
            notification.error({ message: "Mínimo de um sócio cadastrado." })
        } else {
            onNext();
        }
    }

    const onPostLoad = (shareholders = []) => {
        setCount(shareholders.length)
    }

    return (
        <React.Fragment>
            <Content>
                <CompanyShareholderScreen compact={true} company={login.company.id} ref={childRef} onPostLoad={onPostLoad} />
            </Content>
            <Footer>
                <Button type="primary" icon={<ArrowRightOutlined />} onClick={onSubmit}>Próxima Etapa</Button>
            </Footer>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, {})(ActivationShareholderScreen);