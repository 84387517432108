import React from "react";
import { connect } from "react-redux";
import {
  Layout,
  Table,
  Button,
  Typography,
  Tooltip,
  Row,
  Col,
  Statistic,
  Card,
  Space,
  Modal,
  Tag
} from "antd";
import { SaveOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Header,
  CardCompanyBalance,
  InputCurrency,
  CardInfo,
  FormatDate,
  FormatMoney
} from "components";
import { getAllRefunds } from "services/refund-service";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "utils";
import { bindActionCreators } from "redux";
import * as balanceAction from "actions/balance-action";
//import { pendingSolicitation } from './solicitation';

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer,
  balance: state.balanceReducer
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...balanceAction
    },
    dispatch
  );

const DataFetch = {
  data: [],
  loading: false
};

const SolicitationRefundScreen = (props) => {
  const navigate = useNavigate();
  const { login, balance, getCompanyBalanceAction } = props;
  const [data, setData] = React.useState(DataFetch);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [refunds, setRefunds] = React.useState([]);
  const [totalValue, setTotalValue] = React.useState(0);
  const [fee, setFee] = React.useState(login.company.refundBillCost);

  const createRevision = () => {
    const createCheckout = () => {
      console.log(balance);
      const available = balance.company.available;
      let discount =
        available > totalValue + fee ? totalValue + fee : available;

      return [
        { label: "Valor", value: totalValue },
        { label: "Taxa", value: fee },
        { discount: true, label: "Desconto", value: discount },
        {
          total: true,
          discount: true,
          hasPayment: totalValue + fee - discount > 0,
          label: "Valor Total",
          value: fee + totalValue - discount
        },
        {
          total: true,
          discount: false,
          label: "Valor Total",
          hasPayment: fee + totalValue > 0,
          value: fee + totalValue
        }
      ];
    };

    const getLines = () => {
      return selectedRowKeys.map((row) => {
        return {
          id: row
        };
      });
    };

    return {
      request: {
        url: "/api/solicitation/refund",
        method: "post"
      },
      body: {
        solicitation: {
          lines: getLines()
        },
        data: {}
      },
      checkout: createCheckout(),
      type: "refund",
      successMessage:
        "Assim que você pagar esse boleto os colaboradores afetados vão receber o valor do reembolso."
    };
  };

  const onConfirm = () => {
    if (selectedRowKeys.length === 0) {
      Modal.error({
        title: "Nenhum registro selecionado",
        content: "Por favor, selecione pelo menos um reembolso para continuar."
      });
      return;
    }

    Modal.confirm({
      title: "Deseja continuar?",
      icon: <InfoCircleOutlined />,
      content: "Após a confirmação, você irá fazer a revisão do seu pedido.",
      onOk() {
        navigate("/solicitation/charge/revision", {
          state: { data: createRevision() }
        });
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };

  const load = async () => {
    setData((x) => ({ ...x, loading: true }));
    const res = await getAllRefunds();
    await getCompanyBalanceAction();
    setRefunds(
      res.filter((x) => x.status.toString().toLowerCase() === "aprovado")
    );
    setData({ data: res, loading: false });
  };

  const changeRowTable = (rows) => {
    setSelectedRowKeys(rows);
    let total = 0,
      fee = 0,
      bankOption = 0;
    for (let row of rows) {
      let refund = refunds.filter((r) => r.id == row)[0];
      total += refund.valueNF ? refund.valueNF : 0;
      if (refund.option.code == "B") bankOption++;
    }
    setFee(
      bankOption * login.company.refundCost + login.company.refundBillCost
    );
    setTotalValue(total);
  };

  React.useEffect(() => {
    load();
  }, []);

  return (
    <Layout className="alymente-layout">
      <Header
        title="Selecione os reembolsos"
        subtitle="Selecione os reembolsos aprovados que deseja pagar."
      >
        <Row gutter={12} align="middle">
          <Col xl={4}>
            <CardInfo
              title="Selecionados"
              value={`${selectedRowKeys.length}/${refunds.length}`}
            />
          </Col>
          <Col xl={4}>
            <CardInfo
              title="Valor dos Reembolsos"
              value={formatMoney(totalValue)}
            />
          </Col>
          <Col xl={4}>
            <CardInfo title="Taxa" value={formatMoney(fee)} />
          </Col>
          <Col xl={4}>
            <CardInfo
              title="Valor Total"
              value={formatMoney(fee + totalValue)}
            />
          </Col>
        </Row>
      </Header>
      <Content>
        <Table
          rowKey={"id"}
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: changeRowTable
          }}
          scroll={{ y: "50vh" }}
          dataSource={refunds}
          loading={data.loading}
          pagination={false}
          bordered
        >
          <Table.Column title="Nome" dataIndex={"employee"} />
          <Table.Column
            width={130}
            title="Data"
            dataIndex="createdAt"
            key="createdAt"
            render={(value) => <FormatDate value={value} />}
          />
          <Table.Column
            width={130}
            title="Valor"
            dataIndex="value"
            key="value"
            render={(value) => <FormatMoney value={value} />}
          />
          <Table.Column
            width={130}
            title="Valor a Pagar"
            dataIndex="valueNF"
            key="valueNF"
            render={(value) => <FormatMoney value={value} />}
          />
          <Table.Column width={180} title="Benefício" dataIndex="benefit" />
          <Table.Column
            width={120}
            align="center"
            title="Opção"
            dataIndex={["option", "name"]}
            key="option.name"
          />
          <Table.Column
            width={120}
            title="Status"
            dataIndex="status"
            key="status"
            render={(value, row) => (
              <Tag color={row.category}>{value.toUpperCase()}</Tag>
            )}
          />
        </Table>
      </Content>
      <Footer>
        <Button
          className="btn-save"
          type="primary"
          icon={<SaveOutlined />}
          onClick={onConfirm}
        >
          Fazer Pedido
        </Button>
      </Footer>
    </Layout>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolicitationRefundScreen);
