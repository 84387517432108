import React from "react";
import { useSearchParams } from "react-router-dom";

export default function usePage(load, localLoad){

  const [pageParams, setPageParams] = useSearchParams();
  const [page, setPage] = React.useState(pageParams.get("page") ?? 1);
  const [firstRender, setFirstRender] = React.useState(true);
  React.useEffect(() => {
    setPageParams((p) => {
      p.set("page", page);
      return p;
    });
  }, [page]);
  async function  search(filter){
    if(firstRender){
      setFirstRender(false)
    }else {

      setPage(1)
    }
    await load(filter)
  }
  async function initialLoad(filter){
    setPage(1)
    await load(filter)
  }
   function  localSearch(e,filtered){
    if(firstRender){
      setFirstRender(false)
    }else{
      setPage(1)
    }
    if(filtered){
      localLoad(e,filtered)
    }else{
    localLoad(e)
    }
   }
  function changePage(page){
    setPage(page)
  }
  return {page, search, changePage, localSearch, initialLoad}
}