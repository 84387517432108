export const styleTable = (themeColors) => ({
   '.ant-table': {
      '.ant-table-container': {
         borderLeft: '0 !important',
         borderTop: '0 !important',
         '.ant-table-caption': {
            padding: '10px 0',
            textAlign: 'right',
         },
      },
      '.ant-table-title': {
         border: '0 !important',
         paddingLeft: '0 !important',
         paddingRight: '0 !important',
      },
      '.ant-table-thead': {
         '.ant-table-cell': {
            background: themeColors.mediumColor,
         },
      },
      '.ant-table-filter-trigger.active': {
         color: 'rgba(0, 0, 0, 0.29)',
      },
   },
});
