import React from "react";
import { connect } from "react-redux";
import { useOutletContext } from 'react-router-dom';
import { Layout, Button } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'

import CompanyDetailsScreen from "views/company/company-details-screen";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const ActivationCompanyScreen = (props) => {
    const { login } = props;
    const { onNext, childRef } = useOutletContext();
    const [loading, setLoading] = React.useState(false);

    const onSubmit = () => {
        setLoading(true);
        childRef.current.submit();
    }

    const onAfterSubmit = (submit = false) => {
        if (submit) {
            onNext();
        } else {
            setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <Content>
                <CompanyDetailsScreen compact={true} company={login.company.id} ref={childRef} onAfterSubmit={onAfterSubmit} />
            </Content>
            <Footer>
                <Button loading={loading} type="primary" icon={<ArrowRightOutlined />} onClick={onSubmit}>Próxima Etapa</Button>
            </Footer>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, {})(ActivationCompanyScreen);