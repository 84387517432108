import axios from "axios";
import { handlerError } from "utils";
import { notification } from "antd";

export const getAllCompanyCatalog = async (company) => {
  try {
    const res = await axios.get(`/api/company/catalog`, {
      params: { company: company }
    });
    return res.data.catalogs;
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getAllCompanyAdvantages = async (company) => {
  try {
    const res = await axios.get(`/api/company/catalog/advantages/${company}`);
    return res.data.catalogs;
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const saveCompanyCatalog = async (
  company,
  lines,
  advantages = false
) => {
  try {
    const req = {
      catalogs: {
        lines: lines
          .filter((x) => (advantages ? x.enabled && !x.default : !x.enabled))
          .map((x) => ({ catalog: x.catalog, enabled: x.enabled }))
      }
    };
    await axios.put(`/api/company/catalog`, req, {
      params: { company: company }
    });
    notification.success({
      message: "Salvo com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
  }
};
