import React from "react";
import { connect } from "react-redux";
import { Layout, Table, Switch, Button } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { Header, AutoCompleteCompany } from "components";
import { getAllParentManagers, saveParentManagers } from "services/company-service";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const DataFetch = {
    data: [],
    loading: false
}

const UserOtherManagerScreen = (props) => {
    const { login } = props;
    const [company, setCompany] = React.useState(login.company.id);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(DataFetch)

    React.useEffect(() => {
        load();
    }, [company])

    const onChangeCompany = (x) => {
        setCompany(x);
    }

    const load = async () => {
        if (company) {
            setData((x) => ({ ...x, loading: true }));
            const resData = await getAllParentManagers(company);
            setData({ data: resData, loading: false });

        }
    }

    const onEdit = (value, index, field) => {
        const newData = [...data.data];
        newData[index][field] = value;
        setData((x) => ({ ...x, data: newData }));
    }

    const onSave = async () => {
        setLoading(true);
        const list = data.data.filter(x => x.enabled).map(x => ({ company: x.company.id, user: x.user.id }));
        await saveParentManagers(company, list);
        setLoading(false);
    }

    return (
        <Layout className="alymente-layout">
            <Header
                title="Outros Gerentes de Filiais"
                subtitle="Você pode permitir gerentes de filiais para gerenciar a matriz."
                middle={
                    login.company.master ? <AutoCompleteCompany value={company} onChange={onChangeCompany} /> : undefined
                }>
            </Header>
            <Content>
                <Table
                    scroll={{ y: 400 }} dataSource={data.data} loading={data.loading} pagination={false} bordered>
                    <Table.Column title="Empresa" dataIndex={["user", "company", "name"]} key="user.company.name" />
                    <Table.Column title="Nome" dataIndex={["user", "name"]} key="user.name" />
                    <Table.Column title="Email" dataIndex={["user", "email"]} key="user.email" />
                    <Table.Column align="center" title="Habilitado?" dataIndex="enabled" key="enabled" width={200}
                        render={(value, record, index) => <Switch checked={value} onChange={(x) => onEdit(x, index, "enabled")} />} />
                </Table>
            </Content>
            <Footer>
                <Button loading={loading} className="btn-save" type="primary" icon={<SaveOutlined />} onClick={onSave}>Salvar</Button>
            </Footer>
        </Layout>
    )
}

export default connect(mapStateToProps, {})(UserOtherManagerScreen);

