import React, { useEffect } from 'react';

const Wootric = ({ userId, userEmail }) => {
   useEffect(() => {
      const timestampInMilliseconds = new Date().getTime();
      const timestampInSeconds = Math.floor(timestampInMilliseconds / 1000);
      const setupScript = document.createElement('script');
      setupScript.type = 'text/javascript';
      setupScript.id = 'wootric-settings';
      setupScript.async = true;
      setupScript.innerHTML = `
      window.wootricSettings = {
        email: "${userEmail}",
        external_id: "${userId}",
        created_at: "${timestampInSeconds}",
        account_token: "NPS-e73fd5eb"
      };
    `;
      if (document.getElementById('wootric-settings') == null) {
         document.body.appendChild(setupScript);
      }
      const beacon = document.createElement('script');
      beacon.type = 'text/javascript';
      beacon.id = 'wootric-beacon';
      beacon.async = true;
      beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
      beacon.onload = function () {
         window.wootric('run');
      };
      if (document.getElementById('wootric-beacon') == null) {
         document.body.appendChild(beacon);
      }
      return () => {
         if (!document.getElementById('wootric-beacon') || !document.getElementById('wootric-settings')) return;
         window.WootricSurvey?.stop();
         document.body.removeChild(document.getElementById('wootric-beacon'));
         document.body.removeChild(document.getElementById('wootric-settings'));
      };
   }, [userEmail, userId]);
   return <div />;
};

export default Wootric;
