import React from "react";
import { connect } from "react-redux";
import { Layout, Typography, Row, Col, Button, Table, Modal, Tooltip, Space, Radio, Input } from 'antd';
import { SaveOutlined, LoadingOutlined, CloseCircleOutlined, CheckCircleOutlined, MinusCircleOutlined, UnorderedListOutlined, FileExcelOutlined } from '@ant-design/icons';
import { getAllEmployee } from "services/employee-service";
import { getCompany } from "services/company-service";
import { createOrder } from "services/card-service";
import { Header, CardInfo, TableSearch } from "components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatMoney, saveFileResume, clearFormatedCPF } from "utils";
import { importFile } from "utils/import-file";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const DataFetch = {
    data: [],
    loading: false,
    company: null
}

const CardOrderEmployeeScreen = (props) => {
    const { login } = props;
    const navigate = useNavigate();
    const [data, setData] = React.useState(DataFetch)
    const [searchParams, setSearchParams] = useSearchParams();
    const [saving, setSaving] = React.useState(false);
    const [solicitationBtn, setSolicitationBtn] = React.useState(false);
    const [confirmation, setConfirmation] = React.useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
    const [cardTotalCost, setCardTotalCost] = React.useState(0);
    const [cardTotal, setCardTotal] = React.useState(0);
    const [cardTotalDelivery, setCardTotalDelivery] = React.useState(0);
    const [countSave, setCountSave] = React.useState(0);
    const [selectAll, setSelectAll] = React.useState(false);
    const [importData, setImportData] = React.useState({ show: false, text: null, type: "email" });

    const load = async () => {
        setData((x) => ({ ...x, loading: true }));
        const res = login.company.master ? await getAllEmployee(searchParams.get("company"), true) : await getAllEmployee(login.company.id, true)
        const res_company = await getCompany(login.company.master ? searchParams.get("company") : null)
        console.log(res)
        setData({
            data: res.employees.length > 0 ? res.employees : res.businessUnits.flatMap(x => x.employees),
            company: res_company, loading: false
        });
    }

    const count = () => {
        if (data.company) {
            setCardTotalCost(selectedRowKeys.length * data.company.cardCost)
            setCardTotalDelivery(selectedRowKeys.length * data.company.deliveryCost)
            setCardTotal(selectedRowKeys.length * (data.company.deliveryCost + data.company.cardCost))
        }
    }

    const timeout = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const onSave = async () => {
        setSaving(true)
        setConfirmation(false)

        const saveList = []
        for (let item of data.data) {
            if (selectedRowKeys.includes(item.id)) {
                saveList.push(item)
                item.status = 'saving'
                item.message = `Gerando Envio...`
            } else {
                item.status = 'notChanges'
                item.message = `Colaborador não selecionado.`
            }
        }
        setData((x) => ({ ...x, data: data.data }))

        for (let i = 0; i < saveList.length; i++) {
            save(saveList[i])

            if (i % 5 === 0) {
                await timeout(2500)
            }
        }
    }

    const save = async (item) => {
        let res = await createOrder(item.id)
        const element = data.data.filter(d => d.id === item.id)[0]
        if (res && res.success) {
            element.status = 'success'
            element.message = 'Envio gerado com sucesso!'
            element.statusCard = res.data.cardStatus.name
        } else {
            element.status = 'error'
            element.message = res.errorMessage
        }
        setData((x) => ({ ...x, data: [...data.data] }))
        setCountSave(count => count + 1)
    }

    const getIcon = (status, message) => {
        let icon;
        if (status == "saving") {
            icon = <LoadingOutlined className="color-gray" />
        } else if (status == "error") {
            icon = <CloseCircleOutlined className="color-error" />
        } else if (status == "notChanges") {
            icon = <MinusCircleOutlined className="color-gray" />
        } else if (status == "success") {
            icon = <CheckCircleOutlined />
        }
        return <span style={{ marginLeft: 10 }}>
            <Tooltip title={message}>
                {!!icon && icon}
            </Tooltip>
        </span>
    }

    const onImport = () => {
        const { content, users } = importFile.doImport(data.data, importData.text);
        if (content.rows.length > 0) {
            const usersSelected = [];
            content.rows.forEach(x => {
                if (importData.type == 'cpf') {
                   x.key = clearFormatedCPF(x.key);
                   if (x.key.length === 10) {
                      x.key = '0' + x.key;
                   }
                }
                const user = users.find(u => u[importData.type] === x.key);
                if (user) {
                    usersSelected.push(user);
                }
            })
            setSelectedRowKeys(usersSelected.map(x => x.id))
            setImportData({ show: false });
        }
    }
    const onOpenImport = () => {
        setImportData({ show: true, text: importFile.createExample(data.data, []), type: "email" });
    }

    const getStatusName = (status) => {
        if (status == 'error') {
            return 'Erro'
        }
        else if (status == 'success') {
            return 'Sucesso'
        }
        if (status == 'saving') {
            return 'Salvando'
        }
        if (status == 'notChanges') {
            return 'Sem alterações'
        }
        return ''
    }

    const prepareFileErrors = () => {
        let ws_data = [];
        data.data.forEach(d => {
            let tmp = {
                "Email": d.email,
                "CPF": clearFormatedCPF(d.cpf),
                "Status": getStatusName(d.status),
                "Erros": d.message
            };
            ws_data.push(tmp)
        })
        return ws_data;
    }

    const exportErrors = () => {
        saveFileResume(prepareFileErrors(), "Erros", "Erros")
    }


    React.useEffect(() => {
        load();
    }, [])

    React.useEffect(() => {
        count()
    }, [selectedRowKeys])

    React.useEffect(() => {
        if (countSave > 0 && countSave === selectedRowKeys.length) {
            setSaving(false)
            setSolicitationBtn(true)
            setSelectedRowKeys([])
        }
    }, [countSave])

    React.useEffect(() => {
        selectAll ? setSelectedRowKeys(data.data.map(d => d.id)) : setSelectedRowKeys([])
    }, [selectAll])

    return <Layout className="alymente-layout">
        <Header
            title="Envio de Cartão - Entrega Individual"
            subtitle="Selecione os colaboradores para os quais você quer entregar o cartão na casa."
        >
            <Row gutter={12} align="middle">
                <Col xl={4}>
                    <CardInfo title="Número de cartões" value={selectedRowKeys.length} />
                </Col>
                <Col xl={4}>
                    <CardInfo title="Custo dos Cartões" value={formatMoney(cardTotalCost)} />
                </Col>
                <Col xl={4}>
                    <CardInfo title="Custo da Entrega" value={formatMoney(cardTotalDelivery)} />
                </Col>
                <Col xl={4}>
                    <CardInfo title="Valor Total do Envio" value={formatMoney(cardTotal)} />
                </Col>
                <Col xl={4}>
                    <Button block icon={<FileExcelOutlined />} type="primary" onClick={onOpenImport}>Importar</Button>
                </Col>
            </Row>
        </Header>
        <Content>
            <Row style={{ marginBottom: 10 }}>
                <Col lg={6} sm={12} xs={24} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Button style={{ marginRight: 10 }} size="small" type={"primary"} onClick={() => setSelectAll(!selectAll)}>{selectAll ? "Limpar Todos" : "Selecionar Todos"}</Button>
                    <Typography.Text>Selecionados: {selectedRowKeys.length}/{data.data.length}</Typography.Text>
                </Col>
                <Col lg={18} sm={12} xs={24}>
                    <TableSearch
                        data={data.data}
                        filter={['name', 'address', 'cep']}
                        onSearch={(e) => setData((prev) => ({ ...prev, data: e }))}
                    /></Col>
            </Row>
            <Table rowKey={"id"} scroll={{ y: 450 }} dataSource={data.data} loading={data.loading} pagination={true} bordered
                rowSelection={{
                    selectedRowKeys: selectedRowKeys,
                    onChange: setSelectedRowKeys,
                    getCheckboxProps: (d) => ({
                        disabled: saving
                    })
                }}
            >
                <Table.Column width={300} title="Nome" dataIndex="name" key="name"
                    render={(value, record, index) => record.status ? <div style={{ display: 'flex', justifyContent: 'space-between' }}>{value}{getIcon(record.status, record.message)}</div> : value}
                />
                <Table.Column width={80} align={"center"} title="Cartão" dataIndex="statusCard" key="statusCard" render={(value, record, index) => value} />
                <Table.Column width={300} title="Endereço" dataIndex="address" key="address" />
                <Table.Column width={80} align={"center"} title="CEP" dataIndex="cep" key="cep" />
            </Table>
            <Row style={data.data.length > 0 ? { marginTop: -40 } : { marginTop: 20 }}>
                <Col sm={8} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 10 }}>
                    <Button disabled={data.data.filter(d => d.status).length == 0} style={{ marginRight: 10 }} size="small" type={"primary"} onClick={() => exportErrors()}>Exportar Erros</Button>
                    <Typography.Text style={{ marginRight: 15 }}>Sucesso: {data.data.filter(d => d.status == "success").length}</Typography.Text>
                    <Typography.Text>Erro: {data.data.filter(d => d.status == "error").length}</Typography.Text>
                </Col>
            </Row>
        </Content>
        <Footer>
            <Space align="end">
                <Button disabled={saving || selectedRowKeys.length == 0} loading={saving} className="btn-save" type="primary" icon={<SaveOutlined />} onClick={() => setConfirmation(true)}>Salvar Envios</Button>
                {
                    solicitationBtn &&
                    <Button className="btn-save" type="primary" icon={<UnorderedListOutlined />} onClick={() => {
                        navigate("/card/shipments")
                    }}>Continuar</Button>
                }
            </Space>
        </Footer>
        <Modal
            open={confirmation}
            title="Gerar Envios"
            onOk={onSave}
            onCancel={() => setConfirmation(false)}
        >
            <div>
                <Row type="flex" justify="center">
                    <Typography.Text>Confirma o envio de cartão para {selectedRowKeys.length} colaboradores?</Typography.Text>
                </Row>
                <Row type="flex" justify="center">
                    <Typography.Text type="secondary" align="center">Os envios ficarão com o status Novo até estarem associados a um pedido.</Typography.Text>
                </Row>
            </div>
        </Modal>
        {importData.show &&
            <Modal width={800} title={"Importação"} open={importData.show} onOk={onImport} onCancel={() => setImportData({ show: false })}>
                <Row gutter={[12, 12]}>
                    <Col span={24}>
                        <Radio.Group onChange={(e) => setImportData((prev) => ({ ...prev, text: importFile.createExample(data.data, [], e.target.value), type: e.target.value }))}
                            defaultValue="email" value={importData.type} buttonStyle="solid" style={{ width: "50%" }}>
                            <Radio.Button value="email" style={{ width: "50%" }}>Email</Radio.Button>
                            <Radio.Button value="cpf" style={{ width: "50%" }}>CPF</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col span={24}>
                        <Typography.Text>Copie o conteúdo de sua planilha</Typography.Text>
                    </Col>
                    <Col span={24}>
                        <Input.TextArea rows={10} value={importData.text}
                            onChange={(e) => setImportData((prev) => ({ ...prev, text: e.target.value }))}></Input.TextArea>
                    </Col>
                </Row>
            </Modal>
        }
    </Layout>
}

export default connect(mapStateToProps, {})(CardOrderEmployeeScreen);
