import { Button, Modal } from 'antd';
import { CardInfo } from 'components/card/card-info';
import SubBalance from 'components/subbalance/sub-balance';
import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
const mapStateToProps = (state) => ({
   login: state.authReducer.loginReducer,
   balance: state.balanceReducer,
});
const BalanceCard = ({ loading, title, value, balance }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const hasSubBalance = useMemo(() => balance.company.subBalances.length > 1, [balance.company]);

   return (
      <div style={{ maxWidth: '100%' }}>
         <CardInfo loading={loading} title={title} value={value}>
            {hasSubBalance && !loading && (
               <Button disabled={loading}  type="text" onClick={() => setIsModalOpen(true)}>
                  Detalhes
               </Button>
            )}
         </CardInfo>
         {hasSubBalance && (
            <Modal title="Saldos" width={'60%'} onCancel={() => setIsModalOpen(false)} open={isModalOpen} footer={null}>
               <SubBalance />
            </Modal>
         )}
      </div>
   );
};
export default connect(mapStateToProps, {})(BalanceCard);
