import React from 'react';
import { connect } from 'react-redux';
import { Layout, Button, Typography, Row, Col, Spin, notification, Modal, Select, Space, Form } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Header, UserBalance, InputCurrency } from 'components';
import { useParams, useLocation } from 'react-router-dom';
import { getAllBenefit } from 'services/benefit-service';
import { translate } from 'config/language';
import { formatMoney } from 'utils';
import { getVehicle, getVehicleBalance, vehicleRedeem } from 'services/vehicle-service';

const { Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
   login: state.authReducer.loginReducer,
});

const DataFetch = {
   balance: null,
   loadingBalance: false,
};

const VehicleRedeemScreen = (props) => {
  const { login } = props;
  const [form] = Form.useForm();
  const { id } = useParams();
  const location = useLocation();

  const [benefits, setBenefits] = React.useState([]);
   const [openPartial, setOpenPartial] = React.useState(false);
   const { benefit: toBenefit } = location.state || props;

   const [data, setData] = React.useState(DataFetch);
   const [saving, setSaving] = React.useState(false);

   const loadBenefits = async () => {
      if (login.company.benefitAccount) {
         const res = await getAllBenefit(login.company.id, true);
         const benefits = res.filter((x) => x.account != null && x.enabled);
         setBenefits(benefits);
      }
   };

   const load = async () => {
      setData((x) => ({ ...x, loadingBalance: true }));
      await loadBenefits();
      const balance = await getVehicleBalance(id);
      const balanceData = {
        details: balance.filter(b => b.balance > 0)
      }
      setData((x) => ({ ...x, balance: balanceData }));
      const vehicleData = await getVehicle(id);
      setData((x) => ({ ...x, vehicle: vehicleData, loadingBalance: false }));
      form.resetFields();
   };

   React.useEffect(() => {
      load();
   }, []);

   const getType = (type) => {
      switch (type) {
         case 'C':
            return 'completo';
         case 'F':
            return 'de incentivo';
         case 'B':
            return 'de benefícios';
         default:
            return '';
      }
   };

   const onRedeem = (type) => {
      if (type) {
         Modal.confirm({
            title: 'Deseja continuar?',
            icon: <InfoCircleOutlined />,
            content: `Após a confirmação, o ${translate('balance')} ${getType(type)} do veículo será resgatado.`,
            onOk: async () => {
               redeem(type);
            },
            onCancel() {},
         });
      } else setOpenPartial(true);
   };

   const redeem = async (type, benefit = null, value = null) => {
      setSaving(true);
      try {
         let res = await vehicleRedeem({
          vehicle: id,
          vehicleName: data.vehicle.name,
          vehicleLicensePlate: data.vehicle.licensePlate,
          vehicleCode: data.vehicle.code,
          vehicleAccount: data.vehicle.account,
          vehicleEnabled: data.vehicle.enabled,
          vehicleCompany: login.company.master ? data.vehicle.company.id : login.company.id,
         }, type, toBenefit, benefit, value);
         if (res.success) notification.success({ message: `${translate('balance')} Resgatado! No próximo pedido você pode usar ele como crédito.` });
      } catch (e) {
         console.log(e);
      } finally {
         setSaving(false);
         load();
      }
   };

   const onSubmitPartialRedeem = async (values) => {
      const benefit = values.benefit === 0 ? null : values.benefit;
      const value = values.value;
      const balance = data.balance.details.find((item) => item.benefit === benefit);
      if (value > balance.balance) {
         notification.error({ message: `${translate('balance')} maior que o disponível.` });
      } else {
         await redeem(null, benefit, value);
         setOpenPartial(false);
      }
   };

   return (
    <Layout className="alymente-layout">
       <Header title={`Resgate de ${translate('balance')}` + (data.vehicle?.licensePlate ? ' - ' + data.vehicle?.licensePlate : '')}>
          <Row></Row>
       </Header>
       <Content>
          <Row style={{ marginBottom: 40 }} justify="center">
             <UserBalance loading={data.loadingBalance} balance={data.balance} benefit={toBenefit} showTotal={false} />
          </Row>
          {data.loadingBalance ? (
             <Row justify="center">
                <Spin spinning={data.loadingBalance}></Spin>
             </Row>
          ) : (
             <React.Fragment>
                <Row style={{ marginBottom: 40 }} justify="center">
                   {toBenefit && benefits.length > 0 && (
                      <Col sm={24} style={{ textAlign: 'center' }}>
                         <Text>
                            Só é possível resgatar o {translate('balance')} {benefits.filter((b) => b.benefit === toBenefit)[0].name}.
                         </Text>
                      </Col>
                   )}
                   <Col sm={24} style={{ textAlign: 'center' }}>
                      <Text>Esse {translate('balance')} fica como um crédito para o seu próximo pedido.</Text>
                   </Col>
                </Row>
                {!toBenefit && (
                   <Row justify="center" style={{ marginBottom: 10 }}>
                      <Col xl={7} lg={9} md={16} sm={24} style={{ justifyContent: 'center' }}>
                         <Button disabled={saving} loading={saving} block type="primary" onClick={() => onRedeem('C')}>
                            Resgatar {translate('balance')} Completo
                         </Button>
                      </Col>
                   </Row>
                )}
                {(!toBenefit || benefits.length > 0) && (
                   <Row justify="center">
                      <Col xl={7} lg={9} md={16} sm={24} style={{ justifyContent: 'center' }}>
                         <Button disabled={saving} loading={saving} block type="primary" onClick={() => onRedeem(null)}>
                            Resgatar {translate('balance')} Parcial
                         </Button>
                      </Col>
                   </Row>
                )}
             </React.Fragment>
          )}
          {openPartial && data.balance && data.balance.details.length > 0 && (
             <Modal
                title={`Resgate parcial de ${translate('balance')}`}
                open={openPartial}
                okText={'Resgatar'}
                cancelText={'Voltar'}
                onOk={() => form.submit()}
                okButtonProps={{
                   loading: saving,
                }}
                confirmLoading={false}
                onCancel={() => {
                   setOpenPartial(false);
                }}
             >
                <Form form={form} layout="vertical" name="basic" onFinish={onSubmitPartialRedeem}>
                   <Form.Item label={translate('balance')} hasFeedback name="benefit" rules={[{ required: true, message: 'Selecione um item' }]}>
                      <Select style={{ width: '100%' }}>
                         {data.balance.details.map((item) => (
                            <Select.Option value={item.benefit === null ? 0 : item.benefit}>{`${
                               item.benefit === null ? 'Flexível' : item.nameBenefit
                            } - Total ${formatMoney(item.balance)}`}</Select.Option>
                         ))}
                      </Select>
                   </Form.Item>
                   <Form.Item label={'Valor'} hasFeedback name="value" rules={[{ required: true, message: 'Informe um valor' }]}>
                      <InputCurrency autoComplete="off" />
                   </Form.Item>
                </Form>
             </Modal>
          )}
       </Content>
    </Layout>
 );

}

export default connect(mapStateToProps, {})(VehicleRedeemScreen);