import React from "react";
import { connect } from "react-redux";
import {
  Layout,
  Button,
  Space,
  Row,
  Col,
  Modal,
  Form,
  Input,
  Spin,
  Typography,
  notification
} from "antd";
import { useNavigate } from "react-router-dom";
import { Header, AutoCompleteCompany, TableForm } from "components";
import { SaveOutlined } from "@ant-design/icons";
import {
  generateTemplateExcel,
  validateCpf,
  formatDate,
  formatCpf,
  replacePhone,
  emptyValue,
  saveFileResume
} from "utils";
import { getAllEmployee } from "services/employee-service";
import { saveUserBatch, savePasswordUser } from "services/user-service";
import { getCompany } from "services/company-service";
import moment from "moment";
import CheckboxMenu from "components/button/checkbox-menu";
import pLimit from "p-limit";
import useHttpGeo from "hooks/use-http-geo";
import { AccountSystem } from "utils/account-system";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const DataFetch = {
  data: [],
  dataTable: [],
  loading: false,
  header: [
    {
      title: "Nome",
      key: "name"
    },
    {
      title: "Email",
      key: "email"
    },
    {
      title: "Cpf",
      key: "cpf"
    },
    {
      title: "Nascimento",
      key: "birthday"
    },

    {
      title: "Mãe",
      key: "mother"
    },
    {
      title: "Telefone",
      key: "phone"
    },
    {
      title: "sexo",
      key: "sex"
    },
    {
      title: "Rua",
      key: "address"
    },
    {
      title: "Número",
      key: "number"
    },
    {
      title: "Complemento",
      key: "complement"
    },
    {
      title: "Bairro",
      key: "neighborhood"
    },
    {
      title: "CEP",
      key: "zipcode"
    },
    {
      title: "Cidade",
      key: "city"
    },
    {
      title: "Estado",
      key: "state"
    }
  ],
  fields: [
    "name",
    "email",
    "cpf",
    "birthday",
    "mother",
    "phone",
    "sex",
    "address",
    "number",
    "complement",
    "neighborhood",
    "zipcode",
    "city",
    "state"
  ],
  filter: {
    cpf: (value) => (value ? validateCpf(value.trim()) : ""),
    birthday: (value) => {
      return value ? moment(value).format("DD/MM/YYYY") : "";
    },
    sex: (value) => {
      if (!value) {
        return "";
      }
      let val = value
        .replace(/\n/g, "")
        .replace(/\t/g, " ")
        .replace(/\t/g, "")
        .replace(/ /g, "");
      return val === "M" || val === "F" ? val : "";
    }
  }
};

const UserBatchScreen = (props) => {
  const { login } = props;
  const navigate = useNavigate();
  const [data, setData] = React.useState(DataFetch);
  const [company, setCompany] = React.useState(null);
  const [companyData, setCompanyData] = React.useState(null);
  const [importShow, setImportShow] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [visibleFields, setVisibleFields] = React.useState([
    ...DataFetch.fields.filter(
      (v) =>
        v != "cpf" &&
        v != "number" &&
        v != "complement" &&
        v != "neighborhood" &&
        v != "zipcode" &&
        v != "city" &&
        v != "state"
    ),
    "card",
    "enabled"
  ]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [passwordShow, setPasswordShow] = React.useState(false);
  const [validateFields, setValidateFields] = React.useState(false);
  const [defaultColumns, setDefaultColumns] = React.useState([
    {
      title: "Nome",
      dataIndex: "name",
      width: 300,
      editable: true,
      required: true
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 250,
      editable: true,
      required: true
    },
    {
      title: "CPF",
      dataIndex: "cpf",
      width: 165,
      editable: true,
      required: true
    },
    {
      title: "Nascimento",
      dataIndex: "birthday",
      width: 140,
      editable: true,
      required: true,
      render: (value) => {
        return value ? formatDate(value, "DD/MM/YYYY") : "";
      }
    },
    {
      title: "Mãe",
      dataIndex: "mother",
      width: 170,
      editable: true
    },
    {
      title: "Telefone",
      dataIndex: "phone",
      width: 170,
      editable: true
    },
    {
      title: "Sexo",
      dataIndex: "sex",
      width: 70,
      required: true,
      editable: true
    },
    {
      title: "Status",
      dataIndex: "enabled",
      width: 100,
      editable: true,
      required: visibleFields.some((v) => v == "enabled"),
      render: (value) => (value ? "Ativo" : "Inativo")
    },
    {
      title: "Cartão",
      dataIndex: "card",
      width: 100,
      editable: true,
      required: visibleFields.some((v) => v == "card"),
      render: (value) => (value ? "Sim" : "Não")
    },
    {
      title: "Endereço",
      dataIndex: "address",
      width: 250,
      required: true,
      editable: true
    },
    {
      title: "Número",
      dataIndex: "number",
      width: 110,
      required: true,
      editable: true
    },
    {
      title: "Complemento",
      dataIndex: "complement",
      width: 200,
      editable: true
    },
    {
      title: "Bairro",
      dataIndex: "neighborhood",
      width: 150,
      required: true,
      editable: true
    },
    {
      title: "CEP",
      dataIndex: "zipcode",
      width: 110,
      required: true,
      editable: true
    },
    {
      title: "Cidade",
      dataIndex: "city",
      width: 130,
      required: true,
      editable: true
    },
    {
      title: "Estado",
      dataIndex: "state",
      width: 80,
      required: true,
      editable: true
    }
  ]);
  const [formImport] = Form.useForm();
  const [saveData, setSaveData] = React.useState(false);
  const [form] = Form.useForm();

  const httpGeo = useHttpGeo();
  const formatDataTable = (lines, dataList) => {
    let list = lines.map((x, index) => {
      let find = dataList
        ? dataList.filter((y) => y.email == x.email || y.cpf == x.cpf)
        : [];
      let enabled = true;
      let card = false;
      let line = true;
      if (find.length > 0) {
        let item = find[0];
        enabled = item.enabled;
        card = item.card;
        line = false;
      }
      x.key = index;
      x.enabled = enabled;
      x.card = card;
      x.line = line;
      x.phone = x.phone && x.phone != "" ? x.phone : "";
      x.sex = x.sex && x.sex != "null" ? x.sex : null;
      return x;
    });
    return list;
  };

  const filterFieldsSelected = (d) => {
    if (d == "cpf") return true;
    else if (
      visibleFields.includes("address") &&
      (d == "address" ||
        d == "number" ||
        d == "complement" ||
        d == "neighborhood" ||
        d == "zipcode" ||
        d == "city" ||
        d == "state")
    ) {
      return true;
    } else if (d != "address" && visibleFields.includes(d)) {
      return true;
    }
    return false;
  };

  const onImport = (values) => {
    const lines = generateTemplateExcel().read(
      values.inputData,
      data.data,
      data.fields.filter((d) => filterFieldsSelected(d)),
      data.filter,
      visibleFields
    );
    setData((x) => ({ ...x, dataTable: formatDataTable(lines, data.data) }));
    setImportShow(false);
    setValidateFields(true);
  };

  const onOpenImport = () => {
    setImportShow(true);
    const val = generateTemplateExcel().example(
      data.header
        .filter((d) => filterFieldsSelected(d.key))
        .map((d) => d.title),
      data.data,
      data.fields.filter((d) => filterFieldsSelected(d)),
      data.filter
    );
    formImport.setFieldsValue({
      inputData: val
    });
  };

  const onChangeCompany = (x) => {
    setCompany(x);
  };

  React.useEffect(() => {
    selectAll
      ? setSelectedRowKeys(data.dataTable.map((d) => d.key))
      : setSelectedRowKeys([]);
    if (!saving) setSaveData(false);
  }, [selectAll]);

  React.useEffect(() => {
    if (company || !login.company.master) loadCompany();
  }, [company]);

  React.useEffect(() => {
    if (companyData) load();
  }, [companyData]);

  const getFieldsXls = () => {
    if (companyData && companyData.enableCode) {
      let headerNew = [...data.header];
      let fieldsNew = [...data.fields];

      const hasCodeInHeader = headerNew.some((column) => column.key === "code");
      const hasCodeInFields = fieldsNew.includes("code");

      if (!hasCodeInHeader) {
        headerNew.splice(6, 0, { title: "código do colaborador", key: "code" });
      }

      if (!hasCodeInFields) {
        fieldsNew.splice(6, 0, "code");
      }

      return { header: headerNew, fields: fieldsNew };
    } else {
      return { header: data.header, fields: data.fields };
    }
  };

  const load = async () => {
    setData((x) => ({ ...x, loading: true }));
    const res = login.company.master
      ? await getAllEmployee(company)
      : await getAllEmployee();
    const formatData = res.map((u, index) => {
      return {
        ...u,
        address: u.addressMap ? u.addressMap.address : null,
        number: u.addressMap ? u.addressMap.number : null,
        complement: u.addressMap ? u.addressMap.complement : null,
        zipcode: u.addressMap ? u.addressMap.zipcode : null,
        neighborhood: u.addressMap ? u.addressMap.neighborhood : null,
        city: u.addressMap ? u.addressMap.city : null,
        state: u.addressMap ? u.addressMap.state : null,
        key: index
      };
    });
    const fields = getFieldsXls();
    setData((x) => ({
      ...x,
      data: formatData,
      dataTable: formatData,
      loading: false,
      fields: fields.fields,
      header: fields.header
    }));
    setVisibleFields([
      ...fields.fields.filter(
        (v) =>
          v != "cpf" &&
          v != "number" &&
          v != "complement" &&
          v != "neighborhood" &&
          v != "zipcode" &&
          v != "city" &&
          v != "state"
      ),
      "card",
      "enabled"
    ]);
    setValidateFields(true);
  };

  const loadCompany = async () => {
    setData((x) => ({ ...x, loading: true }));
    setCompanyData(
      login.company.master ? await getCompany(company) : login.company
    );
    setData((x) => ({ ...x, loading: false }));
  };

  // React.useEffect(() => {
  //     load();
  // }, [])

  const validFields = async () => {
    if (data.dataTable.length > 0) {
      try {
        let values = await form.validateFields();
      } catch (errIfo) {
        for (let val of errIfo.errorFields) {
          let split = val.name[0].split("_");
          let index = split[1];
          data.dataTable[index].status = "error";
          data.dataTable[index].message = val.errors[0];
        }
        setData((x) => ({ ...x, dataTable: data.dataTable }));
      }
      setValidateFields(false);
    }
  };

  React.useEffect(() => {
    if (validateFields) {
      validFields();
    }
  }, [validateFields, data.dataTable]);

  if (companyData && companyData.enableCode) {
    const newDefaultColumns = [...defaultColumns];
    const hasCodeColumn = newDefaultColumns.some(
      (column) => column.dataIndex === "code"
    );

    if (!hasCodeColumn) {
      newDefaultColumns.splice(8, 0, {
        title: "Código do colaborador",
        dataIndex: "code",
        width: 110,
        required: false,
        editable: true
      });
      setDefaultColumns(newDefaultColumns);
      // recarrega a lista de employes para exibir o codigo do colaborador
      load();
    }
  }

  if (saveData) {
    const newDefaultColumns = [...defaultColumns];
    const hasStatusColumn = newDefaultColumns.some(
      (column) => column.dataIndex === "status"
    );

    if (!hasStatusColumn) {
      newDefaultColumns.splice(0, 0, {
        title: "",
        dataIndex: "status",
        width: 40,
        fixed: "left",
        align: "center",
        filters: [
          { text: "Sucesso", value: "success" },
          { text: "Salvando", value: "saving" },
          { text: "Sem alteração", value: "notChanges" },
          { text: "Erro", value: "error" }
        ],
        onFilter: (value, record) => record.status === value
      });
      setDefaultColumns(newDefaultColumns);
    }
  }

  const handleSave = (row) => {
    let invalid = false;
    for (let r in row) {
      let col = defaultColumns.filter((d) => d.dataIndex == r);
      if (
        col.length > 0 &&
        col[0].required &&
        (row[r] === "" || row[r] === null)
      ) {
        row.status = "error";
        row.message = `${col[0].title} é um campo obrigatório!`;
        invalid = true;
        break;
      }
    }
    if (!invalid && row.status != "error") {
      row.status = null;
      row.message = "";
      row.errorField = null;
    }
    row.edited = true;
    const newData = [...data.dataTable];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setData((x) => ({ ...x, dataTable: newData, loading: false }));
  };

  const getInputType = (dataIndex) => {
    if (dataIndex === "birthday") return "date";
    else if (
      dataIndex === "enabled" ||
      dataIndex === "sex" ||
      dataIndex === "card"
    )
      return "select";
    else if (dataIndex === "number") return "number";
    return "text";
  };

  const getSelect = (dataIndex) => {
    if (dataIndex === "card")
      return [
        { label: "Sim", value: true },
        { label: "Não", value: false }
      ];
    if (dataIndex === "enabled")
      return [
        { label: "Ativo", value: true },
        { label: "Inativo", value: false }
      ];
    else if (dataIndex === "sex")
      return [
        { label: "M", value: "M" },
        { label: "F", value: "F" }
      ];
    return [];
  };

  const columns = defaultColumns
    .filter((d) => filterFieldsSelected(d.dataIndex) || d.dataIndex == "status")
    .map((col) => {
      if (!col.editable && col.dataIndex != "status") {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          inputType: getInputType(col.dataIndex),
          select: getSelect(col.dataIndex),
          required: col.required,
          handleSave
        })
      };
    });

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const saveAll = async () => {
    setSaveData(true);
    setSaving(true);
    let saveList = [];
    for (let item of data.dataTable) {
      if (item.edited) {
        saveList.push(item);
        item.status = "saving";
        item.message = `Salvando dados...`;
      } else {
        item.status = "notChanges";
        item.message = `Esse colaborador não teve alterações.`;
      }
    }
    setData((x) => ({ ...x, dataTable: data.dataTable }));
    const limit = pLimit(2);
    let promises = saveList.map((user) => {
      return limit(() => save(user));
    });
    await Promise.all(promises);
    setSaving(false);
  };

  const formatData = (item) => {
    let find = data.data.filter(
      (d) => formatCpf(d.cpf) === formatCpf(item.cpf)
    );

    let body = {
      id: find.length > 0 ? find[0].id : 0,
      name: emptyValue(item.name),
      email: emptyValue(item.email),
      cpf: emptyValue(formatCpf(item.cpf)),
      birthday: item.birthday ? moment(item.birthday).toDate() : null,
      mother: emptyValue(item.mother),
      phone: emptyValue(replacePhone(item.phone)),
      sex: emptyValue(item.sex),
      enabled: item.enabled,
      card: item.card
    };
    if (item.address !== "" && item.number !== "" && item.city !== "") {
      body.address = {
        address: emptyValue(item.address),
        number: emptyValue(item.number),
        complement: emptyValue(item.complement),
        zipcode: item.zipcode
          ? emptyValue(item.zipcode.replace(/\D/g, ""))
          : null,
        neighborhood: emptyValue(item.neighborhood),
        city: emptyValue(item.city),
        state: emptyValue(item.state)
      };
    }
    if (login.company.master) {
      body.company = company;
    }
    if (companyData && companyData.enableCode) {
      body.code = emptyValue(item.code);
    }

    body.fields = visibleFields;
    return body;
  };

  const validData = (item, itemClone) => {
    for (let key in item) {
      let col = columns.filter((c) => c.dataIndex === key);
      if (
        (key === "birthday" &&
          isUnderage(item[key]) &&
          (!item["mother"] || item["mother"] === "")) ||
        (col.length > 0 && col[0].required && emptyValue(item[key]) === null)
      ) {
        itemClone.status = "error";
        itemClone.message =
          emptyValue(item[key]) == null
            ? `${col[0].title} é obrigatório!`
            : `Não é possível cadastrar colaborador menor de idade em lote`;
        return false;
      }
    }
    return true;
  };

  const isUnderage = (birthday) => {
    let now = moment();
    let birth = moment(birthday);
    let age = now.diff(birth, "years");
    return age < 18;
  };
  const save = async (item) => {
    let cloneData = [...data.dataTable];
    if (validData(item, cloneData[item.key])) {
      let body = formatData(item);

      if (body.id == 0) {
        for (let field of defaultColumns.filter((c) => c.required)) {
          if (
            !visibleFields.includes(field.dataIndex) &&
            field.dataIndex != "cpf" &&
            field.title != "Rua" &&
            field.dataIndex != "number" &&
            field.dataIndex != "complement" &&
            field.dataIndex != "neighborhood" &&
            field.dataIndex != "zipcode" &&
            field.dataIndex != "city" &&
            field.dataIndex != "state"
          ) {
            cloneData[item.key].status = "error";
            cloneData[item.key].message = "Campo obrigatório não preenchido!";
            setData((x) => ({ ...x, dataTable: cloneData }));
            return;
          }
        }
      }
      let res = await saveUserBatch(body);
      if (res.success) {
        cloneData[item.key].status = "success";
        cloneData[item.key].message = "Colaborador salvo com sucesso";
        cloneData[item.key].errorField = null;
        if (body.id == 0) {
          data.data.push(res.data);
        }
      } else {
        cloneData[item.key].status = "error";
        cloneData[item.key].message = res.errorMessage;
        if (res.infos && res.infos.field) {
          cloneData[item.key].errorField = {
            name: res.infos.field,
            error: res.errorMessage
          };
        }
      }
      setData((x) => ({ ...x, dataTable: cloneData }));
    } else {
      setData((x) => ({ ...x, dataTable: cloneData }));
    }
  };

  const activeAll = (status) => {
    let fields = {};
    data.dataTable
      .filter((d) => selectedRowKeys.includes(d.key))
      .map((d) => {
        d.enabled = status;
        d.edited = true;
        fields["enabled_" + d.key] = status;
      });
    form.setFieldsValue(fields);
    setData((x) => ({ ...x, dataTable: data.dataTable }));
    setSelectedRowKeys([]);
  };

  const card = () => {
    let fields = {};
    data.dataTable
      .filter((d) => selectedRowKeys.includes(d.key))
      .map((d) => {
        d.card = true;
        d.edited = true;
        fields["card_" + d.key] = true;
      });
    form.setFieldsValue(fields);
    setData((x) => ({ ...x, dataTable: data.dataTable }));
    setSelectedRowKeys([]);
  };
  const getAddresses = async () => {
    let fields = {};
    setSaveData(true);
    await Promise.all(
      data.dataTable
        .filter((d) => selectedRowKeys.includes(d.key))
        .map(async (d) => {
          try {
            const {
              bairro,
              localidade,
              logradouro,
              uf: estado
            } = await httpGeo.getCep(d.zipcode);
            fields["address_" + d.key] = logradouro;
            d.address = logradouro;
            fields["neighborhood_" + d.key] = logradouro;
            d.neighborhood = bairro;
            fields["city_" + d.key] = localidade;
            d.city = localidade;
            fields["state_" + d.key] = estado;
            d.state = estado;

            form.setFieldsValue(fields);

            data.dataTable[d.key].status = "success";
            data.dataTable[d.key].message = "Endereço encontrado com sucesso";
            setSelectedRowKeys([]);
          } catch (e) {
            data.dataTable[d.key].status = "error";
            data.dataTable[d.key].message = e.message;

            notification.error({
              message: e.message
            });
          } finally {
            setData((x) => ({ ...x, dataTable: data.dataTable }));
          }
        })
    );

    setSelectedRowKeys([]);
  };

  const sendPassword = async () => {
    setPasswordShow(false);
    setSaving(true);
    let saveList = [];
    for (let item of data.dataTable) {
      if (selectedRowKeys.includes(item.key)) {
        saveList.push(item);
        item.status = "saving";
        item.message = `Enviando e-mail de definição de senha...`;
      } else {
        item.status = "notChanges";
        item.message = `Esse colaborador não teve alterações.`;
      }
    }
    setData((x) => ({ ...x, dataTable: data.dataTable }));
    setSelectedRowKeys([]);

    for (let i = 0; i < saveList.length; i++) {
      await saveListPass(saveList[i]);
    }
    setSaving(false);
  };

  const saveListPass = async (item) => {
    await savePasswordUser(item.id, true, false);
    data.dataTable[item.key].status = "success";
    data.dataTable[item.key].message = "Email Enviado com sucesso";
    data.dataTable[item.key].errorField = null;
    setData((x) => ({ ...x, dataTable: data.dataTable }));
  };

  const getStatusName = (status) => {
    if (status == "error") {
      return "Erro";
    } else if (status == "success") {
      return "Sucesso";
    }
    if (status == "saving") {
      return "Salvando";
    }
    if (status == "notChanges") {
      return "Sem alterações";
    }
    return "";
  };

  const prepareFileErrors = () => {
    let ws_data = [];
    data.dataTable.forEach((d) => {
      let tmp = {
        Nome: d.name,
        Email: d.email,
        CPF: d.cpf,
        Nascimento:
          d.birthday && d.birthday != ""
            ? moment(d.birthday).format("DD/MM/YYYY")
            : "",
        Mãe: d.mother,
        Telefone: d.phone,
        Sexo: d.sex,
        ...((login.company.master || login.company.enableCode) && {
          "Código do Colaborador": d.code
        }),
        Rua: d.address,
        Número: d.number,
        Complemento: d.complement,
        Bairro: d.neighborhood,
        CEP: d.zipcode,
        Cidade: d.city,
        Estado: d.state,
        Status: getStatusName(d.status),
        Erros: d.message,
        ...(login.company.master && { Empresa: companyData.name })
      };
      ws_data.push(tmp);
    });
    return ws_data;
  };

  const exportErrors = () => {
    saveFileResume(prepareFileErrors(), "Erros", "Erros");
  };

  const getWidth = () => {
    return columns.length <= 6 ? null : 3000;
  };

  return (
    <Layout className="alymente-layout">
      <Header
        // title={`Selecionados: ${selectedRowKeys.length}/${data.dataTable.length}`}
        subtitle={"Selecione colaboradores para fazer em ações em lote."}
        middle={
          login.company.master ? (
            <AutoCompleteCompany value={company} onChange={onChangeCompany} />
          ) : undefined
        }
        routes={[{ label: "Importar Dados", button: true }]}
        onSelectRoute={() => onOpenImport()}
      >
        {selectedRowKeys.length > 0 ? (
          <div style={{ marginLeft: -15 }}>
            <Button type={"text"} onClick={() => setPasswordShow(true)}>
              Enviar senha do aplicativo
            </Button>
            <Button type={"text"} onClick={() => activeAll(false)}>
              Inativar
            </Button>
            <Button type={"text"} onClick={() => activeAll(true)}>
              Ativar
            </Button>
            <Button type={"text"} onClick={() => card()}>
              Cartão Entregue
            </Button>
            <Button type={"text"} onClick={getAddresses}>
              Consultar Endereços
            </Button>
          </div>
        ) : null}
      </Header>
      <Content>
        {
          <Row>
            <Col
              sm={12}
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginBottom: 10
              }}
            >
              <Button
                style={{ marginRight: 10 }}
                size="small"
                type={"primary"}
                onClick={() => setSelectAll(!selectAll)}
              >
                {selectAll ? "Limpar Todos" : "Selecionar Todos"}
              </Button>
              <Typography.Text>
                Selecionados: {selectedRowKeys.length}/{data.dataTable.length}
              </Typography.Text>
            </Col>
            {/* <Col sm={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
                            {
                                data.dataTable.filter(d => d.status).length > 0 &&
                                <div>
                                    <Typography.Text style={{ marginRight: 15 }}>Sucesso: {data.dataTable.filter(d => d.status == "success").length}</Typography.Text>
                                    <Typography.Text>Erro: {data.dataTable.filter(d => d.status == "error").length}</Typography.Text>
                                </div>
                            }
                        </Col> */}
            <Col
              sm={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 10
              }}
            >
              <CheckboxMenu
                options={defaultColumns.filter(
                  (v) =>
                    v.dataIndex != "cpf" &&
                    v.dataIndex != "number" &&
                    v.dataIndex != "complement" &&
                    v.dataIndex != "neighborhood" &&
                    v.dataIndex != "zipcode" &&
                    v.dataIndex != "city" &&
                    v.dataIndex != "state"
                )}
                value={visibleFields}
                onChange={(values) => {
                  setVisibleFields(values);
                }}
              >
                <Button size="small" type={"primary"}>
                  Escolher Campos Editáveis
                </Button>
              </CheckboxMenu>
            </Col>
          </Row>
        }
        <TableForm
          bordered
          dataSource={data.dataTable}
          columns={columns}
          scroll={{ x: getWidth(3000), y: 450 }}
          form={form}
          // onFinish={saveAll}
          onChangeRowSelect={setSelectedRowKeys}
          selectedRowKeys={selectedRowKeys}
          selectRows={!saveData}
          loading={data.loading}
        />
        <Row
          style={
            data.dataTable.length > 0 ? { marginTop: -40 } : { marginTop: 20 }
          }
        >
          <Col
            sm={8}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 10
            }}
          >
            <Button
              disabled={data.dataTable.filter((d) => d.status).length == 0}
              style={{ marginRight: 10 }}
              size="small"
              type={"primary"}
              onClick={() => exportErrors()}
            >
              Exportar Erros
            </Button>
            <Typography.Text style={{ marginRight: 15 }}>
              Sucesso:{" "}
              {data.dataTable.filter((d) => d.status == "success").length}
            </Typography.Text>
            <Typography.Text>
              Erro: {data.dataTable.filter((d) => d.status == "error").length}
            </Typography.Text>
          </Col>
        </Row>
      </Content>
      <Footer>
        <Space align="end">
          <Button
            disabled={
              data.loading || saving || (login.company.master && !company)
            }
            loading={data.loading}
            className="btn-save"
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => saveAll()}
          >
            Salvar
          </Button>
        </Space>
      </Footer>
      <Modal
        width={1000}
        open={importShow}
        title="Importação de Colaboradores"
        onCancel={() => setImportShow(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={false}
            onClick={() => formImport.submit()}
          >
            Ler Dados
          </Button>
        ]}
      >
        {
          <Row>
            <Col span={24}>
              <Form
                form={formImport}
                layout="vertical"
                name="basic"
                onFinish={onImport}
                align="center"
              >
                <Spin spinning={data.loading}>
                  <Form.Item
                    label="Copie o conteúdo de sua planilha"
                    name={["inputData"]}
                  >
                    <Input.TextArea rows={8} />
                  </Form.Item>
                </Spin>
              </Form>
            </Col>
          </Row>
        }
      </Modal>
      <Modal
        open={passwordShow}
        title="Confirmação"
        onOk={sendPassword}
        onCancel={() => setPasswordShow(false)}
        style={{ textAlign: "center" }}
      >
        <Typography.Text style={{ textAlign: "center" }}>
          Deseja enviar e-mail para definição de senha do aplicativo para{" "}
          {selectedRowKeys.length} colaboradores?
        </Typography.Text>
      </Modal>
    </Layout>
  );
};

export default connect(mapStateToProps, {})(UserBatchScreen);
