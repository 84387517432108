import { exportXls } from "utils";

export const cardReport = async (solicitations) => {
    const xlsxSolicitation = solicitations.map(x => ({
        "Data de Criação": new Date(x.createdAt),
        "Data de Envio": x.sendDate ? new Date(x.sendDate) : null,
        "Colaborador": x.user ? x.user.name : null,
        "CPF": x.user ? x.user.cpf : null,
        "Empresa": x.company ? x.company.name : null,
        "Status": x.status.name.toUpperCase(),
        "Situação": x.externalStatus,
        "Local Entrega": x.address ? x.address.format : null,
        "Estado": x.address ? x.address.state : null,
        "Quantidade": x.quantity,
        "Link": x.link,
        "Cancelável?": x.cancelable ? "Sim" : "Não",
    }))
    exportXls().createAndSaveFile(["Envios"], { "Envios": xlsxSolicitation }, "Envios");
}