import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import I18NextHttpBackend from 'i18next-http-backend';
import moment from 'moment'

export const translate = (key) => {
    return i18next.t(key);
}

export const changeLanguage = (lng) => {
    i18next.changeLanguage(lng.toLowerCase());
}

export const initLanguage = async () => {
    const versionUrl = () => `?v${moment().format("YYYYMMDDHHmm")}`
    await i18next
        .use(I18NextHttpBackend)
        .use(initReactI18next)
        .init({
            debug: true,
            load: 'languageOnly',
            backend: {
                loadPath: `https://storage.googleapis.com/alymenteapp/languages/{{lng}}.json${versionUrl()}`,
            },
            react: {
                wait: true,
                useSuspense: false
            },
            fallbackLng: 'pt',
            supportedLngs: ['pt', 'en'],
            interpolation: {
                escapeValue: false,
            },
        });
}
