import React, { useEffect } from "react";
import { Row, Col, Input } from "antd";
import PropTypes from "prop-types";
import { findPropObject, replaceNumber } from "utils";
import moment from "moment";
import { DatePicker } from "..";
import { useSearchParams } from "react-router-dom";

const TableSearch = (props) => {
  const {
    onSearch,
    filter = [],
    data = [],
    type = "default",
    value,
    request,
    actions,
    justify = "end",
    setHasInput,
    filterNumber = true,
    onlyInput = false,
    initialData = null
  } = props;
  const [oldData, setOldData] = React.useState([]);
  const [searchParam, setSearchParam] = useSearchParams();
  const hasSearchFirstTime = React.useRef(false);
  const searchQuery = searchParam.get("search");
  const [searchValue, setSearchValue] = React.useState(searchQuery ?? "");

  React.useEffect(() => {
    if (data.length > 0 && oldData.length === 0) {
      setOldData((data) => data);
    }
  }, [data.length, oldData.length]);

  React.useEffect(() => {
    if (initialData?.length > 0) {
      setOldData(initialData);
    }
  }, [initialData]);

  React.useEffect(() => {
    if (data.length > 0 && !initialData && oldData.length === 0) {
      setOldData(data);
    }
  }, [data, initialData, oldData]);

  useEffect(() => {
    if (searchValue) {
      doSearch(searchValue);
    } else {
      doSearch(null);
    }
  }, []);
  const onFilter = (value) => {
    if (setHasInput && value === null) {
      setHasInput(false);
    }
    let newData = [...oldData];
    if (value) {
      if (setHasInput) {
        setHasInput(true);
      }
      value = filterNumber ? replaceNumber(value) : value;
      newData = newData.filter((x) => {
        for (let i = 0; i < filter.length; i++) {
          const obj =
            filter[i] === "cpf"
              ? findPropObject(x, filter[i]).replace(/\D+/g, "")
              : findPropObject(x, filter[i]);
          const valueToSearch =
            filter[i] === "rawCpf" || filter[i] === "cpf"
              ? value.replace(/\D+/g, "")
              : value;
          if (
            valueToSearch &&
            obj &&
            obj.toLowerCase().includes(valueToSearch.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    }
    onSearch(newData, value ? true : false);
  };
  const doSearch = async (value) => {
    if (value) {
      setSearchParam((prev) => {
        if (searchValue.length !== 0) {
          prev.set("search", searchValue);
        } else {
          prev.delete("search");
        }
        return prev;
      });
      if (request) {
        await request(value);
      } else {
        onFilter(value);
      }
    }
  };
  const onPressEnter = async (value) => {
    doSearch(value);
  };

  const onDateChange = (e) => {
    if (request) {
      onPressEnter(e ? e.format("YYYY-MM-DD") : undefined);
    } else {
      onSearch(e);
    }
  };
  if (
    searchQuery &&
    !hasSearchFirstTime.current &&
    oldData.length > 0 &&
    !request
  ) {
    hasSearchFirstTime.current = true;
    onFilter(searchValue);
  }
  const InputSearch = (
    <Input
      allowClear
      value={searchValue}
      onChange={(e) => {
        setSearchValue(e.target.value);
        e.target.value.length === 0 && !request && onFilter("");
      }}
      onPressEnter={(e) => {
        onPressEnter(e.target.value);
      }}
      placeholder="Digite sua pesquisa..."
    />
  );

  if (onlyInput) {
    return InputSearch;
  } else {
    return (
      <Row justify={justify} gutter={[12, 12]}>
        {type === "date" ? (
          <Col
            xl={6}
            lg={6}
            md={24}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <DatePicker
              value={value}
              onChange={onDateChange}
              format={"DD/MM/YYYY"}
              style={{ width: 300 }}
            />
          </Col>
        ) : (
          <Col xl={8} lg={12} md={24}>
            {InputSearch}
          </Col>
        )}
        {actions && (
          <Col xl={3} lg={8} md={24}>
            {actions}
          </Col>
        )}
      </Row>
    );
  }
};

TableSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  filter: PropTypes.array.isRequired,
  type: PropTypes.oneOf(["default", "date"]),
  request: PropTypes.func,
  filterNumber: PropTypes.bool,
  onlyInput: PropTypes.bool
};

export { TableSearch };
