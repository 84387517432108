import React from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Layout, Table, Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { getAllCompanies } from "services/company-service";
import { Header, TableSearch } from "components";
import Env from "env";
import usePage from "hooks/usePage";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const DataFetch = {
  data: [],
  loading: false
};

const CompanyScreen = (props) => {
  const { login } = props;
  const navigate = useNavigate();
  const [data, setData] = React.useState(DataFetch);
  const [pageParams, setPageParams] = useSearchParams();
  const load = async (filter = "") => {
    setData((x) => ({ ...x, loading: true }));
    const res = await getAllCompanies(filter);
    setData((x) => ({ data: res, loading: false }));
  };
  const localLoad = (e) => setData((prev) => ({ ...prev, data: e }));
  const { page, search, changePage, localSearch } = usePage(load, localLoad);

  React.useEffect(() => {
    if (
      !login.company.master ||
      (pageParams.get("page") && !pageParams.get("search"))
    ) {
      load();
    }
  }, []);

  return (
    <Layout className="alymente-layout">
      <Header title="Empresas" />
      <Content>
        <div style={{ padding: "16px" }}>
          <TableSearch
            data={data.data}
            request={login.company.master ? search : null}
            onSearch={(e) => localSearch(e)}
            //   actions={
            //      login.company.master && (
            //         <Button
            //            type="primary"
            //            style={{ width: '100%' }}
            //            onClick={() => {
            //               setPageParams({ page: 1 });
            //               load();
            //            }}
            //         >
            //            Carregar Todos
            //         </Button>
            //      )
            //   }
          />
        </div>
        <Table
          onChange={(page) => {
            changePage(page.current);
          }}
          scroll={{ y: 650, x: 1000 }}
          dataSource={data.data}
          loading={data.loading}
          pagination={{ current: page }}
          bordered
        >
          <Table.Column
            width={200}
            align={"center"}
            title="Conta"
            dataIndex={["accountSystem", "name"]}
            key="accountSystem.name"
          />
          <Table.Column
            align={"left"}
            title="Gerente de Conta"
            dataIndex="accountManager"
            key="accountManager"
          />
          <Table.Column
            align={"left"}
            title="Nome"
            dataIndex="name"
            key="name"
          />
          <Table.Column
            align={"left"}
            title="Razão Social"
            dataIndex="corporateName"
            key="corporateName"
          />
          <Table.Column
            width={200}
            align={"center"}
            title="Tipo"
            dataIndex={["companyType", "name"]}
            key="companyType.name"
          />
          <Table.Column
            width={200}
            align={"center"}
            title="CNPJ"
            dataIndex="cnpj"
            key="cnpj"
          />
          <Table.Column
            fixed="right"
            width={100}
            align={"center"}
            title="Ações"
            dataIndex="id"
            key="id"
            render={(value) => (
              <Button
                size="small"
                type={"link"}
                onClick={() => {
                  const params =
                    pageParams.size > 0 ? `?${pageParams.toString()}` : "";
                  navigate(`/company/${value + params}`);
                }}
              >
                Editar
              </Button>
            )}
          />
        </Table>
      </Content>
      <Footer>
        <Button
          className="btn-save"
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => navigate(`/company/add`)}
        >
          Nova Empresa
        </Button>
      </Footer>
    </Layout>
  );
};

export default connect(mapStateToProps, {})(CompanyScreen);
