import React from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Layout, Table, Button, Tag, Modal } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { getAllVehicles } from "services/vehicle-service";
import { Header, TableSearch } from "components";
import Env from "env";
import usePage from "hooks/usePage";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const DataFetch = {
  data: [],
  loading: false
};

const VehicleScreen = (props) => {
  const { login } = props;
  const navigate = useNavigate();
  const [data, setData] = React.useState(DataFetch);
  const [pageParams, setPageParams] = useSearchParams();

  const load = async () => {
    setData((x) => ({ ...x, loading: true }));
    const res = await getAllVehicles();
    setData((x) => ({ data: res, loading: false }));
  };

  React.useEffect(() => {
    load();
  }, []);

  return (
    <Layout className="alymente-layout">
      <Header
        title="Frota de Veículos"
        routes={[{ label: `Relatórios`, route: `/vehicle/report` }]}
      />
      <Content>
        <Table
          scroll={{ y: 650, x: 1000 }}
          dataSource={data.data}
          loading={data.loading}
          pagination={true}
          bordered
        >
          <Table.Column
            width={400}
            align={"left"}
            title="Motorista"
            dataIndex={["driver", "name"]}
            key="driver"
          />
          <Table.Column
            width={120}
            align={"left"}
            title="Placa"
            dataIndex="licensePlate"
            key="licensePlate"
          />
          <Table.Column
            width={400}
            align={"left"}
            title="Veículo"
            dataIndex="name"
            key="name"
          />
          <Table.Column
            width={120}
            align={"left"}
            title="Categoria"
            dataIndex="type"
            key="type"
            render={(value) => (value == "car" ? "Carro" : "Caminhão")}
          />
          <Table.Column
            width={120}
            align={"center"}
            title="Ano"
            dataIndex="year"
            key="year"
          />
          <Table.Column
            width={120}
            align={"center"}
            title="Ano Modelo"
            dataIndex="yearModel"
            key="yearModel"
          />
          <Table.Column
            width={200}
            align={"left"}
            title="Código"
            dataIndex="code"
            key="code"
          />
          <Table.Column
            width={120}
            align={"center"}
            title="Cartão"
            dataIndex="card"
            key="card"
            render={(value, row) => (
              <Tag color={value ? "success" : "error"}>
                {value ? "SIM" : "NÃO"}
              </Tag>
            )}
          />
          <Table.Column
            align={"center"}
            width={120}
            title="Status"
            dataIndex="enabled"
            key="enabled"
            render={(value, row) => (
              <Tag color={value ? "success" : "error"}>
                {value ? "ATIVO" : "INATIVO"}
              </Tag>
            )}
          />
          <Table.Column
            fixed="right"
            width={100}
            align={"center"}
            title="Ações"
            dataIndex="id"
            key="id"
            render={(value) => (
              <Button
                size="small"
                type={"link"}
                onClick={() => {
                  navigate(`/vehicle/${value}`);
                }}
              >
                Editar
              </Button>
            )}
          />
        </Table>
      </Content>
      <Footer>
        <Button
          className="btn-save"
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => navigate(`/vehicle/add`)}
        >
          Novo Veículo
        </Button>
      </Footer>
    </Layout>
  );
};

export default connect(mapStateToProps, {})(VehicleScreen);
