import React from 'react';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Card, Row, Col, Layout, Typography, Space, Button, Alert } from 'antd';
import { AuthHeader, Form, InputMask } from 'components';
import * as authAction from 'actions/auth-action';
import { SoftColor } from 'config/constants/Color';
import { send2FA } from 'services/auth-service';

const mapStateToProps = (state) => ({
   login: state.authReducer.loginReducer,
});

const mapDispatchToProps = (dispatch) =>
   bindActionCreators(
      {
         ...authAction,
      },
      dispatch
   );

const TwoFaScreen = (props) => {
   const navigate = useNavigate();
   const [type, setType] = React.useState('');
   const [isLoading, setLoading] = React.useState(false);
   const { login, auth2FAAction } = props;
   const [form] = Form.useForm();

   const handleChooseOption = async (e) => {
      setLoading(true);
      await send2FA(login.user.id, e);
      setType(e);
      setLoading(false);
   };

   const handleAuth = (values) => {
      auth2FAAction(
         login.user.id,
         values.code
            .replaceAll(' ', '')
            .replace(/^(?![+d])/, '')
            .substring(0, 6)
      );
   };

   React.useEffect(() => {
      if (!login.user) navigate('/auth/login');
      else if (login?.user?.auth2fa) {
         if (login.children.has) {
            navigate('/auth/companies');
         } else {
            navigate('/activation');
         }
      }
   }, [login.user]);

   const ChooseOption = () => (
      <>
         <Row justify="center">
            <Col sm={24}>
               <Typography.Text style={{ color: '#fff' }}>Seleciona a opção que deseja receber o código de autenticação:</Typography.Text>
            </Col>
         </Row>
         <Space direction={'vertical'} style={{ width: '100%', marginTop: 20 }}>
            <Button disabled={isLoading} block name="email" type="primary" onClick={() => handleChooseOption('email')}>
               Email
            </Button>
            <Button disabled={isLoading} block name="sms" type="primary" onClick={() => handleChooseOption('sms')}>
               SMS
            </Button>
         </Space>
      </>
   );

   const Code2FA = () => (
      <>
         <Row justify="center">
            <Col sm={24}>
               <Typography.Text style={{ color: '#fff' }}>Informe o código recebido:</Typography.Text>
            </Col>
         </Row>
         <Form form={form} layout="vertical" name="basic" initialValues={{ code: '' }} onFinish={handleAuth}>
            <Form.Item hasFeedback name="code" rules={[{ required: true, type: 'code', message: 'Esse código é obrigatório.' }]}>
               <InputMask mask="9 9 9 9 9 9" placeholder="0 0 0 0 0 0" autoComplete="off" disabled={login.isProcessing} />
            </Form.Item>
            {login.message.length > 0 && (
               <Form.Item>
                  <Alert message={login.message} type="error" showIcon />
               </Form.Item>
            )}
            <Form.Item>
               <Button loading={login.isProcessing} block type="primary" onClick={() => form.submit()}>
                  Validar
               </Button>
            </Form.Item>
         </Form>
      </>
   );

   return (
      <Layout
         style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: SoftColor,
         }}
      >
         <Layout.Content>
            <Row justify="center" align="middle" className={'login'}>
               <Col xxl={6} xl={8} lg={10} md={14} sm={24}>
                  <Card title={<AuthHeader />} bordered={false}>
                     <Row justify="center" align="middle" style={{ textAlign: 'center' }}>
                        <Col sm={24}>
                           <Typography.Title level={5} style={{ color: '#fff' }}>
                              Autenticação de dois fatores
                           </Typography.Title>
                        </Col>
                     </Row>
                     {type.length === 0 ? <ChooseOption /> : <Code2FA />}
                  </Card>
               </Col>
            </Row>
         </Layout.Content>
      </Layout>
   );
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFaScreen);
