import axios from "axios";
const { useState } = require("react");

const useHttpGeo = () => {
  const [loading, setLoading] = useState(false);
  const api = axios.create({
    baseURL: "https://viacep.com.br/ws",
  });

  const getCep = async (cep) => {
    setLoading(true);
    try {
      cep = cep.replace(/[^\d]/g, "");
      const { data } = await api.get(`/${cep}/json`);
      if (data.erro === true) throw new Error();
      return data;
    } catch (ex) {
      throw new Error("Ocorreu um erro na leitura do cep.");
    } finally {
      setLoading(false);
    }
  };

  return { loading, getCep };
};
export default useHttpGeo;
