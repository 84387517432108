import { combineReducers } from 'redux';

import AuthReducer from './auth-reducer';
import BalanceReducer from './balance-reducer';
import ConfigReducer from './config-reducer';
import ExpenseReducer from './expense-reducer';

const appReducer = combineReducers({
   authReducer: AuthReducer,
   balanceReducer: BalanceReducer,
   configReducer: ConfigReducer,
   expenseReducer: ExpenseReducer,
});

const rootReducer = (state, action) => {
   if (action.type === 'LOGOUT') {
      state = undefined;
   }
   return appReducer(state, action);
};

export default rootReducer;
