import React from "react";
import ShareholdersForm from "./shareholders-form";
import PropTypes from "prop-types";

export default function ShareholdersSaveForm({
  onSave,
  onCancel,
  isOpen,
  loading,
  setIsOpen,
  formConfig
}) {
  return (
    <ShareholdersForm
      loading={loading}
      isOpen={isOpen}
      title="Inserir um novo sócio"
      okText="Salvar novo sócio"
      onSave={onSave}
      onCancel={onCancel}
      setIsOpen={setIsOpen}
      formConfig={formConfig}
      successMessage="Sócio salvo com sucesso"
    />
  );
}

ShareholdersSaveForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  formConfig: PropTypes.shape({
    name: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    cpf: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    cnpj: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    birthday: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    foundedAt: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    mother: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    email: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    phone: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    shareholderType: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    address: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    })
  }).isRequired
};
