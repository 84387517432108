import React from "react";
import { Button, Modal, Spin, Row, Col, Typography } from 'antd';
import PropTypes from "prop-types";
import { createInvoice, getByIdSolicitation, getInvoiceById } from "services/solicitation-service";
import { formatDate, formatMoney } from "utils";
import { Logo } from "components/image/logo";
import { useReactToPrint } from 'react-to-print';

const SolicitationInvoiceScreen = (props) => {
    const { visible, onHide, solicitation } = props;
    const [data, setData] = React.useState(null);
    const [lines, setLines] = React.useState([]);
    const componentRef = React.useRef();
    const handlePrint = useReactToPrint({
        bodyClass: "print-body",
        copyStyles: true,
        onAfterPrint: () => {
            onHideFirst();
        },
        content: () => componentRef.current,
    });

    const onCreateInvoice = async () => {
        try {
            await createInvoice(data.id);
            await onLoad();
        } catch (ex) {
            onHideFirst();
        }
    }

    const onLoad = async () => {
        const res = await getByIdSolicitation(solicitation);
        if (res.invoice) {
            const data = await getInvoiceById(res.invoice.id);
            setLines(data.lines)
        }
        setData(res);
    }

    const onHideFirst = () => {
        setData(null);
        onHide();
    }

    React.useEffect(() => {
        if (solicitation) {
            onLoad();
        }
    }, [solicitation])

    React.useEffect(() => {
        if (data !== null && data.invoice === null) {
            onCreateInvoice();
        }
    }, [data])

    return (
        <Modal
            width={1000}
            open={visible}
            title="Nota de Débito"
            onCancel={onHideFirst}
            footer={
                [
                    <Button key="submit" type="primary" loading={false} onClick={handlePrint}>
                        Imprimir
                    </Button>
                ]}
        >
            {(data === null || data.invoice === null) ?
                (
                    <Row justify="center">
                        <Col>
                            <Spin tip={"Gerando nota de débito..."} />
                        </Col>
                    </Row>
                ) : (
                    <div ref={componentRef} className="print">
                        <div className="print-content">
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td align="center">
                                            <Typography.Title level={3}>
                                                {`Nota de Débito Nº ${data.invoice.number}`}
                                            </Typography.Title>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center">
                                            <div>
                                                <Typography.Text className="green" >
                                                    ALYMENTE BENEFICIOS E SIMILARES LTDA.
                                                </Typography.Text>
                                            </div>
                                            <div>
                                                <Typography.Text className="green">
                                                    CNPJ 29.062.232/0001-56
                                                </Typography.Text>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr><td height={50}></td></tr>
                                    <tr>
                                        <td>
                                            <Typography.Text strong className="black">
                                                {`Data de Emissão: `}
                                            </Typography.Text>
                                            <Typography.Text className="black">
                                                {`${formatDate(data.invoice.createdAt)}`}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text strong className="black">
                                                {`Nome do Cliente: `}
                                            </Typography.Text>
                                            <Typography.Text className="black">
                                                {`${data.company.name}`}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text strong className="black">
                                                {`Endereço: `}
                                            </Typography.Text>
                                            <Typography.Text className="black">
                                                {`${data.company.address.format}`}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text strong className="black">
                                                {`Município: `}
                                            </Typography.Text>
                                            <Typography.Text className="black">
                                                {`${data.company.address.city}`}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text strong className="black">
                                                {`CNPJ: `}
                                            </Typography.Text>
                                            <Typography.Text className="black">
                                                {`${data.company.cnpj}`}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr><td height={20}></td></tr>
                                    <tr>
                                        <td>
                                            <Typography.Text strong className="black">
                                                {`Descrição: `}
                                            </Typography.Text>
                                            <Typography.Text className="black">
                                                {`${data.invoice.description}`}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text strong className="black">
                                                {`Valor do Débito: `}
                                            </Typography.Text>
                                            <Typography.Text className="black">
                                                {`${formatMoney(lines.length > 0 ? lines.map(l => l.value).reduce((a, b) => a + b, 0) : data.invoice.value)}`}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography.Text className="black">
                                                {`Instrução Normativa SF/SUREM n° 06/2018, com a redação dada pela Instrução Normativa SF/SUREM n° 17/2021 e Instrução Normativa SF/SUREM n° 4/2022`}
                                            </Typography.Text>
                                        </td>
                                    </tr>
                                    <tr><td height={20}></td></tr>
                                </tbody>
                            </table>
                            {
                                lines.length > 0 &&
                                <div className="table-detail">
                                    <table style={{ width: "100%" }}>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: 'left' }}><Typography.Text strong className="white">Quantidade</Typography.Text></th>
                                                <th style={{ textAlign: 'left' }}><Typography.Text strong className="white">Descrição</Typography.Text></th>
                                                <th style={{ textAlign: 'right' }}><Typography.Text strong className="white">Valor</Typography.Text></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                lines.map(l => {
                                                    return (
                                                        <tr>
                                                            <td><Typography.Text className="black">{l.quantity}</Typography.Text></td>
                                                            <td><Typography.Text className="black">{l.description}</Typography.Text></td>
                                                            <td style={{ textAlign: 'right' }}><Typography.Text className="black">{formatMoney(l.value)}</Typography.Text></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                        <div className="print-footer">
                            <div>
                                <Logo justify="flex-start" />
                            </div>
                            <div className="alymente">
                                <Typography.Text className="black" strong>Alymente Benefícios e Similares LTDA</Typography.Text>
                                <Typography.Text className="black"><strong>CNPJ:</strong> 29.062.232/0001-56 — <strong>PAT:</strong> 180642210</Typography.Text>
                                <Typography.Text className="black">Rua Funchal, 203, Conj 71</Typography.Text>
                                <Typography.Text className="black">Vila Olímpia, São Paulo/SP, CEP: 04551-904</Typography.Text>
                            </div>
                        </div>
                    </div>
                )
            }
        </Modal >
    )
}

SolicitationInvoiceScreen.defaultProps = {
    visible: false
};

SolicitationInvoiceScreen.propTypes = {
    visible: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    solicitation: PropTypes.number.isRequired
};

export default SolicitationInvoiceScreen;