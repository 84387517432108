import { clearFormatedCPF } from 'utils'
import lodash from 'lodash';

export const importFile = {
    doImport: (data, text) => {
        const users = importFile.extractUsers(data);
        const keys = importFile.createKeys(users);
        const content = importFile.readContent(text);
        return { users, keys, content };

    },
    doImportVehicle: (data, text) => {
        const vehicles = importFile.extractVehicles(data);
        const keys = importFile.createKeysVehicle(vehicles);
        const content = importFile.readContent(text);
        return { vehicles, keys, content };

    },
    extractUsers: (data) => {
        const map = (x) => ({ id: x.id, email: x.email, cpf: clearFormatedCPF(x.cpf) });
        const users = data.map(x => x.employee || x).map(map);
        return lodash.uniqBy(lodash.uniqBy(users, 'email'), 'cpf');
    },
    extractVehicles: (data) => {
        const map = (x) => ({ id: x.id, licensePlate: x.licensePlate, code: x.code });
        const vehicles = data.map(x => x.vehicle || x).map(map);
        return lodash.uniqBy(lodash.uniqBy(vehicles, 'licensePlate'), 'code');
    },
    createKeys: (users) => {
        /* Constrói dicionário de chaves, com CPFs e emails */
        let keys = {
            cpf: {},
            email: {}
        }

        users.forEach((user) => {
            if (user.cpf) {
                keys.cpf[user.cpf] = user.id;
            }

            if (user.email) {
                keys.email[user.email] = user.id;
            }
        });

        return keys;
    },
    createKeysVehicle: (vehicles) => {
        /* Constrói dicionário de chaves, com placa e código */
        let keys = {
            licensePlate: {},
            code: {}
        }

        vehicles.forEach((vehicle) => {
            if (vehicle.licensePlate) {
                keys.licensePlate[vehicle.licensePlate] = vehicle.id;
            }

            if (vehicle.code) {
                keys.code[vehicle.code] = vehicle.id;
            }
        });

        return keys;
    },
    extractBenefits: (doc) => {
        // Constrói os benefícios
        let benefits = Array.from(doc.getElementsByClassName('benefit')).map((b) => {
            let splits = b.innerText.split(". ");
            let benefit = { id: splits[0], description: splits[1] };
            return benefit;
        });

        return benefits;
    },
    getHeaderName: (name) => {
        if(name == 'placa')
            return 'licensePlate'
        else if(name == 'código')
            return 'code'
        else
            return name
    },
    readContent: (text) => {
        let content = {};
        let createHeader = (lines) => {
            let headerLine = lines[0];
            let header = {
                key: importFile.getHeaderName(headerLine[0])
            };
            if (headerLine.length > 1) {
                header.columns = headerLine.slice(1, headerLine.length);
            }

            return header;
        };
        let buildRows = (lines) => {
            /* Verifica se tem linhas além do cabeçalho */
            if (lines.length > 1) {
                let valueLines = lines.slice(1, lines.length);

                let rows = valueLines.map((line) => {
                    let row = {
                        key: line[0],
                        values: []
                    };

                    /* Verifica se a linha possui colunas além da chave */
                    if (line.length > 1) {
                        row.values = line.slice(1, line.length);
                    }
                    ;

                    return row;
                });

                content.rows = rows;
            }
        };

        let lines = text.split("\n").map((line) => line.split("\t"));
        content.header = createHeader(lines);
        buildRows(lines);

        return content;
    },
    createExample: (data, benefits = [], type = "email") => {
        let users = importFile.extractUsers(data);
        return `${type.toLowerCase()}\t${benefits.map(b => b.import.trim()).join("\t")}\n${users.map(x => `${x[type].trim()}\t${benefits.map(b => 0).join("\t")}`).join("\n")}`
    },
    createExampleVehicle: (data, benefits = [], type = "licensePlate") => {
        let vehicles = importFile.extractVehicles(data);
        let name = type == 'licensePlate' ? 'placa' : 'código'
        return `${name}\t${benefits.map(b => b.import.trim()).join("\t")}\n${vehicles.map(x => `${x[type].trim()}\t${benefits.map(b => 0).join("\t")}`).join("\n")}`
    },
};