import React from 'react';
import { connect } from 'react-redux';
import { Layout, Table, Button, Typography, Row, Col, Tooltip, Alert, Select, notification } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { getAllEmployee } from 'services/employee-service';
import { Header, CardInfo, TableSearch } from 'components';
import { useNavigate, useLocation } from 'react-router-dom';

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({});

const DataFetch = {
   data: {
      employees: [],
      businessUnits: [],
   },
   loading: false,
};

const SolicitationEmployeeScreen = (props) => {
   const navigate = useNavigate();
   const [state] = React.useState(useLocation().state);
   const [data, setData] = React.useState(DataFetch);
   const [employees, setEmployees] = React.useState([]);
   const [filterEmployees, setFilterEmployees] = React.useState([]);
   const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
   const [showMessageAccount, setShowMessageAccount] = React.useState(false);
   const [businessUnit, setBusinessUnit] = React.useState(-1);
   const [selectAll, setSelectAll] = React.useState(false);
   const benefit = state?.benefit || null;
   const load = async () => {
      setData((x) => ({ ...x, loading: true }));
      const res = await getAllEmployee(null, true);
      const filter = res.employees.filter((x) => x.enabled);
      setEmployees(filter);
      setData({ data: res, loading: false });
   };

   const onContinue = () => {
      if (selectedRowKeys.length === 0) {
         notification.error({ message: 'Selecione ao menos um colaborador.' });
      } else {
         navigate(`save`, {
            state: {
               benefit: benefit,
               employees: employees.filter((x) => selectedRowKeys.indexOf(x.id) > -1),
               businessUnit: businessUnit > 1 ? businessUnit : null,
            },
         });
      }
   };

   React.useEffect(() => {
      load();
   }, []);

   React.useEffect(() => {
      verifyUsersAccount();
      if (data.data.employees.length === 0 && data.data.businessUnits.length > 0) {
         filterBusinessUnit(data.data.businessUnits[0].id);
      }
   }, [data]);

   React.useEffect(() => {
      if (employees.length > 0 && state?.employees?.length > 0) {
         setSelectedRowKeys(state.employees);
      }
   }, [employees, state]);

   const verifyUsersAccount = () => {
      let length = data.data.employees.filter((u) => u.account == null).length;
      setShowMessageAccount(length > 0);
   };

   const filterBusinessUnit = (value) => {
      setBusinessUnit(value);
      let users = [];
      if (value === -1) {
         users = data.data.employees.filter((x) => x.enabled);
      } else {
         let filter = data.data.businessUnits.filter((x) => x.id === value);
         users = filter.length > 0 ? filter[0].employees.filter((x) => x.enabled) : [];
      }
      setEmployees(users);
      setSelectedRowKeys([]);
   };

   React.useEffect(() => {
      let rows = selectAll ? employees.filter((d) => d.account != null).map((d) => d.id) : [];
      setSelectedRowKeys(rows);
   }, [selectAll]);

   return (
      <Layout className="alymente-layout">
         <Header title="Selecione os colaboradores" subtitle="Selecione os colaboradores para os quais você quer fazer o pedido de carga.">
            <Row>
               <Col lg={6} md={12}>
                  <CardInfo title="Selecionados" value={`${selectedRowKeys.length}/${employees.length}`} />
               </Col>
               <Col lg={18} md={12} align="right">
                  <Select
                     placeholder="Selecione a Equipe..."
                     defaultValue="0"
                     value={businessUnit}
                     onChange={filterBusinessUnit}
                     style={{ width: 300, textAlign: 'left' }}
                  >
                     <Select.Option value={-1} key={1}>
                        Todos os colaboradores
                     </Select.Option>
                     {data.data && data.data.businessUnits
                        ? data.data.businessUnits.map((x, index) => (
                             <Select.Option value={x.id} key={index + 2}>
                                {x.name}
                             </Select.Option>
                          ))
                        : null}
                  </Select>
               </Col>
               <Col span={24}>
                  {showMessageAccount ? (
                     <Alert
                        message="Alguns colaboradores não podem receber carga pois não possuem conta ativa. 
                                Por favor, revise os dados desses colaboradores para resolver a situação."
                        type={'warning'}
                        style={{ marginTop: 10 }}
                        showIcon
                     />
                  ) : null}
               </Col>
            </Row>
         </Header>
         <Content>
            <Table
               rowKey={'id'}
               rowSelection={{
                  selectedRowKeys: selectedRowKeys,
                  onChange: (items) => {
                     if (filterEmployees.length > 0) {
                        var newItems = [...selectedRowKeys];
                        for (let i of filterEmployees) {
                           if (items.filter((f) => f === i.id).length > 0 && selectedRowKeys.filter((s) => s === i.id).length === 0)
                              newItems.push(i.id);
                           else if (items.filter((f) => f === i.id).length === 0 && selectedRowKeys.filter((s) => s === i.id).length > 0) {
                              newItems.splice(
                                 newItems.findIndex((f) => f === i.id),
                                 1
                              );
                           }
                        }
                        setSelectedRowKeys(newItems);
                     } else setSelectedRowKeys(items);
                  },
                  getCheckboxProps: (d) => ({
                     disabled: d.account == null ? true : false,
                  }),
               }}
               title={() => (
                  <Row>
                     <Col xs={12}>
                        <Button style={{ marginRight: 10 }} size="small" type={'primary'} onClick={() => setSelectAll(!selectAll)}>
                           {selectAll ? 'Limpar Todos' : 'Selecionar Todos'}
                        </Button>
                        <Typography.Text>
                           Selecionados: {selectedRowKeys.length}/{data.data.length === 0 ? [data.solicitation].length : employees.length}
                        </Typography.Text>
                     </Col>
                     <Col xs={12}>
                        <TableSearch
                           data={employees}
                           filter={['cpf', 'name', 'email']}
                           filterNumber={false}
                           onSearch={(e, filtered) => {
                              setFilterEmployees(e);
                           }}
                        />
                     </Col>
                  </Row>
               )}
               pagination={{ defaultPageSize: 1000 }}
               scroll={{ y: 400 }}
               dataSource={filterEmployees.length > 0 ? filterEmployees : employees}
               loading={data.loading}
               bordered
            >
               <Table.Column
                  title="Nome"
                  dataIndex={'name'}
                  render={(value, row) => {
                     if (row.account == null) {
                        return (
                           <Tooltip placement="bottom" title={'Usuário sem conta associada.'}>
                              <Text mark>{value}</Text>
                           </Tooltip>
                        );
                     }
                     return value;
                  }}
               />
               <Table.Column width={150} title="CPF" dataIndex={'cpf'} />
               <Table.Column width={300} title="Email" dataIndex={'email'} />
            </Table>
         </Content>
         <Footer>
            <Button  className="btn-save" type="primary" icon={<SaveOutlined />} onClick={onContinue}>
               Continuar
            </Button>
         </Footer>
      </Layout>
   );
};

export default connect(mapStateToProps, {})(SolicitationEmployeeScreen);
