import React from "react";
import { Select, Spin } from "antd";
import PropTypes from "prop-types";
import { getAllBank } from "services/banking-service";

const AutoCompleteBank = (props) => {
    const { value, onChange, onSelectedItem } = props;
    const [loading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [valueLocal, setValueLocal] = React.useState(null);

    const load = async () => {
        setLoading(true);
        setOptions(await getAllBank());
        setLoading(false);
    };

    React.useEffect(() => {
        load();
    }, [])

    React.useEffect(() => {
        if (value && options.length > 0) {
            const obj = options.find(x => x.code === value);
            if (obj) {
                setValueLocal(obj.code);
            }
        }
    }, [value, options])

    const onChangeLocal = (e) => {
        setValueLocal(e);
        const obj = options.find(x => x.code === e);
        if (onChange) {
            onChange(obj ? obj.code : null);
        }
    }

    return (
        <Select
            allowClear
            optionLabelProp="label"
            notFoundContent={loading ? <Spin size="small" /> : null}
            style={{ width: "100%" }}
            placeholder="Selecione um banco" value={valueLocal} onChange={onChangeLocal} showSearch
            filterOption={(input, option) => {
                return option && option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
        >
            {options.map(x =>
                <Select.Option value={x.code} label={x.name}>
                    {`${x.code} - ${x.name}`}
                </Select.Option>
            )}

        </Select>
    );
};

AutoCompleteBank.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onSelectedItem: PropTypes.func
};

export { AutoCompleteBank };
