import React from "react";
import { connect } from "react-redux";
import { Layout, Button, Select, Table } from 'antd';
import { Header } from "components";
import { SaveOutlined } from '@ant-design/icons';
import { useParams, useLocation } from "react-router-dom";
import { saveCompanyTeamLevel } from "services/business-unit-service";
import { getAllEmployees } from "./user-equip";

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const { Footer, Content } = Layout;

const DataFetch = {
    employees: [],
    levelBusinessUnits: [],
    loading: false
}

const UserLevelEquip = (props) => {
    const { login } = props;
    const location = useLocation();
    const { company = null } = location.state || {};
    const [data, setData] = React.useState(DataFetch);
    const params = useParams();
    const level = params.level ? Number(params.level) : null;

    const load = async () => {
        setData((x) => ({ ...x, loading: true }));
        const res = await getAllEmployees(login.company.master, company, level);
        setData({
            ...res,
            loading: false
        });
    }

    const editRow = (value, employee) => {
        console.log(value)
        const employees = [...data.employees];
        const findEmployee = employees.find(x => x.id === employee.id);
        if (findEmployee) {
            findEmployee.businessUnit.id = value
            setData((prev) => ({ ...prev, employees: employees }))
        }
    }

    React.useEffect(() => {
        load();
    }, [level])


    const save = async () => {
        setData((x) => ({ ...x, loading: true }));
        try {
            const body = {
                lines: data.levelBusinessUnits.map(x => ({
                    businessUnit: x.id,
                    employees: data.employees.filter(e => e.businessUnit.id === x.id).map(e => e.id)
                })),
                company: login.company.master ? company : null
            }
            await saveCompanyTeamLevel(body);
        } catch (e) {
            console.log(e)
        } finally {
            setData((x) => ({ ...x, loading: false }));
        }
    }

    return <Layout className="alymente-layout">
        <Header title={"Participantes do Nível" + (level && ` ${level}`)} />
        <Content>
            <Table rowKey={"id"} scroll={{ y: 800 }} dataSource={data.employees} loading={data.loading} pagination={false} bordered>
                <Table.Column title="Nome" dataIndex="name" key="name" />
                <Table.Column width={300} title="Email" dataIndex="email" key="email" />
                <Table.Column width={200} align="center" title="CPF" dataIndex="cpf" key="cpf" />
                <Table.Column width={200} title="Equipes" dataIndex="id" key="id"
                    render={(value, row) =>
                        <Select style={{ width: "100%" }} value={row.businessUnit.id} onChange={(e) => editRow(e, row)} allowClear>
                            {data.levelBusinessUnits.map(x => <Select.Option value={x.id}>{x.name}</Select.Option>)}
                        </Select>} />
            </Table>
        </Content>
        <Footer>
            <Button disabled={data.loading} loading={data.loading} className="btn-save" type="primary" icon={<SaveOutlined />} onClick={() => save()}>Salvar</Button>
        </Footer>
    </Layout>
}

export default connect(mapStateToProps, {})(UserLevelEquip);