import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Row, Col } from 'antd';
import { Header, CardCompanyBalance, CardInfo } from 'components';
import { formatMoney } from 'utils';

const mapStateToProps = (state) => ({
   login: state.authReducer.loginReducer,
   balance: state.balanceReducer,
});

const SolicitationTypeContainerScreen = (props) => {
   const { balance } = props;
   const isMasterCompany = ()=>{
      return props.login.company.master;
   }

   return (
      <Layout className="alymente-layout">
         <Header title="Selecione o tipo do pedido">
            <Row gutter={12}>
               <Col xxl={5} xl={8} lg={12}>
                  <CardCompanyBalance />
               </Col>
               {!isMasterCompany() && (
                  <Col xxl={5} xl={8} lg={12}>
                     <CardCompanyBalance type={'discount'} />
                  </Col>
               )}
            </Row>
         </Header>
         <Outlet />
      </Layout>
   );
};

export default connect(mapStateToProps, {})(SolicitationTypeContainerScreen);
