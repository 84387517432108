const ExpenseState = {
   createSolicitation: false,
   filter: {
      benefit: null,
      dates: [],
   },
};

const ExpenseReducer = (state = ExpenseState, action) => {
   const { payload } = action;
   switch (action.type) {
      case 'EXPENSE_FILTER_SOLICITATION':
         return Object.assign({}, state, {
            createSolicitation: true,
            filter: {
               benefit: payload.benefit,
               dates: payload.dates,
            },
         });
      case 'EXPENSE_FILTER_SOLICITATION_CLEAR':
         return Object.assign({}, state, {
            createSolicitation: false,
            filter: ExpenseState.filter,
         });
      default:
         return state;
   }
};

export default ExpenseReducer;
