import React from 'react';
import { Modal, Typography } from 'antd'
import QRCode from 'react-qr-code';
import LogoSwap from 'assets/img/logo-swap.svg'

export const SolicitationPix = (props) => {
    const { open = false, onClose, pixQRCode } = props;
    return (
        <Modal
            open={open}
            title="PIX de Pagamento"
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={onClose}
            onCancel={onClose}
        >
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                {pixQRCode &&
                    <React.Fragment>
                        <QRCode value={pixQRCode} />
                        <Typography.Text style={{ marginTop: 10, fontWeight: 900 }}>{pixQRCode}</Typography.Text>
                        <Typography.Text style={{ marginTop: 50 }}>Powered by Swap Meios de Pagamentos:</Typography.Text>
                        <img src={LogoSwap} width={100} alt="swap" />
                    </React.Fragment>
                }
            </div>
        </Modal>
    )
}