import { Input } from "antd";
import React, { forwardRef, useState, useEffect } from "react";
import ReactInputMask from "react-input-mask";
import PropTypes from "prop-types";

export const InputMask = forwardRef(function InputMask(props, ref) {
  const [internalValue, setInternalValue] = useState("");

  useEffect(() => {
    const fixedValue = getFixedValue(
      props.uppercase === true ? String(props.value).toUpperCase() : props.value
    );
    setInternalValue(fixedValue);
  }, [props.value, props.mask, props.uppercase]);

  const getFixedValue = (value) => {
    value = value ?? "";
    if (value.length > props.mask.length) {
      value = value.slice(0, props.mask.length);
    }
    return value;
  };

  const handleChange = (event) => {
    const fixedValue = getFixedValue(event.target.value);
    setInternalValue(fixedValue);
    props.onChange?.(fixedValue);
  };

  return (
    <ReactInputMask {...props} value={internalValue} onChange={handleChange}>
      {(inputProps) => {
        return (
          <Input
            {...inputProps}
            ref={ref}
            disabled={props.disabled ? props.disabled : null}
          />
        );
      }}
    </ReactInputMask>
  );
});

InputMask.propTypes = {
  mask: PropTypes.string.isRequired,
  maskChar: PropTypes.string,
  formatChars: PropTypes.object,
  alwaysShowMask: PropTypes.bool,
  inputRef: PropTypes.func,
  value: PropTypes.string,
  uppercase: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};
