import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Layout, Table, Row, Col, Typography, Tag, Descriptions, Checkbox, Spin, Popover, notification, Button, Tooltip, Modal } from 'antd';
import { getEmployee } from "services/employee-service";
import { getUserBalance, getStatement, getStatementResume, getTransaction } from "services/user-service";
import { Header, FormatMoney, FormatDate, LoadingText, UserBalance } from "components";
import { useParams } from "react-router-dom";
import { formatDate, formatMoney, getCountryByCode, isStaging } from "utils";
import { CopyOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { translate } from 'config/language';
import { MdAssignmentLate as AlertIcon } from "react-icons/md";

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const DataFetch = {
  data: [],
  loadingBalance: false,
  loadingUser: false,
  loadingStatement: false
}

const UserStatementScreen = (props) => {
  const { login } = props;
  const [data, setData] = React.useState(DataFetch)
  const [filter, setFilter] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [loadingPage, setLoadingPage] = React.useState(1)
  const [loadingCopy, setLoadingCopy] = React.useState(false)
  const [detailsShow, setDetailsShow] = React.useState(false)
  const [loadDetails, setLoadDetails] = React.useState(false)
  const [details, setDetails] = React.useState(null)
  const { id } = useParams();

  const load = async () => {
    setData((x) => ({ ...x, loadingBalance: true, loadingStatement: true, loadingUser: true }));
    const balance = await getUserBalance(id);
    setData((x) => ({ ...x, loadingBalance: false, balance: balance }))
    const userData = await getEmployee(id);
    setData((x) => ({ ...x, loadingUser: false, user: userData, swap: prepareIds(userData) }))
    getStatementUser(userData)
  }

  React.useEffect(() => {
    load();
  }, [])

  React.useEffect(() => {
    onFilter();
  }, [filter])

  React.useEffect(() => {
    if (page > 1)
      getStatementUser(data.user)
  }, [page])

  const getStatementUser = async (user) => {
    setLoadingPage(true)
    var url = user.companyExternal.accountSystem.code == 'swap' ? user.companyExternal.accountSystem.urlStatement : 
    (isStaging() ? "https://staging.alymente.com.br/dock/statement" : "https://app.alymente.com.br/dock/statement");
    const statement = await getStatementResume(user.companyExternal.externalKey, user.account, user.externalKey, page, url);
    const statementAux = [...(data.statement ? data.statement : []), ...statement]
    setData((x) => ({ ...x, loadingStatement: false, statement: statementAux, data: insertLoad(statementAux) }))
    setLoadingPage(false)
  }

  const getAllStatement = async () => {
    const statement = await getStatement(id, null);
    return statement
  }

  const prepareIds = (userData) => {
    return [
      { user: userData.externalKey, balanceAccount: userData.account, company: userData.companyExternal.externalKey }
    ]
  }

  const onChangeTransactionLink = (id, event) => {
    let checked = event.target.checked
    let filterAux = [...filter]
    if (checked)
      filterAux.push(id)
    else {
      const index = filterAux.indexOf(id);
      filterAux.splice(index, 1);
    }
    setFilter(filterAux)
  }

  const onFilter = () => {
    if (filter.length > 0) {
      let dataFilter = []
      for (let id of filter) {
        let links = data.statement.filter((t) => t.id == id)[0].transactionLink
        dataFilter = [...dataFilter, ...data.statement.filter((t) => t.id == id || links.includes(t.id))]
      }
      setData((x) => ({ ...x, data: dataFilter }))
    }
    else {
      setData((x) => ({ ...x, data: insertLoad(data.statement) }))
    }
  }

  const insertLoad = (statement) => {
    if (statement) {
      let statementAux = [...statement]
      statementAux.push({
        id: "Carregar mais...",
        load: true
      })
      return statementAux
    }
    return statement
  }

  const sharedOnCell = (_, index) => {
    if (index === data.statement.length) {
      return {
        colSpan: 0,
      };
    }

    return {};
  };

  const loadMore = () => {
    if (!loadingPage) {
      setPage(page + 1)
    }
  }

  const getContentValues = (row) => {
    return row.amountClearedNet != undefined && row.amountExpired != undefined ?
      <div style={{ width: 300 }}>
        <Row gutter={3}>
          <Col sm={16}>Transação confirmada:</Col>
          <Col sm={8}>{row.cleared ? 'Sim' : 'Não'}</Col>
        </Row>
        <Row gutter={3}>
          <Col sm={16}>Valor já confirmado:</Col>
          <Col sm={8}><FormatMoney value={row.amountClearedNet}></FormatMoney></Col>
        </Row>
        <Row gutter={3}>
          <Col sm={16}>Transação expirada:</Col>
          <Col sm={8}>{row.expired ? 'Sim' : 'Não'}</Col>
        </Row>
        <Row gutter={3}>
          <Col sm={16}>Valor expirado:</Col>
          <Col sm={8}><FormatMoney value={row.amountExpired}></FormatMoney></Col>
        </Row>
      </div>
      :
      <div>
        <p>Sem Detalhes!</p>
      </div>
  }

  const getAddressValues = ({ country, city, houseNumber, postalCode }) => {
    if (!country && !city && !houseNumber && !postalCode) {
      return (
        <div>
          <p>Sem Endereço associado!</p>
        </div>
      );
    }
    return (
      <div style={{ width: 300 }}>
        {country && (
          <Row gutter={3}>
            <Col sm={16}>País :</Col>
            <Col sm={8}>{country}</Col>
          </Row>
        )}
        {city && (
          <Row gutter={3}>
            <Col sm={16}>Cidade :</Col>
            <Col sm={8}>{city}</Col>
          </Row>
        )}
        {houseNumber && (
          <Row gutter={3}>
            <Col sm={16}>Número :</Col>
            <Col sm={8}>{houseNumber}</Col>
          </Row>
        )}
        {postalCode && (
          <Row gutter={3}>
            <Col sm={16}>Código postal :</Col>
            <Col sm={8}>{postalCode}</Col>
          </Row>
        )}
      </div>
    );
  };
  const createCsv = (arr, header) => {
    let csvContent = "";
    let h = header.join("\t") + "\n";
    csvContent += h

    for (let data of arr) {
      let d = Object.values(data).join("\t")
      csvContent += d + "\n";
    }

    return csvContent
  }

  const copy = async () => {
    setLoadingCopy(true)
    const statement = await getAllStatement()
    var transactions = []
    for (let t of statement) {
      if (!t.notBalance) {
        transactions.push({
          "Estabelecimento": t.name,
          "Data": t.date ? formatDate(t.date, "DD/MM/YYYY HH:MM") : "",
          "Valor": t.valueAuthorized ? ((t.reversal || t.name == "Carga" ? '' : '-') + (formatMoney(t.valueAuthorized / 100, false))) : 0,
          "Estorno": t.amountExpired > 0 ? (formatMoney(t.amountExpired - t.partialClearing, false)) : "",
        })
      }
    }
    let header = ["Estabelecimento", "Data", "Valor", "Estorno"]
    let csv = createCsv(transactions, header)
    navigator.clipboard.writeText(csv);
    notification.success({ message: `${translate('statement')} copiado para a área de transferência` });
    setLoadingCopy(false)
  }

  const openDetails = async (transaction, type) => {
    setLoadDetails(true)
    setDetailsShow(true)
    const detail = await getTransaction(transaction, type, id)
    setDetails(detail)
    setLoadDetails(false)
  }

  return (
    <Layout className="alymente-layout">
      <Header title={data.user?.name}>
        <Row></Row>
      </Header>
      <Content>
        <Row style={{ marginBottom: 20 }} justify="center">
          <UserBalance loading={data.loadingBalance} balance={data.balance} />
        </Row>
        <Descriptions labelStyle={{ fontWeight: "bold" }} column={3}>
          <Descriptions.Item label="User">
            <LoadingText
              loading={data.loadingUser}
              value={data.user?.externalKey}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Balance Account Group">
            <LoadingText
              loading={data.loadingUser}
              value={data.user?.account}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Company">
            <LoadingText
              loading={data.loadingUser}
              value={data.user?.companyExternal.externalKey}
            />
          </Descriptions.Item>
        </Descriptions>
        <Table
          scroll={{ x: 1300, y: 400 }}
          dataSource={data.data}
          loading={data.loadingStatement || loadingCopy}
          pagination={false}
          bordered
        >
          <Table.Column
            onCell={(_, index) => ({
              style: index == data.statement.length ? { padding: 0 } : {},
              colSpan: index == data.statement.length ? 6 : 1
            })}
            align={"center"}
            title="ID"
            dataIndex="id"
            key="id"
            render={(value, row) =>
              row.load ? (
                <div onClick={loadMore} className="loadMore">
                  {loadingPage ? <Spin /> : value}
                </div>
              ) : (
                value
              )
            }
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Data"
            dataIndex="date"
            key="date"
            render={(value, data, index) => (
              <>
                <FormatDate value={value} format={"DD/MM/YYYY HH:mm"} />
              </>
            )}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title={translate("statement")}
            dataIndex="name"
            key="name"
            render={(value, row) => (
              // <div style={{display:"flex",flexDirection:"column"}}>
              // {/* {row.service ? (
              // <Tooltip
              //   placement="bottom"
              //   title={"Serviço: " + row.service.toUpperCase()}
              // > */}
              value
              //   </Tooltip>
              //   ) : ( value )}
              //    {row.country || row.city || row.houseNumber ||
              //   row.postalCode ? (
              //   <Popover
              //     content={getAddressValues({...row,country:getCountryByCode(row.country)})}
              //     title={
              //       <div>
              //         <Text>Endereço</Text>
              //       </div>
              //     }
              //   >
              //     <a style={{color:"inherit"}} href={`https://www.google.com/maps/place/${getCountryByCode(row.country)}+${row.postalCode}/`} rel="noreferrer" target="_blank">
              //       <EnvironmentOutlined style={{ padding: "5px" }} />
              //     </a>
              //   </Popover>
              //   ) : null}
              // </div>
            )}
          />
          {/* <Table.Column
              onCell={sharedOnCell}
              align={"center"}
              title="Status"
              dataIndex="benefit"
              key="benefit"
              render={(value, row) => (
                <Tooltip title={row.declined ? row.declined.message : ""}>
                  <Tag
                    style={{ whiteSpace: "normal" }}
                    color={
                      row.declined
                        ? "error"
                        : row.promise
                        ? "warning"
                        : "success"
                    }
                  >
                    {row.promise
                      ? "PENDENTE"
                      : row.declined
                      ? row.declined.name.toUpperCase()
                      : "APROVADO"}
                  </Tag>
                </Tooltip>
              )}
            /> */}
          {/* <Table.Column onCell={sharedOnCell} align={"center"} title="Mensagem" dataIndex="code" key="code" render={(value, row) => row.declined ? row.declined.message : ""} /> */}
          {/* <Table.Column
              onCell={sharedOnCell}
              align={"center"}
              title="MCC"
              dataIndex="mcc"
              key="mcc"
            /> */}
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Valor"
            dataIndex="value"
            key="value"
            render={(value, row) => (
              // <Popover
              //   content={getContentValues(row)}
              //   title={
              //     <div style={{ textAlign: "center" }}>
              //       <Text>Resumo</Text>
              //     </div>
              //   }
              // >
              <div style={{display:"flex",flexDirection:"colunm"}}>
                {row.declined && (
                  <Tooltip title="Transação negada">
                    <AlertIcon color="#faad14" />
                  </Tooltip>
                )}
                <Text style={{flex: 1}}>
                  <FormatMoney
                    value={
                      (row.financialImpact == "credit" ? '+' : (row.financialImpact == "debit" ? '-' : "")) +
                      (row.declined ? 0 : value)
                    }
                  />
                </Text>
              </div>
              // </Popover>
            )}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Limite Usado"
            dataIndex="benefit"
            key="benefit"
            render={(value, row) => {
              return row.benefit ? (row.benefit.name != "" ? row.benefit.name : "Flexível") : ""
            }}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Detalhes"
            dataIndex="id"
            key="id"
            render={(value, row) => (
              <Button
                size="small"
                type={"link"}
                loading={false}
                onClick={() => openDetails(value, row.type)}
              >
                Detalhes
              </Button>
            )}
          />
          {/* <Table.Column
              onCell={sharedOnCell}
              align={"center"}
              title="Benefício"
              dataIndex="nameBenefit"
              key="nameBenefit"
            /> */}
          {/* <Table.Column
              onCell={sharedOnCell}
              align={"center"}
              title="Modo de Pagamento"
              dataIndex="mode"
              key="mode"
              render={(value, row) => (
                <Tooltip title={row.modeMessage != "" ? row.modeMessage : ""}>
                  <Text>
                    {row.mode != null && row.mode != "" ? row.mode.name : ""}
                  </Text>
                </Tooltip>
              )}
            /> */}
          {/* {data.statement &&
              data.statement.length > 0 &&
              data.statement[0].merchantKey != undefined && (
                <Table.Column
                  onCell={sharedOnCell}
                  align={"center"}
                  title="Merchant"
                  dataIndex="merchantKey"
                  key="merchantKey"
                />
              )}
            {data.statement &&
              data.statement.length > 0 &&
              data.statement[0].transactionLink != undefined && (
                <Table.Column
                  onCell={sharedOnCell}
                  align={"center"}
                  title="Transações Associadas"
                  dataIndex="transactionLink"
                  key="transactionLink"
                  render={(value, row) =>
                    value && value.length > 0 ? (
                      <Checkbox
                        checked={filter.includes(row.id) ? true : false}
                        onChange={(e) => onChangeTransactionLink(row.id, e)}
                      />
                    ) : (
                      ""
                    )
                  }
                />
              )} */}
        </Table>
      </Content>
      <Footer>
        <Button
          loading={loadingCopy}
          type="primary"
          icon={<CopyOutlined />}
          onClick={copy}
        >
          Copiar
        </Button>
      </Footer>

      <Modal
        open={detailsShow}
        title="Detalhes"
        onOk={() => setDetailsShow(false)}
        onCancel={() => setDetailsShow(false)}
      >
        {
          (loadDetails || !details) ?
            <Spin spinning={loadDetails}>
            </Spin>
            :
            <Descriptions title="" column={1}>
              <Descriptions.Item label="ID">{details ? details.id : ""}</Descriptions.Item>
              <Descriptions.Item label="Data">{details ? <FormatDate value={details.date} format={"DD/MM/YYYY HH:mm"} /> : ""}</Descriptions.Item>
              <Descriptions.Item label="Fatura">{
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {details.service ? (
                    <Tooltip
                      placement="bottom"
                      title={"Serviço: " + details.service.toUpperCase()}
                    >
                      {details.name}
                    </Tooltip>
                  ) : (details.name)}
                  {details.country || details.city || details.houseNumber ||
                    details.postalCode ? (
                    <Popover
                      content={getAddressValues({ ...details, country: getCountryByCode(details.country) })}
                      title={
                        <div>
                          <Text>Endereço</Text>
                        </div>
                      }
                    >
                      <a style={{ color: "inherit" }} href={`https://www.google.com/maps/place/${getCountryByCode(details.country)}+${details.postalCode}/`} rel="noreferrer" target="_blank">
                        <EnvironmentOutlined style={{ padding: "5px" }} />
                      </a>
                    </Popover>
                  ) : null}
                </div>
              }</Descriptions.Item>
              <Descriptions.Item label="Status">
                <Tooltip title={details.declined ? details.declined.message : ""}>
                  <Tag
                    style={{ whiteSpace: "normal" }}
                    color={
                      details.declined
                        ? "error"
                        : details.promise
                          ? "warning"
                          : "success"
                    }
                  >
                    {details.promise
                      ? "PENDENTE"
                      : details.declined
                        ? details.declined.name.toUpperCase()
                        : "APROVADO"}
                  </Tag>
                </Tooltip>
              </Descriptions.Item>
              <Descriptions.Item label="Mensagem">
                {details.declined ? details.declined.message : ""}
              </Descriptions.Item>
              <Descriptions.Item label="MCC">
                {details.mcc ? details.mcc : ""}
              </Descriptions.Item>
              <Descriptions.Item label="Valor">
                {
                  <Popover
                    content={getContentValues(details)}
                    title={
                      <div style={{ textAlign: "center" }}>
                        <Text>Resumo</Text>
                      </div>
                    }
                  >
                    <Text>
                      <FormatMoney
                        value={
                          (details.financialImpact == "credit" ? '+' : (details.financialImpact == "debit" ? '-' : "")) +
                          details.value
                        }
                      />
                    </Text>
                  </Popover>
                }
              </Descriptions.Item>
              <Descriptions.Item label="Limite Usado">{
                details.balanceUsage ? (details.balanceUsage.name != "" ? details.balanceUsage.name : "Flexível") : ""
              }</Descriptions.Item>
              <Descriptions.Item label="Benefício">{
                details.benefit ? (details.benefit.name != "" ? details.benefit.name : "Flexível") : ""
              }</Descriptions.Item>
              <Descriptions.Item label="Modo de Pagamento">{
                <Tooltip title={details.modeMessage != "" ? details.modeMessage : ""}>
                  <Text>
                    {details.mode != null && details.mode != "" ? details.mode.name : ""}
                  </Text>
                </Tooltip>
              }</Descriptions.Item>
              <Descriptions.Item label="Merchant Key">{
                details.merchantKey
              }</Descriptions.Item>
            </Descriptions>
        }
      </Modal>
    </Layout>
  );
}

export default connect(mapStateToProps, {})(UserStatementScreen);