import React from "react";
import { connect } from "react-redux";
import { Layout, Button, Row, Col, Table } from 'antd';
import { Header, ColumnEmployee, TableSearch } from "components";
import { SaveOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useParams, useLocation } from "react-router-dom";
import { saveUserUnit } from "services/business-unit-service";
import { getAllEmployee } from "services/employee-service";

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const { Footer, Content } = Layout;

const DataFetch = {
    all_employees: [],
    unit_employees: [],
    bu: {},
    loading: false
}

const UserEmployeesEquip = (props) => {
    const { login } = props;
    const location = useLocation();
    const { company = null } = location.state || {};
    const [data, setData] = React.useState(DataFetch);
    const params = useParams();
    const id = params.id ? Number(params.id) : null;
    const [selectedRowKeysInsert, setSelectedRowKeysInsert] = React.useState([]);
    const [selectedRowKeysRemove, setSelectedRowKeysRemove] = React.useState([]);

    const load = async () => {
        setData((x) => ({ ...x, loading: true }));
        const res = login.company.master && company ? await getAllEmployee(company, true) : await getAllEmployee(null, true)
        const employees_bu = res.businessUnits.filter(bu => bu.id === id)[0].employees
        setData({
            bu: res.businessUnits.filter(bu => bu.id === id)[0],
            all_employees: res.employees.map(u => {
                return { ...u, show: true }
            }),
            unit_employees: employees_bu.map(u => {
                return { ...u, show: true }
            }),
            loading: false
        });
    }

    React.useEffect(() => {
        load();
    }, [id])

    const insertUser = (users) => {
        const usersInsert = data.all_employees.filter(e => users.includes(e.id))
        setData((x) => ({ ...x, unit_employees: [...usersInsert, ...data.unit_employees] }));
        setSelectedRowKeysInsert([])
        setSelectedRowKeysRemove([])
    }

    const removeUser = (users) => {
        const newUsers = [...data.unit_employees]
        for (const user of users) {
            const indexOfObject = newUsers.findIndex(object => {
                return object.id == user;
            });
            newUsers.splice(indexOfObject, 1)
        }
        setData((x) => ({ ...x, unit_employees: [...newUsers] }));
        setSelectedRowKeysInsert([])
        setSelectedRowKeysRemove([])
    }

    const save = async () => {
        setData((x) => ({ ...x, loading: true }));
        try {
            const body = {
                lines: data.unit_employees.map(u => {
                    return { user: u.id }
                }),
                company: login.company.master ? company : null
            }
            let res = await saveUserUnit(id, body);
            load()
        } catch (e) {
            console.log(e)
        } finally {
        }
    }

    return <Layout className="alymente-layout">
        <Header title={"Participantes da Equipe"} />
        <Content>
            <Row gutter={16}>
                <Col lg={12}>
                    <Table rowKey={"id"} scroll={{ y: 800 }} dataSource={data.all_employees} loading={data.loading} pagination={true} bordered
                        rowSelection={{
                            selectedRowKeys: selectedRowKeysInsert,
                            onChange: setSelectedRowKeysInsert,
                            getCheckboxProps: (d) => ({
                                disabled: data.unit_employees.filter(e => e.id == d.id).length > 0
                            })
                        }}
                        title={() =>
                            <div style={{ display: 'flex' }}>
                                {
                                    selectedRowKeysInsert.length > 0 &&
                                    <Button size="small" type={"link"} onClick={() => insertUser(selectedRowKeysInsert)}>Adicionar Selecionados</Button>
                                }
                                <div style={{ flex: 1 }}>
                                    <TableSearch data={data.all_employees} filter={["name", "email", "cpf"]}
                                        onSearch={(e) => setData((prev) => ({ ...prev, all_employees: e }))}
                                    />
                                </div>
                            </div>
                        } >
                        <Table.Column title="Todos os colaboradores" dataIndex="name" key="name" render={(value, row) => <ColumnEmployee name={row.name} email={row.email} cpf={row.cpf} />} />
                        <Table.Column width={80} align="center" title="Ações" dataIndex="id" key="id" render={(value, row) => <Button type="primary" disabled={data.unit_employees.filter(e => e.id == value).length > 0} shape="circle" icon={<PlusOutlined />} onClick={() => insertUser([value])}></Button>} />
                    </Table>
                </Col>
                <Col lg={12}>
                    <Table rowKey={"id"} scroll={{ y: 800 }} dataSource={data.unit_employees.filter(e => e.show == true)} loading={data.loading} pagination={true} bordered
                        rowSelection={{
                            selectedRowKeys: selectedRowKeysRemove,
                            onChange: setSelectedRowKeysRemove
                        }}
                        title={() =>
                            <div style={{ display: 'flex' }}>
                                {
                                    selectedRowKeysRemove.length > 0 &&
                                    <Button size="small" type={"link"} onClick={() => removeUser(selectedRowKeysRemove)}>Remover Selecionados</Button>
                                }
                                <div style={{ flex: 1 }}>
                                    <TableSearch data={data.unit_employees} filter={["name", "email", "cpf"]}
                                        onSearch={(e) => {
                                            let users = [...data.unit_employees]
                                            for (let user of users) {
                                                if (e.filter(employee => employee.id == user.id).length > 0) {
                                                    user.show = true
                                                } else {
                                                    user.show = false
                                                }
                                            }
                                            setData((prev) => ({ ...prev, unit_employees: users }))
                                        }}
                                    />
                                </div>
                            </div>
                        }>
                        <Table.Column title={data.bu.name ? data.bu.name : ""} dataIndex="name" key="name" render={(value, row) => <ColumnEmployee name={row.name} email={row.email} cpf={row.cpf} />} />
                        <Table.Column width={80} align="center" title="Ações" dataIndex="id" key="id" render={(value, row) => <Button type="primary" shape="circle" icon={<MinusOutlined />} onClick={() => removeUser([value])}></Button>} />
                    </Table>
                </Col>
            </Row>
        </Content>
        <Footer>
            <Button disabled={data.loading} loading={data.loading} className="btn-save" type="primary" icon={<SaveOutlined />} onClick={() => save()}>Salvar</Button>
        </Footer>
    </Layout>
}

export default connect(mapStateToProps, {})(UserEmployeesEquip);