import axios from "axios";
import { handlerError } from "utils";

export const searchCities = async (search) => {
  try {
    const { data } = await axios.get(`/api/city/`, {
      params: { city: search }
    });
    return data.data ? data.data : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};
