import axios from "axios";
import { handlerError } from "utils";
import { notification } from "antd";

export const createPixQRCodeMaster = async (value) => {
  try {
    const req = {
      value: value
    };
    const res = await axios.post(`/api/company/pix/qrcode`, req);
    notification.success({
      message: "Pix criado com sucesso."
    });
    return res.data ? res.data.qrcode : null;
  } catch (ex) {
    handlerError(ex);
    throw null;
  }
};
