import axios from "axios";
import { notification } from "antd";
import { handlerError } from "utils";

export const getLastBatch = async () => {
  try {
    const { data } = await axios.get(`/api/transfeera/lastBatch`);
    return data && data.data && data.data.batchId !== -1 ? data.data : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const openBatch = async () => {
  try {
    await axios.post(`/api/transfeera/batch`, {});
    notification.success({
      message: "Lote aberto com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
  }
};

export const closeLastBatch = async () => {
  try {
    await axios.delete(`/api/transfeera/batch`, {});
    notification.success({
      message: "Lote fechado com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
  }
};
