import React from "react";
import { connect } from "react-redux";
import { Layout, Table } from 'antd';
import { useParams } from "react-router-dom";
import { getCompaniesUsers } from "services/user-service";
import { Header, FormatDate } from "components";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const DataFetch = {
    data: [],
    loading: false
}

const UserHistoryScreen = (props) => {
    const { id } = useParams();
    const [data, setData] = React.useState(DataFetch)

    const load = async () => {
        setData((x) => ({ ...x, loading: true }));
        const res = await getCompaniesUsers(id);
        setData({ data: res, loading: false });
    }

    React.useEffect(() => {
        load();
    }, [])

    return (
        <Layout className="alymente-layout">
            <Header title="Histórico de Empresas"></Header>
            <Content>
                <Table scroll={{ y: 650, x: 1000 }} dataSource={data.data} loading={data.loading} pagination={false} bordered >
                    <Table.Column width={180} align={"center"} title="Data" dataIndex="createdAt" key="createdAt" render={(value) => <FormatDate value={value} />} />
                    <Table.Column align={"left"} title="Empresa Origem" dataIndex={["fromCompany", "name"]} key="fromCompany.name" />
                    <Table.Column align={"left"} title="Empresa Destino" dataIndex={["toCompany", "name"]} key="toCompany.name" />
                    <Table.Column align={"left"} title="Cartão Bloqueado" dataIndex="card" key="card" />
                    <Table.Column align={"center"} title="Descrição" width={250} dataIndex="name" key="name" />
                </Table>
            </Content>
        </Layout>
    )
}

export default connect(mapStateToProps, {})(UserHistoryScreen);