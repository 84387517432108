const TOKEN = "token";
export const setAuthorization = (token) => {
    localStorage.setItem(TOKEN, token);
}

export const removeAuthorization = () => {
    localStorage.removeItem(TOKEN);
}

export const getAuthorization = () => {
    return localStorage.getItem(TOKEN);
}

export const isAuthorization = () => {
    return localStorage.getItem(TOKEN) != null;
}
