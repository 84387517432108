import React from "react";
import { connect } from "react-redux";
import { Layout, Row, Col, Modal, Button, notification } from 'antd';
import { CardInfo } from "components";
import UserDetailsScreen from "views/user/user-details-screen";
import UserScreen from "views/user/user-screen";
import { SaveOutlined, PlusOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useOutletContext } from 'react-router-dom';

const { Content, Footer } = Layout;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const ActivationUserScreen = (props) => {
    const { login } = props;
    const childRef = React.useRef("");
    const [userEdit, setUserEdit] = React.useState(null);
    const [refresh, setRefresh] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [usersTotal, setUsersTotal] = React.useState(0);
    const [add, setAdd] = React.useState(false);
    const userRef = React.useRef("");
    const { onNext } = useOutletContext();

    const editUser = (user) => {
        setUserEdit(user)
    }

    const onSubmit = () => {
        if (usersTotal < login.company.employees) {
            notification.error({ message: `Mínimo de ${login.company.employees} cadastrados.` })
        } else {
            onNext();
        }
    }

    return (
        <React.Fragment>
            <Modal
                title="Inserir um novo sócio"
                open={add}
                okText={'Salvar novo sócio'}
                cancelText={'Voltar'}
                onOk={() => {
                    setLoading(true);
                    userRef.current.submit()

                }}
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: loading
                }}
                confirmLoading={false}
                onCancel={() => { setAdd(false) }}
                width={'100%'}
            >
                <UserDetailsScreen user={userEdit} ref={userRef} compact={true} onSubmit={() => {
                    setUserEdit(null)
                    setRefresh(!refresh)
                    setAdd(false)
                    setLoading(false)
                }} />
            </Modal>
            <Content>
                <Row gutter={12} align="middle">
                    <Col xl={5}>
                        <CardInfo title="Colaboradores Contratados" value={login.company.employees} />
                    </Col>
                    <Col xl={5}>
                        <CardInfo title="Colaboradores Cadastrados" value={usersTotal} />
                    </Col>
                    <Col xs={24} style={{ textAlign: 'end' }}>
                        <Button type='primary' icon={<PlusOutlined />} onClick={() => { setAdd(true) }}>Adicionar Novo Colaborador</Button>
                    </Col>
                    <Col xs={24}>
                        <UserScreen
                            company={login.company.id}
                            ref={childRef}
                            compact={true}
                            onEditCompact={editUser}
                            refresh={refresh}
                            onLoadUsers={(users) => setUsersTotal(users.length || 0)}
                        />
                    </Col>
                </Row>
            </Content>
            <Footer>
                <Button type="primary" icon={<ArrowRightOutlined />} onClick={onSubmit}>Próxima Etapa</Button>
            </Footer>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, {})(ActivationUserScreen);