import React, { useEffect, useMemo, useState } from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import { isAuthorization } from "config/service/auth-service";
import { ContainerAuth } from "views/auth/container-auth";

import LoginScreen from "views/auth/login-screen";
import ForgotScreen from "views/auth/forgot-screen";
import ResetScreen from "views/auth/reset-screen";
import CompaniesScreen from "views/auth/companies-screen";
import TwoFaScreen from "views/auth/two-fa-screen";

//Home View
import HomeScreen from "views/home/home-screen";
import HomeDashboardScreen from "views/home/home-dashboard-screen";
import HomeFotobaseScreen from "views/home/home-fotobase-screen";

//Activation View
import ActivationCompanyScreen from "views/activation/activation-company-screen";
import ActivationContainerScreen from "views/activation/activation-container-screen";
import ActivationShareholderScreen from "views/activation/activation-shareholder-screen";
import ActivationUserScreen from "views/activation/activation-user-screen";
import ActivationCheckoutCardScreen from "views/activation/activation-checkout-card-screen";
import ActivationCheckoutChargeScreen from "views/activation/activation-checkout-charge-screen";
import ActivationSolicitationScreen from "views/activation/activation-solicitation-screen";

//Benefit View
import BenefitContainerScreen from "views/benefit/benefit-container-screen";
import BenefitScreen from "views/benefit/benefit-screen";
import IncentiveScreen from "views/benefit/incentive-screen";
import CatalogScreen from "views/benefit/catalog-screen";

//Solicitation View
import SolicitationScreen from "views/solicitation/solicitation-screen";
import SolicitationTypeContainerScreen from "views/solicitation/solicitation-type-container-screen";
import SolicitationTypeScreen from "views/solicitation/solicitation-type-screen";
import SolicitationEmployeeScreen from "views/solicitation/solicitation-employee-screen";
import SolicitationSaveScreen from "views/solicitation/solicitation-save-screen";
import SolicitationCreditScreen from "views/solicitation/solicitation-credit-screen";
import SolicitationRefundScreen from "views/solicitation/solicitation-refund-screen";
import SolicitationRevisionScreen from "views/solicitation/solicitation-revision-screen";
import SolicitationReportScreen from "views/solicitation/solicitation-report-screen";
import SolicitationDetailsScreen from "views/solicitation/solicitation-details-screen";
import SolicitationTreasuryScreen from "views/solicitation/solicitation-treasury-screen";
import SolicitationBalanceScreen from "views/solicitation/solicitation-balance-screen";
import SolicitationCashFlow from "views/solicitation/solicitation-cash-flow";
import SolicitationAdvanceScreen from "views/solicitation/solicitation-advance-screen";

//Company View
import CompanyScreen from "views/company/company-screen";
import CompanyStatementScreen from "views/company/company-statement-screen";
import CompanyDetailsScreen from "views/company/company-details-screen";
import CompanyShareholderScreen from "views/company/company-shareholder-screen";
import CompanyAdvantagesScreen from "views/company/company-advantages-screen";

//Refunds View
import RefundScreen from "views/refund/refund-screen";
import RefundDetailsScreen from "views/refund/refund-details-screen";

//User View
import UserScreen from "views/user/user-screen";
import UserStatementScreen from "views/user/user-statement-screen";
import UserDetailsScreen from "views/user/user-details-screen";
import ParentAdministratorScreen from "views/user/parent-administrator-screen";
import UserBatchScreen from "views/user/user-batch-screen";
import UserRedeemScreen from "views/user/user-redeem-screen";
import UserAdvantagesScreen from "views/user/user-advantages-screen";
import UserChargeScreen from "views/user/user-charge-screen";
import UserChangeCompanyScreen from "views/user/user-change-company-screen";
import BenefitManagerScreen from "views/user/benefit-manager-screen";
import UserCardManagerScreen from "views/user/manager/user-card-manager-screen";
import UserHistoryScreen from "views/user/user-history-screen";
import UserOtherManagerScreen from "views/user/manager/user-other-manager-screen";
import UserPasswordScreen from "views/user/user-password-screen";
import UserDetailsManager from "views/user/manager/user-details-manager";

//Equip View
import UserEquipScreen from "views/user/equip/user-equip-screen";
import UserEmployeesEquip from "views/user/equip/user-employees-equip";
import UserLevelEquip from "views/user/equip/user-level-equip";

//Card View
import CardTypeScreen from "views/card/card-type-screen";
import CardOrderEmployeeScreen from "views/card/card-order-employee-screen";
import CardOrderCompanyScreen from "views/card/card-order-company-screen";
import CardShipmentsScreen from "views/card/card-shipments-screen";
import CardListScreen from "views/card/card-list-screen";
import CardSolicitationsScreen from "views/card/card-solicitations-screen";

//Transfer View
import TransferContainerScreen from "views/transfer/transfer-container-screen";
import TransferScreen from "views/transfer/transfer-screen";
import TransferRefundScreen from "views/transfer/transfer-refund-screen";

//Expenses View
import ExpenseScreen from "views/expense/expense-screen";
import ExpenseReportScreen from "views/expense/expense-report-screen";
import ExpenseDetailsScreen from "views/expense/expense-details-screen";

//Vehicles View
import VehicleScreen from "views/vehicle/vehicle-screen";
import VehicleDetailsScreen from "views/vehicle/vehicle-details-screen";
import VehicleStatementScreen from "views/vehicle/vehicle-statement-screen";
import VehicleReportScreen from "views/vehicle/vehicle-report-screen";
import VehicleRedeemScreen from "views/vehicle/vehicle-redeem-screen";

//ExpenseType View
import ExpenseTypeScreen from "views/expense-type/expense-type-screen";

//Value Transfer View
import ValueTransferScreen from "views/value-transfer/value-transfer-screen";

import LoggedRoutes from "./logged-routes";
import UserRegisterManagerScreen from "views/user/manager/user-register-manager-screen";
/*
import Login from 'supabase/view/login';
import PosLogin from 'supabase/view/poslogin';
import SessionProvider from 'supabase/providers/SessionProvider';
import supabaseAuthenticationService from 'supabase/services/auth/supabaseAuthenticationService';
*/
import Wootric from "components/nps/wootric";
import SolicitationCarFleetScreen from "views/solicitation/solicitation-car-fleet-screen";
import BillScreen from "views/bill/bill-screen";
//
//Advance View
import AdvanceScreen from "views/advance/advance-screen";
import AdvanceDetailsScreen from "views/advance/advance-details-screen";
import AdvanceRedeemScreen from "views/advance/advance-redeem-screen";

function RequireAuth({ children, redirectTo }) {
  let isAuthenticated = isAuthorization();
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}
export const MainRoutes = (props) => {
  const [testSupabase] = useState(false);
  return (
    <BrowserRouter>
      <Routes>
        {/*testSupabase &&
          <>
          <Route path="/supa" element={<Login />} />
            <Route path="/supalogged">
               <Route
                  index
                  element={
                     <SessionProvider authenticationService={supabaseAuthenticationService}>
                        <PosLogin />
                     </SessionProvider>
                  }
               />
            </Route>
            </>
          */}
        <Route path="*" element={<Navigate to="/auth/login" />} />
        <Route path="auth" element={<ContainerAuth />}>
          <Route path={"login"} element={<LoginScreen />} />
          <Route path={"forgot"} element={<ForgotScreen />} />
          <Route path={"reset"} element={<ResetScreen />} />
          <Route path={"companies"} element={<CompaniesScreen />} />
          <Route path={"2fa"} element={<TwoFaScreen />} />
        </Route>
        <Route
          path="/"
          element={
            <RequireAuth redirectTo="/auth/login">
              <LoggedRoutes />
            </RequireAuth>
          }
        >
          <Route index element={<Navigate to="/activation" />} />
          <Route path="activation" element={<ActivationContainerScreen />}>
            <Route path="company" element={<ActivationCompanyScreen />} />
            <Route
              path="shareholder"
              element={<ActivationShareholderScreen />}
            />
            <Route path="user" element={<ActivationUserScreen />} />
            <Route path="card" element={<ActivationCheckoutCardScreen />} />
            <Route path="charge" element={<ActivationCheckoutChargeScreen />} />
            <Route
              path="solicitation"
              element={<ActivationSolicitationScreen />}
            />
          </Route>
          <Route path="home" element={<HomeScreen />}>
            <Route index element={<HomeDashboardScreen />} />
            <Route path="fotobase" element={<HomeFotobaseScreen />} />
          </Route>
          <Route path="benefit" element={<BenefitContainerScreen />}>
            <Route index element={<BenefitScreen />} />
            <Route path="incentive" element={<IncentiveScreen />} />
            <Route path="catalog" element={<CatalogScreen />} />
          </Route>
          <Route path="refund">
            <Route index element={<RefundScreen />} />
            <Route path=":id">
              <Route index element={<RefundDetailsScreen />} />
            </Route>
          </Route>
          <Route path="company">
            <Route index element={<CompanyScreen />} />
            <Route path="statement" element={<CompanyStatementScreen />} />
            <Route path="add" element={<CompanyDetailsScreen />} />
            <Route path=":id">
              <Route index element={<CompanyDetailsScreen />} />
              <Route
                path="shareholder"
                element={<CompanyShareholderScreen />}
              />
              <Route path="advantages" element={<CompanyAdvantagesScreen />} />
            </Route>
          </Route>
          <Route path="expense">
            <Route index element={<ExpenseScreen />} />
            <Route path={"report"} element={<ExpenseReportScreen />} />
            <Route path=":id">
              <Route index element={<ExpenseDetailsScreen />} />
            </Route>
          </Route>
          <Route path="expense-type">
            <Route index element={<ExpenseTypeScreen />} />
          </Route>
          <Route path="value-transfer">
            <Route index element={<ValueTransferScreen />} />
          </Route>
          <Route path="vehicle">
            <Route index element={<VehicleScreen />} />
            <Route path="add" element={<VehicleDetailsScreen />} />
            <Route path="report" element={<VehicleReportScreen />} />
            <Route path=":id">
              <Route index element={<VehicleDetailsScreen />} />
              <Route path="statement" element={<VehicleStatementScreen />} />
              <Route path="redeem" element={<VehicleRedeemScreen />} />
            </Route>
          </Route>

          <Route path="solicitation">
            <Route index element={<SolicitationScreen />} />
            <Route path=":id">
              <Route index element={<SolicitationDetailsScreen />} />
              <Route path="report" element={<SolicitationReportScreen />} />
            </Route>
            <Route path="type" element={<SolicitationTypeContainerScreen />}>
              <Route
                index
                element={<SolicitationTypeScreen type="default" />}
              />
              <Route
                path="employee"
                element={<SolicitationTypeScreen type="employee" />}
              />
            </Route>
            <Route path="charge">
              <Route path="carFleet" element={<SolicitationCarFleetScreen />} />
              <Route path=":type">
                <Route index element={<SolicitationEmployeeScreen />} />
                <Route path="save" element={<SolicitationSaveScreen />} />
              </Route>
              <Route path="revision" element={<SolicitationRevisionScreen />} />
            </Route>
            <Route path="credit">
              <Route index element={<SolicitationCreditScreen />} />
            </Route>
            <Route path="refund">
              <Route index element={<SolicitationRefundScreen />} />
            </Route>
            <Route path="treasury">
              <Route index element={<SolicitationTreasuryScreen />} />
            </Route>
            <Route path="balance">
              <Route index element={<SolicitationBalanceScreen />} />
            </Route>
            <Route path="cashflow">
              <Route index element={<SolicitationCashFlow />} />
            </Route>
            <Route path="advance">
              <Route index element={<SolicitationAdvanceScreen />} />
              <Route path="save" element={<SolicitationSaveScreen />} />
            </Route>
          </Route>
          <Route path="user">
            <Route index element={<UserScreen />} />
            <Route path=":id">
              <Route index element={<UserDetailsScreen />} />
              <Route path="statement" element={<UserStatementScreen />} />
              <Route path="redeem" element={<UserRedeemScreen />} />
              <Route path="advantages" element={<UserAdvantagesScreen />} />
              <Route path="solicitations" element={<UserChargeScreen />} />
              <Route path="company" element={<UserChangeCompanyScreen />} />
              <Route path="history" element={<UserHistoryScreen />} />
              <Route path="forgot" element={<UserPasswordScreen />} />
              <Route path="manager" element={<UserDetailsManager />} />
            </Route>
            <Route path="add" element={<UserDetailsScreen />} />
            <Route
              path="parent/administrator"
              element={<ParentAdministratorScreen />}
            />
            <Route path="benefit/manager" element={<BenefitManagerScreen />} />
            <Route path="batch" element={<UserBatchScreen />} />
            <Route path="equip">
              <Route index element={<UserEquipScreen />} />
              <Route path=":id/employees" element={<UserEmployeesEquip />} />
              <Route path="level/:level" element={<UserLevelEquip />} />
            </Route>
            <Route path="manager">
              <Route path="card" element={<UserCardManagerScreen />} />

              <Route path="register" element={<UserRegisterManagerScreen />} />
              <Route path="others" element={<UserOtherManagerScreen />} />
            </Route>
          </Route>
          <Route path="bill">
            <Route index element={<BillScreen />} />
          </Route>
          <Route path="advance">
            <Route index element={<AdvanceScreen />} />
            <Route path="redeem" element={<AdvanceRedeemScreen />} />
            <Route path=":id">
              <Route index element={<AdvanceDetailsScreen />} />
            </Route>
          </Route>
          <Route path="card">
            <Route index element={<CardTypeScreen />} />
            <Route path="employee" element={<CardOrderEmployeeScreen />} />
            <Route path="company/:type" element={<CardOrderCompanyScreen />} />
            <Route path="shipments" element={<CardShipmentsScreen />} />
            <Route path="list" element={<CardListScreen />} />
            <Route path="solicitations" element={<CardSolicitationsScreen />} />
          </Route>
          <Route path="transfer" element={<TransferContainerScreen />}>
            <Route index element={<TransferScreen />} />
            <Route path="refund" element={<TransferRefundScreen />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
