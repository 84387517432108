export const PrivateRoutes = [
  { url: "/home", label: "Home", context: "home" },
  { url: "/benefit", label: "Benefícios", context: "cbenefit" },
  { url: "/solicitation", label: "Pedidos", context: "solicitations" },
  { url: "/refund", label: "Reembolsos", context: "refund" },
  { url: "/company", label: "Empresas", context: "companies" },
  { url: "/user", label: "Colaboradores", context: "employees" },
  { url: "/card", label: "Cartão", context: "card" },
  { url: "/transfer", label: "Transferências", context: "transfers" },
  { url: "/expense", label: "Gastos", context: "expense" },
  { url: "/expense-type", label: "Tipos de Despesas", context: "expenseType" },
  { url: "/vehicle", label: "Veículos", context: "vehicles" },
  { url: "/bill", label: "Boletos", context: "bill" },
  { url: "/advance", label: "Adiantamento", context: "advance" },
  {
    url: "/value-transfer",
    label: "Transferência de Valores",
    context: "valueTransfer"
  }
];
