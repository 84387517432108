import React from "react";
import { connect } from "react-redux";
import { Outlet, useNavigate } from 'react-router-dom';
import { Steps, Layout, Row, Col } from 'antd';
import { Header, LoadingScreen } from "components";
import { config } from "./activation";
import { getCompanyActivation } from 'services/company-service';

const { Step } = Steps;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const ActivationContainerScreen = (props) => {
    const { login } = props;
    const navigate = useNavigate();
    const [current, setCurrent] = React.useState(null);
    const childRef = React.useRef("");

    const load = async () => {
        const res = await getCompanyActivation(login.company.id);
        if (res !== null) {
            setCurrent(res);
        } else {
            navigate("/home")
        }
    }

    const onNext = async () => {
        await load();
    }

    React.useEffect(() => {
        if (current !== null) {
            navigate(config[current].route);
        }
    }, [current])

    React.useEffect(() => {
        load();
    }, [login.company])

    if (current !== null) {
        return (
            <Layout className="alymente-layout">
                <Header title={config[current].title} subtitle={config[current].subtitle}>
                    <Row>
                        <Col xl={login.company.employees > 0 ? 24 : 12} lg={24}>
                            <Steps
                                type="navigation"
                                size="small"
                                current={current}
                            >
                                {Object.keys(config).filter(x => login.company.employees > 0 ? true : x < 2).map(x => {
                                    const index = parseInt(x);
                                    return (
                                        <Step
                                            status={current === index ? "process" : (current > index ? "finish" : "wait")}
                                            title={config[index].step}
                                            disabled
                                        />)
                                })}
                            </Steps>
                        </Col>
                    </Row>
                </Header>
                <Outlet context={{ onNext, childRef }} />
                {/* <Content>
                    <Layout style={{ marginTop: 20 }}>
                        
                    </Layout>
                </Content>
                <Footer>
                    <Button loading={loading} type="primary" icon={<ArrowRightOutlined />} onClick={onSubmitNext}>Próxima Etapa</Button>
                </Footer> */}
            </Layout>
        )
    } else { return <LoadingScreen color={"#fff"} /> }
}

export default connect(mapStateToProps, {})(ActivationContainerScreen);