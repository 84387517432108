import React from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Layout,
  Spin,
  Button,
  Select,
  Row,
  Card,
  Col,
  Space,
  Text,
  Typography,
  notification
} from "antd";
import {
  FileExcelOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  SaveOutlined
} from "@ant-design/icons";
import { FormatMoney, Header, InputCurrency, Form } from "components";
import Env from "env";
import usePage from "hooks/usePage";
import { formatMoney } from "utils";
import { getCompany, updateValueTransfer } from "services/company-service";
import { getAllChildrenCompanies } from "services/company-service";
import { getTotalBalance } from "services/company-service";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const ValueTransfer = (props) => {
  const { login } = props;
  const [form] = Form.useForm();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [getChildrenCompanies, setGetChildrenCompanies] = React.useState([]);
  const [deEmpresa, setDeEmpresa] = React.useState(null);
  const [deSaldoOptions, setDeSaldoOptions] = React.useState([]);
  const [paraEmpresa, setParaEmpresa] = React.useState(null);
  const [paraSaldoOptions, setParaSaldoOptions] = React.useState([]);

  const selectedDeSaldo = Form.useWatch("deSaldo", form);
  const selectedParaSaldo = Form.useWatch("paraSaldo", form);

  const onSave = async (values) => {
    setLoading(true);
    const selectedDeOption = deSaldoOptions.find(
      (option) => option.benefit == values.deSaldo
    );

    // Extrai o valor de available para comparar
    const availableValue = selectedDeOption ? selectedDeOption.available : 0;
    const selectedDeSaldo = values.deSaldo === null ? 0 : values.deSaldo;
    const selectedParaSaldo = values.paraSaldo === null ? 0 : values.paraSaldo;

    if (deEmpresa == paraEmpresa && selectedDeSaldo == selectedParaSaldo) {
      notification.error({
        message: "",
        description:
          "Não é possível transferir valor para a mesma Empresa e mesmo Saldo."
      });

      setLoading(false);
      return;
    }

    if (value <= 0) {
      notification.error({
        message: "",
        description:
          "O valor deve ser maior que 0 para realizar a transferência."
      });
      form.setFieldsValue({
        valorTransfer: undefined
      });
      setValue(0);
      setLoading(false);
      return;
    }

    // Compara o valor de available com o valor de transferência
    if (value > availableValue) {
      notification.error({
        message: "",
        description:
          "O valor a transferir não pode ser maior que o saldo disponível."
      });

      form.setFieldsValue({
        valorTransfer: undefined
      });
      setValue(0);
      setLoading(false);
      return;
    }

    try {
      await updateValueTransfer(
        deEmpresa,
        selectedDeSaldo,
        paraEmpresa,
        selectedParaSaldo,
        value
      );
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      form.setFieldsValue({
        deEmpresa: undefined,
        deSaldo: undefined,
        paraEmpresa: undefined,
        paraSaldo: undefined,
        valorTransfer: undefined
      });

      setDeSaldoOptions([]);
      setParaSaldoOptions([]);
      setDeEmpresa(null);
      setParaEmpresa(null);
      setValue(0);

      await load();
    }
  };

  const load = async () => {
    setLoading(true);
    const resChildren = await getAllChildrenCompanies(true);
    setGetChildrenCompanies(resChildren);
    setLoading(false);
  };

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    if (deEmpresa !== null) {
      form.resetFields(["deSaldo"]);
      setDeSaldoOptions([]);
    }
  }, [deEmpresa, form]);

  React.useEffect(() => {
    if (paraEmpresa !== null) {
      form.resetFields(["paraSaldo"]);
      setParaSaldoOptions([]);
    }
  }, [paraEmpresa, form]);

  const handleSelectChangeDeEmpresa = async (value) => {
    setDeEmpresa(value);
    form.setFieldsValue({ deSaldo: undefined });
    setDeSaldoOptions([]);
    setLoading(true);

    try {
      const res = await getTotalBalance(value);
      setDeSaldoOptions(res.subBalances || []);
    } catch (error) {
      console.error("Erro ao buscar o saldo:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChangeParaEmpresa = async (value) => {
    setParaEmpresa(value);
    form.setFieldsValue({ paraSaldo: undefined });
    setParaSaldoOptions([]);
    setLoading(true);

    try {
      const res = await getTotalBalance(value);
      setParaSaldoOptions(res.subBalances || []);
    } catch (error) {
      console.error("Erro ao buscar o saldo:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout className="alymente-layout">
      <Header title="Transferência de Valores" />
      <Content>
        <Form
          form={form}
          layout="vertical"
          name="basic"
          onFinish={(values) => {
            form
              .validateFields()
              .then(() => onSave(values))
              .catch(() => console.error("Erro de validação"));
          }}
        >
          <Row justify="center" gutter={24}>
            <Col xl={16} lg={12} md={24}>
              <Card>
                <Spin spinning={loading}>
                  <Row justify="left" style={{ marginBottom: 10 }}>
                    <Typography.Text strong>
                      Transferir da Empresa:
                    </Typography.Text>
                  </Row>
                  <Row gutter={16}>
                    <Col lg={12}>
                      <Form.Item
                        name="deEmpresa"
                        key={`deEmpresa-${deEmpresa}`}
                        initialValue={deEmpresa}
                        rules={[
                          {
                            required: true,
                            message: "Selecione uma Empresa para Transferir!"
                          }
                        ]}
                      >
                        <Select
                          placeholder="Selecione uma Empresa"
                          style={{ width: "100%" }}
                          onChange={handleSelectChangeDeEmpresa}
                        >
                          {getChildrenCompanies.map((x) => (
                            <Select.Option key={x.id} value={x.id}>
                              {x.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item
                        name="deSaldo"
                        rules={[
                          {
                            required: true,
                            validator: (_, value) => {
                              // Permitir null e valores válidos
                              if (value === null || value >= -1) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Selecione um saldo válido!")
                              );
                            }
                          }
                        ]}
                      >
                        <Select
                          placeholder="Selecione de qual Saldo"
                          style={{ width: "100%" }}
                          loading={loading}
                          onChange={(value) => {
                            form.setFieldsValue({ deSaldo: value });
                          }}
                        >
                          {deSaldoOptions.map((option, index) => (
                            <Select.Option key={index} value={option.benefit}>
                              {option.nameBenefit +
                                " - " +
                                formatMoney(option.available)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row
                    justify="left"
                    style={{ marginBottom: 10, marginTop: 20 }}
                  >
                    <Typography.Text strong>Para a Empresa:</Typography.Text>
                  </Row>
                  <Row gutter={16}>
                    <Col lg={12}>
                      <Form.Item
                        name="paraEmpresa"
                        key={`paraEmpresa-${paraEmpresa}`}
                        initialValue={paraEmpresa}
                        rules={[
                          {
                            required: true,
                            message:
                              "Selecione uma Empresa para receber a transferência!"
                          }
                        ]}
                      >
                        <Select
                          placeholder="Selecione para qual Empresa"
                          style={{ width: "100%" }}
                          onChange={handleSelectChangeParaEmpresa}
                        >
                          {getChildrenCompanies.map((x) => (
                            <Select.Option key={x.id} value={x.id}>
                              {x.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item
                        name="paraSaldo"
                        rules={[
                          {
                            required: true,
                            validator: (_, value) => {
                              // Permitir null e valores válidos
                              if (value === null || value >= -1) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Selecione um saldo válido!")
                              );
                            }
                          }
                        ]}
                      >
                        <Select
                          placeholder="Selecione de qual Saldo"
                          style={{ width: "100%" }}
                          loading={loading}
                          onChange={(value) => {
                            form.setFieldsValue({ paraSaldo: value });
                          }}
                        >
                          {paraSaldoOptions.map((option, index) => (
                            <Select.Option key={index} value={option.benefit}>
                              {option.nameBenefit +
                                " - " +
                                formatMoney(option.available)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="left" style={{ marginTop: 20 }}>
                    <Typography.Text strong>
                      Valor a Transferir:
                    </Typography.Text>
                  </Row>
                  <Row gutter={16} justify="left">
                    <Col xl={8} sm={10} style={{ marginTop: 15 }} lg={7}>
                      <Form.Item
                        name="valorTransfer"
                        rules={[
                          {
                            required: true,
                            message: "Insira um valor válido!"
                          }
                        ]}
                      >
                        <InputCurrency
                          value={value}
                          onChange={(val) => {
                            setValue(val);
                            form.setFieldsValue({ valorTransfer: val });
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} justify="left">
                    <Col xl={10} sm={20} style={{ marginTop: 20 }} lg={7}>
                      <Button
                        className="btn-save"
                        disabled={loading}
                        loading={loading}
                        type="primary"
                        icon={<SaveOutlined />}
                        onClick={() => form.submit()}
                      >
                        Transferir Valor
                      </Button>
                    </Col>
                  </Row>
                </Spin>
              </Card>
            </Col>
          </Row>
        </Form>
      </Content>
    </Layout>
  );
};

export default connect(mapStateToProps, {})(ValueTransfer);
