import axios from "axios";
import { notification } from "antd";
import { handlerError } from "utils";

export const send2FA = async (user, type) => {
  try {
    const req = {
      user,
      type
    };
    await axios.post(`/api/auth/2fa/send`, req);
    notification.success({
      message: "Enviado com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
  }
};

export const auth2FA = async (user, code) => {
  let req = {
    user,
    code
  };
  return await axios.post(`/api/auth/2fa`, req);
};
