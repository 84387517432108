import axios from "axios";
import { notification } from "antd";
import { handlerError } from "utils";

export const getHolidays = async (year) => {
  try {
    const res = await axios.get(`/api/bank/holiday`, {
      params: { year: year }
    });
    return res.data.data && res.data.data.length > 0 ? res.data.data : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getAllBank = async () => {
  try {
    const res = await axios.get(`/api/bank`);
    return res.data.data && res.data.data.length > 0 ? res.data.data : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};
