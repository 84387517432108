import React from 'react';
import { Row, Col } from "antd";
import { Logo } from "components/image/logo";

const AuthHeader = () => {
    return (
        <Row justify="center" align="middle" style={{marginTop: 15}}>
            <Col style={{paddingTop: 16, paddingBottom: 16}}>
                <Logo />
            </Col>
        </Row>
    );
}

export { AuthHeader }