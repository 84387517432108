import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, Layout, Modal, Row, Space, Spin, Typography, notification } from 'antd';
import { useParams } from 'react-router-dom';
import { getByIdUser } from 'services/user-service';
import { Header, SwitchLabel } from 'components';
import { savePasswordUser, saveRefresh } from 'services/user-service';

const { Content } = Layout;

const mapStateToProps = (state) => ({
   login: state.authReducer.loginReducer,
});

const DataFetch = {
   user: {},
   loading: false,
};

const UserPasswordScreen = (props) => {
   const { login } = props;
   const { id } = useParams();
   const [data, setData] = React.useState(DataFetch);
   const [loading, setLoading] = React.useState(false);
   const [sendShow, setSendShow] = React.useState(false);
   const [passwordShow, setPasswordShow] = React.useState(false);
   const [copyShow, setCopyShow] = React.useState(false);
   const [infosCopy, setInfosCopy] = React.useState({});
   const [formSend] = Form.useForm();
   const [formPassword] = Form.useForm();

   const load = async () => {
      setData((x) => ({ ...x, loading: true }));
      const res = await getByIdUser(id);
      setData({
         user: res.data,
      });
   };

   React.useEffect(() => {
      load();
   }, []);

   const formatPhone = (phone) => {
      phone = phone.slice(2);
      return phone.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
   };

   const onSaveRefresh = async () => {
      setLoading(true);
      try {
         await saveRefresh(id);
         notification.success({ message: 'Usuário liberado com sucesso.' });
      } catch (e) {
         console.log(e);
      } finally {
         setLoading(false);
      }
   };

   const saveSend = async (values) => {
      setLoading(true);
      try {
         await savePasswordUser(id, values.sendEmail, values.sendSms);
         notification.success({ message: 'Envio de redefinição de senha efetuado com sucesso!' });
         setSendShow(false);
      } catch (e) {}
      setLoading(false);
   };

   const savePassword = async (values) => {
      setLoading(true);
      try {
         await savePasswordUser(id, false, false, values.password, values.confirmation);
         notification.success({ message: 'Senha salva com sucesso!' });
         setPasswordShow(false);
      } catch (e) {}
      setLoading(false);
   };

   const loadCopy = async () => {
      setLoading(true);
      try {
         let ret = await savePasswordUser(id, false, false, null, null, true);
         setInfosCopy(ret);
      } catch (e) {
         setCopyShow(false);
      }
      setLoading(false);
   };

   const copyLink = async () => {
      setLoading(true);
      try {
         navigator.clipboard.writeText(infosCopy.link);
         notification.success({ message: `Link copiado para a área de transferência` });
      } catch (e) {
         setCopyShow(false);
      }
      setLoading(false);
   };

   React.useEffect(() => {
      if (copyShow) loadCopy();
   }, [copyShow]);

   return (
      <Layout className="alymente-layout">
         <Header title="Senha do Colaborador" subtitle={data.user.name} />
         <Content style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Space direction="vertical">
               <Button loading={loading} disabled={loading} block type="primary" onClick={() => setSendShow(true)}>
                  Envio ao colaborador
               </Button>
               <Button loading={loading} disabled={loading} block type="primary" onClick={() => setPasswordShow(true)}>
                  Cadastro de senha
               </Button>
               {/* <Button loading={loading} disabled={loading} block type="primary" onClick={() => setCopyShow(true)}>Cópia do link de envio</Button> */}
               {login.company.master && (
                  <Button loading={loading} disabled={loading} block type="primary" onClick={onSaveRefresh}>
                     Liberar usuário por erro de tentativas
                  </Button>
               )}
            </Space>
         </Content>
         <Modal
            open={sendShow}
            title="Envio ao colaborador"
            onCancel={() => setSendShow(false)}
            onOk={() => formSend.submit()}
            confirmLoading={loading}
            width={600}
            okText={'Salvar'}
         >
            <Form form={formSend} layout="vertical" name="basic" onFinish={saveSend} align="center">
               <Space direction="vertical" size="large" align="center">
                  <Spin spinning={loading}>
                     <Form.Item name={'sendEmail'} valuePropName="checked">
                        <SwitchLabel label="ENVIAR E-MAIL PARA REDEFINIÇÃO DE SENHA DO APLICATIVO" />
                     </Form.Item>
                     <Form.Item name={'sendSms'} valuePropName="checked">
                        <SwitchLabel label="ENVIAR SMS PARA REDEFINIÇÃO DE SENHA DO APLICATIVO" />
                     </Form.Item>
                  </Spin>
               </Space>
            </Form>
         </Modal>
         <Modal
            open={passwordShow}
            title="Cadastro de senha"
            onCancel={() => {
               setInfosCopy({});
               setPasswordShow(false);
            }}
            onOk={() => formPassword.submit()}
            confirmLoading={loading}
            width={600}
            okText={'Salvar'}
         >
            <Form form={formPassword} layout="vertical" name="basic" onFinish={savePassword} align="center">
               <Space direction="vertical" size="large" align="center">
                  <Spin spinning={loading}>
                     <Form.Item name={'password'}>
                        <Input placeholder="Senha do Aplicativo" type={'password'} autoComplete="off" />
                     </Form.Item>
                     <Form.Item name={'confirmation'}>
                        <Input placeholder="Confirmação de Senha" type={'password'} autoComplete="off" />
                     </Form.Item>
                  </Spin>
               </Space>
            </Form>
         </Modal>
         <Modal
            open={copyShow}
            title="Link de envio"
            onCancel={() => setCopyShow(false)}
            onOk={() => copyLink()}
            confirmLoading={loading}
            width={600}
            okText={'Copiar Link'}
         >
            <Space direction="vertical" size="large" align="center">
               <Spin spinning={loading}>
                  <div>
                     <Row type="flex" justify="center">
                        <Typography.Text>Segue o link de redefinição de senha.</Typography.Text>
                     </Row>
                     <Row type="flex" justify="center">
                        <Typography.Text type="secondary" align="center">
                           O link de redefinição de senha está associado exclusivamente ao colaborador.
                        </Typography.Text>
                     </Row>
                     <Row type="flex" justify="center" align="center">
                        <div style={{ textAlign: 'center', marginTop: 20 }}>
                           <a href={infosCopy.link} target="_blank" rel="noreferrer">
                              {' '}
                              <Typography.Text align="center">{infosCopy.link}</Typography.Text>{' '}
                           </a>
                        </div>
                     </Row>
                     <Row type="flex" justify="center" style={{ marginTop: 20 }}>
                        <Typography.Text type="secondary" align="center">
                           O colaborador pode efetuar o login usando o email <Typography.Text>{infosCopy.email}</Typography.Text>
                           {infosCopy.phone ? (
                              <Typography.Text type="secondary">
                                 {' '}
                                 ou o telefone <Typography.Text>{formatPhone(infosCopy.phone)}</Typography.Text>
                              </Typography.Text>
                           ) : (
                              ''
                           )}
                           .
                        </Typography.Text>
                     </Row>
                  </div>
               </Spin>
            </Space>
         </Modal>
      </Layout>
   );
};

export default connect(mapStateToProps, {})(UserPasswordScreen);
