import React from "react";
import { Skeleton } from 'antd';
import PropTypes from "prop-types";

const LoadingText = (props) => {
	const { loading, value } = props;

	return <React.Fragment>{ loading ? <Skeleton className="loading-text" active loading={true} paragraph={false} title={{width: '90%'}} /> : value}</React.Fragment>;
};

export { LoadingText};