import React, { Fragment } from "react";
import { Layout, Row, Col, Input, Form, Table, Steps, Button, Select, Modal } from 'antd';
import { InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Header, AutoCompleteCompany } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { getByIdUser, getNewUserCompany, saveNewCompanyUser } from "services/user-service";
import { cancelLinesByUser } from "services/solicitation-service";
import { getCompany } from "services/company-service";
import { translate } from 'config/language';

const { Footer, Content } = Layout;

const DataFetch = {
    user: {},
    userKeys: [],
    newAccount: null,
    loading: false
}


const UserChangeCompanyScreen = (props) => {
    const { id = null } = useParams();
    const [data, setData] = React.useState(DataFetch)
    const [recipientCompany, setRecipientCompany] = React.useState(null);
    const [type, setType] = React.useState("migration");
    const [email, setEmail] = React.useState(null);
    const [descriptions, setDescriptions] = React.useState([{ label: '', status: null }, { label: '', status: null }]);
    const [current, setCurrent] = React.useState(null);
    const [userDuplicate, setUserDuplicate] = React.useState(false);
    const [form] = Form.useForm();

    const load = async () => {
        setData((x) => ({ ...x, loading: true }));
        const res = await getByIdUser(id)
        setData({
            user: res.data,
            userKeys: [{
                account_holder_id: res.data.externalKey,
                balance_account_group_id: res.data.account,
                treasury_account_holder_id_actual: res.data.companyExternal.externalKey,
                treasury_account_holder_id_new: '',
                treasury_account_id_new: ''
            }],
            loading: false
        });
        form.setFieldsValue({
            "senderCompany": res.data.company.name,
            "type": "migration"
        });
    }

    const loadCompanyRecipient = async () => {
        setData((x) => ({ ...x, loading: true }));
        setStatusStep('wait', 0)
        const res = await getCompany(recipientCompany)
        const resNew = await getNewUserCompany(id, recipientCompany, type)
        if (resNew.newCompany) {
            if(resNew.duplicate){
                setDescriptions([{ label: '', status: null }, { label: '', status: null }, { label: '', status: null }, { label: '', status: null }])
                setUserDuplicate(true)
            } else {
                setDescriptions([{ label: '', status: null }, { label: '', status: null }])
                setUserDuplicate(false)
            }
            setStatusStep('process', 0, resNew.duplicate)
        } else if(type == "migration"){
            setDescriptions([{ label: '', status: null }, { label: '', status: null }])
            setUserDuplicate(false)
            setStatusStep('process', 0)
        } 
        else {
            setDescriptions([{ label: '', status: null }, { label: '', status: null }])
            setUserDuplicate(false)
            setStatusStep('error', 0)
        }
        setData((x) => ({ ...x, userKeys: [{ ...data.userKeys[0], treasury_account_holder_id_new: res.externalKey, treasury_account_id_new: res.account }], loading: false }));
    }

    const setStatusStep = (status, step, duplicate = false) => {
        if (step === 0) {
            descriptions[0] = { label: '', status: null }
            descriptions[1] = { label: '', status: null }
            if(duplicate){
                descriptions[2] = { label: '', status: null }
                descriptions[3] = { label: '', status: null }
            }
        }
        if (status === 'process') {
            if (step === 0) {
                descriptions[step].label = duplicate ? 
                    'Esse usuário já possui conta na nova empresa. Lembrando que esse usuário terá que ativar um novo cartão.' : 
                    (type == "migration" ?
                    'Conta apta a ser migrada'
                    :
                    `A conta foi ${type === "duplication" ? "duplicada" : "migrada"} na nova empresa.`)
            }
            if(!duplicate){
                if (step === 1) {
                    descriptions[step].label = `O usuário foi ${type === "duplication" ? "duplicado" : "migrado"} na nova empresa`
                }
            } else {
                if (step === 1) {
                    descriptions[step].label = 'O cartão do usuário foi bloqueado e desvinculado.'
                }
                if (step === 2) {
                    descriptions[step].label = `O ${translate('balance')} foi transferido para a nova conta.`
                }
                if (step === 3) {
                    descriptions[step].label = 'O usuário foi cadastrado na nova empresa.'
                }
            }
            descriptions[step].status = status
        }
        else if (status === 'error') {
            if (step === 0) {
                descriptions[step].label = `Usuário não foi ${type === "duplication" ? "duplicado" : "migrado"} na nova empresa pela SWAP.`
            }
            if(!duplicate){
                if (step === 1) {
                    descriptions[step].label = `Erro na ${type === "duplication" ? "duplicação" : "migração"} do usuário na nova empresa.`
                }

            } else {
                if (step === 1) {
                    descriptions[step].label = 'Erro no bloqueio do cartão.'
                }
                if (step === 2) {
                    descriptions[step].label = `Erro na transferência de ${translate('balance')}.`
                }
                if (step === 3) {
                    descriptions[step].label = 'Erro na mudança de empresa.'
                }
            }
            descriptions[step].status = status
        }
        setDescriptions(descriptions)
        setCurrent(step)
    }

    const onChangeCompany = (values) => {
        setType(values.type)
        if (values.recipientCompany && values.type) {
            setRecipientCompany(values.recipientCompany)
        }
    }

    const cancelLines = async () => {
        setData((x) => ({ ...x, loading: true }));
        const res = await cancelLinesByUser(id)
        setData((x) => ({ ...x, loading: false }));
    }

    const onSave = async () => {
        setData((x) => ({ ...x, loading: true }));
        setStatusStep('wait', 1)
        try {
            await form.validateFields()
            const res = await saveNewCompanyUser(id, { company: recipientCompany, email: email, type: type });

            if(res.duplicate){
                if (res.blockCard)
                    setStatusStep('process', 1, res.duplicate)
                else
                    setStatusStep('error', 1, res.duplicate)
                if (res.balanceTransfer)
                    setStatusStep('process', 2, res.duplicate)
                else
                    setStatusStep('error', 2, res.duplicate)
                if (res.swapCompany)
                    setStatusStep('process', 3, res.duplicate)
                else
                    setStatusStep('error', 3, res.duplicate)
            } else if (res.swapCompany) {
                setStatusStep('process', 1)
            } else if(res.pendingLine){
                setStatusStep('error', 1)
                Modal.confirm({
                    title: `Deseja cancelar as linhas pendentes do colaborador?`,
                    icon: <InfoCircleOutlined />,
                    onOk: async () => {
                        await cancelLines();
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            }
            else
                setStatusStep('error', 1)

        } catch (e) {
            setStatusStep('error', 1)
            if(userDuplicate){
                setStatusStep('error', 2, userDuplicate)
                setStatusStep('error', 3, userDuplicate)
            }
        } finally {
            setData((x) => ({ ...x, loading: false }));
        }
    }

    React.useEffect(() => {
        if (recipientCompany) {
            loadCompanyRecipient()
        }
    }, [recipientCompany, type])

    React.useEffect(() => {
        load()
    }, [id])

    React.useEffect(() => {
        if(userDuplicate){

        }
    }, [userDuplicate])

    return <Layout className="alymente-layout">
        <Header title={"Alteração de Empresa"} subtitle={data.user.name} />
        <Content>
            <Form form={form} layout="vertical" name="basic" onFinish={onChangeCompany}>
                <Row>
                    <Col span={18} offset={3}>
                        <Row gutter={16}>
                            <Col md={10}>
                                <Form.Item
                                    label="Empresa Atual"
                                    hasFeedback
                                    name="senderCompany"

                                >
                                    <Input autoComplete="off" disabled={true}></Input>
                                </Form.Item>
                            </Col>
                            <Col md={10}>
                                <Form.Item
                                    label="Nova Empresa"
                                    hasFeedback
                                    name="recipientCompany"
                                >
                                    <AutoCompleteCompany value={recipientCompany} onChange={() => form.submit()} />
                                </Form.Item>
                            </Col>
                            <Col md={4}>
                                <Form.Item
                                    label="Tipo"
                                    hasFeedback
                                    name="type"
                                >
                                    <Select style={{ width: 120 }} onChange={() => form.submit()} >
                                        <Select.Option value="migration">Migração</Select.Option>
                                        <Select.Option value="duplication">Duplicação</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {
                    type === "duplication" &&
                    <Row style={{ marginTop: 30 }}>
                        <Col sm={24}>
                            <Table dataSource={data.userKeys} loading={data.loading} pagination={false} bordered >
                                <Table.Column align={"center"} title="account_holder_id" dataIndex="account_holder_id" key="account_holder_id" />
                                <Table.Column align={"center"} title="balance_account_group_id" dataIndex="balance_account_group_id" key="balance_account_group_id" />
                                <Table.Column align={"center"} title="treasury_account_holder_id_actual" dataIndex="treasury_account_holder_id_actual" key="treasury_account_holder_id_actual" />
                                <Table.Column align={"center"} title="treasury_account_holder_id_new" dataIndex="treasury_account_holder_id_new" key="treasury_account_holder_id_new" />
                                <Table.Column align={"center"} title="treasury_account_id_new" dataIndex="treasury_account_id_new" key="treasury_account_id_new" />
                            </Table>
                        </Col>
                    </Row>
                }
                <Row style={{ marginTop: 50, display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: 300 }}>
                        <Steps direction="vertical" current={current}>
                            <Steps.Step title={userDuplicate ? 'O usuário já possui conta na nova empresa' : (type == "migration" ? "Conta pronta a ser criada" : "Conta Criada")} description={descriptions[0].label} status={descriptions[0].status} />
                            {
                                !(descriptions.length == 4 && userDuplicate) ?
                                <Steps.Step title={`Usuário ${type === "duplication" ? "duplicado" : "migrado"}`} description={descriptions[1].label} status={descriptions[1].status} />
                                :
                                <Fragment>
                                    <Steps.Step title="Cartão bloqueado" description={descriptions[1].label} status={descriptions[1].status} />
                                    <Steps.Step title={`${translate('balance')} Transferido`} description={descriptions[2].label} status={descriptions[2].status} />
                                    <Steps.Step title="Cadastro atualizado" description={descriptions[3].label} status={descriptions[3].status} />
                                </Fragment>
                            }
                        </Steps>
                        {
                            type === "duplication" &&
                            <Form.Item
                                style={{marginTop: 50}}
                                label="Email na nova empresa"
                                hasFeedback
                                name="email"
                                onChange={(val) => setEmail(val.target.value)} 
                                value={email}
                            >
                                <Input autoComplete="off" type="email" />
                            </Form.Item>
                        }
                    </div>
                </Row>
            </Form>
        </Content>
        <Footer>
            <Button
                disabled={(!((descriptions[0].status === 'process' && current === 0 && recipientCompany && email && type === 'duplication') || (type === 'migration' && recipientCompany))) || data.loading}
                loading={data.loading}
                className="btn-save"
                type="primary"
                icon={<SaveOutlined />}
                width={300}
                onClick={() => onSave()}>
                {type === 'duplication' ? "Duplicar" : "Migrar de"} Empresa
            </Button>
        </Footer>
    </Layout>
}

export default UserChangeCompanyScreen; 