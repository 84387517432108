import React from "react";
import PropTypes from "prop-types";

const BoxColor = (props) => {
	const { color } = props;

	return <div style={{backgroundColor: color, width: 30, height: 10, borderRadius: 2}}></div>;
};

BoxColor.defaultProps = {
	color: "#005F53"
};

BoxColor.propTypes = {
	color: PropTypes.string
};

export { BoxColor };