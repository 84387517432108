import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "utils/index"

const FormatDate = (props) => {
	const { value, format } = props;

	const getText = () => {
		return formatDate(value, format);
	};

	return <React.Fragment>{getText()}</React.Fragment>;
};

FormatDate.defaultProps = {
	value: new Date(),
	format: "DD/MM/YYYY"
};

FormatDate.propTypes = {
	value: PropTypes.string,
	format: PropTypes.string
};

export { FormatDate };