import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Layout, Button, Typography, Row, Col, Card, Input, Select, Space, Spin, Alert, Modal } from 'antd';
import { SaveOutlined, FileTextOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { getByIdSolicitation, getAllSolicitationStatus, saveSolicitation, createInvoiceExternal, updateSolicitation } from 'services/solicitation-service';
import { Header, CardCompanyBalance, InputCurrency, Form, CardInfo } from 'components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { formatDate, formatMoney } from 'utils';
import moment from 'moment';
import { string } from 'prop-types';

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
   login: state.authReducer.loginReducer,
   balance: state.balanceReducer,
});

const SolicitationDetailsScreen = (props) => {
   const { login, balance } = props;
   const { id } = useParams();
   const navigate = useNavigate();
   const [form] = Form.useForm();
   const [status, setStatus] = React.useState([]);
   const [data, setData] = React.useState(null);
   const [loading, setLoading] = React.useState(false);
   const [searchParam, setSearchParam] = useSearchParams();
   const [descriptionInvoiceManual, setDescriptionInvoiceManual] = React.useState('');
   const [isModalOpen, setIsModalOpen] = useState(false);

   const showModal = () => {
      setIsModalOpen(true);
   };

   const load = async () => {
      setLoading(true);
      setStatus(await getAllSolicitationStatus());
      const res = await getByIdSolicitation(id);
      setData(res);
      if (res) {
         loadFields(res);
      }
      setLoading(false);
   };

   const loadFields = (data) => {

      form.setFieldsValue({
         ...data,
         payment: {
            paidAt: data.payment ? formatDate(data.payment.paidAt) : null,
            paymentAt: data.payment ? formatDate(data.payment.paymentAt) : null,
         },
         approval: {
            approvalAt: data.approval ? formatDate(data.approval.approvalAt) : null,
            approvedAt: data.approval ? formatDate(data.approval.approvedAt) : null,
         },
         transferValue: data.transferValue ? data.transferValue : 0,
      });
   };

   const onInvoiceManual = async () => {
      showModal();
   };

   const onSaveInvoice = async () => {
      setLoading(true);
      try {
         setIsModalOpen(false)
         //Update the Invoice description
         if (descriptionInvoiceManual != null && descriptionInvoiceManual.trim() != "") {

            let body = {
               data: {},
            };
            body.data.descriptionInvoiceManual = descriptionInvoiceManual;
            await updateSolicitation(id, body);

         }
         //Create the Invoice
         await createInvoiceExternal(data.id);
         load();

      } catch (e) {
         console.log(e);
      } finally {
         setLoading(false);
      }
   }

   const onSave = async (values) => {
      setLoading(true);
      try {
         await saveSolicitation(id, values.status, values.invoiceUrl, values.value);

         const params = searchParam.size > 0 ? `?${searchParam.toString()}` : '';
         navigate(`/solicitation${params}`);
      } catch (e) {
         console.log(e);
      } finally {
         setLoading(false);
      }
   };

   React.useEffect(() => {
      load();
   }, [id]);

   return (
      <Layout className="alymente-layout">
         <Header title={`Pedido ${id}`}>
            <Row justify="space-between" align="bottom">
               <Col xxl={6} xl={8} lg={12}>
                  <CardCompanyBalance type={'balance'} />
               </Col>
            </Row>
            {login.company.master && data && data.approval && moment(data.approval.approvalAt).isSame(moment(), 'day') && (
               <Row style={{ marginTop: 10 }}>
                  <Col sm={24}>
                     <Alert message="Hoje é o dia de aprovação desse pedido" />
                  </Col>
               </Row>
            )}
            {login.company.master && data && data.approval && moment(data.approval.approvalAt).isAfter() && (
               <Row style={{ marginTop: 10 }}>
                  <Col sm={24}>
                     <Alert type="warning" message="Esse pedido tem data de aprovação posterior" />
                  </Col>
               </Row>
            )}
         </Header>
         <Content>
            <Row justify="center">
               <Col xl={10} lg={16} md={24}>
                  <Form form={form} layout="vertical" name="basic" onFinish={onSave}>
                     <Space direction="vertical" size="large">
                        <Card title="Informações Básicas">
                           <Spin spinning={loading}>
                              <Form.Item label="Razao Social" name={['company', 'corporateName']}>
                                 <Input disabled autoComplete="off" />
                              </Form.Item>
                              <Form.Item label="CNPJ" name={['company', 'cnpj']}>
                                 <Input disabled autoComplete="off" />
                              </Form.Item>
                              <Form.Item label="Nome da Empresa" name={['company', 'name']}>
                                 <Input disabled autoComplete="off" />
                              </Form.Item>
                              <Form.Item label="Tipo" name={['type', 'name']}>
                                 <Input disabled autoComplete="off" />
                              </Form.Item>
                              <Form.Item label="Status" hasFeedback name={['status', 'code']}>
                                 <Select loading={status.length === 0} placeholder="Selecione um status">
                                    {status.map((x) => (
                                       <Select.Option value={x.code}>{x.name}</Select.Option>
                                    ))}
                                 </Select>
                              </Form.Item>
                              <Row gutter={24}>
                                 <Col xl={12} lg={24}>
                                    <Form.Item label="Data para pagamento" name={['payment', 'paymentAt']}>
                                       <Input disabled autoComplete="off" />
                                    </Form.Item>
                                 </Col>
                                 <Col xl={12} lg={24}>
                                    <Form.Item label="Data do pagamento" name={['payment', 'paidAt']}>
                                       <Input disabled autoComplete="off" />
                                    </Form.Item>
                                 </Col>
                                 <Col xl={12} lg={24}>
                                    <Form.Item label="Data para Aprovação" name={['approval', 'approvalAt']}>
                                       <Input disabled autoComplete="off" />
                                    </Form.Item>
                                 </Col>
                                 <Col xl={12} lg={24}>
                                    <Form.Item label="Data da Aprovação" name={['approval', 'approvedAt']}>
                                       <Input disabled autoComplete="off" />
                                    </Form.Item>
                                 </Col>
                                 <Col xl={12} lg={24}>
                                    <Form.Item label="Valor Bruto" name="value">
                                       <InputCurrency disabled />
                                    </Form.Item>
                                 </Col>
                                 <Col xl={12} lg={24}>
                                    <Form.Item label="Desconto" name="discount">
                                       <InputCurrency disabled />
                                    </Form.Item>
                                 </Col>
                                 <Col xl={12} lg={24}>
                                    <Form.Item label="Valor Líquido" name="netValue">
                                       <InputCurrency disabled />
                                    </Form.Item>
                                 </Col>
                                 <Col xl={12} lg={24}>
                                    <Form.Item label="Tesouraria" name="transferValue">
                                       <InputCurrency disabled />
                                    </Form.Item>
                                 </Col>
                              </Row>
                              <Form.Item label="Benefício" name={['benefit', 'name']}>
                                 <Input disabled />
                              </Form.Item>
                              <Form.Item label="Link da Nota Fiscal" hasFeedback name="invoiceUrl">
                                 <Input autoComplete="off" />
                              </Form.Item>
                           </Spin>
                        </Card>
                        <Card title="Dados Bancários">
                           <Spin spinning={loading}>
                              <Row gutter={24}>
                                 <Col xl={12} lg={24}>
                                    <Form.Item label="Tipo de Conta" name={['bankAccount', 'type', 'name']}>
                                       <Input disabled autoComplete="off" />
                                    </Form.Item>
                                 </Col>
                                 <Col xl={12} lg={24}>
                                    <Form.Item label="Banco" name={['bankAccount', 'bank', 'name']}>
                                       <Input disabled autoComplete="off" />
                                    </Form.Item>
                                 </Col>
                                 <Col xl={12} lg={24}>
                                    <Form.Item label="Agência" name={['bankAccount', 'branch', 'number']}>
                                       <Input disabled autoComplete="off" />
                                    </Form.Item>
                                 </Col>
                                 <Col xl={12} lg={24}>
                                    <Form.Item label="Dígito da Agência" name={['bankAccount', 'branch', 'digit']}>
                                       <Input disabled autoComplete="off" />
                                    </Form.Item>
                                 </Col>
                                 <Col xl={12} lg={24}>
                                    <Form.Item label="Número da Conta" name={['bankAccount', 'account', 'number']}>
                                       <Input disabled autoComplete="off" />
                                    </Form.Item>
                                 </Col>
                                 <Col xl={12} lg={24}>
                                    <Form.Item label="Dígito da Conta" name={['bankAccount', 'account', 'digit']}>
                                       <Input disabled autoComplete="off" />
                                    </Form.Item>
                                 </Col>
                                 <Col lg={24}>
                                    <Form.Item label="CPF/CNPJ" name={['bankAccount', 'holder', 'document']}>
                                       <Input disabled autoComplete="off" />
                                    </Form.Item>
                                 </Col>
                              </Row>
                           </Spin>
                        </Card>
                     </Space>
                  </Form>
               </Col>
            </Row>
         </Content>
         <Footer>
            <Space>
               {login.company.master && data && data.company.createInvoiceAuto == false &&
                  (data.invoiceUrl == "" || data.invoiceUrl == null) &&
                  ((data.type.code != 'R' && data.cashinValue > 0) ||
                     (data.type.code == 'R' && data.netValue > 0) ||
                     (data.type.code == 'D' && data.netValue > 0)) && (
                     <Button loading={loading} className="btn-gerar-nota" type="primary" icon={<FileTextOutlined />} onClick={() => onInvoiceManual()}>
                        Gerar Nota Fiscal
                     </Button>
                  )}
               {login.company.master && (
                  <Button loading={loading} className="btn-save" type="primary" icon={<SaveOutlined />} onClick={() => form.submit()}>
                     Salvar
                  </Button>
               )}
            </Space>
         </Footer>
         <Modal
            open={isModalOpen}
            title="Nota Fiscal Manual:"
            onOk={onSaveInvoice}
            onCancel={() => setIsModalOpen(false)}
         >
            <div>
               <Form.Item>
                  <Alert message="Manter campo vazio para descrição padrão." />
               </Form.Item>
               <Form.Item>
                  <Input.TextArea rows={4} value={descriptionInvoiceManual} onChange={(e) => setDescriptionInvoiceManual(e.target.value)} />
               </Form.Item>
            </div>

         </Modal>
      </Layout >
   );
};

export default connect(mapStateToProps, {})(SolicitationDetailsScreen);
