import axios from "axios";
import Env from "env";
import { getAuthorization } from "config/service/auth-service";

const setupInterceptors = (store) => {
    axios.defaults.baseURL = Env.api
    axios.defaults.responseType = "json";
    axios.defaults.headers["Accept"] = "*/*";
    axios.defaults.headers["Content-Type"] = "application/json";

    axios.interceptors.request.use(
        config => {
            const token = getAuthorization();
            if (token) {
                config.headers.Authorization = `${token}`;
            }
            if (config.data === undefined) {
                config.data = null;
            }
            return config;
        },
        error => Promise.reject(error)
    )

    axios.interceptors.response.use(response => {
        if (response.data && (response.data.success || response.data.success === undefined)) {
            return response;
        } else {
            throw (response)
            //return response;
        }
    }, error => {
        if (error.response.status === 401) {
            window.location.href = "/auth/login"
        }

        if (error.response.status === 404) {
            //history.push("/not-found");
        }

        return Promise.reject(error);
    });
}

export { setupInterceptors };
