import React from "react";
import { useOutletContext } from 'react-router-dom';
import { Layout, Table, Switch, Button, Alert, Row, Col, Tooltip } from 'antd';
import { SaveOutlined,  InfoCircleOutlined } from '@ant-design/icons';
import { getAllBenefit, saveBenefit } from "services/benefit-service";
import { InputCurrency, BoxColor } from "components";
import { translate } from 'config/language';

const { Footer, Content } = Layout;

const DataFetch = {
    data: [],
    loading: false
}

const BenefitScreen = () => {
    const { company } = useOutletContext();
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(DataFetch)
    // const [editManager, setEditManager] = React.useState({ show: false, row: null });


    React.useEffect(() => {
        load();
    }, [company])

    const load = async () => {
        if (company) {
            setData((x) => ({ ...x, loading: true }));
            const res = await getAllBenefit(company);
            setData({ data: res.filter(x => x.isBenefit), loading: false });
        }
    }

    const onEdit = (value, index, field) => {
        const newData = [...data.data];
        newData[index][field] = value;
        if (field === "completeBalance") {
            newData[index]["minimumValue"] = value || !newData[index]["hasMinValue"] ? 1000000 : 0;
        }
        setData((x) => ({ ...x, data: newData }));
    }

    const onSave = async () => {
        setLoading(true);
        const res = await getAllBenefit(company);
        const incentive = res.filter(x => !x.isBenefit);
        await saveBenefit(
            company,
            data.data.concat(incentive)
        );
        await load();
        setLoading(false);
    }

    const getBenefitsNotMinValue = () => {
        let ret = ""
        let benefitsLength = data.data.filter(b => !b.hasMinValue).length;
        data.data.filter(b => !b.hasMinValue).forEach((x, index, array) => {
            ret += x.name
            if (benefitsLength > 1 && index < (benefitsLength - 2) && benefitsLength > 1) {
                ret += ", "
            } else if (benefitsLength > 1 && index < (benefitsLength - 1) && benefitsLength > 1) {
                ret += " e "
            }
        })
        return ret
    }

    // const loadManager = (row) => {
    //     setEditManager({ show: true, row: row });
    // }

    // const onSaveManager = async (value) => {
    //     const newData = [...data.data];
    //     const find = newData.find(x => x.benefit === value.benefit);
    //     if (find) {
    //         find.manager = value.manager;
    //     }
    //     setData((prev) => ({ ...prev, data: newData }))
    //     await onSave();
    //     onCloseManager();
    // }

    // const onCloseManager = () => {
    //     setEditManager({ show: false, row: null });
    // }

    return (
        <React.Fragment>
            <Content>
                <Row >
                    {
                        (!data.loading && data.data.filter(b => !b.hasMinValue).length > 0) &&
                        <Col span={24}><Alert message={`Os benefícios ${getBenefitsNotMinValue()} não possuem valor mínimo.`} type={"warning"} style={{ marginBottom: 10 }} /></Col>
                    }
                    <Col span={24}>
                        <Table scroll={{ y: "60vh" }} dataSource={data.data} loading={data.loading} pagination={false} bordered>
                            <Table.Column width={300} title="Benefício" dataIndex="name" key="name" render={(value, record, index) => {
                                return <Row width="100%" style={{ alignItems: "center" }}>
                                    <Col flex={1}>
                                        {value}
                                        {record.account != null &&
                                            <Tooltip placement="bottom" title={`Benefício possui ${translate('balance')} específico`}>
                                                <InfoCircleOutlined style={{ marginLeft: 10 }} />
                                            </Tooltip>
                                        }
                                    </Col>
                                    <Col>
                                        <BoxColor color={record.color} />
                                    </Col>
                                </Row>
                            }} />
                            {/* <Table.Column width={300} title="Gerente" dataIndex={["manager", "name"]} key="manager.name"
                                render={(value, row) =>
                                    <React.Fragment>
                                        <Row justify="space-between">
                                            <Col>
                                                {value ? value : ""}
                                            </Col>
                                            <Col>
                                                <Tooltip placement="bottom" title={`Editar Gerente`}>
                                                    <EditFilled style={{ fontSize: 14 }} onClick={() => loadManager(row)} />
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </React.Fragment>} /> */}
                            <Table.Column align="center" title="Habilitar Benefício" dataIndex="enabled" key="enabled" width={170}
                                render={(value, record, index) => <Switch checked={value} onChange={(x) => onEdit(x, index, "enabled")} />} />
                            <Table.Column align="center" title={`Completar ${translate('balance')}`} dataIndex="completeBalance" key="completeBalance" width={170}
                                render={(value, record, index) => <Switch checked={value} onChange={(x) => onEdit(x, index, "completeBalance")} />} />
                            <Table.Column align="center" title="Valor Mínimo" dataIndex="minimumValue" key="minimumValue" width={200}
                                render={(value, record, index) => <InputCurrency disabled={record.completeBalance || !record.hasMinValue} value={value} onChange={(x) => onEdit(x, index, "minimumValue")} />} />
                            <Table.Column align="center" title="Comprovante de Gastos" dataIndex="companySpending" key="companySpending" width={200}
                                render={(value, record, index) => <Switch checked={value}  disabled={!record.spending} onChange={(x) => onEdit(x, index, "companySpending")} />} />

                        </Table>
                    </Col>
                </Row>
                {/* <BenefitManagerScreen company={company} show={editManager.show} row={editManager.row} onCancel={onCloseManager} loading={loading} onSave={onSaveManager} /> */}
            </Content>
            <Footer>
                <Button loading={loading} className="btn-save" type="primary" icon={<SaveOutlined />} onClick={onSave}>Salvar</Button>
            </Footer>
        </React.Fragment>
    )
}

export default BenefitScreen;
