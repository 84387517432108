import React from 'react';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Input, Button, Card, Row, Col, Layout, Alert } from 'antd';
import { AuthHeader, Form } from 'components';
import * as authAction from 'actions/auth-action';
import { UserOutlined, LockFilled } from '@ant-design/icons';
import { SoftColor } from 'config/constants/Color';

const mapStateToProps = (state) => ({
	login: state.authReducer.loginReducer,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			...authAction,
		},
		dispatch
	);

const LoginScreen = (props) => {
	const navigate = useNavigate();
	const { login, loginAction, logoutAction } = props;

	React.useEffect(() => {
		logoutAction();
	}, [logoutAction]);

	React.useEffect(() => {
		if (login.user) {
			navigate('/auth/2fa');
		}
	}, [login]);

	const onLogin = (values) => {
		loginAction(values.email, values.password);
	};

	return (
		<Layout
			style={{
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'row',
				backgroundColor: SoftColor,
			}}>
			<Layout.Content>
				<Row
					justify="center"
					align="middle"
					className={'login'}>
					<Col
						xxl={6}
						xl={8}
						lg={10}
						md={14}
						sm={24}>
						<Card
							title={<AuthHeader />}
							bordered={false}>
							<Form
								style={{ width: '100%' }}
								name="basic"
								initialValues={{ email: '', password: '' }}
								onFinish={onLogin}>
								<Form.Item
									hasFeedback
									name="email"
									rules={[{ required: true, type: 'email', message: 'Insira um email válido' }]}>
									<Input
										autoComplete="off"
										prefix={<UserOutlined />}
										inputMode="email"
										placeholder="E-mail"
									/>
								</Form.Item>
								<Form.Item
									hasFeedback
									name="password"
									rules={[{ required: true, message: 'Insira sua senha' }]}>
									<Input.Password
										prefix={<LockFilled />}
										placeholder="Senha do aplicativo"
									/>
								</Form.Item>
								{login.message.length > 0 && (
									<Form.Item>
										<Alert
											message={login.message}
											type="error"
											showIcon
										/>
									</Form.Item>
								)}
								<Form.Item>
									<Button
										block
										loading={login.isProcessing}
										type="primary"
										htmlType="submit">
										Entrar
									</Button>
								</Form.Item>
								<Form.Item>
									<Button
										type="link"
										href="/auth/forgot">
										Esqueci minha senha
									</Button>
								</Form.Item>
							</Form>
						</Card>
					</Col>
				</Row>
			</Layout.Content>
		</Layout>
	);
	//}
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
