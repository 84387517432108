import React, { Fragment } from "react";
import { Typography, Space } from "antd";
import PropTypes from "prop-types";

const ColumnVehicle = (props) => {
    return <Space direction="vertical" size={0}>
      <Fragment>
        <Typography.Text style={{ fontSize: 12 }}><Typography.Text style={{ fontSize: 12 }} strong>Nome: </Typography.Text>{props.name}</Typography.Text>
      </Fragment>
      <Fragment>
        <Typography.Text style={{ fontSize: 12 }}><Typography.Text style={{ fontSize: 12 }} strong>Placa: </Typography.Text>{props.licensePlate}</Typography.Text>
      </Fragment>
      <Fragment>
        <Typography.Text style={{ fontSize: 12 }}><Typography.Text style={{ fontSize: 12 }} strong>Código: </Typography.Text>{props.code}</Typography.Text>
      </Fragment>
    </Space>;
};

ColumnVehicle.propTypes = {
    name: PropTypes.string.isRequired,
    licensePlate: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
};

export { ColumnVehicle };