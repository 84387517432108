import React from "react";

const MAX = 10;

const BatchUpdate = (props) => {
    const [batch, setBatch] = React.useState([]);
    const [garbage, setGarbage] = React.useState([]);
    const [count, setCount] = React.useState({ filter: 0, finished: 0 })
    const { data = [], request, fieldKey, fieldResponse, onChange, onChangeItem, filter, start = false } = props;

    const dataFilter = () => [...data.filter(filter)];

    const loadAsync = async (e) => {
        const res = await request(e[fieldKey]);
        setCount(prev => ({ ...prev, finished: prev.finished + 1 }))
        if (res) {
            setBatch(x => x.concat({ id: e[fieldKey], [fieldResponse]: res[fieldResponse] }));
        }
    }

    const load = async () => {
        let i = 0, y = 0;
        const newData = dataFilter();
        setCount(prev => ({ ...prev, filter: newData.length }))
        while (i < newData.length) {
            const call = [];
            while (y < MAX) {
                if (i === newData.length) break;
                call.push(loadAsync(newData[i]))
                i++;
                y++;
            }
            y = 0;
            await Promise.all(call);
        }
    }

    const changeData = (reprocess = false) => {
        const array = reprocess ? garbage : batch;
        const newData = [...data];
        array.reverse().forEach((e) => {
            const find = newData.find(x => x[fieldKey] === e[fieldKey])
            if (find) {
                find[fieldResponse] = e[fieldResponse];
                if (onChangeItem) {
                    onChangeItem(find[fieldResponse])
                }
            }
        })
        if (reprocess) {
            setGarbage([]);
        } else {
            setGarbage(prev => [...prev, ...batch]);
        }
        setBatch([]);

        if (onChange) {
            onChange(newData);
        }
    }

    React.useEffect(() => {
        if (batch.length > 0) {
            changeData();
        }
    }, [batch])

    React.useEffect(() => {
        // if (start) {
            load()
        // }
    }, [start])

    React.useEffect(() => {
        console.log(count)
        if (count.filter > 0) {
            if (count.filter === count.finished) {
                setTimeout(() => {
                    changeData(true);
                }, 5000);
            }
        }
    }, [count])

    return null;
};

export { BatchUpdate };