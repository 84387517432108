import React from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Layout,
  Table,
  Button,
  Tag,
  Modal,
  Select,
  Row,
  Col,
  TimePicker,
  DatePicker,
  notification,
  Space,
  Divider
} from "antd";
import {
  FileExcelOutlined,
  PlusCircleOutlined,
  SearchOutlined
} from "@ant-design/icons";
import {
  getAllVehicles,
  getStatement,
  getVehicle,
  getAllVehicleEmployee,
  getVehicleEmployee,
  getEmployeeVehicle,
  getReportVehicleComposition
} from "services/vehicle-service";
import { FormatDate, FormatMoney, Header, TableSearch } from "components";
import Env from "env";
import usePage from "hooks/usePage";
import { getCompany } from "services/company-service";
import moment from "moment";
import { formatDate, formatDecimalNumber } from "utils";
import { exportXls } from "utils";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const DataFetch = {
  data: [],
  loading: false
};

const VehicleReportScreen = (props) => {
  const { login } = props;
  const navigate = useNavigate();
  const [data, setData] = React.useState(DataFetch);
  const [type, setType] = React.useState(null);
  const [filter, setFilter] = React.useState([]);
  const [filterValues, setFilterValues] = React.useState([]);
  const [filterLoading, setFilterLoading] = React.useState(false);
  const [dates, setDates] = React.useState(null);
  const [fields, setFields] = React.useState([]);
  const [openTable, setOpenTable] = React.useState(false);
  const [disabledFilter, setDisabledFilter] = React.useState(false);

  const fieldsVehicleDriver = [
    {
      title: "Nome Reduzido",
      dataIndex: ["vehicle", "company", "name"],
      key: "vehicle.company.name"
    },
    {
      title: "Data Transação",
      dataIndex: ["date"],
      key: "date",
      render: (value, data, index) => (
        <>
          <FormatDate value={value} format={"DD/MM/YYYY HH:mm"} />
        </>
      )
    },
    {
      title: "Placa",
      dataIndex: ["vehicle", "licensePlate"],
      key: "vehicle.licensePlate"
    },
    {
      title: "Tipo Veículo",
      dataIndex: ["vehicle", "type"],
      key: "vehicle.type",
      render: (value) => (value === "car" ? "Carro" : "Caminhão")
    },
    {
      title: "Categoria Veículo",
      dataIndex: ["vehicle", "category"],
      key: "vehicle.category"
    },
    {
      title: "Modelo Veículo",
      dataIndex: ["vehicle", "model"],
      key: "vehicle.model"
    },
    {
      title: "Código Veículo",
      dataIndex: ["vehicle", "code"],
      key: "vehicle.code"
    },
    {
      title: "Ano Veículo",
      dataIndex: ["vehicle", "year"],
      key: "vehicle.year"
    },
    {
      title: "Ano Modelo Veículo",
      dataIndex: ["vehicle", "year"],
      key: "vehicle.yearModel"
    },
    {
      title: "Renavam Veículo",
      dataIndex: ["vehicle", "document"],
      key: "vehicle.document"
    },
    {
      title: "Cor Veículo",
      dataIndex: ["vehicle", "color"],
      key: "vehicle.color"
    },
    {
      title: "Chassis Veículo",
      dataIndex: ["vehicle", "chassis"],
      key: "vehicle.chassis"
    },
    {
      title: "Marca Veículo",
      dataIndex: ["vehicle", "brand"],
      key: "vehicle.brand"
    },
    {
      title: "Capacidade Tanque Veículo",
      dataIndex: ["vehicle", "tankCapacity"],
      key: "vehicle.tankCapacity",
      render: (value, data, index) => {
        return value ? `${formatDecimalNumber(value, 2)}` : "";
      }
    },
    {
      title: "Tipo Comb. Veículo",
      dataIndex: ["vehicle", "typeFuel"],
      key: "vehicle.typeFuel"
    },
    {
      title: "Autonomia Comb. Veículo",
      dataIndex: ["vehicle", "fuelRange"],
      key: "vehicle.fuelRange",
      render: (value, data, index) => {
        return value ? `${formatDecimalNumber(value, 2)}` : "";
      }
    },
    {
      title: "Km Inicial Veículo",
      dataIndex: ["vehicle", "initialMileage"],
      key: "vehicle.initialMileage",
      render: (value, data, index) => {
        return value ? `${formatDecimalNumber(value, 2)}` : "";
      }
    },
    {
      title: "Nome Motorista",
      dataIndex: ["employee", "name"],
      key: "employee.name"
    },
    {
      title: "Tipo Combustível",
      dataIndex: ["firstExpense", "fuelTypeName"],
      key: "firstExpense.fuelTypeName"
    },
    {
      title: "Litros (L)",
      dataIndex: ["firstExpense", "litersFuel"],
      key: "firstExpense.litersFuel",
      render: (value, data, index) => {
        return value ? `${formatDecimalNumber(value, 2)}` : "";
      }
    },
    {
      title: "Vl/Litro (R$)",
      dataIndex: ["firstExpense", "litersFuelValue"],
      key: "firstExpense.litersFuelValue",
      render: (value, data, index) => {
        return value ? `${formatDecimalNumber(value, 2)}` : "";
      }
    },
    {
      title: "Hodometro",
      dataIndex: ["firstExpense", "mileage"],
      key: "firstExpense.mileage",
      render: (value, data, index) => {
        return value ? `${formatDecimalNumber(value, 2)}` : "";
      }
    },
    {
      title: "Valor",
      dataIndex: ["value"],
      key: "value",
      render: (value, data, index) => {
        return <FormatMoney value={(data.reversal ? "+" : "-") + value} />;
      }
    },
    {
      title: "Status",
      dataIndex: ["benefit"],
      key: "benefit",
      render: (value, data, index) => {
        return data.promise
          ? "PENDENTE"
          : data.declined
            ? data.declined.name.toUpperCase()
            : "APROVADO";
      }
    },
    { title: "Numero Cartão", dataIndex: ["cardNumber"], key: "cardNumber" },
    { title: "Modo pagamento", dataIndex: ["mode", "name"], key: "mode.name" },
    { title: "Merchant Key", dataIndex: ["merchantKey"], key: "merchantKey" },
    { title: "Nome Estabelecimento", dataIndex: ["name"], key: "name" },
    {
      title: "Endereço",
      dataIndex: ["firstExpense", "street"],
      key: "firstExpense.street"
    },
    {
      title: "Bairro",
      dataIndex: ["firstExpense", "neighborhood"],
      key: "firstExpense.neighborhood"
    },
    {
      title: "Cidade",
      dataIndex: ["firstExpense", "city"],
      key: "firstExpense.city"
    },
    {
      title: "UF",
      dataIndex: ["firstExpense", "state"],
      key: "firstExpense.state"
    }
  ];

  const fieldsComposition = [
    { title: "Marca", dataIndex: ["brand"], key: "brand" },
    { title: "Modelo", dataIndex: ["model"], key: "model" },
    {
      title: "Tipo",
      dataIndex: ["type"],
      key: "type",
      render: (value) => (value === "car" ? "Carro" : "Caminhão")
    },
    { title: "Categoria", dataIndex: ["category"], key: "category" },
    { title: "Idade Média (Anos)", dataIndex: ["media"], key: "media" },
    { title: "10 anos de Uso", dataIndex: ["yearTen"], key: "yearTen" },
    { title: "8 anos de Uso", dataIndex: ["yearEight"], key: "yearEight" },
    { title: "6 anos de Uso", dataIndex: ["yearSix"], key: "yearSix" },
    { title: "5 anos de Uso", dataIndex: ["yearFive"], key: "yearFive" },
    { title: "4 anos de Uso", dataIndex: ["yearFour"], key: "yearFour" },
    { title: "3 anos de Uso", dataIndex: ["yearThree"], key: "yearThree" },
    { title: "2 anos de Uso", dataIndex: ["yearTwo"], key: "yearTwo" },
    { title: "1 anos de Uso", dataIndex: ["yearOne"], key: "yearOne" }
  ];

  const fieldsValue = {
    vehicle: fieldsVehicleDriver,
    driver: fieldsVehicleDriver,
    composition: fieldsComposition
  };

  const getEmployee = (date, employeeList, vehicles) => {
    let list = employeeList.filter(
      (e) =>
        vehicles.includes(e.vehicleId) &&
        moment(date).isAfter(moment(e.driver.linkedAt)) &&
        (!e.driver.unlinkedAt ||
          moment(date).isBefore(moment(e.driver.unlinkedAt)))
    );
    return list.length > 0 ? list[0] : null;
  };

  const isDriverAccount = (account, employees, listEmployees, allVehicles) => {
    var vehicles = listEmployees
      .filter((l) => employees.includes(l.driver.id))
      .map((l) => l.vehicleId);
    return allVehicles
      .filter((v) => vehicles.includes(v.id))
      .filter((v) => v.account == account).length;
  };

  const transactionDriver = (transaction, employees, listEmployee) => {
    const driversFilter = listEmployee.filter((l) => {
      return (
        employees.includes(l.driver.id) &&
        moment(transaction.date).isAfter(moment(l.driver.linkedAt)) &&
        (!l.driver.unlinkedAt ||
          moment(transaction.date).isBefore(moment(l.driver.unlinkedAt)))
      );
    });

    return driversFilter;
  };

  const getvehiclesFilter = (vehicles) => {
    return vehicles.filter((v) => filter.includes(v.id));
  };

  const getDataVehicle = async () => {
    const vehicleData = await getAllVehicles();
    return await Promise.all([
      getCompany(login.company.id),
      getEmployeeVehicle()
    ]).then(async (values) => {
      const statement = await getStatement(
        null,
        values[0],
        1,
        dates[0].format("YYYY-MM-DDTHH:mm:ss"),
        dates[1].format("YYYY-MM-DDTHH:mm:ss")
      );
      let statementAux = [...statement];
      statementAux = statementAux
        .filter(
          (t) =>
            t.card &&
            getvehiclesFilter(vehicleData).filter((v) => v.account == t.account)
              .length > 0
        )
        .map((transaction) => {
          const employee = getEmployee(transaction.date, values[1], filter);
          const expense =
            transaction.expenses && transaction.expenses.length > 0
              ? transaction.expenses[0]
              : {};
          return {
            employee: {
              name: employee ? employee.driver.name : "",
              id: employee ? employee.driver.id : ""
            },
            vehicle: vehicleData.filter(
              (v) => v.account == transaction.account
            )[0],
            firstExpense: expense,
            ...transaction
          };
        });
      return statementAux;
    });
  };

  const getDataDriver = async () => {
    return await Promise.all([
      getCompany(login.company.id),
      getAllVehicles(),
      getEmployeeVehicle()
    ]).then(async (values) => {
      const statementData = await getStatement(
        null,
        values[0],
        1,
        dates[0].format("YYYY-MM-DDTHH:mm:ss"),
        moment(dates[1]).endOf("day").format("YYYY-MM-DDTHH:mm:ss")
      );
      let statementAux = [...statementData];
      statementAux = statementAux
        .filter(
          (t) =>
            t.card &&
            isDriverAccount(
              t.account,
              Object.values(filter),
              values[2],
              values[1]
            ) &&
            transactionDriver(t, Object.values(filter), values[2]).length > 0
        )
        .map((transaction) => {
          const employee = transactionDriver(
            transaction,
            Object.values(filter),
            values[2]
          )[0].driver;
          const expense =
            transaction.expenses && transaction.expenses.length > 0
              ? transaction.expenses[0]
              : {};
          return {
            employee: {
              name: employee ? employee.name : "",
              id: employee ? employee.id : ""
            },
            vehicle: values[1].filter(
              (v) => v.account == transaction.account
            )[0],
            firstExpense: expense,
            ...transaction
          };
        });
      return statementAux;
    });
  };

  const getDataComposition = async () => {
    const reportData = await getReportVehicleComposition();
    return reportData;
  };

  const save = async () => {
    if (type && (disabledFilter || (filter && dates))) {
      setOpenTable(true);
      loadingTable();
      setData((x) => ({ ...x, loading: true }));
      let _data = [];
      if (type == "vehicle") {
        _data = await getDataVehicle();
      } else if (type == "driver") {
        _data = await getDataDriver();
      } else if (type == "composition") {
        _data = await getDataComposition();
      }
      setData((x) => ({ data: _data, loading: false }));
    } else {
      setOpenTable(false);
      notification.error({
        message: "Preencha os campos de filtro"
      });
    }
  };

  const loadingTable = () => {
    setFields(fieldsValue[type]);
  };

  const getFilter = async () => {
    setFilterLoading(true);

    if (type == "vehicle") {
      const vehicles = await getAllVehicles();
      setFilterValues(
        vehicles.map((v) => {
          return { value: v.id, label: v.licensePlate + " : " + v.name };
        })
      );
    } else if (type == "driver") {
      const res = await getEmployeeVehicle();
      const drivers = res
        .map((d) => {
          return {
            value: d.driver.id,
            label: d.driver.name
          };
        })
        .filter(
          (obj1, i, arr) =>
            arr.findIndex((obj2) => obj2.value === obj1.value) === i
        );
      setFilterValues(drivers);
    }

    setFilterLoading(false);
  };

  const getValue = (index, data) => {
    if (index.length == 1) return data[index[0]];
    else {
      let newIndex = index[0];
      index.shift();
      return getValue(index, data[newIndex]);
    }
  };

  const format = (value, render, key, data) => {
    if (key == "date") {
      return formatDate(value, "DD/MM/YYYY HH:mm");
    } else if (key == "value") {
      return (data.reversal ? "+" : "-") + value;
    } else if (render) {
      return render(value, data);
    } else {
      return value;
    }
  };

  const createExpenseReportFile = () => {
    const columns = data.data.map((d) => {
      return fields.reduce(
        (a, v) => ({
          ...a,
          [v.title]: format(getValue([...v.dataIndex], d), v.render, v.key, d)
        }),
        {}
      );
    });
    exportXls().createAndSaveFile(
      ["Relatório"],
      { Relatório: columns },
      "Relatório de Veiculo",
      "Relatório de Veiculo"
    );
  };

  const onExportReport = () => {
    createExpenseReportFile();
  };

  const disabledDate = (current, { from }) => {
    if (from) {
      return current.diff(from, "days") >= 30;
    }

    return false;
  };

  const handleSelectAllClick = () => {
    setFilter(filterValues.map((f) => f.value));
  };

  React.useEffect(() => {
    if (type) {
      getFilter();
    }
  }, [type]);

  return (
    <Layout className="alymente-layout">
      <Header title="Relatórios" />
      <Content>
        <Row gutter={16}>
          <Col lg={7}>
            <Select
              placeholder="Selecione um tipo"
              onChange={(value) => {
                setFilter([]);
                setDates(null);
                setType(value);
                if (value == "composition") {
                  setDisabledFilter(true);
                } else {
                  setDisabledFilter(false);
                }
              }}
              style={{ width: "100%" }}
              value={type}
            >
              <Select.Option value="vehicle">Veículo</Select.Option>
              <Select.Option value="driver">Motorista</Select.Option>
              <Select.Option value="composition">
                Composição de Frota Idade Média
              </Select.Option>
            </Select>
          </Col>
          <Col lg={7}>
            <Select
              mode="multiple"
              maxTagCount={2}
              maxTagTextLength={8}
              onChange={setFilter}
              disabled={!type || disabledFilter}
              style={{ width: "100%" }}
              value={filter}
              loading={filterLoading}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider />
                  <div>
                    <Button
                      type="primary"
                      size="small"
                      style={{ marginBottom: 5 }}
                      onClick={handleSelectAllClick}
                    >
                      Selecionar todos
                    </Button>
                  </div>
                </>
              )}
            >
              {filterValues.map((s) => {
                return <Select.Option value={s.value}>{s.label}</Select.Option>;
              })}
            </Select>
          </Col>
          <Col lg={7}>
            <DatePicker.RangePicker
              format={"DD/MM/YYYY"}
              disabled={disabledFilter}
              disabledDate={disabledDate}
              style={{ width: "100%" }}
              value={dates}
              onChange={setDates}
            />
          </Col>
          <Col lg={3}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              icon={<SearchOutlined />}
              onClick={() => save()}
            ></Button>
          </Col>
        </Row>
        {openTable && (
          <Table
            scroll={{ y: 650, x: 7000 }}
            dataSource={data.data}
            columns={fields}
            loading={data.loading}
            pagination={true}
            bordered
            style={{ marginTop: 20 }}
          ></Table>
        )}
      </Content>
      <Footer>
        <Space>
          <Button
            icon={<FileExcelOutlined />}
            disabled={!openTable}
            onClick={onExportReport}
            type="primary"
          >
            Exportar Relatório
          </Button>
        </Space>
      </Footer>
    </Layout>
  );
};

export default connect(mapStateToProps, {})(VehicleReportScreen);
