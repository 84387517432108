import axios from "axios";
import {
  setAuthorization,
  removeAuthorization
} from "config/service/auth-service";
import { handlerError } from "utils";
import { getAllChildrenCompanies } from "services/company-service";
import { auth2FA } from "services/auth-service";

export const auth2FAAction = (user, code) => async (dispatch) => {
  try {
    dispatch({ type: "LOGIN_2FA" });
    const res = await auth2FA(user, code);
    setAuthorization(res.headers.authorization);
    const resChildren = await getAllChildrenCompanies();
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: { user: res.data, children: resChildren }
    });
  } catch (ex) {
    dispatch({ type: "LOGIN_2FA_FAIL", payload: handlerError(ex, false) });
  }
};

export const loginAction = (login, password) => async (dispatch) => {
  let req = {
    login: login,
    password: password,
    app: false
  };
  try {
    dispatch({ type: "LOGIN" });
    const res = await axios.post("/api/auth/login", req);
    //   setAuthorization(res.headers.authorization);
    //   const resChildren = await getAllChildrenCompanies();
    dispatch({ type: "LOGIN_SUCCESS", payload: { user: res.data } });
  } catch (ex) {
    dispatch({ type: "LOGIN_FAIL", payload: handlerError(ex, false) });
  }
};

export const loginCompanyAction = (company) => async (dispatch) => {
  let req = {
    company: company
  };
  try {
    dispatch({ type: "LOGIN" });
    const res = await axios.post("/api/auth/matriz", req);
    setAuthorization(res.headers.authorization);
    dispatch({ type: "LOGIN_SUCCESS", payload: { user: res.data } });
  } catch (ex) {
    dispatch({ type: "LOGIN_FAIL", payload: handlerError(ex, false) });
  }
};

export const forgotAction = (login, type, recaptcha) => async (dispatch) => {
  let req = {
    user: login,
    recaptcha: recaptcha
  };
  try {
    dispatch({ type: "FORGOT" });
    await axios.post("/api/auth/forgot", req);
    dispatch({
      type: "FORGOT_SUCCESS",
      payload:
        type == "email"
          ? "Enviamos um email para a recuperação da sua senha do aplicativo"
          : "Enviamos um SMS para a recuperação da sua senha do aplicativo"
    });
  } catch (ex) {
    dispatch({ type: "FORGOT_FAIL", payload: handlerError(ex, false) });
  }
};

export const getResetAction = (token) => async (dispatch) => {
  let req = {};
  try {
    const res = await axios.post("/api/auth/reset/" + token, req);
    dispatch({ type: "GET_RESET_SUCCESS", payload: res.data.data });
  } catch (ex) {
    dispatch({ type: "RESET_FAIL", payload: handlerError(ex, false) });
  }
};

export const resetAction = (token, password) => async (dispatch) => {
  let req = {
    password: password
  };
  try {
    dispatch({ type: "RESET" });
    const res = await axios.post(`/api/auth/reset/${token}/password`, req);
    dispatch({ type: "RESET_SUCCESS", payload: res.data.type });
  } catch (ex) {
    dispatch({ type: "RESET_FAIL", payload: handlerError(ex, false) });
  }
};

export const getProfileAction = () => async (dispatch) => {
  try {
    dispatch({ type: "LOGIN" });
    const res = await axios.get(`/api/employee/profile`);
    const resCompany = await axios.get(`/api/company/current`);
    const resChildren = await getAllChildrenCompanies(false);
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: {
        user: res.data,
        company: resCompany.data.data,
        children: resChildren
      }
    });
  } catch (ex) {
    dispatch({ type: "LOGIN_FAIL", payload: handlerError(ex, false) });
  }
};

export const logoutAction = () => async (dispatch) => {
  dispatch({ type: "LOGOUT" });
  removeAuthorization();
};
