import React from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Layout, Table, Button, Switch } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { getAllCompanyAdvantages, saveCompanyCatalog } from "services/company-catalog-service";
import { Header } from "components";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const DataFetch = {
    data: [],
    loading: false
}

const CompanyAdvantagesScreen = (props) => {
    const { login } = props;
    const { id = '' } = useParams();
    const location = useLocation();
    const { company = {} } = location.state || {};
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(DataFetch)

    const load = async () => {
        setData((x) => ({ ...x, loading: true }));
        const res = await getAllCompanyAdvantages(id);
        setData({ data: res, loading: false });
    }

    const onEdit = (value, index, field) => {
        const newData = [...data.data];
        newData[index][field] = value;
        setData((x) => ({ ...x, data: newData }));
    }

    const onSave = async () => {
        setLoading(true);
        await saveCompanyCatalog(id, [...data.data], true);
        await load();
        setLoading(false);
    }

    React.useEffect(() => {
        if (id.length > 0) {
            load();
        }
    }, [id])

    return (
        <Layout className="alymente-layout">
            <Header title={`Vantages da ${company.name || ""}`} />
            <Content>
                <Table scroll={{ y: 650, x: 1000 }} dataSource={data.data} loading={data.loading} pagination={false} bordered>
                    <Table.Column title="Nome" dataIndex="name" key="name" />
                    <Table.Column align="center" title="Status" dataIndex="enabled" key="enabled" width={200}
                        render={(value, record, index) => <Switch disabled={record.default} checked={value} onChange={(x) => onEdit(x, index, "enabled")} />} />
                </Table>
            </Content>
            <Footer>
                <Button loading={loading} className="btn-save" type="primary" icon={<SaveOutlined />} onClick={onSave}>Salvar</Button>
            </Footer>
        </Layout>
    )
}

export default connect(mapStateToProps, {})(CompanyAdvantagesScreen);
