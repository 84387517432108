import React from "react";
import { connect } from "react-redux";
import { useOutletContext } from 'react-router-dom';
import { Layout, Button, notification } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'

import SolicitationScreen from 'views/solicitation/solicitation-screen';

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const ActivationSolicitationScreen = (props) => {
    const { login } = props;

    return (
        <React.Fragment>
            <Content>
                <SolicitationScreen compact={true} />
            </Content>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, {})(ActivationSolicitationScreen);